import React from 'react'
import { useStore } from 'effector-react'
import { $dataGrid, dataGridIsChanged } from '$models/dataGrid'
import { deferred } from '$root/utils/functions'
import { useModals } from '$hooks/useModals'
import { ClientEditModal } from '$components/Clients/Modal/Detail/EditModal'
import { usePermissions } from '$hooks/usePermissions'
import { Routes } from '$root/utils/constants/routes'

export const PageHeader = () => {
  const dataGrid = useStore($dataGrid)
  const { openModal } = useModals()
  const { isAllow } = usePermissions()

  const deferredSearch = deferred((options) => {
    dataGridIsChanged({ search: options.search })
  })

  const onShowEditForm = () => {
    openModal('NewClient', ClientEditModal, {
      title: 'Новый клиент',
      routes: Routes.leads.clients,
    })
  }

  return (
    <div className="header__wrap">
      <label className="search">
        <input
          className="search__input"
          type="text"
          placeholder="По ФИО, телефону и номеру счета"
          defaultValue={dataGrid.search}
          onInput={(ev) => {
            deferredSearch({ search: ev.target.value }, 1.5)
          }}
        />
        <button className="search__btn">Поиск</button>
      </label>
    </div>
  )
}
