import { useEffect, useState } from 'react'
import clsx from 'clsx'
import { Input } from '$components/UI/Inputs/Input'
import { useForm } from '@inertiajs/react'
import {
  useFormValidation,
  useNewFormValidation,
} from '$hooks/useFormValidation'
import { router } from '@inertiajs/react'
import { Routes } from '$root/utils/constants/routes'
import { getExtraCostCity } from '$lib/deliveries/settings/helpers'
import { verboseCurrency } from '$root/utils/functions'

export const DeliveryExtraCost = ({ title, data, userCityID }) => {
  const [show, setOpen] = useState()
  const [isChanged, setChanged] = useState(false)
  const form = useForm(data)
  const { data: formData, setData, errors, setError, clearErrors } = form

  const onCheckIsValid = () => {
    const validationErrors = {}
    if (!formData.price) {
      validationErrors['price'] = 'Обязательное поле'
    }
    return validationErrors
  }

  const [validate, markErrors, clearSubmit] = useNewFormValidation({
    persistKey: `delivery-extra-cost-${title}`,
    onCheckIsValid,
    form,
  })

  const onSubmit = (e) => {
    e.preventDefault()

    if (validate()) {
      const city_id = getExtraCostCity(userCityID)

      router.post(
        route(Routes.settings.delivery_zones.extra_charge),
        {
          id: data.id,
          delivery_type: data.delivery_type,
          city_id,
          price: formData.price || 0,
        },
        {
          onSuccess() {
            setOpen(false)
          },
        },
      )
    }
  }

  return (
    <div
      className={clsx('accordion-district__item', {
        open: show,
      })}
    >
      <button
        className="accordion-district__btn"
        onClick={() => setOpen(!show)}
      >
        <span className="accordion-district__type">{title}</span>
        <span className="accordion-district__inner">
          <span className="accordion-district__increase">
            {formData.price && formData.price > 0 ? '+' : ''}
            {(formData.price && verboseCurrency(formData.price)) ||
              formData.price ||
              '0 ₽'}
          </span>
        </span>
      </button>
      <div className="accordion-district__body">
        <Input
          value={formData.price}
          placeholder="Введите сумму"
          onChange={(ev) => {
            setChanged(true)
            setData('price', ev.target.value)
          }}
          error={markErrors && !!errors.price}
        />
        <button
          disabled={!isChanged}
          className="btn btn__filled btn--blue"
          onClick={onSubmit}
        >
          Сохранить
        </button>
      </div>
    </div>
  )
}
