import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'

const data = [
  {
    name: '10:00',
    Расход: 4000,
    Приход: 2400,
    'Прошлый период': 2400,
  },
  {
    name: '12:00',
    Расход: 3000,
    Приход: 1398,
    'Прошлый период': 2210,
  },
  {
    name: '14:00',
    Расход: 2000,
    Приход: 5000,
    'Прошлый период': 2290,
  },
  {
    name: '16:00',
    Расход: 2780,
    Приход: 3908,
    'Прошлый период': 2000,
  },
  {
    name: '18:00',
    Расход: 1890,
    Приход: 4800,
    'Прошлый период': 2181,
  },
  {
    name: '20:00',
    Расход: 2390,
    Приход: 3800,
    'Прошлый период': 2500,
  },

  {
    name: '21:00',
    Расход: 2390,
    Приход: 3800,
    'Прошлый период': 2500,
  },
  {
    name: '22:00',
    Расход: 2390,
    Приход: 3800,
    'Прошлый период': 2500,
  },
  {
    name: '23:00',
    Расход: 2390,
    Приход: 3800,
    'Прошлый период': 2500,
  },
  {
    name: '24:00',
    Расход: 2390,
    Приход: 3800,
    'Прошлый период': 2500,
  },
  {
    name: '25:00',
    Расход: 2390,
    Приход: 3800,
    'Прошлый период': 2500,
  },
  {
    name: '26:00',
    Расход: 2390,
    Приход: 3800,
    'Прошлый период': 2500,
  },
  {
    name: '27:00',
    Расход: 2390,
    Приход: 3800,
    'Прошлый период': 2500,
  },
  {
    name: '28:00',
    Расход: 2390,
    Приход: 3800,
    'Прошлый период': 2500,
  },
]

export const DashboardChart = () => {
  return (
    <div className="reports-company__col reports-company__col--two-thirds">
      <div className="reports-company__graph">
        <p className="reports-company__title">Расходы компании</p>
        <ResponsiveContainer width="100%" height={600}>
          <AreaChart
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" domain={['dataMin', 'dataMax']} />
            <YAxis />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="Расход"
              stackId="1"
              stroke="#F1523E"
              fill="#FEEEEC"
            />
            <Area
              type="monotone"
              dataKey="Приход"
              stackId="2"
              stroke="#14B632"
              fill="#E8F8EB"
            />
            <Area
              type="monotone"
              dataKey="Прошлый период"
              stackId="3"
              stroke="#FAD704"
              fill="#FFFBE6"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
