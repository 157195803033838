import React, { useEffect } from 'react'
import { Head, router, usePage } from '@inertiajs/react'
import clsx from 'clsx'
import { CurrentUser } from '$components/UI/Sidebar/CurrentUser'
import { Menu } from '$components/UI/Sidebar/Menu/Menu'
import { Loader } from '$components/UI/Loader'
import { Logo } from '$components/UI/Sidebar/Logo'
import { useStore } from 'effector-react'
import { useLoader } from '$hooks/useLoader'
import { $menuIsShort } from '$models/menu'
import { useModals } from '$hooks/useModals'
import { onCopyPast, scrollTop } from '$root/utils/functions'
import { usePageErrors } from '$hooks/usePageErrors'
import { useGlobalStore } from '$lib/page/provider'
import { observer } from 'mobx-react'
import {
  ClientsSectionRouteRegExp,
  DeliveriesSectionRouteRegExp,
  KitchenSectionRouteRegExp,
  LeadsSectionRouteRegExp,
  ReportsSectionRouteRegExp,
  SettingsSectionRouteRegExp,
} from '$root/config/root-routes'
import { resetDatagrid } from '$models/dataGrid'

// TODO: вывод окна ошибок при существующем поле errors в pageProps
export const MainLayout = observer(({ children, PageHeader }) => {
  usePageErrors()
  const props = usePage().props
  const { menu, user, title } = props
  const { loading, showLoader, hideLoader } = useLoader()
  const menuIsShort = useStore($menuIsShort)
  const { Modals } = useModals()
  const { pageSelfCleans } = useGlobalStore()

  useEffect(() => {
    router.on('start', showLoader)
    router.on('finish', hideLoader)
    router.on('navigate', () => {
      pageSelfCleans.setCurrentRouteHref(location.pathname)
      scrollTop()
    })
    onCopyPast()
  }, [])

  useEffect(() => {
    if (pageSelfCleans.currentRouteHref) {
      pageSelfCleans.handleChangeHref(pageSelfCleans.currentRouteHref)
    }
  }, [pageSelfCleans.currentRouteHref])

  useEffect(() => {
    if (import.meta.env.MODE !== 'production') {
    }
  }, [props])

  useEffect(() => {
    pageSelfCleans.addCleaningPages(
      [
        LeadsSectionRouteRegExp,
        ClientsSectionRouteRegExp,
        DeliveriesSectionRouteRegExp,
        KitchenSectionRouteRegExp,
        ReportsSectionRouteRegExp,
        SettingsSectionRouteRegExp,
      ],
      () => {
        resetDatagrid()
      },
    )
  }, [pageSelfCleans.currentRouteHref])

  return (
    <>
      {title && (
        <Head>
          <title>{title}</title>
        </Head>
      )}
      <div className="wrapper">
        <aside className={clsx('sidebar', { 'short-sidebar': menuIsShort })}>
          <Logo />
          <CurrentUser user={user} />
          <Menu className="menu" menu={menu.items || null} />
        </aside>
        <div className="content">
          <header className="header">
            <h1 className="header__title">{title && title}</h1>
            {PageHeader && <PageHeader />}
          </header>

          <main className="main">
            {children}
            <Modals />
          </main>
        </div>
      </div>

      {loading && <Loader />}
    </>
  )
})
