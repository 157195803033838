import { usePage } from '@inertiajs/react'
import { getActiveTabCode } from '$lib/leads/statused/public/helpers'
import { TabsGridColumns } from '$lib/leads/statused/public/const'

export const Columns = () => {
  const { enums: { tabs = [] } = {} } = usePage().props

  const activeTabCode = getActiveTabCode(tabs)
  const GridColumnsComponent =
    (activeTabCode && TabsGridColumns[activeTabCode]) || (() => <></>)
  return <GridColumnsComponent />
}
