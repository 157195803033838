import { useEffectorDomain } from '$hooks/useEffectorDomain'
import { StaffTransactionEffector } from '$lib/staff/effector/staff-transaction'
import { useForm, usePage } from '@inertiajs/react'
import { DropdownBtn } from '$components/UI/Inputs/DropdownBtn/DropdownBtn'
import { InputSelectActiveItem } from '$components/UI/Inputs/InputSelect/InputSelectActiveItem'
import { useEffect } from 'react'
import { Input } from '$components/UI/Inputs/Input'
import { TextArea } from '$components/UI/Inputs/TextArea'
import clsx from 'clsx'
import { DatePickerInput } from '$components/UI/Inputs/DatePickerInput'
import { useDateTime } from '$hooks/useDateTime'
import { Label } from '$components/UI/Inputs/Label'

export const StaffTransactionsDropdown = ({
  user,
  getFormDataCallback,
  transaction_types,
  onChange,
  transaction_currencies = {},
  disabled = false,
  customTable = null,
}) => {
  if (!user) return <></>
  const { dateVerbosed } = useDateTime()

  const { domain, service: staffService } = useEffectorDomain(
    new StaffTransactionEffector({
      user,
      transaction_types,
      transaction_currencies,
      onChange,
    }),
  )

  const payroll = staffService.usePayrollStore()
  const currency = staffService.useCurrencyStore()
  const transactionType = staffService.useTransactionTypeStore()
  const count = staffService.useCountStore()
  const additionalAmount = staffService.useAdditionalAmountStore()
  const currentComment = staffService.useCommentStore()
  const date = staffService.useDateStore()
  const roleTitle = staffService.getRoleTitle()

  const { isOpen, setOpen } = staffService.useToggle()

  const { data, setData } = useForm({
    payroll,
    currency,
    transaction_type: transactionType,
    comment: currentComment,
  })

  useEffect(() => {
    setData({ payroll, currency, transaction_type: transactionType })
  }, [payroll, currency, transactionType])

  useEffect(() => {
    if (getFormDataCallback) {
      getFormDataCallback(() => data)
    }
  }, [data])

  useEffect(() => {
    return () => {
      domain.remove()
    }
  }, [])
  //TODO: user.role_name должность сотрудника для выплаты ЗП

  return (
    <div className={clsx('payroll', { open: isOpen })}>
      <button className="payroll__btn" onClick={() => setOpen(!isOpen)}>
        <div className="payroll__name">
          {user.full_name ||
            `${user.last_name} ${user.name} ${user.second_name}`}
        </div>
        <div className="payroll__info">
          <div className="payroll__info-left">
            {roleTitle && <span>{roleTitle}</span>}
            <span className="payroll__money">{payroll || '-'} ₽</span>
          </div>

          <div className="payroll__info-right">
            {dateVerbosed(new Date(date))}
          </div>
        </div>
      </button>
      <div className="payroll__body">
        <div className="payroll__row">
          <div className="date-picker">
            <Label text="Дата"></Label>
            <DatePickerInput
              value={date}
              onChange={(date) => staffService.changeDate(date)}
              showPickerTag={false}
            />
          </div>
        </div>
        <div className="payroll__row">{customTable && customTable()}</div>
        <div className="payroll__row">
          <div className="input-select js-dropdown">
            <Input
              type="number"
              classes="w-100"
              placeholder="Кол-во"
              value={count}
              onChange={(ev) => staffService.changeCount(ev.target.value)}
            />
            <DropdownBtn
              disabled={disabled}
              blockClasses={{
                wrapper: 'input-select__dropdown',
                current: 'input-select__dropdown-btn js-dropdown-btn',
                list: 'input-select__dropdown-list',
                item: 'input-select__dropdown-item',
              }}
              needEmpty={false}
              items={{ [currency.id]: currency }}
              titleKey={(item) => {
                return (
                  (item.value &&
                    `${item.value.amount} ${item.value.currency_title}`) ||
                  item.currency_title
                )
              }}
              valueKey="key"
              ActiveItemComponent={InputSelectActiveItem}
              onChange={(selected) => staffService.changeCurrency(selected)}
            />
          </div>
        </div>
        <div className="payroll__row payroll__row--free">
          <label className="input input--grow">
            <Input
              type="number"
              placeholder="Сумма"
              value={additionalAmount}
              onChange={(ev) =>
                staffService.changeAdditionalAmount(ev.target.value)
              }
            />
          </label>
          <DropdownBtn
            blockClasses={{
              wrapper:
                'dropdown-multiple dropdown-multiple--no-check js-dropdown',
              current: 'dropdown-multiple__btn js-dropdown-btn',
              list: 'dropdown-multiple__list',
              item: 'dropdown-multiple__list-item',
            }}
            needEmpty={false}
            appendFirst={true}
            items={transaction_types}
            titleKey={(item) => (item.value && item.value.title) || item.title}
            valueKey="key"
            onChange={(selected) =>
              staffService.changeTransactionType(selected)
            }
          />
        </div>
        <div className="payroll__row payroll__row--offset">
          <TextArea
            name=""
            id=""
            cols="30"
            rows="4"
            placeholder="Обоснование"
            value={currentComment}
            onChange={(comment) => staffService.changeComment(comment)}
          />
        </div>
      </div>
    </div>
  )
}
