import {
  $client,
  $clientRoutes,
  $orders,
  ordersTableApi,
} from '$lib/orders/models/orders-store'
import { router } from '@inertiajs/react'
import { onDeleteSended, onPostSended } from '$models/page'
import { useStore } from 'effector-react'
import { OrderStatus } from '$lib/orders/utils/order-status-const'
import { uid } from '$root/utils/functions'
import { useModals } from '$hooks/useModals'
import { useDateTime } from '$hooks/useDateTime'
import {
  doneOrderNotChangeableErrorMessage,
  hasDoneOrders,
} from '$lib/orders/helpers/table-functions'

export const useOrdersTable = () => {
  // TODO: Рефактор
  const { openModal } = useModals()
  const { dateToSaveFormat } = useDateTime()
  let client = useStore($client)
  let clientRoutes = useStore($clientRoutes)

  /**
   * Отправляет запрос на сохранение/создание доставки
   * @param order {Object | Array[Object]}
   * */
  const sendSaveOrder = (order) => {
    const orders = _.castArray(order).map((order) => ({
      ...order,
      client_id: client.id,
    }))
    // TODO: api...
    router.post(
      route(clientRoutes.orders.update, route().params),
      { orders },
      {
        onSuccess() {
          onPostSended({ action: 'order updated', id: order.id })
        },
      },
    )
  }

  /**
   * Отправляет запрос на удаление существующей доставки из БД
   * @param order {Object}
   * */
  const sendDeleteOrder = (order) => {
    // TODO: api...
    router.delete(route(clientRoutes.orders.delete, route().params), {
      data: { id: order.id },
      onSuccess() {
        onDeleteSended()
      },
    })
  }

  /**
   * Формирует доставку копированием или от базовых параметров доставки клиента
   * @param order {Object | undefined}
   * */
  const buildNewOrder = ({ order } = {}) => {
    const status = OrderStatus.Inactive

    if (order) {
      order._id = order.id
      delete order['id']
    }

    return {
      ...((order && order) || { status: OrderStatus.Inactive }),
      status,
      uid: uid(),
    }
  }

  const updateOrder = ({
    order,
    updatingOrders,
    isNeedUpdateStore = false,
    isNeedRequest = true,
  }) => {
    /**
     * Обновление доставок
     * @param order {Object | undefined} - существующая доставка при обновлении только одного элемента
     * @param updatingOrders {Object | Array[Object]} - одна или несколько измененных/новых элементов
     * @param isNeedRequest {boolean} - Отправлять запрос при изменении
     * */
    updatingOrders = normalizeUpdatingOrders(updatingOrders)

    if (validateUpdating(updatingOrders)) {
      if (isNeedUpdateStore) {
        ordersTableApi.updateTable({ order, updatingOrders })
      }

      if (isNeedRequest) {
        sendSaveOrder(updatingOrders)
      }
    }
  }

  /**
   * Удаляет существующую доставку из таблицы и отправляет запрос в БД
   * @param order {Object}
   * */
  const deleteOrder = ({ order }) => {
    const deletingOrders = normalizeUpdatingOrders(order)
    if (validateDeleting(deletingOrders)) {
      ordersTableApi.deleteFromTable({ order })
      sendDeleteOrder(order)
    }
  }

  const normalizeUpdatingOrders = (orders) => {
    return _.castArray(orders).map((order) => {
      if (!!order.delivery_date && !order.delivery_dates) {
        order.delivery_dates = [order.delivery_date]
      } else if (!order.delivery_date && !order.delivery_dates) {
        order.delivery_dates = null
      }
      return order
    })
  }

  const validateUpdating = (orders) => {
    const hasDoneOrder = hasDoneOrders(orders)

    if (hasDoneOrder) {
      openModal.apply(
        this,
        doneOrderNotChangeableErrorMessage(
          'Нельзя изменить завершенную доставку',
        ),
      )
    }

    return !hasDoneOrder
  }

  const validateDeleting = (orders) => {
    const hasDoneOrder = hasDoneOrders(orders)
    if (hasDoneOrder) {
      openModal.apply(
        this,
        doneOrderNotChangeableErrorMessage(
          'Нельзя удалять завершенные доставки',
        ),
      )
    }
    return !hasDoneOrder
  }

  return {
    init: ({ client, orders, isPickUp = false }) => {
      const normalizeOrdersDates = (orders_) => {
        const normalizedOrders = {}

        const normalizeFn = (order) => {
          order.delivery_date = order.delivery_date
            ? dateToSaveFormat(new Date(order.delivery_date))
            : null
          return order
        }

        if (!isPickUp) {
          for (const status in orders_) {
            normalizedOrders[status] = orders_[status].map(normalizeFn)
          }
        } else {
          normalizedOrders[OrderStatus.Active] = orders_.map(normalizeFn)
        }

        return normalizedOrders
      }

      ordersTableApi.init({ client, orders: normalizeOrdersDates(orders) })
    },
    buildNewOrder,
    newOrder: ({ order } = {}) => {
      const newOrders = _.castArray(order).map((orderItem) => {
        return buildNewOrder({ order: orderItem })
      })

      updateOrder({ updatingOrders: newOrders })
    },
    updateOrder,
    deleteOrder,
  }
}
