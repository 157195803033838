import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { uid } from '$root/utils/functions'

export const Input = ({
  type = 'text',
  placeholder,
  disabled,
  classes,
  value,
  onChange,
  error,
  ...other
}) => {
  const inputRef = useRef(null)

  useEffect(() => {
    if (type == 'number') {
      const ignoreScroll = (e) => {
        e.preventDefault()
      }
      inputRef.current &&
        inputRef.current.addEventListener('wheel', ignoreScroll)
    }
  }, [inputRef])

  return (
    // TODO: во всех местах classes -> className
    <label className={clsx('input', classes)}>
      <input
        ref={inputRef}
        // onFocus={disableWheel}
        type={type}
        placeholder={placeholder}
        value={(value && value) || ''}
        onInput={onChange}
        disabled={disabled}
        className={clsx({
          error,
        })}
        {...other}
      />
    </label>
  )
}
