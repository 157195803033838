import React from 'react'
import { useModals } from '$hooks/useModals'
import { AddRoleForm } from '$components/Settings/Permissions/AddRoleForm'
import { Modal } from '$components/UI/Modals/Modal'

export const AddRole = () => {
  const { openModal } = useModals()

  const onClickButton = () => {
    openModal('NewRole', Modal, {
      title: 'Добавить должность',
      contentComponent: AddRoleForm,
    })
  }

  return (
    <div className="second-head__inner">
      <button className="header__btn-add" onClick={onClickButton}>
        Добавить должность
      </button>
    </div>
  )
}
