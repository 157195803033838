export const InvoiceStatus = {
  Pending: 'pending',
  Paid: 'paid',
  Canceled: 'canceled',
}

export const InvoicePayableType = {
  Order: 'order',
  Client: 'client',
}

export const InvoicePayableTypePrefix = {
  [InvoicePayableType.Order]: 'Счет заказа ',
  [InvoicePayableType.Client]: 'Счет клиента ',
}

export const ClientPaymentType = {
  Prepayment: 'prepayment',
  Postpaid: 'postpaid',
}
