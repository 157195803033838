import React, { useCallback } from 'react'
import { useModals } from '$hooks/useModals'
import { Modal } from '$components/UI/Modals/Modal'
import { PickAddress } from '$components/Clients/Modal/PickAddress'
import { ModalSize } from '$root/utils/constants/modals'
import { TextArea } from '$components/UI/Inputs/TextArea'
import iconPlacemark from '~svg/icons/icon-placemark.svg'
import {
  createZoneInstances,
  distanceFromCoordinates,
  isCoordinatesInsidePolygon,
  reversedPoint,
} from '$lib/osm/functions'
import geometryUtil from 'leaflet-geometryutil'

export const AddressesWithMap = ({
  city = null,
  zones = [],
  addresses = [],
  placeholder = 'Адрес',
  addLabel = 'Добавить адрес',
  classes = [],
  addBtnClassName = 'form__address',
  onChange,
}) => {
  const { openModal } = useModals()

  const emptyAddress = { address: '', point: null }

  const removeAddress = useCallback(
    (removingAddress) => {
      const updatedAddress = [...addresses]

      if (removingAddress.id) {
        const index = updatedAddress.findIndex(
          (address) => address.id === removingAddress.id,
        )
        if (index > -1) {
          updatedAddress.splice(index, 1)
        }
      }

      if (onChange) {
        onChange(updatedAddress)
      }
    },
    [addresses],
  )

  const showAddressPicker = useCallback(
    (address = null) => {
      openModal('AddressPicker', Modal, {
        title: 'Выберите адрес с помощью карты',
        size: ModalSize.ExtraLarge,
        classes: { content: 'w-100 h-100' },
        showOnMount: true,
        contentComponent: PickAddress,
        address: { ...address, point: reversedPoint(address.point) },
        city,
        zones,
        onChoice(map, choiceAddress) {
          const point = choiceAddress.point

          if (zones.length) {
            const layers = []

            map.eachLayer((layer) => {
              if (layer.toGeoJSON) {
                const { geometry: { type } = {} } = layer.toGeoJSON()
                if (type === 'Polygon') {
                  layers.push(layer)
                }
              }
            })

            let zoneisContains = false
            const deliveryZonePolygons = createZoneInstances(zones)
            for (const deliveryZonePolygon of deliveryZonePolygons) {
              if (
                isCoordinatesInsidePolygon(
                  point.geometry.coordinates,
                  deliveryZonePolygon,
                )
              ) {
                choiceAddress.zone_id = deliveryZonePolygon.properties.id
                choiceAddress.zone_name = deliveryZonePolygon.properties.name
                zoneisContains = true
                break
              }
            }
            if (!zoneisContains) {
              choiceAddress.zone_id = null
              choiceAddress.zone_name = null

              const latLng = L.latLng(
                point.geometry.coordinates[0],
                point.geometry.coordinates[1],
              )

              let closestLayer = geometryUtil.closestLayer(map, layers, latLng)
              if (closestLayer && closestLayer.layer) {
                closestLayer = closestLayer.layer
                const closestPoint = geometryUtil.closest(
                  map,
                  closestLayer,
                  latLng,
                )
                const distance = distanceFromCoordinates(
                  latLng.lat,
                  latLng.lng,
                  closestPoint.lat,
                  closestPoint.lng,
                )
                if (distance < 150) {
                  choiceAddress.zone_id = closestLayer.properties.id
                  choiceAddress.zone_name = closestLayer.properties.name
                }
              }
            }
          }

          const updatedAddress = [...addresses]

          if (choiceAddress.id) {
            const index = updatedAddress.findIndex(
              (address) => address.id === choiceAddress.id,
            )
            if (index > -1) {
              updatedAddress.splice(index, 1, choiceAddress)
            }
          } else {
            updatedAddress.push({
              ...choiceAddress,
              id: `*${updatedAddress.length + 1}*`,
            })
          }

          if (onChange) {
            onChange(updatedAddress)
          }
        },
      })
    },
    [city, addresses],
  )

  return (
    <>
      {addresses.length > 0 &&
        addresses.map((address, i) => (
          <div className="form__row" key={i}>
            <div
              className="client-address"
              onClick={() => showAddressPicker(address)}
            >
              <TextArea
                placeholder={placeholder}
                className={classes}
                wrapperClassName={'no-margin'}
                value={address.address}
                disabled
              />
              <img
                src={iconPlacemark}
                alt="Посмотреть/изменить"
                title="Посмотреть/изменить"
              />
            </div>
            {address.zone_id && (
              <div className="client-address-zone">
                <a href="javascript:void(0)" title="Зона доставки">
                  {address.zone_name}
                </a>
              </div>
            )}
            <div
              className="client-address-remove"
              onClick={() => removeAddress(address)}
            >
              Удалить
            </div>
          </div>
        ))}
      <div className="form__row">
        <button
          className={addBtnClassName}
          onClick={() => showAddressPicker(emptyAddress)}
        >
          {addLabel}
        </button>
      </div>
    </>
  )
}
