import { dataGridInitialized } from '$models/dataGrid'
import { useEffect } from 'react'

export const useDataGrid = (data, route) => {
  useEffect(() => {
    if (data) {
      dataGridInitialized({ ...data, route })
    }
  }, [data])
}
