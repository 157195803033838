import { useForm } from '@inertiajs/react'

import { Routes } from '$root/utils/constants/routes'
import { useFormValidation } from '$hooks/useFormValidation'
import { useModals } from '$hooks/useModals'

import { Modal } from '$components/UI/Modals/Modal'
import { Input } from '$components/UI/Inputs/Input'

export const CreateUpdateProductModal = (props) => {
  const { product = null } = props
  const { closeModal } = useModals()
  const { data, setData, post, errors, setError, clearErrors } = useForm(
    (product && product) || {
      name: null,
      price: null,
      multiplier_id: null,
    },
  )

  const onCheckIsValid = () => {
    let isValid = true

    if (!data.name) {
      setError('name', 'Название - обязательное поле')
      isValid = false
    }

    return isValid
  }

  const [validate, markErrors] = useFormValidation(
    `update-product-${(product && product.id) || 'new'}`,
    onCheckIsValid,
    data,
    errors,
    clearErrors,
  )

  const onSubmit = (e) => {
    e.preventDefault()

    if (validate()) {
      post(route(Routes.settings.leads.rations.new_product), {
        onSuccess() {
          closeModal()
        },
      })
    }
  }

  return (
    <Modal {...props}>
      <>
        <div className="modal__row">
          <Input
            placeholder="Название"
            classes="mb-1"
            value={data.name}
            onChange={(ev) => setData('name', ev.target.value)}
            error={markErrors && !!errors && errors.name}
          />
        </div>
        <div className="modal__row">
          <button className="btn btn__filled btn--green" onClick={onSubmit}>
            Сохранить
          </button>
        </div>
      </>
    </Modal>
  )
}
