import { EffectorContext } from '$root/utils/effector/effector-services'
import { useContext } from 'react'

const InvoicesModalHeader = ({ title = 'Счета' }) => {
  const services = useContext(EffectorContext)

  const { invoicesService } = services

  const newInvoice = invoicesService.newInvoice.bind(invoicesService)

  return (
    <div className="invoices-modal__header">
      <div className="modal-right__header-top">
        <p className="modal-right__header-name">{title}</p>
        <div className="modal-right__header-inner">
          <button className="modal-right__btn-report" onClick={newInvoice}>
            Новый счет
          </button>
        </div>
      </div>
    </div>
  )
}

export default InvoicesModalHeader
