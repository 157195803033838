import { useDropzone } from 'react-dropzone'
import { useEffect, useMemo, useState } from 'react'
import { router } from '@inertiajs/react'
import { Routes } from '$root/utils/constants/routes'
import { TagsDropdown } from '$components/UI/Inputs/TagsDropdown'
import { usePage } from '@inertiajs/react'
import { useModals } from '$hooks/useModals'

const baseStyle = {
  cursor: 'pointer',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  marginBottom: '10px',
}

const focusedStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

function StyledDropzone(props) {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
      ],
    },
    ...props,
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  )

  useEffect(() => {
    if (props.onChangeFiles) {
      props.onChangeFiles(acceptedFiles)
    }
  }, [acceptedFiles])

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p style={{ margin: 'unset' }}>
          Перетащите файл или нажмите на эту область для выбора файла с диска.
        </p>
      </div>
    </div>
  )
}

export const ImportClients = () => {
  const { closeModal } = useModals()

  const { enums } = usePage().props
  const [city, setCity] = useState()
  const [acceptedFiles, setFiles] = useState([])

  const files = acceptedFiles.map((file) => (
    <p key={file.path} style={{ textAlign: 'right' }}>
      {file.path} - {(file.size / 1024 / 1024).toFixed(2)} Мб
    </p>
  ))

  const onChangeFiles = (files) => {
    setFiles(files)
  }

  const uploadFile = () => {
    if (city && acceptedFiles.length > 0) {
      router.post(
        route(Routes.settings.leads.clients.import),
        {
          file: acceptedFiles[0],
          city_id: city.id,
        },
        {
          async onSuccess() {
            await closeModal()
          },
        },
      )
    } else {
      alert('Нужно прикрепить файл и выбрать город')
    }
  }

  return (
    <section className="dropzone">
      <div className="mb-1">
        <TagsDropdown
          variants={enums.cities || []}
          titleKey="name"
          multiple={false}
          onChange={(selectedCities) => {
            const city = _.first(selectedCities)
            setCity(city)
          }}
          needEmpty={true}
        >
          {(activeCities) =>
            activeCities.map((activeCity, i) => (
              <TagsDropdown.SearcheableActiveItem
                tag={activeCity}
                key={i}
                style={{ cursor: 'pointer' }}
              >
                <TagsDropdown.SearcheableList key={i} />
              </TagsDropdown.SearcheableActiveItem>
            ))
          }
        </TagsDropdown>
      </div>

      <StyledDropzone onChangeFiles={onChangeFiles} maxFiles={1} />
      <aside>
        {files}
        <button className="deal-head__btn-import" onClick={uploadFile}>
          Импортировать
        </button>
      </aside>
    </section>
  )
}
