import icon from '~svg/icons/icon-lg-promotions.svg'

export const SalesPane = ({ amount }) => {
  return (
    <div className="color-panel color-panel--yellow">
      <img className="color-panel__icon" src={icon} alt="Акции" title="Акции" />
      <p className="color-panel__title">Акции</p>
      <p className="color-panel__descr">
        Ведение рекламы в социальных сетях и мессенджерах
      </p>
      <p className="color-panel__value">{amount || '-'}</p>
    </div>
  )
}
