import React, { useEffect } from 'react'
import { Map } from '$components/Deliveries/DistributionDeliveries/Map'
import { SpecificChild } from '$components/UI/React/SpecificChild'
import { DeliveryZones } from '$components/Deliveries/DistributionDeliveries/DeliveryZones'
import { DataGrid } from '$components/Deliveries/DistributionDeliveries/DataGrid/DataGrid'
import {
  $selectedZoneID,
  deliveryZoneSelected,
} from '$lib/deliveries/models/zones'
import { Routes } from '$root/utils/constants/routes'
import { useStore } from 'effector-react'
import { router } from '@inertiajs/react'
import { useEffectorDomain } from '$hooks/useEffectorDomain'
import { DistributionInfoEffector } from '$lib/deliveries/distribution/effector/distribution-effector'
import { useDeliveryZones } from '$lib/deliveries/hooks/useDeliveryZones'
import { DistributionDeliveriesContext } from '$components/Deliveries/DistributionDeliveries/context'

export const DistributionDeliveries = ({ children, deliveries }) => {
  const { zones } = useDeliveryZones()
  const selectedZone = useStore($selectedZoneID)
  const currentRouteID = route().params.id

  const { service: distributionService } = useEffectorDomain(
    new DistributionInfoEffector({
      zoneID: selectedZone,
      zones,
      deliveries,
    }),
  )

  distributionService.useContainer(deliveries)
  distributionService.useZoneCouriersContainer(zones)
  distributionService.useStores()

  const checkSelectedZone = () => {
    if (route().current() === Routes.deliveries.zone.active) {
      if (currentRouteID) {
        deliveryZoneSelected(parseInt(currentRouteID))
      }
    }
  }

  useEffect(() => {
    checkSelectedZone()

    const unsubscribe = deliveryZoneSelected.watch((id) => {
      if (!!id && id !== selectedZone) {
        router.get(
          route(Routes.deliveries.zone.active, {
            ...{ ...route().params, city_id: undefined },
            id,
          }),
        )
      } else if (!id && !!route().params.id) {
        router.get(
          route(Routes.deliveries.zone.list, {
            ...{ ...route().params, id: undefined },
          }),
        )
      }
    })

    return unsubscribe
  }, [])

  return (
    <DistributionDeliveriesContext.Provider
      value={{
        distributionService,
      }}
    >
      <div className="delivery">
        <div className="delivery__wrap">
          <div className="delivery__col-map">
            <div className="delivery__map">
              <SpecificChild
                childs={children}
                type={DistributionDeliveries.Map}
              />
            </div>
          </div>
          <div className="delivery__col-info">
            <SpecificChild
              childs={children}
              type={DistributionDeliveries.DeliveryZones}
              zones={zones}
            />
          </div>
        </div>
        <SpecificChild
          childs={children}
          type={DistributionDeliveries.DataGrid}
          deliveries={deliveries}
        />
      </div>
    </DistributionDeliveriesContext.Provider>
  )
}

DistributionDeliveries.Map = Map
DistributionDeliveries.DeliveryZones = DeliveryZones
DistributionDeliveries.DataGrid = DataGrid
