import clsx from 'clsx'
import React, { useMemo } from 'react'

export const DropdownBtnActiveItem = ({
  classes,
  toggleItems,
  error,
  disabled,
  activeItems,
  multiple,
  itemTitle,
  emptyText,
  items,
  activeItem,
  needTitle,
}) => {
  const title = useMemo(() => {
    const textOrNode = multiple
      ? activeItems.length > 0
        ? activeItems
            .map((item) => {
              return itemTitle(item)
            })
            .join(', ')
        : (emptyText && emptyText) || 'Не выбрано'
      : (activeItems.length > 0 &&
          typeof activeItem === 'function' &&
          activeItem(activeItems[0])) ||
        itemTitle(activeItems[0]) ||
        emptyText ||
        'Не выбрано'

    window.textOrNode = textOrNode
    return textOrNode
  }, [activeItems, multiple, itemTitle, emptyText, items, activeItem])

  return (
    <button
      className={clsx(classes.current, { active: true }, { error })}
      onClick={toggleItems}
      disabled={disabled}
      title={
        (needTitle &&
          ((typeof title === 'object' && title.props.children) || title)) ||
        ''
      }
    >
      {title}
    </button>
  )
}
