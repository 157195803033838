import React from 'react'
import { MainLayout } from '$components/UI/Layouts/MainLayout'
import { KitchenSettings } from '$components/Settings/Kitchen/KitchenSettings'
import { Routes } from '$root/utils/constants/routes'

const tabs = [{ title: 'Комментарии', route: Routes.settings.kitchen.comments }]

export const SettingsKitchen = ({ kitchen_comments }) => {
  return <KitchenSettings tags={kitchen_comments} tabs={tabs} />
}

SettingsKitchen.layout = (page) => <MainLayout>{page}</MainLayout>
