import React, { useEffect, useMemo } from 'react'
import { MainLayout } from '$components/UI/Layouts/MainLayout'
import { Deliveries as DeliveriesComponent } from '$components/Deliveries'
import { usePage } from '@inertiajs/react'
import DeliveriesPageTabs from '$components/Deliveries/DeliveriesPageTabs'
import { Routes } from '$root/utils/constants/routes'
import PickUpDeliveries from '$components/Deliveries/PickUpDeliveries'
import { DeliveriesHead } from '$components/Deliveries/Head/DeliveriesHead'
import { useDeliveryPageCleanings } from '$lib/deliveries/hooks/useDeliveryPageCleanings'
import { resetOrdersTable } from '$lib/orders/models/orders-store'
import { useGlobalStore } from '$lib/page/provider'
import { DeliveriesSectionRouteRegExp } from '$root/config/root-routes'

export const Deliveries = () => {
  const { zones = [], orders, zone_orders, zone = [] } = usePage().props
  const { pageSelfCleans } = useGlobalStore()

  const { cleanChangePage } = useDeliveryPageCleanings()

  const deliveries = orders || zone_orders
  useEffect(() => {
    pageSelfCleans.addCleaning(DeliveriesSectionRouteRegExp, () => {
      cleanChangePage()
      resetOrdersTable()
    })
  }, [])

  const currentRoute = route().current()
  const routeParams = route().params

  const isPickUpPage = useMemo(() => {
    return currentRoute === Routes.deliveries.pickup.page
  }, [currentRoute, routeParams])

  return (
    <>
      <DeliveriesPageTabs />
      <DeliveriesHead />
      {(!isPickUpPage && (
        <DeliveriesComponent zones={zones} deliveries={deliveries} />
      )) || <PickUpDeliveries deliveriesGrid={deliveries} />}
    </>
  )
}

Deliveries.layout = (page) => <MainLayout>{page}</MainLayout>
