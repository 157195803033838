import { usePage } from '@inertiajs/react'
import { TabLink } from '$components/Leads/Statused/DataGrid/Header/Tabs/TabLink/TabLink'

export const Tabs = () => {
  const { enums } = usePage().props

  return (
    <div className="deals-filter">
      {enums &&
        enums.tabs &&
        enums.tabs.map((tab, index) => (
          <TabLink tab={tab} key={index} index={index} />
        ))}
    </div>
  )
}
