import { EffectorStore } from '$root/utils/effector/effector-services'
import { GridColumnsMap } from '$lib/invoices/utils/grid-component-map'
import { usePage } from '@inertiajs/react'
import {
  CashColumnRenderer,
  ColumnRenderer,
  DateColumnRenderer,
  EnumColumnRenderer,
} from '$lib/datagrid/columns'
import { verboseCurrency } from '$root/utils/functions'
import { useDateTime } from '$hooks/useDateTime'

export class InvoiceGridStore extends EffectorStore {
  pageProps = {}
  date = () => {}
  verboseCurrency = () => {}

  useContainer() {
    const { date } = useDateTime()
    this.pageProps = usePage().props

    this.setOptions({
      date,
      verboseCurrency,
    })
  }

  renderColumn(invoice, column) {
    let renderer = GridColumnsMap[column.name]

    if (renderer instanceof EnumColumnRenderer) {
      return renderer.render({
        column,
        item: invoice,
        pageProps: this.pageProps,
      })
    } else if (renderer instanceof CashColumnRenderer) {
      return renderer.render({
        column,
        item: invoice,
        verboseFn: this.verboseCurrency,
      })
    } else if (renderer instanceof DateColumnRenderer) {
      return renderer.render({ column, item: invoice, verboseFn: this.date })
    }

    if (!renderer) renderer = new ColumnRenderer(column.name)

    return renderer.render({ column, item: invoice })
  }
}
