/** Глубокое сравнение нескольких объектов */
import { onCtrlV } from '$models/page'

export const unhandledError = () => {
  alert('Непредвиденная ошибка!')
}

export const plural = (n, one, two, many) => {
  return n % 10 == 1 && n % 100 != 11
    ? one
    : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
      ? two
      : many
}

export function deepCompare() {
  var i, l, leftChain, rightChain

  function compare2Objects(x, y) {
    var p

    // remember that NaN === NaN returns false
    // and isNaN(undefined) returns true
    if (
      isNaN(x) &&
      isNaN(y) &&
      typeof x === 'number' &&
      typeof y === 'number'
    ) {
      return true
    }

    // Compare primitives and functions.
    // Check if both arguments link to the same object.
    // Especially useful on the step where we compare prototypes
    if (x === y) {
      return true
    }

    // Works in case when functions are created in constructor.
    // Comparing dates is a common scenario. Another built-ins?
    // We can even handle functions passed across iframes
    if (
      (typeof x === 'function' && typeof y === 'function') ||
      (x instanceof Date && y instanceof Date) ||
      (x instanceof RegExp && y instanceof RegExp) ||
      (x instanceof String && y instanceof String) ||
      (x instanceof Number && y instanceof Number)
    ) {
      return x.toString() === y.toString()
    }

    // At last checking prototypes as good as we can
    if (!(x instanceof Object && y instanceof Object)) {
      return false
    }

    if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
      return false
    }

    if (x.constructor !== y.constructor) {
      return false
    }

    if (x.prototype !== y.prototype) {
      return false
    }

    // Check for infinitive linking loops
    if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
      return false
    }

    // Quick checking of one object being a subset of another.
    for (p in y) {
      if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
        return false
      } else if (typeof y[p] !== typeof x[p]) {
        return false
      }
    }

    for (p in x) {
      if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
        return false
      } else if (typeof y[p] !== typeof x[p]) {
        return false
      }

      switch (typeof x[p]) {
        case 'object':
        case 'function':
          leftChain.push(x)
          rightChain.push(y)

          if (!compare2Objects(x[p], y[p])) {
            return false
          }

          leftChain.pop()
          rightChain.pop()
          break

        default:
          if (x[p] !== y[p]) {
            return false
          }
          break
      }
    }

    return true
  }

  if (arguments.length < 1) {
    return true //Die silently? Don't know how to handle such case, please help...
    // throw "Need two or more arguments to compare"
  }

  for (i = 1, l = arguments.length; i < l; i++) {
    leftChain = []
    rightChain = []

    if (!compare2Objects(arguments[0], arguments[i])) {
      return false
    }
  }

  return true
}

/** Откладывает выполнение часто вызываемой функции, на указанное количество секунд до последнего её вызова */
export const deferred = (fn) => {
  let _deferTimeoutID = null

  return (options, seconds) => {
    if (_deferTimeoutID) {
      clearTimeout(_deferTimeoutID)
    }
    _deferTimeoutID = setTimeout(() => {
      fn(options)
    }, seconds * 1000)
  }
}

export const scrollTop = () => {
  // window.scroll({ top: 0, left: 0, behavior: 'smooth' })
}

export const isScrollVisible = function (target) {
  // Все позиции элемента
  var targetPosition = {
      top: window.pageYOffset + target.getBoundingClientRect().top,
      left: window.pageXOffset + target.getBoundingClientRect().left,
      right: window.pageXOffset + target.getBoundingClientRect().right,
      bottom: window.pageYOffset + target.getBoundingClientRect().bottom,
    },
    // Получаем позиции окна
    windowPosition = {
      top: window.pageYOffset,
      left: window.pageXOffset,
      right: window.pageXOffset + document.documentElement.clientWidth,
      bottom: window.pageYOffset + document.documentElement.clientHeight,
    }

  if (
    targetPosition.bottom > windowPosition.top && // Если позиция нижней части элемента больше позиции верхней чайти окна, то элемент виден сверху
    targetPosition.top < windowPosition.bottom && // Если позиция верхней части элемента меньше позиции нижней чайти окна, то элемент виден снизу
    targetPosition.right > windowPosition.left && // Если позиция правой стороны элемента больше позиции левой части окна, то элемент виден слева
    targetPosition.left < windowPosition.right
  ) {
    // Если позиция левой стороны элемента меньше позиции правой чайти окна, то элемент виден справа
    // Если элемент полностью видно, то запускаем следующий код
    return true
  } else {
    // Если элемент не видно, то запускаем этот код
    return false
  }
}

export const uid = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  )
}

export const onCopyPast = () => {
  document.body.addEventListener('keydown', async function (event) {
    try {
      var key = event.key
      var cmd_held = event.metaKey
      var cmd_ctr = event.ctrlKey

      if (
        (cmd_held && key.toLowerCase() == 'v') ||
        (cmd_ctr && key.toLowerCase() == 'v')
      ) {
        const clipboard = await navigator.clipboard.readText()
        onCtrlV(clipboard)
      }
    } catch (e) {
      console.error(e)
    }
  })
}

export const normalizePhone = (phone) => {
  phone = phone.replaceAll(/[+()\s]/gi, '')
  if (phone && phone.length && phone[0] === '8') {
    phone = phone.replace('8', '7')
  }
  return phone
}

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const randomColor = () => {
  // const colors = [
  //     '#5B14B6', '#F69E45', '#6D7596',
  //     '#5D6FBB', '#F1523E', '#333333',
  //     '#FAD704', '#14B632', '#FAD704',
  // ]
  //
  // return colors[randomIntFromInterval(0, colors.length - 1)]

  function componentToHex(c) {
    var hex = c.toString(16)
    return hex.length == 1 ? '0' + hex : hex
  }

  function rgbToHex(r, g, b) {
    return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
  }

  function colorChange() {
    var number_one = Math.floor(Math.random() * 255)
    var number_two = Math.floor(Math.random() * 255)
    var number_three = Math.floor(Math.random() * 255)
    return rgbToHex(number_one, number_two, number_three)
  }

  return colorChange()
}

export const checkChildType = (childType, checkingType) => {
  return childType.name === checkingType.name || childType === checkingType
}

export const retrieveChild = (children, type) => {
  return _.isArray(children)
    ? children.find(({ type: childType }) => {
        return checkChildType(childType, type)
      })
    : (children && checkChildType(children.type, type) && children) || undefined
}

export const normalizeAddressSending = (address) => {
  const normalizedSendingAddress = { ...address }

  if (normalizedSendingAddress.id.toString().indexOf('*') > -1) {
    delete normalizedSendingAddress.id
  }
  return normalizedSendingAddress
}

export const normalizeContacts = (contact, i) => ({
  ...contact,
  sort: i,
  value: contact.value.replaceAll(/[^\d]+/gi, ''),
})

export const verboseCurrency = (number, digits = 2) => {
  if (isNaN(number)) return number

  const formatter = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: digits, // (causes 2500.99 to be printed as $2,501)
  })

  return formatter.format(number)
}

export const isClass = (cls, object) => {
  if (typeof cls !== 'function')
    throw new Error('class argument must be a class type')
  return typeof object === 'function' && cls.name === object.name
}

export const goExportRoute = (route_) => {
  window.open(route(route_, route().params), '_blank')
}

export const formatPhone = (phone) => {
  if (phone) {
    const phoneMaskRegExp = /[7|8](\d{3})(\d{3})(\d{2})(\d{2})/
    const phoneMask = '+7 ($1) $2-$3-$4'
    let normalizedPhone = phone.replaceAll(/[^\d]+/gi, '')

    if (/\d{11}/.test(normalizedPhone)) {
      return normalizedPhone.replace(phoneMaskRegExp, phoneMask)
    } else {
      try {
        return normalizedPhone.replace(phoneMaskRegExp, phoneMask)
      } catch (e) {
        console.error(e)
      }
    }
  }

  return phone
}
