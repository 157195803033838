import { FabricSalaries } from '$components/Reports/Company/Salaries/FabricSalaries'
import { OfficeSalaries } from '$components/Reports/Company/Salaries/OfficeSalaries'
import { ManagementSalaries } from '$components/Reports/Company/Salaries/ManagementSalaries'
import { OtherSalaries } from '$components/Reports/Company/Salaries/OtherSalaries'
import { SalariesDetail } from '$components/Reports/Company/Salaries/SalariesDetail'
import { useEffectorDomain } from '$hooks/useEffectorDomain'
import { ReportsSalariesEffector } from '$lib/reports/effector/report-salaries'
import { SalaryPaneCode } from '$lib/reports/salaries-panes-const'

export const SalariesPanes = ({ salaries }) => {
  const { service: reportSalariesService } = useEffectorDomain(
    new ReportsSalariesEffector({
      salaries,
    }),
  )

  reportSalariesService.useStores()

  const currentCategory = reportSalariesService.currentCategory
  const onSelectCategory = reportSalariesService.onSelectCategory.bind(
    reportSalariesService,
  )

  return (
    <>
      <p className="reports-company__title">Зарплаты сотрудников</p>
      <div className="reports-stats">
        <div className="reports-stats__btns">
          <FabricSalaries
            salary={salaries[SalaryPaneCode.Fabric].title}
            isSelected={currentCategory === SalaryPaneCode.Fabric}
            onSelect={() => onSelectCategory(SalaryPaneCode.Fabric)}
          />
          <OfficeSalaries
            salary={salaries[SalaryPaneCode.Office].title}
            isSelected={currentCategory === SalaryPaneCode.Office}
            onSelect={() => onSelectCategory(SalaryPaneCode.Office)}
          />
          <ManagementSalaries
            salary={salaries[SalaryPaneCode.Management].title}
            isSelected={currentCategory === SalaryPaneCode.Management}
            onSelect={() => onSelectCategory(SalaryPaneCode.Management)}
          />
          <OtherSalaries
            salary={salaries[SalaryPaneCode.Other].title}
            isSelected={currentCategory === SalaryPaneCode.Other}
            onSelect={() => onSelectCategory(SalaryPaneCode.Other)}
          />
        </div>
        <SalariesDetail
          salaries={reportSalariesService.salaries}
          isOpen={!!currentCategory}
        />
      </div>
    </>
  )
}
