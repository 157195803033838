import { EffectorDomainLogic } from '$root/utils/effector/domain-logic'
import { useStore } from 'effector-react'
import { createEvent, createStore, sample } from 'effector'
import { StaffTransactionType } from '$lib/staff/const'
import { useDateTime } from '$hooks/useDateTime'

export class SalaryTransactionsEffector extends EffectorDomainLogic {
  salaries = []
  $transactions = []
  transactions = []

  events = {
    update: null,
    remove: null,
    reset: null,
  }

  constructor() {
    super()
  }

  identify() {
    return 'staff-transactions'
  }

  init() {
    this.events.update = createEvent()
    this.events.remove = createEvent()
    this.events.reset = createEvent()

    this.$transactions = createStore([])
      .on(this.events.update, (transactions, transaction) => {
        const issetTransactionIndex = _.findIndex(
          transactions,
          (iterTransaction) =>
            iterTransaction.user_id === transaction.user_id &&
            iterTransaction.type === transaction.type,
        )

        if (issetTransactionIndex >= 0) {
          transactions.splice(issetTransactionIndex, 1, transaction)
        } else {
          if (transaction.type !== 'salary') {
            const tmpTransactionIndex = _.findIndex(
              transactions,
              (iterTransaction) =>
                iterTransaction.user_id === transaction.user_id &&
                iterTransaction.type !== StaffTransactionType.Salary,
            )
            if (tmpTransactionIndex >= 0) {
              transactions.splice(tmpTransactionIndex, 1)
            }
          }
          transactions.push(transaction)
        }

        return [...transactions]
      })
      .on(this.events.remove, (transactions, userID) => {
        const issetTransactionIndex = _.findIndex(
          transactions,
          (iterTransaction) => iterTransaction.user_id === userID,
        )

        if (issetTransactionIndex >= 0) {
          transactions.splice(issetTransactionIndex, 1)
        }

        return [...transactions]
      })
      .reset(this.events.reset)
  }

  updateTransaction(transaction) {
    this.events.update(transaction)
  }

  removeTransaction(userID) {
    this.events.remove(userID)
  }

  updateTransactions(transactions) {
    for (const transaction of transactions) {
      if (transaction.amount !== 0 && !isNaN(transaction.amount)) {
        this.updateTransaction(transaction)
      } else {
        this.removeTransaction(transaction.user_id)
      }
    }
  }

  useContainer() {
    this.dateUtils = useDateTime()
  }

  useStores() {
    this.transactions = useStore(this.$transactions)
  }

  getTransactions() {
    return this.transactions.map((transaction) => ({
      ...transaction,
      date: this.dateUtils.dateToSaveFormat(transaction.date),
    }))
  }

  reset() {
    this.events.reset()
  }
}
