import React, { useContext, useMemo } from 'react'
import { DataGridPagination } from '$components/UI/DataGrid/DataGridPagination'
import { plural } from '$root/utils/functions'
import { getActiveTabCode } from '$lib/leads/statused/public/helpers'
import { usePage } from '@inertiajs/react'
import { TabCode } from '$lib/leads/statused/public/tab-codes.const'
import { DataGridContext } from '$components/UI/DataGridCompound/DataGridCompound'
import { $dataGrid } from '$models/dataGrid'
import { useStore } from 'effector-react'

const DeliveriesTotal = () => {
  let { totals } = useStore($dataGrid)
  if (!totals) {
    totals = {
      rations_count: 0,
      price_sum: 0,
    }
  }

  const rationsTotal = parseInt(totals.rations_count || 0)
  const amountTotal = parseFloat(totals.price_sum || 0)

  return (
    <div className="deal-total">
      <p className="deal-total__count">
        {rationsTotal} {plural(rationsTotal, 'рацион', 'рациона', 'рационов')}
      </p>
      <p className="deal-total__sum">
        <span className="deal-total__sum-money">{amountTotal} ₽</span>
        {/*<span className="deal-total__sum-reward">925 Бв</span>*/}
      </p>
    </div>
  )
}

const ClientsTotal = () => {
  let { totals } = useStore($dataGrid)
  if (!totals) {
    totals = { clients_count: 0 }
  }
  const clientsCount = parseInt(totals.clients_count || 0)

  return (
    <div className="deal-total">
      <p></p>
      <p className="deal-total__client-count">
        {clientsCount} {plural(clientsCount, 'клиент', 'клиента', 'клиентов')}
      </p>
    </div>
  )
}

export const Footer = () => {
  const { data } = useContext(DataGridContext)
  const {
    enums: { tabs },
  } = usePage().props

  const activeTabCode = tabs && getActiveTabCode(tabs)
  const isOrderStatus = useMemo(
    () => [TabCode.active, TabCode.problem].includes(activeTabCode),
    [data],
  )
  const isClientStatus = useMemo(
    () =>
      [TabCode.prolong, TabCode.frozen, TabCode.archive].includes(
        activeTabCode,
      ),
    [data],
  )

  return (
    <>
      {isOrderStatus && <DeliveriesTotal orders={data.rows}></DeliveriesTotal>}
      {isClientStatus && <ClientsTotal orders={data.rows}></ClientsTotal>}
      <DataGridPagination data={data} />
    </>
  )
}
