import { EffectorDomains } from '$root/utils/effector/domains'
import { $modals } from '$models/modals'
import { scope } from '$models/scope'
import { useState } from 'react'

export const useEffectorDomain = (logic, domainFactory = EffectorDomains) => {
  const modals = scope.getState($modals)
  const [isOpenedModal] = useState(_.last(modals))

  const domain = domainFactory.domain(
    `${logic.identify()}-${(isOpenedModal && isOpenedModal.id) || ''}`,
    logic,
  )

  return {
    domain: domain,
    service: domain.getService(),
  }
}
