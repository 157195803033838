import { ActiveOrderItem } from '$components/Clients/Orders/OrdersByStatus/OrderLineItems/ActiveOrderItem'
import { InactiveOrderItem } from '$components/Clients/Orders/OrdersByStatus/OrderLineItems/InactiveOrderItem'
import { DoneOrderItem } from '$components/Clients/Orders/OrdersByStatus/OrderLineItems/DoneOrderItem'
import { CanceledOrderItem } from '$components/Clients/Orders/OrdersByStatus/OrderLineItems/CanceledOrderItem'
import { ErrorStatusOrderItem } from '$components/Clients/Orders/OrdersByStatus/OrderLineItems/ErrorStatusOrderItem'
import { DateField } from '$components/Clients/Orders/OrdersByStatus/Fields/DateField'
import { RationsField } from '$components/Clients/Orders/OrdersByStatus/Fields/RationsField'
import { AddressField } from '$components/Clients/Orders/OrdersByStatus/Fields/AddressField'
import { ActionsField } from '$components/Clients/Orders/OrdersByStatus/Fields/ActionsField'
import { DeliveryInfoField } from '$components/Clients/Orders/OrdersByStatus/Fields/DeliveryInfoField'
import { AmountField } from '$components/Clients/Orders/OrdersByStatus/Fields/AmountField'
import { ProblemStatusOrderItem } from '$components/Clients/Orders/OrdersByStatus/OrderLineItems/ProblemStatusOrderItem'
import { OrderStatus } from '$lib/orders/utils/order-status-const'

export const OrderItemComponentByStatus = {
  [OrderStatus.Active]: ActiveOrderItem,
  [OrderStatus.Inactive]: InactiveOrderItem,
  [OrderStatus.Canceled]: CanceledOrderItem,
  [OrderStatus.Done]: DoneOrderItem,
  [OrderStatus.Problem]: ProblemStatusOrderItem,
  [OrderStatus.Default]: ErrorStatusOrderItem,
}

export const OrderFieldComponentsByStatus = {
  [OrderStatus.Active]: [
    DateField,
    RationsField,
    AddressField,
    DeliveryInfoField,
    AmountField,
    ActionsField,
  ],
  [OrderStatus.Inactive]: [
    DateField,
    RationsField,
    AddressField,
    DeliveryInfoField,
    null,
    ActionsField,
  ],
  [OrderStatus.Canceled]: [
    DateField,
    RationsField,
    AddressField,
    DeliveryInfoField,
    AmountField,
    ActionsField,
  ],
  [OrderStatus.Done]: [
    DateField,
    RationsField,
    AddressField,
    DeliveryInfoField,
    AmountField,
    ActionsField,
  ],
  [OrderStatus.Problem]: [
    DateField,
    RationsField,
    AddressField,
    DeliveryInfoField,
    AmountField,
    ActionsField,
  ],
}
