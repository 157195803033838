import moment from 'moment-timezone'
import { DatePickerInput } from '$components/UI/Inputs/DatePickerInput'
import { DropdownBtn } from '$components/UI/Inputs/DropdownBtn/DropdownBtn'
import { TabCode } from '$lib/leads/statused/public/tab-codes.const'
import { TextArea } from '$components/UI/Inputs/TextArea'

// TODO: вынести в $lib/leads

const ReasonField = {
  title: 'Причина',
  component: DropdownBtn,
  fieldName: 'status_reasons',
  required: true,
  props: ({ data, fieldName, formChangeFn, enums, ...other }) => {
    return {
      title: '',
      titleKey: 'value',
      valueKey: 'value',
      needEmpty: false,
      multiple: true,
      items:
        enums.statusReasons && !!enums.statusReasons[data.status]
          ? enums.statusReasons[data.status]
          : [],
      onChange: (reasons) =>
        formChangeFn(
          fieldName,
          reasons.map((reason) => ({
            ...reason,
            status_reason_id: reason.id,
          })),
        ),
      ...other,
    }
  },
}

const StatusField = {
  title: 'Статус',
  component: DropdownBtn,
  fieldName: 'new_status',
  required: true,
  props: ({ data, fieldName, formChangeFn, enums, ...other }) => {
    return {
      titleKey: 'value',
      valueKey: 'key',
      needEmpty: false,
      items: enums.orderStatuses || [],
      onChange: (status) =>
        formChangeFn(fieldName, (status && status.key) || null),
      ...other,
    }
  },
}

export const ActionCode = {
  ProblemNewDate: 'new-delivery-date',
  ProblemCancel: 'problem-cancel',
  ProblemReturnCourier: 'problem-return-courier',
  ProblemToFreeze: 'problem-to-freeze',
}

export const AllowedLeadsPageActions = {
  [TabCode.new]: {
    status: [TabCode.archive, TabCode.pending],
  },
  [TabCode.pending]: {
    status: [TabCode.archive],
    actions: [],
  },
  [TabCode.problem]: {
    status: [],
    actions: [
      ActionCode.ProblemNewDate,
      ActionCode.ProblemCancel,
      ActionCode.ProblemReturnCourier,
      ActionCode.ProblemToFreeze,
    ],
  },
}

export const ChangeStatusLabel = {
  [TabCode.new]: {
    [TabCode.archive]: 'В работу',
    [TabCode.pending]: 'В ожидание',
  },
  [TabCode.pending]: {
    [TabCode.archive]: 'В работу',
  },
  [TabCode.problem]: {
    [ActionCode.ProblemNewDate]: 'Перенести на другой день',
    [ActionCode.ProblemCancel]: 'Отменить',
    [ActionCode.ProblemReturnCourier]: 'Вернуть курьеру',
    [ActionCode.ProblemToFreeze]: 'В заморозку',
  },
}

export const ChangeStatusFieldsMapped = {
  [TabCode.pending]: [
    ReasonField,
    {
      title: 'В ожидание до',
      component: DatePickerInput,
      fieldName: 'contact_at',
      required: true,
      props: ({ data, fieldName, formChangeFn, ...other }) => ({
        inline: true,
        onChange: (contactAt) => {
          return formChangeFn(fieldName, moment(contactAt).format('YYYY-MM-DD'))
        },
        ...other,
      }),
    },
  ],
  [ActionCode.ProblemNewDate]: [
    StatusField,
    {
      title: 'Дата',
      component: DatePickerInput,
      fieldName: 'delivery_date',
      required: true,
      props: ({ data, fieldName, formChangeFn, ...other }) => ({
        inline: true,
        onChange: (delivery_date) => {
          return formChangeFn(
            fieldName,
            moment(delivery_date).format('YYYY-MM-DD'),
          )
        },
        ...other,
      }),
    },
  ],
  [ActionCode.ProblemToFreeze]: [
    {
      title: 'Причина',
      component: TextArea,
      fieldName: 'status_comment',
      required: true,
      props: ({ data, fieldName, formChangeFn, ...other }) => ({
        onChange: (status_comment) => {
          formChangeFn(fieldName, status_comment)
        },
        ...other,
      }),
    },
    {
      title: 'В заморозку до',
      component: DatePickerInput,
      fieldName: 'contact_at',
      required: true,
      props: ({ data, fieldName, formChangeFn, ...other }) => ({
        inline: true,
        onChange: (contactAt) => {
          return formChangeFn(fieldName, moment(contactAt).format('YYYY-MM-DD'))
        },
        ...other,
      }),
    },
  ],
  [ActionCode.ProblemReturnCourier]: [
    {
      title: 'Причина',
      component: TextArea,
      fieldName: 'status_comment',
      required: true,
      props: ({ data, fieldName, formChangeFn, ...other }) => ({
        onChange: (status_comment) => {
          formChangeFn(fieldName, status_comment)
        },
        ...other,
      }),
    },
  ],
}
