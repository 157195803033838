import React, { useState } from 'react'
import { useModals } from '$hooks/useModals'
import { useStatusValidation } from '$components/Leads/Statused/Entities/Leads/Modal/ChangeStatus/utils/use-status-validation'
import { ConfirmModal } from '$components/UI/Modals/ConfirmModal'
import { usePage } from '@inertiajs/react'
import { ChangeStatusComponent } from '$components/Leads/Statused/Entities/Leads/Modal/ChangeStatus/ChangeStatusComponent'
import { useLeads } from '$components/Leads/utils/use-leads'

export const LeadsChangeStatusModal = (props) => {
  const { openModal, closeModal, clearModals } = useModals()
  const [statusError, setStatusError] = useState()
  const { update } = useLeads()
  const { lead } = props
  const modalOptions = { ...props, classes: { wrapper: 'modal--no-overflow' } }
  const defaultData = {
    status: null,
  }
  const { data, setData, validate, errors, markErrors } =
    useStatusValidation(defaultData)

  const updateFormData = (field, value) => {
    setData(field, value)
  }

  const changeStatus = () => {
    const isValid = validate()

    if (isValid) {
      openModal('Confirm', ConfirmModal, {
        title: <span>Вы уверены?</span>,
        onChosen(isConfirm) {
          clearModals()

          if (isConfirm) {
            update(lead, data)
          }
        },
      })
    }
  }

  return (
    <ChangeStatusComponent
      data={data}
      statusError={statusError}
      setStatusError={setStatusError}
      errors={errors}
      markErrors={markErrors}
      updateFormData={updateFormData}
      onSubmit={changeStatus}
      modalOptions={modalOptions}
      closeModal={closeModal}
    />
  )
}
