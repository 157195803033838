import { usePage } from '@inertiajs/react'
import { useCallback, useEffect } from 'react'
import { useModals } from '$hooks/useModals'
import { MessageModal } from '$components/UI/Modals/MessageModal'

export const usePageErrors = () => {
  const { openModal } = useModals()
  let { errors } = usePage().props

  const checkErrors = useCallback(() => {
    if (errors && Object.keys(errors).length > 0) {
      openModal('PageErrors', MessageModal, {
        permit: true,
        title: 'Ошибка!',
        messages: typeof errors !== 'string' ? Object.values(errors) : errors,
      })
    }
  }, [errors])

  useEffect(checkErrors, [])
  useEffect(checkErrors, [errors])
}
