import clsx from 'clsx'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { TagsDropdownContext } from '$components/UI/Inputs/TagsDropdown'
import { ActiveTagContext } from '$components/UI/Inputs/TagsDropdown/ActiveTag'
import { useOutsideClick } from '$hooks/useOutsideClick'

export const SearcheableActiveItem = ({
  tag,
  children,
  prefix = 'Город: ',
  ...other
}) => {
  const params = useContext(TagsDropdownContext)
  const { titleKey, tags, onRemove, onChange, disabled } = params
  const [isOpen, setIsOpen] = useState(false)

  const ref = useRef()
  const listRef = useRef()

  const toggleList = () => {
    if (disabled) {
      return
    }

    if (!isOpen) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }

    // if (ref.current.classList.contains('open')) {
    //     ref.current && ref.current.classList.remove('open')
    // } else {
    //     ref.current && ref.current.classList.add('open')
    // }
  }

  const onSelectItem = (currentTag, selectedTag, needToggle = true) => {
    const valid = onChange(currentTag, selectedTag)
    if (needToggle && valid) {
      toggleList()
    }
  }

  useOutsideClick(ref, () => setIsOpen(false))

  return (
    <div
      ref={ref}
      {...other}
      className={clsx(
        'comment-dropdown comment-dropdown--green js-dropdown',
        other.className,
        {
          open: isOpen,
        },
      )}
      style={{ display: 'flex', cursor: 'pointer' }}
    >
      <div
        className="dropdown dropdown__btn js-dropdown-btn"
        onClick={toggleList}
      >
        {prefix}
        {titleKey instanceof Function
          ? titleKey(tag)
          : (tag && tag[titleKey]) || 'Выбрать'}
      </div>
      <div
        className="comment-dropdown__list"
        ref={listRef}
        style={{ top: '100%' }}
      >
        <ActiveTagContext.Provider value={{ onSelectItem }}>
          {children}
        </ActiveTagContext.Provider>
      </div>
    </div>
  )
}
