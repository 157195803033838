export const TagDropdownItem = ({
  tag,
  currentItem,
  titleKey,
  onSelectItem,
  disabled,
}) => {
  return (
    <>
      <div
        className="tag-dropdown__list-item"
        onClick={() => {
          if (disabled) return
          onSelectItem(currentItem, tag)
        }}
      >
        <p className="tag-dropdown__text">
          {titleKey instanceof Function ? titleKey(tag) : tag[titleKey]}
        </p>
      </div>
    </>
  )
}
