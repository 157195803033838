import { $client, $orders } from '$lib/orders/models/orders-store'
import { OrderStatus } from '$lib/orders/utils/order-status-const'
import { useOrderSuggest } from '$lib/orders/hooks/useOrderSuggest'
import { useMemo } from 'react'
import { useStore } from 'effector-react'

// TODO: нужен рефактор
export const getOrdersByStatus = (status) => {
  let orders = useStore($orders)
  let client = useStore($client)

  const ordersStoreByStatus = (status_) => {
    return orders[status_] || []
  }

  let ordersByStatus = ordersStoreByStatus(status)

  if (status === OrderStatus.Active) {
    ordersByStatus = ordersByStatus.concat(
      ordersStoreByStatus(OrderStatus.Canceled),
    )
    ordersByStatus = ordersByStatus.concat(
      ordersStoreByStatus(OrderStatus.Inactive).filter(
        (order) => !!order.delivery_date,
      ),
    )
  }

  const sortedOrders = useMemo(
    () =>
      _.sortBy(ordersByStatus, (order) =>
        new Date(order.delivery_date).getTime(),
      ),
    [status, orders, client],
  )

  const listIsEmpty = !ordersByStatus.length
  const { wrapSuggest } = useOrderSuggest(sortedOrders, status)

  const suggestedOrders = useMemo(
    () => sortedOrders.map((order, i) => wrapSuggest(order, i)),
    [status, orders, client],
  )

  return {
    orders: suggestedOrders,
    listIsEmpty,
  }
}
