import {
  resetCurrentCity,
  resetDeliveryZones,
  resetSelectedZoneID,
} from '$lib/deliveries/models/zones'
import { onResetDistributionDeliveriesDate } from '$lib/deliveries/distribution/models/filters'

export const useDeliveryPageCleanings = () => {
  const cleanChangePage = () => {
    onResetDistributionDeliveriesDate()
    resetDeliveryZones()
    resetSelectedZoneID()
    resetCurrentCity()
  }

  const cleanChangeTab = () => {
    resetSelectedZoneID()
  }

  return {
    cleanChangePage,
    cleanChangeTab,
  }
}
