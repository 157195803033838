import { isActiveTab } from '$components/Settings/Leads/utils/functions'
import { LeadSettingsTab } from '$components/Settings/Leads/utils/const'
import { NewStatusTag } from '$components/Settings/Leads/TagsTab/NewStatusTag'
import { usePage } from '@inertiajs/react'
import { StatusTagsPanel } from '$components/Settings/Leads/TagsTab/StatusTagsPanel'

export const TagsTab = () => {
  if (!isActiveTab(LeadSettingsTab.Tags)) return <></>

  const { enums } = usePage().props
  const { statusReasons } = enums ? enums : {}

  return (
    <div className="comments">
      <NewStatusTag statusReasons={statusReasons ? statusReasons : []} />
      <div className="comments__wrap">
        {statusReasons &&
          _.isObject(statusReasons) &&
          _.keys(statusReasons).map((statusKey, i) => (
            <StatusTagsPanel
              status={{ code: statusKey, name: statusReasons[statusKey] }}
              key={i}
            />
          ))}
      </div>
    </div>
  )
}
