import { useStore } from 'effector-react'

export const DistributionDeliveryZoneSubtitle = ({
  deliveriesCount,
  recommendStaffCount,
}) => {
  return (
    <span className="delivery-zone__info">
      <span className="delivery-zone__term">
        Доставок
        <span className="delivery-zone__term-value">{deliveriesCount}</span>
      </span>
      <span className="delivery-zone__term">
        Рекомендуется курьеров
        <span className="delivery-zone__term-value">{recommendStaffCount}</span>
      </span>
    </span>
  )
}
