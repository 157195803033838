import React from 'react'
import clsx from 'clsx'

import { AccessList } from '$components/Settings/Permissions/AccessList'

export const RolePermissionsRow = ({
  title,
  permissionName,
  currentValues,
  isOpen,
}) => {
  return (
    <tr className={clsx('table__accordion--row', { open: isOpen })}>
      <td>{title}</td>
      <AccessList
        permissionName={permissionName}
        currentValues={currentValues}
      />
    </tr>
  )
}
