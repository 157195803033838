export const getEnumTitleById = (enums, id) => {
  if (!enums) return '-'
  const enumItem = enums.find((enumIterItem) => enumIterItem.id === id)
  return enumItem ? enumItem.name : null
}

export const getEnumItemById = (enums, id) => {
  if (!enums) return '-'
  const enumItem = enums.find((enumIterItem) => enumIterItem.id === id)
  return (enumItem && enumItem) || undefined
}

export const getEnumIdByCode = (enums, code) => {
  const enumItem = enums.find((enumIterItem) => enumIterItem.code === code)
  return enumItem ? enumItem.id : null
}

export const getEnumMappedIdByData = (enum_, field, value) => {
  return _.values(enum_).find((enumItem) => enumItem[field] === value)
}
