import React, { useEffect } from 'react'
import { DataGrid } from '$components/UI/DataGrid/Table'
import { usePage } from '@inertiajs/react'
import { useModals } from '$hooks/useModals'
import { EditUserModal } from '$components/Settings/Users/Modal/EditUser'
import { UserListHeader } from '$components/Settings/Users/List/Header'
import { UNDEFINED_TITLE } from '$root/utils/constants/service'
import { showCloseConfirmModal } from '$components/UI/Modals/CloseConfirmModal'

export const UsersList = ({ data, detail }) => {
  const { enums } = usePage().props
  const { openModal, closeModal } = useModals()

  useEffect(() => {
    if (detail) {
      openModal('EditUser', EditUserModal, {
        title: 'Изменить сотрудника',
        user: detail,
        // TODO: брать enums от usePage().props
        enums,
        onClose: ({ isUserClick }) => {
          if (isUserClick) {
            showCloseConfirmModal(openModal, closeModal)
            return true
          }
        },
        classes: { wrapper: 'modal--no-overflow' },
      })
    }
  }, [])

  return (
    <>
      <UserListHeader data={data} />
      <DataGrid
        className="table table--fit-content"
        data={{
          ...data,
          columns: [
            {
              name: 'full_name',
              title: 'Ф.И.О',
              sortable: false,
            },
            {
              name: 'phone',
              title: 'Телефон',
              sortable: false,
            },
            {
              name: 'first_role',
              title: 'Должность',
              render: (item) =>
                (item.first_role && item.first_role.title) || UNDEFINED_TITLE,
              sortable: false,
            },
            {
              name: 'city_name',
              title: 'Город',
              sortable: false,
            },
          ],
        }}
      />
    </>
  )
}
