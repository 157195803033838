import { DeliveryZonesLayer } from '$components/Deliveries/Maps/OSM/DeliveryZonesLayer'
import React, { useEffect, useMemo } from 'react'
import { DeliverySettingsColumn } from '$components/Settings/Deliveries/SettingsColumn'
import { DeliveryZoneList } from '$components/Settings/Deliveries/ZoneList'
import { useDeliveryZones } from '$lib/deliveries/hooks/useDeliveryZones'
import { DeliveryExtraCostsSettings } from '$components/Settings/Deliveries/DeliveryExtraCostsSettings'
import { OpenStreetMap } from '$components/UI/Maps/OSM/OpenStreetMap'
import { DeliverySettingsFilters } from '$components/Settings/Deliveries/DeliverySettingsFilters'
import { getCityCoordinates } from '$lib/cities/helpers'
import { MapEmptyCoordinates } from '$root/utils/constants/map'

export const DeliverySettings = ({ zones, delivery_type_prices }) => {
  const { city } = useDeliveryZones()

  useEffect(() => {}, [zones])

  return (
    <div className="delivery delivery--settings">
      <DeliverySettingsFilters />
      {/*{ cityPoint && (*/}
      <div className="delivery__wrap">
        <DeliverySettingsColumn isLeft>
          <div className="delivery__map">
            <OpenStreetMap
              center={getCityCoordinates(city) || MapEmptyCoordinates}
              zoom={10}
              zoomControl={true}
              style={{
                height: '450px',
                width: '450px',
                zIndex: 0,
              }}
            >
              <DeliveryZonesLayer zones={zones} allowEdit={true} />
            </OpenStreetMap>
          </div>
          <DeliveryExtraCostsSettings
            delivery_type_prices={delivery_type_prices}
          />
        </DeliverySettingsColumn>
        <DeliverySettingsColumn isRight>
          <DeliveryZoneList zones={zones} />
        </DeliverySettingsColumn>
      </div>
      {/*) }*/}
    </div>
  )
}
