export const DeliveryAmountField = ({ delivery }) => {
  return (
    <div className="table-plan__amounts">
      <span className="table-plan__sum">
        <span className="table-plan__sum-money">
          {(delivery.discount_price && delivery.discount_price) ||
            delivery.price ||
            '-'}{' '}
          ₽
        </span>
        {/*<span className="table-plan__sum-reward">125 Бв</span>*/}
      </span>
      {delivery.discount && delivery.discount.amount && (
        <button className="table-plan__discount">
          {(delivery.discount &&
            delivery.discount.amount &&
            delivery.discount.amount) ||
            'Скидки нет'}
          {(delivery.discount &&
            delivery.discount.currency &&
            delivery.discount.currency === 'ruble' &&
            '₽') ||
            '%'}
        </button>
      )}
    </div>
  )
}
