import { useMemo } from 'react'
import { useModals } from '$hooks/useModals'
import { Modal } from '$components/UI/Modals/Modal'
import { ClientReport } from '$components/Clients/Report'

export const Header = ({ client, orders, title }) => {
  const { openModal } = useModals()

  const totalAmount = useMemo(() => {
    return (
      (client.order_discount_price_sum &&
        parseFloat(client.order_discount_price_sum)) ||
      0
    )
  }, [orders])

  const formattedTotalAmount = useMemo(() => {
    return totalAmount.toFixed(2)
  }, [orders])

  const onShowClientReport = () => {
    openModal('ClientReport', Modal, {
      title: '',
      contentComponent: ClientReport,
      classes: { wrapper: 'modal--report' },
      client,
      orders,
    })
  }

  return (
    <div className="modal-right__header">
      <div className="modal-right__header-top">
        <p className="modal-right__header-name">
          {(title && title) || `${client.full_name}`}
        </p>
        <div className="modal-right__header-inner">
          <button
            className="modal-right__btn-report"
            onClick={onShowClientReport}
          >
            Отчет по клиенту
          </button>
          <p className="modal-right__header-sum">
            <span className="modal-right__header-money">
              {formattedTotalAmount || 0} ₽
            </span>
            {/*<span className="modal-right__header-reward">{client.bonus_balance || 0} Бв</span>*/}
          </p>
        </div>
      </div>
    </div>
  )
}
