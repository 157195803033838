import { DropdownBtn } from '$components/UI/Inputs/DropdownBtn/DropdownBtn'
import { useStore } from 'effector-react'
import { getDeliveryClientAddresses } from '$lib/deliveries/helpers/clients'
import { $client } from '$lib/orders/models/orders-store'
import { useOrdersTable } from '$lib/orders/hooks/useOrdersTable'
import { usePersistOrderDeliveryType } from '$lib/clients/hooks'

const HIDING_DELIVERY_TYPE = 'pickup'

export const AddressField = ({ order, allowEdit }) => {
  const { orderDeliveryTypes } = usePersistOrderDeliveryType()
  const client = useStore($client)
  const { updateOrder } = useOrdersTable()

  const currentAddress = { address: order.address, point: order.point }
  const clientAddresses = getDeliveryClientAddresses(client)

  const orderDeliveryType = orderDeliveryTypes[order.id] || order.delivery_type

  return (
    orderDeliveryType !== HIDING_DELIVERY_TYPE && (
      <DropdownBtn
        className="address-field"
        items={clientAddresses}
        value={currentAddress.address}
        valueKey="address"
        titleKey={(address) => (
          <span>
            {(address && address.address) ||
              (!clientAddresses.length && 'Адресов нет') ||
              'Не выбрано'}
          </span>
        )}
        checkActive={false}
        noItemsText="Адресов нет"
        onChange={(addressInfo) => {
          const address = (addressInfo && addressInfo.address) || null
          const point = (addressInfo && addressInfo.point) || null

          updateOrder({
            order,
            updatingOrders: { ...order, address, point },
          })
        }}
        disabled={!allowEdit}
      />
    )
  )
}
