import { useDateTime } from '$hooks/useDateTime'

export const PendingAgo = ({ lead }) => {
  const { date } = useDateTime()
  const pendingAgoDate = lead.updated_status_at
    ? date(lead.updated_status_at)
    : '-'

  return (
    <div className="deal__pending">
      <p className="deal__pending-text">в ожидании с</p>
      <p className="deal__pending-date">{pendingAgoDate}</p>
    </div>
  )
}
