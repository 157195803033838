import { UsersStoreForm } from '$components/Settings/Users/Detail/UsersStoreForm'
import { Modal } from '$components/UI/Modals/Modal'
import { ModalSize } from '$utils/constants/modals'

const Header = () => <p className="modal__title">Добавить сотрудника</p>

export const EditUserModal = (props) => {
  return (
    <Modal size={ModalSize.ExtraLarge} title="Добавить сотрудника" {...props}>
      <Header {...props} />
      <UsersStoreForm {...props} />
    </Modal>
  )
}
