import clsx from 'clsx'
import { router } from '@inertiajs/react'

export const KitchenSettingsTabs = ({ tabs }) => {
  return (
    <div className="second-head">
      <div className="tab-category">
        {tabs.map((tab, i) => (
          <button
            key={i}
            className={clsx('tab-category__btn', {
              active: route().current() === tab.route,
            })}
            onClick={() => {
              if (route().current() !== tab.route) {
                router.get(route(tab.route))
              }
            }}
          >
            {tab.title}
          </button>
        ))}
      </div>
    </div>
  )
}
