import React, { useEffect } from 'react'
import { usePage } from '@inertiajs/react'
import { useDeliveryZones } from '$lib/deliveries/hooks/useDeliveryZones'
import { DatePickerInput } from '$components/UI/Inputs/DatePickerInput'
import { useDistributionDeliveries } from '$lib/deliveries/distribution/hooks/useDistributionDeliveries'
import { TagsDropdown } from '$components/UI/Inputs/TagsDropdown'
import useDeliveryPageFilter from '$lib/deliveries/hooks/useDeliveryPageFilter'
import { $distributionDeliveriesDate } from '$lib/deliveries/distribution/models/filters'
import { useDateTime } from '$hooks/useDateTime'

export const DeliveriesFilters = () => {
  const { dateToSaveFormat } = useDateTime()
  const { enums } = usePage().props
  const { filter, updateFilter } = useDeliveryPageFilter()
  const { city } = useDeliveryZones()
  const cities = (enums && enums.cities) || []

  const { date } = filter

  return (
    <div className="second-head__group">
      <TagsDropdown
        variants={cities ? cities : []}
        values={[city]}
        titleKey="name"
        multiple={false}
        onChange={(tags) => {
          const city = _.first(tags)

          updateFilter('city_id', city.id)
        }}
      >
        {(activeCities) =>
          activeCities.map((activeRation, i) => (
            <TagsDropdown.SearcheableActiveItem
              tag={activeRation}
              key={i}
              style={{ marginBottom: 'unset', cursor: 'pointer' }}
            >
              <TagsDropdown.SearcheableList key={i} ration={activeRation} />
            </TagsDropdown.SearcheableActiveItem>
          ))
        }
      </TagsDropdown>
      <DatePickerInput
        onChange={(newDate) => updateFilter('date', dateToSaveFormat(newDate))}
        value={(date && new Date(date)) || null}
        showPickerTag={false}
      />
    </div>
  )
}
