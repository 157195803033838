import { createEvent, createStore } from 'effector'

export const resetInactiveOrdersDates = createEvent()

export const newSelectedDate = createEvent()
export const deleteSelectedDate = createEvent()
export const resetOrderInactiveDates = createEvent()

export const $persistInactivePlanOrdersDates = createStore({})
  .reset(resetInactiveOrdersDates)
  .on(newSelectedDate, (data, { id, date }) => {
    const issetDates = [...(data[id] || [])]
    const newData = { ...data, [id]: [...issetDates, date] }

    return newData
  })
  .on(deleteSelectedDate, (data, { id, date }) => {
    const searchFn = (item) => item.toString() === date.toString()
    const index = data[id].findIndex(searchFn)
    if (index >= 0) {
      data[id].splice(index, 1)
    }

    return { ...data }
  })
  .on(resetOrderInactiveDates, (data, id) => {
    if (data[id]) {
      delete data[id]
    }
    return { ...data }
  })
