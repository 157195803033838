import { useDateTime } from '$hooks/useDateTime'

export const ErrorStatusOrderItem = ({ order }) => {
  const { date } = useDateTime()

  return (
    <tr className="table-plan--row-warning">
      <td colSpan={6}>
        Ошибка! Неизвестный статус доставки: {date(order.delivery_date)}
      </td>
    </tr>
  )
}
