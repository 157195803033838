import React from 'react'

export const Loader = () => {
  return (
    <div className="loader__bg">
      <div className="loader">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  )
}
