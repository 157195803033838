export const getCityCoordinates = (city) => {
  const cityCoordinates =
    (city &&
      city.point &&
      city.point.coordinates &&
      [...city.point.coordinates].reverse()) ||
    null

  return cityCoordinates
}
