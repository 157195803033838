import { date } from '$root/utils/time'
import { useMemo } from 'react'
import { plural } from '$root/utils/functions'
import { usePage } from '@inertiajs/react'
import { getEnumTitleById } from '$root/utils/enums'

export const Source = ({ lead }) => {
  const { enums } = usePage().props

  const whenCreated = useMemo(() => {
    if (!lead.updated_status_at) return '-'
    const createdDate = new Date(lead.updated_status_at)

    if (createdDate < new Date()) {
      const { days, hours, minutes } = date.getDifference(
        new Date(),
        createdDate,
      )

      let whenAgo =
        (days ? `${days} ${plural(days, 'день', 'дня', 'дней')}, ` : '') +
        (hours ? `${hours} ч., ` : '') +
        (minutes ? `${minutes} мин` : '')
      if (!whenAgo) whenAgo = 'несколько секунд'
      return whenAgo
    }

    return '-'
  }, [lead])

  return (
    <div className="deal__info">
      <p className="deal__source">
        Источник{' '}
        <span>
          {lead.contact_type_id
            ? getEnumTitleById(enums.contactTypes, lead.contact_type_id)
            : 'Неизвестный'}
        </span>
      </p>
      <p className="deal__time">
        <span>{whenCreated}</span> назад
      </p>
    </div>
  )
}
