import {
  $persistInactivePlanOrdersDates,
  deleteSelectedDate,
  newSelectedDate,
  resetOrderInactiveDates,
} from '$lib/clients/store'
import { useStore } from 'effector-react'
import {
  $persistOrderDeliveryTypes,
  resetOrderDeliveryType,
  selectedDeliveryType,
} from '$lib/clients/plan-order-delivery-types-store'

export const usePersistOrderDates = () => {
  const inactiveOrdersDates = useStore($persistInactivePlanOrdersDates)

  return {
    inactiveOrdersDates: inactiveOrdersDates,
    newSelectedDate,
    deleteSelectedDate,
    resetOrderId: resetOrderInactiveDates,
  }
}

export const usePersistOrderDeliveryType = () => {
  const orderDeliveryTypes = useStore($persistOrderDeliveryTypes)

  return {
    orderDeliveryTypes,
    selectedDeliveryType,
    resetOrderDeliveryType,
  }
}
