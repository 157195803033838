import { EffectorDomainLogic } from '$root/utils/effector/domain-logic'
import { useStore } from 'effector-react'
import { createEvent, sample } from 'effector'

export class ReportsSalariesEffector extends EffectorDomainLogic {
  _salaries = {}

  $salaries = {}
  $currentCode = null
  $detailSalaries = null
  currentCategory = null
  salaries = null

  events = {
    onSelectCategory: null,
  }

  constructor({ salaries }) {
    super()
    this._salaries = _.keys(salaries).reduce((result, category) => {
      result[category] = salaries[category].list || []
      return result
    }, {})
  }

  identify() {
    return 'report-salaries-panes'
  }

  _initAllSalariesStore() {
    this.$salaries = this._domain.store('allSalaries', this._salaries)
  }

  _initCurrentCodeStore() {
    this.events.onSelectCategory = createEvent()
    this.$currentCode = this._domain
      .store('currentCode', null)
      .on(this.events.onSelectCategory, (state, category) => category)
  }

  _initDetailStore() {
    this.$detailSalaries = this._domain.store('detailSalaries', [])
  }

  _initSamples() {
    sample({
      clock: this.$currentCode,
      source: this.$salaries,
      fn(salaries, code) {
        return salaries[code] || []
      },
      target: this.$detailSalaries,
    })
  }

  init() {
    this._initAllSalariesStore()
    this._initCurrentCodeStore()
    this._initDetailStore()
    this._initSamples()
  }

  useStores() {
    this.currentCategory = useStore(this.$currentCode)
    this.salaries = useStore(this.$detailSalaries)
  }

  onSelectCategory(category) {
    this.events.onSelectCategory(
      (this.currentCategory !== category && category) || null,
    )
  }
}
