import React from 'react'
import { AccessItem } from '$components/Settings/Permissions/AccessItem'

export const AccessList = ({ permissionName, currentValues }) => {
  const accessList = ['read', 'create', 'edit', 'delete']

  return (
    <>
      {accessList.map((access, key) => (
        <td key={key}>
          <AccessItem
            type={access}
            permissionName={permissionName}
            currentValue={currentValues[access]}
          />
        </td>
      ))}
    </>
  )
}
