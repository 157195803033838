import React, { useCallback } from 'react'
import { CityInput } from './CityInput'
import { AddressesWithMap } from './AddressesWithMap'
import { Label } from '$components/UI/Inputs/Label'
import { usePage } from '@inertiajs/react'
import { ContactType } from '$root/utils/constants/clients'
import { getEnumIdByCode } from '$root/utils/enums'
import { PhoneContact } from '$components/Clients/Fields/Contacts/EditContacts/PhoneContact'
import { ContactByType } from '$components/Clients/Fields/Contacts/EditContacts/ContactByType'
import { SpecificChild } from '$components/UI/React/SpecificChild'

export const EditContacts = ({
  children,
  contacts = [],
  clientID,
  clientCity,
  markErrors,
  addresses = [],
  errors,
  onChange,
}) => {
  const { enums } = ({} = usePage().props)
  const { deliveryZones = [], cities = [] } = enums

  const getNewContactsWithUpdatedFn = useCallback(
    (updatedContact) => {
      const updatedArrayItem = {
        ...updatedContact,
        client_id: clientID,
        contact_type_id: getEnumIdByCode(
          enums.contactTypes,
          updatedContact.contact_type_code,
        ),
      }

      let newContacts = [...contacts]

      const index = newContacts.findIndex(
        (contact) => contact._id === updatedArrayItem._id,
      )

      if (index < 0) {
        newContacts.push(updatedArrayItem)
      } else {
        newContacts.splice(index, 1, updatedArrayItem)
      }

      return newContacts
    },
    [contacts, clientID, clientCity],
  )

  return (
    <>
      <div className="form__section" data-testid="edit-contacts">
        <Label text="Адреса" />
        <SpecificChild
          childs={children}
          type={EditContacts.City}
          cities={cities}
          onChange={(city) => onChange('city', city || null)}
          cityId={(clientCity && clientCity.id) || null}
          isError={markErrors && !!errors && errors.city}
        />
        {(((clientID && clientCity) || !clientID) && (
          <SpecificChild
            childs={children}
            addresses={addresses}
            type={EditContacts.AddressesWithMap}
            city={clientCity}
            zones={deliveryZones}
            onChange={(addresses) => onChange('addresses', addresses)}
          />
        )) || (
          <p>
            <strong>Чтобы работать с адресами необходимо выбрать город </strong>
          </p>
        )}
      </div>
      <div className="form__section">
        <div className="form__row">
          <SpecificChild
            markErrors={markErrors}
            errors={errors}
            childs={children}
            type={EditContacts.PhoneContact}
            contacts={contacts}
            clientId={clientID}
            getNewContactsWithUpdatedFn={getNewContactsWithUpdatedFn}
            onChange={(contacts) => onChange('contacts', contacts)}
          />
        </div>
      </div>
      <div className="form__section">
        <Label text="Социальные сети" />
        <div className="form__row">
          <SpecificChild
            childs={children}
            type={EditContacts.TelegramContact}
            contacts={contacts}
            getNewContactsWithUpdatedFn={getNewContactsWithUpdatedFn}
            onChange={(contacts) => onChange('contacts', contacts)}
          />
        </div>
        <div className="form__row">
          <SpecificChild
            childs={children}
            type={EditContacts.ViberContact}
            contacts={contacts}
            getNewContactsWithUpdatedFn={getNewContactsWithUpdatedFn}
            onChange={(contacts) => onChange('contacts', contacts)}
          />
        </div>
        <div className="form__row">
          <SpecificChild
            childs={children}
            type={EditContacts.WhatsAppContact}
            contacts={contacts}
            getNewContactsWithUpdatedFn={getNewContactsWithUpdatedFn}
            onChange={(contacts) => onChange('contacts', contacts)}
          />
        </div>
      </div>
      <div className="form__section">
        <div className="form__row form__row--two-col">
          <SpecificChild
            childs={children}
            type={EditContacts.VkontakteContact}
            contacts={contacts}
            getNewContactsWithUpdatedFn={getNewContactsWithUpdatedFn}
            onChange={(contacts) => onChange('contacts', contacts)}
          />
          <SpecificChild
            childs={children}
            type={EditContacts.InstagramContact}
            contacts={contacts}
            getNewContactsWithUpdatedFn={getNewContactsWithUpdatedFn}
            onChange={(contacts) => onChange('contacts', contacts)}
          />
        </div>
      </div>
    </>
  )
}

const TelegramContact = ({
  onChange,
  getNewContactsWithUpdatedFn,
  ...props
}) => {
  return (
    <ContactByType
      placeholder="Телеграм"
      className="input--tg"
      data-testid="telegram-input"
      onChange={(contact) => onChange(getNewContactsWithUpdatedFn(contact))}
      contactType={ContactType.Telegram}
      {...props}
    />
  )
}
const ViberContact = ({ onChange, getNewContactsWithUpdatedFn, ...props }) => {
  return (
    <ContactByType
      placeholder="Viber"
      className="input--vb"
      data-testid="viber-input"
      onChange={(contact) => onChange(getNewContactsWithUpdatedFn(contact))}
      contactType={ContactType.Viber}
      {...props}
    />
  )
}
const WhatsAppContact = ({
  onChange,
  getNewContactsWithUpdatedFn,
  ...props
}) => {
  return (
    <ContactByType
      placeholder="WhatsApp"
      className="input--wa"
      data-testid="whatsapp-input"
      onChange={(contact) => onChange(getNewContactsWithUpdatedFn(contact))}
      contactType={ContactType.WhatsApp}
      {...props}
    />
  )
}
const VkontakteContact = ({
  onChange,
  getNewContactsWithUpdatedFn,
  ...props
}) => {
  return (
    <ContactByType
      placeholder="Вконтакте"
      data-testid="vk-input"
      onChange={(contact) => onChange(getNewContactsWithUpdatedFn(contact))}
      contactType={ContactType.Vk}
      {...props}
    />
  )
}
const InstagramContact = ({
  onChange,
  getNewContactsWithUpdatedFn,
  ...props
}) => {
  return (
    <ContactByType
      placeholder="Инстаграм"
      data-testid="inst-input"
      onChange={(contact) => onChange(getNewContactsWithUpdatedFn(contact))}
      contactType={ContactType.Instagram}
      {...props}
    />
  )
}

EditContacts.City = CityInput
EditContacts.AddressesWithMap = AddressesWithMap
EditContacts.PhoneContact = PhoneContact
EditContacts.TelegramContact = TelegramContact
EditContacts.ViberContact = ViberContact
EditContacts.WhatsAppContact = WhatsAppContact
EditContacts.VkontakteContact = VkontakteContact
EditContacts.InstagramContact = InstagramContact
