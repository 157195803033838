import { DateTypeKitchenFilter } from '$components/Kitchen/DateTypeKitchenFilter'
import { useStore } from 'effector-react'
import { $dataGrid } from '$models/dataGrid'
import { useCallback, useMemo } from 'react'
import { usePage } from '@inertiajs/react'

export const KitchenPackersTable = () => {
  const { enums } = usePage().props
  const dataGrid = useStore($dataGrid)

  const getProductName = (id) => {
    let name = '<>'
    if (enums && enums.products) {
      const product = enums.products.find(
        (product) => product.id.toString() === id.toString(),
      )
      if (product) {
        name = product.name
      }
    }
    return name
  }

  const rows = useMemo(() => {
    let rows = {}

    for (let productID in dataGrid.rows) {
      rows[productID] = dataGrid.rows[parseInt(productID)]
      for (let multiplier of (enums && enums.multipliers) || []) {
        if (!rows[productID][multiplier.id]) {
          rows[productID][multiplier.id] = {
            product_name: getProductName(productID),
            multiplier_code: multiplier.code,
            total: 0,
          }
        }
      }
    }
    return rows
  }, [dataGrid])

  const getTotal = useCallback(
    (productID) => {
      return _.values(dataGrid.rows[parseInt(productID)]).reduce(
        (total, x) => total + (x.total ? parseInt(x.total) : 0),
        0,
      )
    },
    [dataGrid],
  )

  return (
    <>
      <DateTypeKitchenFilter
        field="delivery_date"
        title="Показать"
        variants={[
          { name: 'Сегодня', value: 'today', showRemoveIcon: false },
          { name: 'Завтра', value: 'tomorrow' },
          { name: 'Послезавтра', value: 'after_tomorrow' },
        ]}
      />
      <table className="table table--last-td-bold">
        <tbody>
          <tr>
            {dataGrid.columns.map((column, i) => (
              <th key={i}>{column.title}</th>
            ))}
          </tr>
          {_.keys(rows).map((productId) => (
            <tr key={productId}>
              {dataGrid.columns.map((column, i) => (
                <td key={`${column.name}${i}`}>
                  {rows[productId][column.name]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}
