const InvoicePayBtn = ({ onPay, route }) => {
  return (
    <div
      className="invoice-header-btn invoice-header-btn__pay"
      onClick={() => onPay({ route })}
    >
      Оплатить
    </div>
  )
}

export default InvoicePayBtn
