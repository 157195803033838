import React from 'react'
import { SpecificChild } from '$components/UI/React/SpecificChild'

import { AllContacts } from './AllContacts'
import { BirthdayInfo } from './BirthdayInfo'
import { AddressContacts } from '$components/Clients/Fields/Contacts/ViewContacts/AddressContacts'
import { CityContact } from '$components/Clients/Fields/Contacts/ViewContacts/CityContact'

export const ViewContacts = ({ children }) => {
  return (
    <>
      <p className="modal-right__title">Контакты</p>
      <table className="modal-right__table-contacts">
        <tbody>
          <SpecificChild childs={children} type={ViewContacts.BirthdayInfo} />
          <SpecificChild childs={children} type={ViewContacts.City} />
          <SpecificChild
            childs={children}
            type={ViewContacts.AddressContacts}
          />
          <SpecificChild childs={children} type={ViewContacts.AllContacts} />
        </tbody>
      </table>
    </>
  )
}

ViewContacts.AddressContacts = AddressContacts
ViewContacts.AllContacts = AllContacts
ViewContacts.City = CityContact
ViewContacts.BirthdayInfo = BirthdayInfo
