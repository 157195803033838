import { DistributionDeliveriesContext } from '$components/Deliveries/DistributionDeliveries/context'
import { useContext } from 'react'
import clsx from 'clsx'

export const TitleCouriers = ({ couriers }) => {
  const { distributionService } = useContext(DistributionDeliveriesContext)

  return couriers.map((courier, i) => (
    <button
      className={clsx('delivery-table__courier', {
        active: distributionService.currentCourierStore === courier,
        disabled: distributionService.isEditingCouriers,
      })}
      title={
        (distributionService.isEditingCouriers &&
          'Сначала нужно сохранить курьеров зоны') ||
        undefined
      }
      key={i}
      onClick={() => {
        if (distributionService.isEditingCouriers) return

        distributionService.setCourier(courier)
      }}
    >
      <span className="delivery-table__courier-name">{courier.name}</span>
      <span className="delivery-table__courier-addresses">
        {courier.orders_count || 0}
      </span>
    </button>
  ))
}
