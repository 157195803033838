import { TextArea } from '$components/UI/Inputs/TextArea'
import React, { useEffect } from 'react'
import { onPostSended } from '$models/page'
import { useForm } from '@inertiajs/react'
import { OrderStatus } from '$lib/orders/utils/order-status-const'

const OrderGiven = ({ id: modalId, order, onSave, closeModal }) => {
  const form = useForm(order)

  useEffect(() => {
    return () => onPostSended.watch(() => closeModal())
  }, [])

  const saveGiven = () => {
    onSave({
      id: form.data.id,
      status_comment: form.data.status_comment,
      status: OrderStatus.Done,
    })
  }

  return (
    <>
      <TextArea
        value={form.data.status_comment}
        onChange={(status_comment) =>
          form.setData('status_comment', status_comment)
        }
        placeholder="Комментарий"
      />
      <button className="btn btn__filled btn--blue" onClick={saveGiven}>
        Сохранить
      </button>
    </>
  )
}

export default OrderGiven
