import React from 'react'
import { NoResults } from '$components/UI/DataGrid/NoResults'
import { TableDataGridHead } from '$components/UI/DataGrid/Table/Head'
import { TableDataGridBody } from '$components/UI/DataGrid/Table/Body'
import { TableDataGridFooter } from '$components/UI/DataGrid/Table/Footer'
import { TableDataGridWrapper } from '$components/UI/DataGrid/Table/Wrapper'
import { useDataGrid } from '$hooks/useDataGrid'
import { DataGridContext } from '$components/UI/DataGridCompound/DataGridCompound'

export const DataGrid = ({
  data,
  className,
  route,
  HeadComponent = TableDataGridHead,
  BodyComponent = TableDataGridBody,
  FooterComponent = TableDataGridFooter,
  WrapperComponent = TableDataGridWrapper,
}) => {
  useDataGrid(data, route)

  WrapperComponent = WrapperComponent
    ? WrapperComponent
    : ({ children, ...other }) => <div {...other}>{children}</div>

  return (
    <DataGridContext.Provider value={data}>
      <WrapperComponent className={className}>
        <HeadComponent data={data} />
        {!data.rows.length && (
          <NoResults text="Попробуйте изменить параметры поиска" />
        )}
        <BodyComponent data={data} />
      </WrapperComponent>
      <FooterComponent />
    </DataGridContext.Provider>
  )
}
