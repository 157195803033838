import { createEvent, createStore } from 'effector'

export const validationFnAdded = createEvent()
export const $validationFns = createStore({}).on(
  validationFnAdded,
  (fns, { status, name, fn }) => {
    if (!fns[status]) {
      fns[status] = {}
    }
    fns[status][name] = fn
    return { ...fns }
  },
)
