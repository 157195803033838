import { Modal } from '$components/UI/Modals/Modal'
import React from 'react'

const Content = ({ onChosen }) => {
  return (
    <div className="confirm-modal-content">
      <button
        className="btn btn__filled btn--blue"
        onClick={() => onChosen(true)}
      >
        Да
      </button>
      <button
        className="btn btn__filled btn--default"
        onClick={() => onChosen(false)}
      >
        Нет
      </button>
    </div>
  )
}

export const ConfirmModal = (props) => {
  return (
    <Modal {...props}>
      <Content {...props} />
    </Modal>
  )
}
