import React from 'react'
import { ZoneListItem } from '$components/Settings/Deliveries/ZoneList/ZoneListItem'
import { Label } from '$components/UI/Inputs/Label'

export const DeliveryZoneList = ({ zones }) => {
  return (
    <>
      <Label text="Зоны доставки" className="modal-right__title" />
      <div className="accordion-district">
        {zones.map((zone, i) => (
          <ZoneListItem zone={zone} key={zone.properties.id} index={i} />
        ))}
      </div>
    </>
  )
}
