import React from 'react'

import {
  $modals,
  $waitUpdateModal,
  modalsApi,
  setUpdateModal,
} from '$models/modals'
import { Modals } from '$components/UI/Modals/Modals'
import { allSettled } from 'effector'
import { scope } from '$models/scope'

export const useModals = () => {
  const waitUpdating = async (fn) => {
    await new Promise((resolve) => {
      if (scope.getState($waitUpdateModal)) {
        const intervalID = setInterval(() => {
          if (!scope.getState($waitUpdateModal)) {
            resolve()
            clearInterval(intervalID)
          }
        }, 200)
      } else {
        resolve()
      }
    })

    const setUpdating = (isUpdating) =>
      allSettled(setUpdateModal, { scope: scope, params: isUpdating })
    await setUpdating(true)
    await fn()
    await setUpdating(false)
  }
  return {
    Modals,
    openModal: async (id, component, data) => {
      await waitUpdating(async () => {
        const modals = scope.getState($modals)
        const issetModal = modals.find((modal) => modal.id === id)
        if (!issetModal) {
          await allSettled(modalsApi.open, {
            scope: scope,
            params: { component, data: { id, ...data } },
          })
        } else {
          await allSettled(modalsApi.update, {
            scope: scope,
            params: { id, data },
          })
        }
      })
    },
    closeModal: async (needDelay = true) => {
      await waitUpdating(async () => {
        await allSettled(modalsApi.close, { scope: scope })
      })
    },
    clearModals: async () => {
      await waitUpdating(async () => {
        await allSettled(modalsApi.clear, { scope: scope })
      })
    },
  }
}
