import { DayFrom, DayType, NightFrom } from '$root/utils/constants/day-night'

export const time = {
  /**
   * Парсит строку с временем, полученную от маски ввода 00:00 и возвращает числовое значение в минутах
   * @param timeString {string}
   * */
  parseTimeString(timeString) {
    if (timeString.length !== 5) {
      return null
    }
    const timeHoursNMinutes = timeString.split(':')
    return parseInt(timeHoursNMinutes[0]) * 60 + parseInt(timeHoursNMinutes[1])
  },
  /**
   * Конвертирует числовое значение минут в формат поля ввода времени по маске 00:00
   * @param time {number}
   * */
  convertTimeByTimeString(time) {
    if (typeof time === 'number' && time >= 0) {
      let hours = Math.floor(time / 60)
      let minutes = time - hours * 60

      if (hours.toString().length === 1) {
        hours = `0${hours}`
      }
      if (minutes.toString().length === 1) {
        minutes = `0${minutes}`
      }

      return `${hours}:${minutes}`
    }
    return ''
  },
  /** Возворащает день или ночь для вывода иконки луны или солнца */
  getDayType(timeMinutes) {
    if (typeof timeMinutes !== 'number') {
      return null
    }
    const NightTimeFrom = this.parseTimeString(NightFrom)
    const NightTimeTo = this.parseTimeString(DayFrom)

    return timeMinutes >= NightTimeFrom && timeMinutes <= NightTimeTo
      ? DayType.Night
      : DayType.Day
  },
}

export const date = {
  /**
   * Считает разницу в датах в разрезе дней/часов/минут
   * @param fromDate {Date}
   * @param toDate {Date}
   * */
  getDifference(fromDate, toDate) {
    const different = fromDate - toDate
    const hours = Math.floor((different % 86400000) / 3600000)
    const minutes = Math.round(((different % 86400000) % 3600000) / 60000)
    const days = Math.floor(hours / 24)

    return { days, hours, minutes }
  },
}
