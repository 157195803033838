import { Routes } from '$utils/constants/routes'
import { SideModal } from '$components/UI/Modals/SideModal'
import { Detail } from '$components/Clients/Detail'
import { Header } from './Header'
import { router } from '@inertiajs/react'
import { usePermissions } from '$hooks/usePermissions'
import { StatusColorClass } from '$root/utils/constants/clients'

export const ClientViewModal = (props) => {
  const { client, routes, routeParams = route().params } = props
  const { isAllow } = usePermissions()

  const modalClasses = {
    wrapper: [
      'clients-modal',
      {
        [StatusColorClass[(client && client.status) || '_']]:
          client && client.status,
      },
    ],
  }

  const returnToListRoute = () => {
    if (route().current().indexOf(routes.list) !== -1) {
      delete routeParams['id']
      router.get(route(routes.list, routeParams))
    }
    if (props.onClose) {
      props.onClose()
    }
  }

  return (
    <SideModal classes={modalClasses} onClose={returnToListRoute}>
      <Header
        // TODO: хэлпер на сборку имени клиента
        title={
          client.full_name ||
          ((client.last_name || client.name || client.second_name) &&
            `${client.last_name} ${client.name} ${client.second_name}`) ||
          ''
        }
        showEditButton={isAllow('clients.edit')}
        {...props}
      />

      <Detail routes={routes} routeParams={routeParams} {...props} />
    </SideModal>
  )
}
