import { RationsField } from '$components/Leads/Statused/DataGrid/Fields/RationsField'

export const DeliveryRationField = ({ delivery, routes }) => {
  return (
    <RationsField
      rations={delivery.rations}
      routes={routes}
      last_delivery={delivery.client.latest_order_date}
    />
  )
}
