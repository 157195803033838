import React, { useMemo } from 'react'

export const InputSelectActiveItem = ({
  classes,
  toggleItems,
  error,
  disabled,
  activeItems,
  multiple,
  itemTitle,
  emptyText,
  items,
}) => {
  const activeItem = useMemo(() => {
    return (
      (activeItems.length > 0 && activeItems[0]) ||
      (items.length > 0 && items[0]) ||
      {}
    )
  }, [activeItems])

  return (
    <button
      // TODO: обработка ошибки валидации (класс error) красным цветом
      className="input-select__dropdown-btn js-dropdown-btn"
      onClick={toggleItems}
      disabled={disabled}
    >
      <span className="input-select__dropdown-info">
        <span className="input-select__dropdown-value">
          {`${(activeItem.value && activeItem.value.amount) || activeItem.title} ₽`}
        </span>
        <span className="input-select__dropdown-note">
          {(activeItem.value && activeItem.value.currency_title) || ''}
        </span>
      </span>
    </button>
  )
}
