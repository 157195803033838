export class ColumnRenderer {
  constructor({ fieldName } = {}) {
    this.fieldName = fieldName
  }

  getFieldName(column) {
    return this.fieldName || column.name
  }

  render({ column, item }) {
    return <span>{item[this.getFieldName(column)]}</span>
  }
}

export class EnumColumnRenderer extends ColumnRenderer {
  constructor({ enumName }) {
    super()
    this.enumName = enumName
  }

  render({ column, item, pageProps }) {
    if (!pageProps.enums) throw new Error('usePage().props.enums is required!')
    if (!this.enumName) throw new Error('enum name is required!')

    const fieldName = this.getFieldName(column)

    return (
      (checkEnum(pageProps, 'paySystems') && (
        <span>{pageProps.enums[this.enumName][item[fieldName]]}</span>
      )) || <span>{item[fieldName]}</span>
    )
  }
}

export class DateColumnRenderer extends ColumnRenderer {
  render({ column, item, verboseFn }) {
    if (!verboseFn) throw new Error('verbose function is required')

    const dateString = item[this.getFieldName(column)]
    return (dateString && verboseFn(dateString)) || '-'
  }
}

export class CashColumnRenderer extends DateColumnRenderer {}

const checkEnum = (pageProps, enumName) => {
  if (pageProps) {
    const enums = pageProps.enums

    if (enums && enums[enumName]) {
      return true
    }
  }
}
