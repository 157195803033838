import React, { useEffect } from 'react'
import { useForm } from '@inertiajs/react'
import { useFormValidation } from '$hooks/useFormValidation'
import { useLeads } from '$components/Leads/utils/use-leads'
import { Label } from '$components/UI/Inputs/Label'
import { DatePickerInput } from '$components/UI/Inputs/DatePickerInput'
import { useDateTime } from '$hooks/useDateTime'
import { useModals } from '$hooks/useModals'
import { onPostSended } from '$models/page'
import { router } from '@inertiajs/react'
import { Routes } from '$root/utils/constants/routes'
import { TextArea } from '$components/UI/Inputs/TextArea'

export const ChangeContactAtDateClient = ({ client }) => {
  const { update } = useLeads()
  const { dateToSaveFormat } = useDateTime()
  const { closeModal } = useModals()

  const { data, setData, errors, setError, clearErrors } = useForm({
    status: client.status,
    status_comment: client.status_comment,
    contact_at: client.contact_at,
  })

  const onCheckIsValid = () => {
    let isValid = true

    if (!data.contact_at) {
      setError('*', 'Дата: Обязательное поле')
      isValid = false
    }

    return isValid
  }

  const [validate, markErrors] = useFormValidation(
    `change-contact-at-${client.id}`,
    onCheckIsValid,
    data,
    errors,
    clearErrors,
  )

  const onSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      router.post(
        route(Routes.leads.clients.save_status, {
          status: route().params.status,
          id: client.id,
        }),
        data,
        {
          onSuccess() {
            onPostSended()
          },
        },
      )
    }
  }

  useEffect(() => {
    return onPostSended.watch(() => {
      closeModal(false)
    })
  })

  return (
    <>
      <Label text="Когда связаться с клиентом" />
      <DatePickerInput
        value={(data.contact_at && new Date(data.contact_at)) || null}
        onChange={(selectedDate) => {
          setData('contact_at', dateToSaveFormat(selectedDate))
        }}
      />

      <TextArea
        className="w-100"
        value={data.status_comment}
        placeholder="Комментарий"
        rows={7}
        onChange={(text) => setData('status_comment', text)}
      />

      <div className="flex justify-between">
        <button className="btn btn__filled btn--blue" onClick={onSubmit}>
          Сохранить
        </button>
        <button
          className="btn btn__filled btn--default"
          onClick={() => closeModal(false)}
        >
          Закрыть
        </button>
      </div>
    </>
  )
}
