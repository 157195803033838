import {
  CashColumnRenderer,
  ColumnRenderer,
  DateColumnRenderer,
  EnumColumnRenderer,
} from '$lib/datagrid/columns'

export const GridColumnsMap = {
  pay_system: new EnumColumnRenderer({ enumName: 'paySystems' }),
  status: new EnumColumnRenderer({ enumName: 'invoiceStatuses' }),
  full_name: new ColumnRenderer({ fieldName: 'client_full_name' }),
  date_bill: new DateColumnRenderer(),
  date_paid: new DateColumnRenderer(),
  amount: new CashColumnRenderer(),
  other: new ColumnRenderer(),
}
