import React, { useEffect } from 'react'
import { useForm, usePage } from '@inertiajs/react'
import { PhoneInput } from '$components/UI/Inputs/PhoneInput'
import { useFormValidation } from '$hooks/useFormValidation'
import { Input } from '$components/UI/Inputs/Input'
import { DropdownBtn } from '$components/UI/Inputs/DropdownBtn/DropdownBtn'
import { Routes } from '$utils/constants/routes'
import { TagsDropdown } from '$components/UI/Inputs/TagsDropdown'
import { getEnumItemById } from '$root/utils/enums'

const CityInput = ({ cities, cityId, onChange, isError }) => {
  return (
    <div className="mb-1">
      <TagsDropdown
        variants={cities}
        values={
          (cityId && [getEnumItemById(cities, cityId)]) || [_.first(cities)]
        }
        titleKey="name"
        multiple={false}
        onChange={(selectedCities) => {
          const city = _.first(selectedCities)
          onChange(city)
        }}
      >
        {(activeCities) =>
          activeCities.map((activeCity, i) => (
            <TagsDropdown.SearcheableActiveItem
              tag={activeCity}
              key={i}
              style={{ cursor: 'pointer' }}
              className={isError ? 'error' : undefined}
            >
              <TagsDropdown.SearcheableList key={i} ration={activeCity} />
            </TagsDropdown.SearcheableActiveItem>
          ))
        }
      </TagsDropdown>
    </div>
  )
}

export const UsersStoreForm = ({ user, onChangeData, closeModal }) => {
  const { enums } = usePage().props

  const {
    data: userForm,
    setData,
    post,
    errors,
    setError,
    clearErrors,
  } = useForm(
    user
      ? { ...user }
      : {
          last_name: '',
          name: '',
          second_name: '',
          email: '',
          password: '',
          address: '',
          phone: '',
          roles: [],
          city_id: null,
        },
  )

  useEffect(() => {
    if (onChangeData) {
      onChangeData(userForm)
    }
  }, [userForm])

  const onCheckIsValid = () => {
    let isValid = true
    if (!userForm.email) {
      setError('email', 'Email не заполнен')
      isValid = false
    }

    if (!userForm.last_name) {
      setError('last_name', 'Фамилия не заполнена')
      isValid = false
    }

    if (!userForm.name) {
      setError('name', 'Имя не заполнено')
      isValid = false
    }

    if (!userForm.id && !userForm.password) {
      setError('password', 'Пароль не заполнен')
      isValid = false
    }

    if (!userForm.roles.length) {
      setError('roles', 'Должность не выбрана')
      isValid = false
    }

    if (!userForm.city_id) {
      setError('city_id', 'Город не выбран')
      isValid = false
    }

    return isValid
  }

  const [validate, markErrors] = useFormValidation(
    `update-user-${(user && user.id) || '-new'}`,
    onCheckIsValid,
    userForm,
    errors,
    clearErrors,
  )

  const onClickSaveButtonHandler = async () => {
    if (validate()) {
      post(route(Routes.settings.users.store), {
        onSuccess() {
          closeModal()
        },
      })
    }
  }

  return (
    <>
      <div className="modal__row modal__row--two-col">
        <Input
          value={userForm.last_name}
          placeholder="Фамилия"
          onChange={(e) => setData('last_name', e.target.value)}
          error={markErrors && !!errors.last_name}
        />

        <Input
          value={userForm.name}
          placeholder="Имя"
          onChange={(e) => setData('name', e.target.value)}
          error={markErrors && !!errors.name}
        />
      </div>
      <div className="modal__row">
        <Input
          value={userForm.second_name}
          placeholder="Отчество"
          onChange={(e) => setData('second_name', e.target.value)}
        />
      </div>

      <DropdownBtn
        items={enums.roles}
        value={userForm.roles.length > 0 ? userForm.roles[0].id : null}
        titleKey={'title'}
        emptyText="Должность"
        error={markErrors && !!errors.roles}
        onChange={(role) => {
          setData('roles', (role && [role]) || [])
        }}
      />
      <p className="modal__second-title">Данные для входа</p>
      <div className="modal__row modal__row--two-col">
        <Input
          type="email"
          value={userForm.email}
          placeholder="Логин"
          onChange={(e) => setData('email', e.target.value)}
          error={markErrors && !!errors.email}
        />
        <Input
          type="password"
          placeholder="Пароль"
          value={userForm.password}
          onChange={(e) => setData('password', e.target.value)}
          error={markErrors && !!errors.password}
        />
      </div>
      <p className="modal__second-title">Контакты</p>

      <CityInput
        cities={enums.cities}
        cityId={userForm.city_id ? userForm.city_id : null}
        onChange={(city) => {
          setData('city_id', city.id)
        }}
        isError={markErrors && !!errors.city_id}
      />

      <div className="modal__row">
        <label className="input">
          <input
            value={userForm.address}
            placeholder="Адрес"
            onChange={(e) => setData('address', e.target.value)}
          />
        </label>
      </div>
      <div className="modal__row">
        <label className="input">
          <PhoneInput
            value={userForm.phone}
            placeholder="Телефон"
            onChange={(value) => {
              setData('phone', value)
            }}
          />
        </label>
      </div>
      <div className="modal__row">
        <button
          className="btn btn__filled btn--blue"
          onClick={onClickSaveButtonHandler}
        >
          Сохранить
        </button>
      </div>
    </>
  )
}
