import { useContext } from 'react'
import { DistributionDeliveriesContext } from '$components/Deliveries/DistributionDeliveries/context'

export const Head = () => {
  const { distributionService } = useContext(DistributionDeliveriesContext)

  const isAllChecked =
    distributionService.checkedStore.length > 0 &&
    distributionService.checkedStore.length ===
      distributionService.deliveries.length

  return (
    <thead>
      <tr>
        <th>
          {distributionService.currentCourierStore && (
            <label className="input-chckbx">
              <input
                type="checkbox"
                checked={isAllChecked}
                onChange={() => {
                  if (isAllChecked) {
                    distributionService.clearAllChecked()
                  } else {
                    distributionService.toggleAll()
                  }
                }}
              />
              <span></span>
            </label>
          )}
        </th>
        <th>Адрес</th>
        <th>Телефон</th>
        <th>Курьер</th>
        <th>Клиент</th>
        <th>Рацион</th>
        <th>Оплата</th>
        <th></th>
      </tr>
    </thead>
  )
}
