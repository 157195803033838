import { ReportsTabs } from '$components/Reports/Tabs/ReportsTabs'
import { ReportsFilters } from '$components/Reports/Filters/ReportsFilters'
import React from 'react'
import { MainLayout } from '$components/UI/Layouts/MainLayout'
import { ReportsInvoicesDataGrid } from '$components/Reports/DataGrids/Invoices/ReportsInvoicesDataGrid'
import { Routes } from '$root/utils/constants/routes'
import { InvoicesFilters } from '$components/Reports/Filters/InvoicesFilters'
import { PageHeader } from '$components/Invoices/PageHeader'

export const ReportsInvoices = ({ list }) => {
  return (
    <>
      <ReportsTabs />
      <ReportsFilters showDateFilter={false} extra={<InvoicesFilters />} />
      <ReportsInvoicesDataGrid
        data={list}
        route={Routes.reports.invoices.page}
        wrapper={
          <ReportsInvoicesDataGrid.Wrapper className="table table--last-td-bold" />
        }
        header={<ReportsInvoicesDataGrid.Header />}
        body={<ReportsInvoicesDataGrid.Body />}
        footer={<ReportsInvoicesDataGrid.Footer />}
      />
    </>
  )
}

ReportsInvoices.layout = (page) => (
  <MainLayout PageHeader={PageHeader}>{page}</MainLayout>
)
