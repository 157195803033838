import { useContext, useEffect } from 'react'
import { usePage } from '@inertiajs/react'
import { useEffectorDomain } from '$hooks/useEffectorDomain'
import { RationReplacementsEffector } from '$lib/rations/effector/ration-replacements/ration-replacements-domain'
import clsx from 'clsx'
import { TagsDropdownContext } from '$components/UI/Inputs/TagsDropdown'

export const ActiveTagRationReplacer = ({
  order,
  ration,
  ration_id_key,
  routes,
  onChangeCb = undefined,
}) => {
  const { enums } = usePage().props
  const { disabled } = useContext(TagsDropdownContext)

  const { service: rationReplacementsService } = useEffectorDomain(
    new RationReplacementsEffector({
      clientRoutes: routes,
      order,
      ration,
      ration_id_key,
      routes,
      enums,
    }),
  )

  rationReplacementsService.useStores()

  const products = rationReplacementsService.getProductsStore()

  useEffect(() => {
    rationReplacementsService.onRationUpdated(ration)
  }, [ration])

  return products.map((product, i) => (
    <div className="tag-dropdown__list-item" key={i}>
      <p
        className={clsx('tag-dropdown__text', {
          'in-ration': product.isInRation,
        })}
      >
        {product.name}
      </p>
      <div className="tag-dropdown__count">
        <button
          className="tag-dropdown__count-btn tag-dropdown__count-btn--minus"
          onClick={() => {
            if (disabled) return
            rationReplacementsService.onChangeReplace(product, -1, onChangeCb)
          }}
        >
          -
        </button>
        <input
          className="tag-dropdown__count-input"
          type="number"
          value={product.replaceQuantity}
          disabled
        />
        <button
          className="tag-dropdown__count-btn tag-dropdown__count-btn--plus"
          onClick={() => {
            if (disabled) return
            rationReplacementsService.onChangeReplace(product, 1, onChangeCb)
          }}
        >
          +
        </button>
      </div>
    </div>
  ))
}
