import { TabEntityCount } from '$components/Leads/Statused/DataGrid/Header/Tabs/TabLink/TabEntityCount'

export const TabName = ({ tab, index }) => {
  return (
    <>
      {tab.title}
      <TabEntityCount tab={tab} index={index} />
    </>
  )
}
