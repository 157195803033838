import { ChangeStatusFieldsMapped } from '$components/Leads/Statused/Entities/Leads/Modal/ChangeStatus/utils/const'
import { validationFnAdded } from '$components/Leads/Statused/Entities/Leads/Modal/ChangeStatus/utils/models'
import { StatusField } from '$components/Leads/Statused/Entities/Leads/Modal/ChangeStatus/StatusField'
import { usePage } from '@inertiajs/react'
import { useMemo } from 'react'

export const FieldsByStatus = ({
  data,
  status,
  errors,
  markErrors,
  onChange: formChangeFn,
}) => {
  const { enums } = usePage().props

  const Fields = useMemo(() => {
    return ChangeStatusFieldsMapped[status] || []
  }, [status])

  const addValidation = (name, fn) => {
    validationFnAdded({
      status: data.status,
      name,
      fn,
    })
  }

  return Fields.map(
    (
      { title, component: StatusFieldComponent, fieldName, required, props },
      i,
    ) => (
      <StatusField
        {...{ title, fieldName, required, props }}
        data={data}
        errors={errors}
        markErrors={markErrors}
        key={i}
        onValidation={addValidation}
      >
        <StatusFieldComponent
          value={data ? data[fieldName] : null}
          onChange={(value) => formChangeFn(fieldName, value)}
          {...props({ data, fieldName, formChangeFn, enums })}
        />
      </StatusField>
    ),
  )
}
