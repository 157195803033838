import React, { useEffect } from 'react'
import { DropdownBtn } from '$components/UI/Inputs/DropdownBtn/DropdownBtn'
import { Input } from '$components/UI/Inputs/Input'
import { TextArea } from '$components/UI/Inputs/TextArea'

import { TimeInput } from '$components/UI/Inputs/TimeInput'
import { router, useForm, usePage } from '@inertiajs/react'
import { useNewFormValidation } from '$hooks/useFormValidation'
import { onPostSended } from '$models/page'
import { useModals } from '$hooks/useModals'
import { Ration } from '$components/Clients/Ration'
import { KitchenComments } from '$components/Clients/KitchenComments'
import { Label } from '$components/UI/Inputs/Label'
import { EditContacts } from '$components/Clients/Fields/Contacts/EditContacts/EditContacts'
import {
  normalizeAddressSending,
  normalizeContacts,
} from '$root/utils/functions'
import { getEnumItemById } from '$root/utils/enums'
import { DatePickerInput } from '$components/UI/Inputs/DatePickerInput'
import { useDateTime } from '$hooks/useDateTime'
import { ContactType } from '$root/utils/constants/clients'
import { TabCode } from '$lib/leads/statused/public/tab-codes.const'

export const EditForm = ({
  client = null,
  routes,
  onSuccessSave,
  routeParams,
}) => {
  const { enums } = usePage().props
  const { dateToSaveFormat } = useDateTime()

  const defaultForm = {
    last_name: undefined,
    name: undefined,
    second_name: undefined,
    birthday: undefined,
    type: undefined,
    city: undefined,
    discount: undefined,
    delivery_info: undefined,
    payment_type: undefined,
    addresses: [],
    contacts: [],
    comment: undefined,
    rations: undefined,
    kitchen_comments: [],
    status:
      (enums.clientStatuses &&
        _.isObject(enums.clientStatuses) &&
        _.first(_.keys(enums.clientStatuses))) ||
      null,
  }

  if (routeParams && routeParams.lead_status === TabCode.archive) {
    let status = TabCode.active

    if (client) {
      client.status = status
    } else {
      defaultForm.status = status
    }
  }

  const clientData = client
    ? {
        ...client,
        city: client.city_id && getEnumItemById(enums.cities, client.city_id),
      }
    : defaultForm

  const { closeModal } = useModals()

  const form = useForm(clientData)
  const { data, post, errors, setData, transform } = form

  const validateFields = (validationData) => {
    const errors = {}

    const phones = validationData.contacts.filter(
      (contact) => contact.contact_type_code === ContactType.Phone,
    )

    if (
      !validationData.addresses ||
      (validationData.addresses && !validationData.addresses.length)
    ) {
      errors.addresses = 'Необходимо внести хотя-бы 1 адрес'
    }

    if (!validationData.type) {
      errors.type = 'Не выбран тип клиента'
    }

    if (phones.length === 0) {
      errors.contacts = 'Нужно добавить хотя-бы один номер телефона'
    } else if (phones.length > 0) {
      const emptyPhones = phones.filter((phone) => !phone.value)
      if (emptyPhones.length > 0) {
        for (const phone of emptyPhones) {
          errors[`contacts-${phone._id}`] = 'Поле с телефоном не заполнено'
        }
      }
    }

    if (validationData.contacts.length === 0) {
      errors.contacts = 'Нужно добавить хотя-бы один контакт'
    }

    if (!validationData.city) {
      errors.city = 'Не выбран город'
    }

    if (!validationData.status) {
      errors.status = 'Статус клиента не указан'
    }

    if (!validationData.name) {
      errors.name = 'Имя не заполнено'
    }

    if (
      !!validationData.delivery_info &&
      !validationData.delivery_info.delivery_type
    ) {
      errors['delivery_info.delivery_type'] = 'Не указан тип доставки'
    }

    if (!validationData.payment_type) {
      errors.payment_type = 'Не выбран тип оплаты'
    }

    return errors
  }

  const transformDataOnSending = (data) => {
    const lead_status = route().params.lead_status

    for (const k in data) {
      if (data[k] === undefined) {
        delete data[k]
      }
    }

    if (data['last_name'] === '') {
      data['last_name'] = null
    }

    if (data['second_name'] === '') {
      data['second_name'] = null
    }

    if (data.discount && !data.discount.amount && !data.discount.currency) {
      data.discount = null
    }

    const sendingData = {
      ...data,
      city_id: (data.city && data.city.id) || null,
      contacts: data.contacts.map(normalizeContacts),
      addresses: [...(data.addresses || []).map(normalizeAddressSending)],
      ...((lead_status && { lead_status }) || {}),
      kitchen_comments:
        (data.kitchen_comments &&
          data.kitchen_comments.map((comment) => ({
            id: comment.kitchen_comment_id ? comment.id : undefined,
            kitchen_comment_id: comment.kitchen_comment_id || comment.id,
          }))) ||
        [],
    }

    delete sendingData['city']

    return sendingData
  }

  transform(transformDataOnSending)

  const [validate, markErrors] = useNewFormValidation({
    persistKey: `client-edit-${clientData.id}`,
    onCheckIsValid: validateFields,
    form: form,
  })

  const submitAndOpenOrdersList = (e) => {
    // TODO: сохранить и перейти
    submitForm(e, true)
  }

  const submitForm = (e, isNeedOpenOrdersListPage = false) => {
    e.preventDefault()

    if (validate()) {
      if (routeParams && routeParams.lead_status) {
        delete routeParams['lead_status']
      }
      const saveUrl = route(routes.save, routeParams || {})

      post(saveUrl, {
        onSuccess(page) {
          let onPostSuccessParams = {
            client,
            isNew: (!!client && !client.id) || !client,
            isNeedOpenOrdersListPage: isNeedOpenOrdersListPage,
            ...page.props,
          }
          if (onSuccessSave) {
            onPostSuccessParams = { ...onSuccessSave }
          }
          onPostSended(onPostSuccessParams)
          closeModal(false)
        },
      })
    }
  }

  useEffect(() => {
    setData('addresses', [])
  }, [data.city])

  useEffect(() => {
    setData({ ...clientData, addresses: clientData.addresses })
  }, [client])

  useEffect(() => {
    return onPostSended.watch((props) => {
      if (props) {
        const { detail: client, isNeedOpenOrdersListPage } = props
        if (isNeedOpenOrdersListPage) {
          router.get(
            route(routes.orders.list, {
              id: client.id,
            }),
          )
        }
      }
    })
  }, [])

  return (
    <div className="form client-edit">
      <div className="form__section">
        <div className="form__row form__row--two-col">
          <Input
            type="text"
            placeholder="Фамилия"
            value={data.last_name}
            onChange={(e) => setData('last_name', e.target.value)}
            error={markErrors && !!errors.last_name}
          />
          <Input
            type="text"
            placeholder="Имя"
            value={data.name}
            onChange={(e) => setData('name', e.target.value)}
            error={markErrors && !!errors.name}
          />
        </div>
        <div className="form__row">
          <Input
            type="text"
            placeholder="Отчество"
            value={data.second_name}
            onChange={(e) => setData('second_name', e.target.value)}
            error={markErrors && !!errors.second_name}
          />
        </div>
        <div className="form__row">
          <div className="client-edit__birthday">
            <label htmlFor="birthday">День рождения</label>
            <DatePickerInput
              id="birthday"
              value={(data && data.birthday && new Date(data.birthday)) || null}
              onChange={(date) => setData('birthday', dateToSaveFormat(date))}
            />
          </div>

          {/*<Input*/}
          {/*    type="date"*/}
          {/*    placeholder="День рождения"*/}
          {/*    value={ data.birthday }*/}
          {/*    onChange={ e => setData('birthday', e.target.value) }*/}
          {/*/>*/}
        </div>
      </div>

      <div className="form__section">
        <div className="form__row client-edit__half client-edit__half-row  client-edit__type-n-status">
          <div className="client-edit__half-col">
            <Label text="Тип клиента" />
            <DropdownBtn
              className="button-w-full"
              items={enums.clientTypes}
              value={data.type}
              titleKey="value"
              valueKey="key"
              error={markErrors && !!errors.type}
              onChange={(selected) => {
                setData('type', (selected && selected.key) || null)
              }}
            />
          </div>
          <div className="client-edit__half-col">
            <Label text="Статус" />
            <DropdownBtn
              className="button-w-full"
              items={enums.clientStatuses}
              value={data.status}
              titleKey="value"
              valueKey="key"
              needEmpty={false}
              error={markErrors && !!errors.status}
              onChange={(selected) => {
                setData('status', (selected && selected.key) || null)
              }}
            />
          </div>
        </div>
      </div>
      <div className="form__section">
        <div className="form__row client-edit__half client-edit__half-row  client-edit__type-n-status">
          <div className="client-edit__half-col">
            <Label text="Тип доставки" />
            <DropdownBtn
              className="button-w-full"
              items={enums.deliveryTypes}
              titleKey="value"
              valueKey="key"
              error={markErrors && !!errors['delivery_info.delivery_type']}
              value={
                data.delivery_info ? data.delivery_info.delivery_type : null
              }
              onChange={(selected) => {
                setData('delivery_info', {
                  ...data.delivery_info,
                  delivery_type: selected ? selected.key : null,
                })
              }}
            />
          </div>

          <div className="client-edit__half-col">
            <Label text="Тип оплаты" />
            <DropdownBtn
              className="button-w-full"
              items={enums.clientPaymentTypes || []}
              value={data.payment_type}
              titleKey="value"
              valueKey="key"
              error={markErrors && !!errors.payment_type}
              onChange={(selected) => {
                setData('payment_type', (selected && selected.key) || null)
              }}
            />
          </div>
        </div>
        <TimeInput
          className="w-100"
          placeholder="Время __:__"
          value={data.delivery_info ? data.delivery_info.time : null}
          onChange={(value) => {
            setData('delivery_info', { ...data.delivery_info, time: value })
          }}
        />
      </div>

      <EditContacts
        contacts={data.contacts.map((phone, index) => ({
          ...phone,
          _id: `phone-${index}`,
        }))}
        clientID={(data && data.id) || null}
        clientCity={(data && data.city) || null}
        addresses={data.addresses || []}
        markErrors={markErrors}
        errors={errors}
        onChange={(name, value) =>
          setData((actual) => ({ ...actual, [name]: value }))
        }
      >
        <EditContacts.City needAdd={false} />
        <EditContacts.AddressesWithMap />
        <EditContacts.PhoneContact />
        <EditContacts.TelegramContact />
        <EditContacts.ViberContact />
        <EditContacts.WhatsAppContact />
        <EditContacts.VkontakteContact />
        <EditContacts.InstagramContact />
      </EditContacts>

      <div className="form__section">
        <Label text="Рационы" className="modal-right__title"></Label>
        <Ration
          client={data}
          form={data}
          disabled={false}
          routes={routes}
          onChange={(rations) => setData('rations', rations)}
        />
      </div>
      <div className="form__section">
        <KitchenComments
          client={data}
          items={enums.kitchenComments}
          onChange={(comments) => setData('kitchen_comments', comments)}
        />
      </div>
      <div className="form__section">
        <TextArea
          title="Комментарий по клиенту"
          placeholder=""
          value={data.comment}
          onChange={(comment) => {
            setData('comment', comment)
          }}
        />
      </div>
      <div className="form__section">
        <TextArea
          title="Комментарий по курьеру"
          placeholder=""
          value={data.delivery_info ? data.delivery_info.comment : null}
          onChange={(comment) => {
            setData('delivery_info', { ...data.delivery_info, comment })
          }}
        />
      </div>
      <div className="form__section">
        <Label text="Скидка" />
        <div className="form__row form__row--free">
          <Input
            type="number"
            placeholder="Скидка"
            value={data.discount && data.discount.amount}
            error={markErrors && !!errors['discount.amount']}
            onChange={(ev) => {
              const discountAmount = ev.target.value
              const prevDiscount = data.discount

              if (!discountAmount || isNaN(discountAmount)) {
                prevDiscount.currency = null
              }
              setData('discount', {
                ...prevDiscount,
                amount:
                  (ev.target.value && parseFloat(ev.target.value)) || null,
              })
            }}
          />

          <DropdownBtn
            items={enums.discountCurrencies}
            value={(data.discount && data.discount.currency) || null}
            error={markErrors && !!errors['discount.currency']}
            titleKey="value"
            valueKey="key"
            onChange={(selected) => {
              setData('discount', {
                ...data.discount,
                currency: (selected && selected.key) || null,
              })
            }}
          />
          <p className="form__note">
            Персональная скидка клиента, на все заказы
          </p>
        </div>
      </div>
      <div className="form__section">
        <div className="form__row form__row--free">
          <div
            className="btn btn__filled btn--green"
            onClick={submitAndOpenOrdersList}
          >
            Сохранить и перейти к сделкам
          </div>
          <button className="btn btn__filled btn--blue" onClick={submitForm}>
            Сохранить
          </button>
        </div>
      </div>
    </div>
  )
}

EditForm.Contacts = EditContacts
