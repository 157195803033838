import {
  deliveryZoneEdited,
  deliveryZoneSelected,
} from '$lib/deliveries/models/zones'
import clsx from 'clsx'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { DistributionDeliveryZoneSubtitle } from '$components/Deliveries/DistributionDeliveries/DistributionDeliveryZoneSubtitle'
import { CourierLinkingSelector } from '$components/Deliveries/DistributionDeliveries/DeliveryZone/CourierLinkingSelector'
import { usePage } from '@inertiajs/react'
import { useDeliveryZones } from '$lib/deliveries/hooks/useDeliveryZones'
import avatar from '~images/avatar-courier.jpg'
import { onChangeRoute } from '$models/page'
import { DistributionDeliveriesContext } from '$components/Deliveries/DistributionDeliveries/context'
import _ from 'lodash'

export const DeliveryZone = ({ zone, onSave, onSelect }) => {
  const { enums = {} } = usePage().props
  const { distributionService } = useContext(DistributionDeliveriesContext)
  const { selectedZoneID } = useDeliveryZones()

  const couriers = (enums.couriers && _.values(enums.couriers)) || []
  const deliveriesCount =
    (zone && zone.properties && zone.properties.orders_count) || 0

  const ref = useRef()

  const isOpen = useMemo(() => {
    return selectedZoneID === zone.properties.id
  }, [selectedZoneID])

  const [isEditing, setEditing] = useState(false)

  const zoneCouriers = useMemo(() => {
    return zone.properties.couriers || []
  }, [zone])

  const recommendStaffCount = Math.ceil(deliveriesCount / 20)

  const onChangeCourier = (index, courier) => {
    const properties = zone.properties
    const linkedCouriers = properties.couriers || []

    const issetLinkedIndex = linkedCouriers.findIndex((linkedCourier) => {
      return courier && linkedCourier.id === courier.id
    })

    if (issetLinkedIndex > -1 && issetLinkedIndex !== index) {
      linkedCouriers.splice(issetLinkedIndex, 1)
    }

    if (linkedCouriers.length > index) {
      if (courier) {
        linkedCouriers.splice(index, 1, courier)
      } else {
        linkedCouriers.splice(index, 1)
      }
    } else {
      if (courier) {
        linkedCouriers.push(courier)
      }
    }

    distributionService.updateCouriers(linkedCouriers)
    deliveryZoneEdited({
      id: zone.properties.id,
      properties: { ...properties, couriers: linkedCouriers },
    })
  }

  useEffect(() => {
    if (isOpen || isEditing) {
      ref.current.scrollIntoView({ block: 'end' })
    }
    distributionService.setEditingCouriers(isEditing)
  }, [isOpen, isEditing])

  // useEffect(() => {
  //     return onChangeRoute.watch(() => {
  //         if (isOpen) {
  //             setEditing(true)
  //         }
  //     })
  // }, [])
  return (
    <div
      ref={ref}
      className={clsx('delivery-zone__item delivery-zone__item--2', {
        open: isOpen,
        active: isOpen,
      })}
    >
      <button
        className="delivery-zone__btn"
        onClick={() => {
          const selectedZone = !isOpen ? zone.properties.id : null
          deliveryZoneSelected(selectedZone)
        }}
      >
        <p className="delivery-zone__title">{zone.properties.name}</p>
        <DistributionDeliveryZoneSubtitle
          deliveriesCount={deliveriesCount}
          recommendStaffCount={recommendStaffCount}
        />
      </button>
      {(!isEditing && (
        <div className="delivery-zone__deliverymans">
          <div className="delivery-zone__deliverymans-inner">
            {zoneCouriers.map((courier, i) => (
              <div className="delivery-zone__deliveryman" key={i}>
                <img
                  className="delivery-zone__deliveryman-avatar"
                  src={avatar}
                  alt=""
                />
                <div className="delivery-zone__deliveryman-inner">
                  <div className="delivery-zone__deliveryman-name">
                    {courier.name}
                  </div>
                  <div className="delivery-zone__deliveryman-text">
                    {courier.orders_count || 0} адресов
                  </div>
                </div>
              </div>
            ))}
          </div>
          {isOpen && (
            <button
              className="btn btn__bordered btn--blue"
              onClick={() => setEditing(true)}
            >
              Изменить
            </button>
          )}
        </div>
      )) || (
        <div className="delivery-zone__body">
          {zoneCouriers.map((courier, i) => (
            <CourierLinkingSelector
              zone={zone}
              courier={courier}
              couriers={couriers}
              index={i}
              onChangeCourier={onChangeCourier}
              key={i}
            />
          ))}
          <CourierLinkingSelector
            zone={zone}
            courier={null}
            couriers={couriers}
            index={zoneCouriers.length}
            onChangeCourier={onChangeCourier}
          />
          <button
            className="btn btn__filled btn--blue"
            onClick={() => {
              setEditing(false)
              onSave(zone)
            }}
          >
            Сохранить
          </button>
        </div>
      )}
    </div>
  )
}
