import clsx from 'clsx'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { TagsDropdownContext } from '$components/UI/Inputs/TagsDropdown'
import { EffectorDomainLogic } from '$root/utils/effector/domain-logic'
import { createEvent, createStore } from 'effector'
import { useStore } from 'effector-react'
import { useEffectorDomain } from '$hooks/useEffectorDomain'
import { uid } from '$root/utils/functions'
import { useOutsideClick } from '$hooks/useOutsideClick'

export const ActiveTagContext = createContext(null)

export class ActiveTagEffector extends EffectorDomainLogic {
  item = null
  $isOpenStore = null
  isOpenState = null

  events = {
    setIsOpen: null,
    reset: null,
  }

  constructor({ identifier, item, resetCallback }) {
    super()
    this.identifier = identifier
    this.item = item
  }

  identify() {
    return this.identifier
  }

  init() {
    this.events.setIsOpen = createEvent()
    this.events.reset = createEvent()
    this.$isOpenStore = createStore(false)
      .on(this.events.setIsOpen, (state, isOpen) => isOpen)
      .reset(this.events.reset)
  }

  useStores() {
    this.isOpenState = useStore(this.$isOpenStore)

    // useEffect(() => {
    //     return this.events.reset
    // }, [])
  }

  isOpen() {
    return this.isOpenState
  }

  setIsOpen(isOpen) {
    this.events.setIsOpen(isOpen)
  }
}

// TODO: resetCallback remove always
export const ActiveTagDropdown = ({
  tag,
  className,
  children,
  persistKey,
  resetCallback,
}) => {
  const params = useContext(TagsDropdownContext)
  const { titleKey, tags, onRemove, onChange, disabled } = params

  const { service } = useEffectorDomain(
    new ActiveTagEffector({
      identifier: persistKey,
      resetCallback: resetCallback,
      item: tag,
    }),
  )

  service.useStores()

  const toggleList = () => {
    if (service.isOpen()) {
      service.setIsOpen(false)
    } else {
      service.setIsOpen(true)
    }
  }
  const ref = useRef()
  useOutsideClick(ref, () => service.setIsOpen(false))

  const onSelectItem = (currentTag, tag) => {
    service.setIsOpen(false)
    if (onChange) {
      onChange(currentTag, tag)
    }
  }

  return (
    <div
      className={clsx(
        'tag-dropdown js-dropdown',
        { disabled, open: service.isOpen() },
        className,
      )}
      ref={ref}
    >
      <div className="tag-dropdown__btn">
        <button
          className="tag-dropdown__btn-remove"
          onClick={() => onRemove(tag)}
          disabled={disabled}
        >
          Удалить
        </button>
        <button
          className="tag-dropdown__btn-select js-dropdown-btn"
          onClick={(ev) => {
            ev.preventDefault()
            ev.stopPropagation()
            if (disabled) return
            toggleList()
          }}
        >
          {titleKey instanceof Function ? titleKey(tag) : tag[titleKey]}
        </button>
        <div className="tag-dropdown__list">
          <ActiveTagContext.Provider value={{ onSelectItem }}>
            {children}
          </ActiveTagContext.Provider>
        </div>
      </div>
    </div>
  )
}
