import { useStore } from 'effector-react'
import { $checkedRationsCount } from '$components/Settings/Leads/ProductsTab/models'
import { useModals } from '$hooks/useModals'
import { CreateUpdateRationModal } from '$components/Settings/Leads/ProductsTab/Modals/CreateUpdateRationModal'
import { SideModal } from '$components/UI/Modals/SideModal'
import { ModalSize } from '$root/utils/constants/modals'
import { ChangeProductPrices } from '$components/Settings/Leads/ProductsTab/Modals/ChangeProductPrices'
import { Modal } from '$components/UI/Modals/Modal'
import { LinkingProductToRation } from '$components/Settings/Leads/ProductsTab/Modals/LinkingProductToRation'
import { usePage } from '@inertiajs/react'
import { useCallback } from 'react'
import { CreateUpdateProductModal } from '$components/Settings/Leads/ProductsTab/Modals/CreateUpdateProductModal'
import _ from 'lodash'

export const Toolbar = () => {
  const { enums } = usePage().props

  const { openModal } = useModals()
  const checkedRationsCount = useStore($checkedRationsCount)

  const showLinkingProductToRation = useCallback(() => {
    openModal('ProductToolbarLinkProductRation', Modal, {
      title: 'Привязать товар рациону',
      contentComponent: LinkingProductToRation,
      products: (enums.products && _.values(enums.products)) || [],
      multipliers: (enums.multipliers && enums.multipliers) || [],
      classes: { wrapper: 'modal--no-overflow' },
    })
  }, [enums.products, enums.multipliers])

  const showChangePriceModal = () => {
    openModal('ProductToolbarChangePrices', SideModal, {
      title: 'Изменить цены',
      size: ModalSize.Large,
      contentComponent: ChangeProductPrices,
    })
  }

  const showAddProductModal = () => {
    openModal('NewRation', CreateUpdateRationModal, {
      title: 'Добавить рацион',
      multipliers: (enums.multipliers && enums.multipliers) || [],
      classes: { wrapper: 'modal--no-overflow' },
    })
  }

  const showAddRationModal = () => {
    openModal('NewProduct', CreateUpdateProductModal, {
      title: 'Добавить товар',
    })
  }

  return (
    <div className="deal-head">
      <div className="deal-head__btns-wrap">
        {checkedRationsCount > 0 && (
          <>
            <button
              className="deal-head__btn-price"
              onClick={showLinkingProductToRation}
            >
              Добавить товар рациону
            </button>
          </>
        )}
        <button className="deal-head__btn-price" onClick={showChangePriceModal}>
          Изменить цены
        </button>
        <button className="deal-head__btn-add" onClick={showAddProductModal}>
          Добавить рацион
        </button>
        <button className="deal-head__btn-add" onClick={showAddRationModal}>
          Добавить товар
        </button>
      </div>
    </div>
  )
}
