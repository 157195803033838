import { TabsWithFiltersWrapper } from '$lib/leads/statused/public/const'

export const getActiveTab = (tabs) => {
  return tabs.find((tab) => !!tab.active)
}

export const getActiveTabCode = (tabs) => {
  const activeTab = getActiveTab(tabs)
  return (
    (activeTab && activeTab.attributes && activeTab.attributes.code) || null
  )
}

export const isNeedFiltersWrapper = (tabs) => {
  const activeTabCode = getActiveTabCode(tabs)
  return activeTabCode && TabsWithFiltersWrapper.indexOf(activeTabCode) > -1
}
