import React from 'react'
import { Ration } from '../Ration'
import { KitchenComments } from '../KitchenComments'
import { Comment } from './Comment'
import { DeliveryInfo } from './DeliveryInfo'
import { Discount } from './Discount'
import { BalancePreviewCard } from '$components/Clients/BalancePreviewCard'
import { Label } from '$components/UI/Inputs/Label'
import { ClientContacts } from '$components/Clients/Fields/Contacts/ClientContacts'
import { router } from '@inertiajs/react'
import { usePage } from '@inertiajs/react'
import { NoResults } from '$components/UI/DataGrid/NoResults'

export const Detail = ({ client, routes, routeParams = {} }) => {
  if (!client) return <></>
  const { enums } = usePage().props

  const openDeliveryPlanList = () => {
    router.get(
      route(routes.orders.list, {
        id: client.id,
        ...routeParams,
      }),
    )
  }

  return (
    <>
      <div className="modal-right__section">
        <ClientContacts client={client}>
          <ClientContacts.ViewContacts>
            <ClientContacts.ViewContacts.BirthdayInfo />
            <ClientContacts.ViewContacts.City />
            <ClientContacts.ViewContacts.AddressContacts />
            <ClientContacts.ViewContacts.AllContacts />
          </ClientContacts.ViewContacts>
        </ClientContacts>
      </div>
      <div className="modal-right__section">
        <BalancePreviewCard
          client={client}
          routes={routes}
          routeParams={routeParams}
        />
      </div>
      <div className="modal-right__section">
        <Label text="Рационы" className="modal-right__title"></Label>
        {(client.rations && client.rations.length && (
          <Ration client={client} />
        )) || <NoResults className="no-padding" title={false} text="------" />}
      </div>
      <div className="modal-right__section">
        <KitchenComments
          client={client}
          items={enums.kitchenComments}
          disabled
        />
      </div>
      {client.comment && <Comment client={client} />}
      <div className="modal-right__section">
        <DeliveryInfo client={client} />
      </div>
      <div className="modal-right__section">
        <Discount client={client} />
      </div>
      <div className="modal-right__section">
        <button
          className="btn btn__filled btn--green"
          onClick={openDeliveryPlanList}
        >
          Перейти к сделкам
        </button>
      </div>
    </>
  )
}
