import { router } from '@inertiajs/react'
import { useState } from 'react'
import { Routes } from '$root/utils/constants/routes'
import { OrderStatus } from '$lib/orders/utils/order-status-const'

export const ProblemDelivery = ({ delivery }) => {
  const deliveryHasProblem = delivery.status === OrderStatus.Problem

  const [comment, setComment] = useState(
    (deliveryHasProblem && delivery.status_comment) || null,
  )
  const isCanceledPage = route().current() === Routes.mobile.delivery_canceled

  const cancelDelivery = () => {
    router.post(
      route(Routes.mobile.delivery_cancel),
      {
        orders: [
          {
            id: delivery.id,
            status: OrderStatus.Problem,
            status_comment: comment,
          },
        ],
      },
      {
        onSuccess() {
          router.get(
            route(Routes.mobile.detail, { id: delivery.id, problem: 1 }),
          )
        },
      },
    )
  }

  return (
    <div className="mobile-status">
      {deliveryHasProblem && (
        <p className="problem-description">
          Доставка завершена со статусом "Проблема"
        </p>
      )}
      <p className="mobile-status__name">
        {(delivery.client && delivery.client.full_name) ||
          (delivery.client && delivery.client.name) ||
          ''}
      </p>
      <p className="mobile-status__address">{delivery.address}</p>
      {(!isCanceledPage && (
        <>
          <div className="mobile-status__reason">
            <textarea
              className="textarea"
              name=""
              id=""
              cols="30"
              rows="3"
              onChange={(ev) => setComment(ev.target.value)}
              disabled={deliveryHasProblem}
            >
              {comment}
            </textarea>
          </div>
          <div className="mobile-status__btn">
            {!deliveryHasProblem && (
              <button
                className="btn btn--red btn__filled"
                onClick={cancelDelivery}
              >
                Отменить доставку
              </button>
            )}
          </div>
        </>
      )) || (
        <>
          <p className="mobile-status__descr">{delivery.comment}</p>
          <p className="mobile-status__text mobile-status__text--fail">
            Заказ не доставлен
          </p>
        </>
      )}
    </div>
  )
}
