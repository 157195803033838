import { DatePickerInput } from '$components/UI/Inputs/DatePickerInput'
import React, { useMemo } from 'react'
import { $dataGrid, dataGridIsChanged } from '$models/dataGrid'
import { useStore } from 'effector-react'
import { useDateTime } from '$hooks/useDateTime'

export const ReportsFilters = ({
  extra,
  showDateFilter = true,
  dateMultiple = true,
}) => {
  const { currentDate, dateToSaveFormat } = useDateTime()
  const { filter } = useStore($dataGrid)

  const filterWithUrl = useMemo(() => {
    return filter || route().params.filter
  }, [filter])

  const filteringDate =
    (dateMultiple &&
      filterWithUrl &&
      filterWithUrl.date && [filterWithUrl.date[0], filterWithUrl.date[1]]) ||
    (filterWithUrl && filterWithUrl.date) ||
    null

  return (
    <div className="deal-head">
      {showDateFilter && (
        <DatePickerInput
          onChange={(date) => {
            if (!date) {
              dataGridIsChanged({ filter: { ...filterWithUrl, date: null } })
            }
            if (dateMultiple && ((!!date && !date[0]) || !date[1])) {
              return null
            } else {
              let newDateValue = null

              if (!dateMultiple) {
                newDateValue = dateToSaveFormat(new Date(date))
              } else {
                newDateValue = [
                  dateToSaveFormat(new Date(date[0])),
                  dateToSaveFormat(new Date(date[1])),
                ]
              }

              dataGridIsChanged({
                filter: {
                  ...filterWithUrl,
                  date: newDateValue,
                },
              })
            }
          }}
          showPickerTag={dateMultiple}
          value={(filterWithUrl && filteringDate) || null}
          multiple={dateMultiple}
        />
      )}
      {extra && extra}
    </div>
  )
}
