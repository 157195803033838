import { usePage } from '@inertiajs/react'
import { useContext } from 'react'
import { EffectorContext } from '$root/utils/effector/effector-services'
import { useDateTime } from '$hooks/useDateTime'
import { Logo } from '$components/UI/Sidebar/Logo'

const ListItemColumnValue = ({ title, value }) => {
  return (
    <>
      <div className="invoice-body__title">{title}:</div>
      <div className="invoice-body__value">{value}</div>
    </>
  )
}

const EmptyInvoiceFieldValue = () => (
  <span className="empty-value">-- -- --</span>
)

const InvoiceViewListItem = () => {
  const { date } = useDateTime()

  const { invoiceService } = useContext(EffectorContext)

  const invoiceServices = invoiceService.services

  const { formService } = invoiceServices

  invoiceService.useContainer()

  const form = formService.useContainer()

  window.form1 = form
  const invoice = form.data

  const { enums = {} } = usePage().props
  const { paySystems = {}, invoiceStatuses = {} } = enums

  return (
    <div className="invoice-body">
      <div className="issued-at">
        <ListItemColumnValue
          title="Выставлен"
          value={
            (invoice.date_bill && date(invoice.date_bill)) || (
              <EmptyInvoiceFieldValue />
            )
          }
        />
      </div>
      <div className="pay-type">
        <ListItemColumnValue
          title="Оплата"
          value={paySystems[invoice.pay_system] || <EmptyInvoiceFieldValue />}
        />
      </div>
      <div className="issued-at">
        <ListItemColumnValue
          title="Сумма"
          value={invoice.amount || <EmptyInvoiceFieldValue />}
        />
      </div>
      <div className="payed-at">
        <ListItemColumnValue
          title="Оплачен"
          value={
            (invoice.date_paid && date(invoice.date_paid)) || (
              <EmptyInvoiceFieldValue />
            )
          }
        />
      </div>
      <div className="status">
        <ListItemColumnValue
          title="Статус"
          value={invoiceStatuses[invoice.status] || <EmptyInvoiceFieldValue />}
        />
      </div>
    </div>
  )
}

export default InvoiceViewListItem
