import { Link } from '@inertiajs/react'
import clsx from 'clsx'
import { TabName } from '$components/Leads/Statused/DataGrid/Header/Tabs/TabLink/TabName'

export const TabLink = ({ tab, index }) => {
  return (
    <Link
      href={tab.url}
      className={clsx('deals-filter__item', {
        active: tab.active,
      })}
    >
      <TabName tab={tab} index={index} />
    </Link>
  )
}
