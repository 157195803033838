import { ClientsTab } from '$components/Settings/Leads/ClientsTab'
import { ProductsTab } from '$components/Settings/Leads/ProductsTab'
import { TagsTab } from '$components/Settings/Leads/TagsTab'
import { TabLinks } from '$components/Settings/Leads/TabLinks'

export const Tabs = () => {
  return (
    <>
      <TabLinks />
      <TagsTab />
      <ClientsTab />
      <ProductsTab />
    </>
  )
}
