import clsx from 'clsx'
import { ActiveTagContext } from '$components/UI/Inputs/TagsDropdown/ActiveTag'
import { TagsDropdownContext } from '$components/UI/Inputs/TagsDropdown'
import { TagDropdownItem } from '$components/UI/Inputs/TagsDropdown/TagDropdownItem'
import { useContext, useRef, useState } from 'react'

export const SearcheableList = () => {
  const { onSelectItem } = useContext(ActiveTagContext)
  const { tags, titleKey } = useContext(TagsDropdownContext)
  const searchInputRef = useRef()
  const [items, setFilteredItems] = useState(tags)

  const search = (searchString) => {
    if (!searchString) {
      resetSearch()
    } else {
      var re = new RegExp(searchString, 'i')
      setFilteredItems(
        tags.filter((tag) =>
          titleKey instanceof Function
            ? titleKey(tag).match(re)
            : tag[titleKey].match(re),
        ),
      )
    }
  }

  const isSearching = () => {
    return items.length !== tags.length
  }

  const resetSearch = () => {
    setFilteredItems(tags)
    searchInputRef.current.value = ''
  }

  const toggleList = () => {
    if (ref.current.classList.contains('open')) {
      ref.current && ref.current.classList.remove('open')
    } else {
      ref.current && ref.current.classList.add('open')
    }
  }

  return (
    <>
      <div className="comment-dropdown__list-inner">
        {items.map((tag, i) => (
          <TagDropdownItem
            tag={tag}
            titleKey={titleKey}
            key={i}
            onSelectItem={onSelectItem}
          />
        ))}
      </div>
      <div className="comment-dropdown__search">
        <input
          ref={searchInputRef}
          className={clsx('comment-dropdown__search-input')}
          placeholder="Поиск"
          onInput={(ev) => {
            search(ev.target.value)
          }}
        />
        <button
          className={clsx('comment-dropdown__search-btn', {
            reset: isSearching(),
          })}
          onClick={resetSearch}
        >
          Поиск
        </button>
      </div>
    </>
  )
}
