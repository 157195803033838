import { Marker, Popup, useMap } from 'react-leaflet'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { addGeoSearchControl } from '$lib/vendor/leaflet/geocoder'
import 'leaflet-control-geocoder'
import '$lib/vendor/leaflet/geocoder/styles.css'
import {
  mapClicked,
  onSelectGeocodeResult,
  setMapCenter,
} from '$lib/osm/models/map'
import L, { Icon } from 'leaflet'
import { TextArea } from '$components/UI/Inputs/TextArea'
import { DeliveryZoneLayer } from '$components/Deliveries/Maps/OSM/DeliveryZoneLayer'
import { OpenStreetMap } from '$components/UI/Maps/OSM/OpenStreetMap'
import { getCityCoordinates } from '$lib/cities/helpers'
import { MapEmptyCoordinates } from '$root/utils/constants/map'
import { useDeliveryZones } from '$lib/deliveries/hooks/useDeliveryZones'

const GeocoderSearchControl = () => {
  const map = useMap()

  useEffect(() => {
    return addGeoSearchControl(map)
  }, [])

  return <></>
}

export const PickAddressWrapped = ({
  city,
  address,
  zones: zoneList,
  onChoice,
  closeModal,
}) => {
  const { zones } = useDeliveryZones(zoneList)

  const map = useMap()

  const [marker, setMarker] = useState()
  const popupRef = useRef()
  const markerRef = useRef()
  const popupIsOpened = useRef()

  const markerIcon = new Icon({
    iconUrl: '/marker-icon.png',
    iconSize: [25, 41],
  })

  const geocoder = L.Control.Geocoder.nominatim()

  const addressCoordinates = useMemo(() => {
    let addressPoint = address.point
    if (addressPoint) {
      if (addressPoint.geometry) {
        return addressPoint.geometry.coordinates
      }
      if (addressPoint.coordinates) {
        return addressPoint.coordinates
      }
    }
    return null
  }, [address])

  useEffect(() => {
    if (addressCoordinates) {
      setMarker({
        name: address.address,
        html: '',
        center: {
          lat: addressCoordinates[0],
          lng: addressCoordinates[1],
        },
        bbox: {
          _southWest: {
            lat: addressCoordinates[0],
            lng: addressCoordinates[1],
          },
          _northEast: {
            lat: addressCoordinates[0],
            lng: addressCoordinates[1],
          },
        },
        properties: {
          place_id: -1,
          licence:
            'Data © OpenStreetMap contributors, ODbL 1.0. https://osm.org/copyright',
          osm_type: 'relation',
          osm_id: -1,
          lat: addressCoordinates[0],
          lon: addressCoordinates[1],
          display_name: address.address,
        },
      })
    }
  }, [address])

  const markerAddress =
    (marker && marker.properties && marker.properties.address) || false

  const getMarkerAddress = (marker) => {
    let displayName = ''

    const geocodingProperties = marker.properties
    if (
      geocodingProperties.address &&
      (geocodingProperties.address.road ||
        geocodingProperties.address.neighbourhood) &&
      (geocodingProperties.address.house_number ||
        geocodingProperties.address.amenity)
    ) {
      displayName = [
        city.name,
        geocodingProperties.address.road ||
          geocodingProperties.address.neighbourhood,
        `${(geocodingProperties.address.house_number && 'д. ' + geocodingProperties.address.house_number) || geocodingProperties.address.amenity || '-'}`,
      ].join(', ')
    } else if (
      geocodingProperties.address &&
      geocodingProperties.address.road
    ) {
      displayName = `${city.name}, ${geocodingProperties.address.road}${(geocodingProperties.name && ', ' + geocodingProperties.name) || ''}`
    } else if (
      geocodingProperties.address &&
      geocodingProperties.address.city_district
    ) {
      displayName = `${city.name}, ${geocodingProperties.address.city_district}`
    } else {
      displayName = city.name
    }

    return displayName
  }

  useEffect(() => {
    let displayName = ''

    if (!marker) return

    if (marker.properties.display_name) return

    displayName = getMarkerAddress(marker)

    changeMarkerName(marker, displayName)
  }, [markerAddress])

  const changeMarkerName = (_marker, text) => {
    setMarker({
      ..._marker,
      properties: {
        ..._marker.properties,
        display_name: text,
      },
    })
  }

  useEffect(() => {
    const _marker = markerRef.current

    if (_marker) {
      setTimeout(() => {
        _marker.openPopup()
      }, 300)

      popupIsOpened.current = true
    }
  }, [marker])

  useEffect(() => {
    const unsubscribes = []

    unsubscribes.push(
      onSelectGeocodeResult.watch((ev) => {
        const geocode = ev.geocode

        const map = ev.target._map

        geocode.properties.display_name = getMarkerAddress(geocode)

        setMarker(geocode)

        map.fitBounds(geocode.bbox)
      }),
    )

    unsubscribes.push(
      mapClicked.watch((ev) => {
        geocoder.reverse(ev.latlng, map.options.crs.scale(18), (results) => {
          var result = results[0]
          if (result) {
            result.properties.display_name = getMarkerAddress(result)
            setMarker(result)
          }
        })
      }),
    )

    return () => {
      for (const unsubscribe of unsubscribes) {
        unsubscribe()
      }
    }
  }, [marker])

  return (
    <>
      <GeocoderSearchControl />

      {zones.map((zone) => (
        <DeliveryZoneLayer
          key={zone.properties.id}
          zone={zone}
          allowSelect={false}
        />
      ))}

      {marker && (
        <Marker
          icon={markerIcon}
          ref={markerRef}
          draggable={true}
          position={marker.center}
          eventHandlers={{
            dragend(event) {
              var _marker = event.target
              var position = _marker.getLatLng()
              _marker.setLatLng(position, { draggable: 'true' }).update()
            },
          }}
        >
          <Popup ref={popupRef} className="pick-address-popup">
            <div className="flex flex-column align-items-center">
              <TextArea
                className="w-100"
                placeholder="Адрес"
                value={marker.properties.display_name}
                onChange={(text) => {
                  changeMarkerName(marker, text)
                }}
              />
              <button
                className="btn btn__filled btn--default small"
                onClick={() => {
                  onChoice(map, {
                    ...address,
                    address: marker.properties.display_name,
                    point: markerRef.current.toGeoJSON(),
                  })
                  closeModal()
                }}
              >
                Выбрать
              </button>
            </div>
          </Popup>
        </Marker>
      )}
    </>
  )
}

export const PickAddress = ({ city, ...other }) => {
  const center = useMemo(() => {
    return (city && getCityCoordinates(city)) || MapEmptyCoordinates
  }, [city])

  useEffect(() => {
    setMapCenter(center)
  }, [center])

  return (
    <OpenStreetMap
      center={center}
      zoom={10}
      zoomControl={true}
      style={{
        height: '100%',
        width: '100%',
        zIndex: 0,
      }}
    >
      <PickAddressWrapped city={city} {...other} />
    </OpenStreetMap>
  )
}
