import { useDateTime } from '$hooks/useDateTime'
import { DeliveryContactField } from '$components/Leads/Statused/Entities/Deliveries/DataGrid/Fields/DeliveryContactField'
import { DeliveryRationField } from '$components/Leads/Statused/Entities/Deliveries/DataGrid/Fields/DeliveryRationField'
import { DeliveryAmountField } from '$components/Leads/Statused/Entities/Deliveries/DataGrid/Fields/DeliveryAmountField'
import { StatusReasonField } from '$components/Leads/Statused/DataGrid/Fields/StatusReasonField'
import { LeadChangeStatusAction } from '$components/Leads/Statused/Entities/Leads/DataGrid/Fields/LeadChangeStatusAction'

export const ProblemDeliveriesItem = ({ item: delivery }) => {
  const { dateVerbosed } = useDateTime()
  return (
    <>
      <DeliveryContactField delivery={delivery} />

      <div className="deal__freeze deal__freeze--progress">
        <div className="deal__contact-date">
          {dateVerbosed(new Date(delivery.delivery_date))}
        </div>
        {/*<DeliveryChatField delivery={ delivery }/>*/}
        <DeliveryRationField delivery={delivery} />
        <StatusReasonField reason={delivery.status_comment} />
        <DeliveryAmountField delivery={delivery} />
        <LeadChangeStatusAction lead={delivery} />
      </div>
    </>
  )
}
