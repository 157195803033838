import React, { useContext } from 'react'
import { ClientContactsContext } from '$components/Clients/Fields/Contacts/ClientContacts'
import { usePage } from '@inertiajs/react'
import { getEnumItemById } from '$root/utils/enums'

export const CityContact = () => {
  const { enums } = usePage().props
  const { client } = useContext(ClientContactsContext)

  const city = getEnumItemById(enums.cities, client.city_id)

  return (
    client.city_id && (
      <tr>
        <td>Город</td>
        <td>{(city && city.name) || '<>'}</td>
      </tr>
    )
  )
}
