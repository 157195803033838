import React from 'react'

import { pageSelfCleans } from '$lib/page/store'
import { observer, useLocalObservable } from 'mobx-react'

const globalStore = {
  pageSelfCleans,
}

const StoreContext = React.createContext(globalStore)

export const StoreProvider = observer(({ children }) => {
  const stores = useLocalObservable(() => globalStore)

  return (
    <StoreContext.Provider value={stores}>{children}</StoreContext.Provider>
  )
})

export const useGlobalStore = () => {
  return React.useContext(StoreContext)
}
