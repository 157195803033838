import { TabCode } from '$lib/leads/statused/public/tab-codes.const'
import { NewLeadsItem } from '$components/Leads/Statused/Entities/Leads/New/NewLeadsItem'
import { PendingLeadsItem } from '$components/Leads/Statused/Entities/Leads/Pending/PendingLeadsItem'
import { ActiveDeliveriesItem } from '$components/Leads/Statused/Entities/Deliveries/Active/ActiveDeliveriesItem'
import { ProlongFrozenClientsitem } from '$components/Leads/Statused/Entities/Clients/Prolong/ProlongClientsitem'
import { ProblemDeliveriesItem } from '$components/Leads/Statused/Entities/Deliveries/Problem/ProblemDeliveriesItem'
import { DatePickerFilter } from '$components/Leads/Statused/DataGrid/Filters/DatePickerFilter'
import { DropdownFilter } from '$components/UI/DataGrid/Filters/Dropdown'
import { RationFilter } from '$components/Leads/Statused/DataGrid/Filters/RationFilter'

export const MappedItemComponentByStatus = {
  [TabCode.new]: NewLeadsItem,
  [TabCode.pending]: PendingLeadsItem,
  [TabCode.active]: ActiveDeliveriesItem,
  [TabCode.prolong]: ProlongFrozenClientsitem,
  [TabCode.frozen]: ProlongFrozenClientsitem,
  [TabCode.archive]: ProlongFrozenClientsitem,
  [TabCode.problem]: ProblemDeliveriesItem,
}

const DeliveriesFilters = [
  [
    DatePickerFilter,
    {
      field: 'delivery_date',
      // multiple: true,
    },
  ],
  [
    DropdownFilter,
    {
      field: 'debt',
      titleKey: 'name',
      valueKey: 'value',
      emptyText: 'Все',
      variants: [
        { name: 'Есть долг', value: '1' },
        { name: 'Нет долга', value: '0' },
      ],
    },
  ],
  [
    RationFilter,
    {
      field: 'ration',
      titleKey: 'name',
      valueKey: 'value',
      emptyText: 'Рацион',
      multiple: true,
    },
  ],
]

export const MappedFilterComponentsByStatus = {
  [TabCode.active]: DeliveriesFilters,
  [TabCode.problem]: DeliveriesFilters,
}
