import clsx from 'clsx'
import { Routes } from '$root/utils/constants/routes'
import { Link, usePage } from '@inertiajs/react'
import { useModals } from '$hooks/useModals'
import { SideModal } from '$components/UI/Modals/SideModal'
import { StaffTransactions } from '$components/Staff/Transactions/StaffTransactions'
import { usePermissions } from '$hooks/usePermissions'
import { goExportRoute } from '$root/utils/functions'

export const KitchenHeader = () => {
  const { enums } = usePage().props
  const { openModal } = useModals()
  const { isAllow } = usePermissions()
  const isActive = (checkingRoute) => {
    return route().current() === checkingRoute
  }

  const showSalaryModal = () => {
    openModal('KithenSalary', SideModal, {
      contentComponent: StaffTransactions,
      title: 'Расчет зарплаты',
      users: enums.employees || [],
      salaryRates: enums.salaryRates,
      routes: { save: Routes.kitchen.save_salary },
    })
  }

  const cooksIsActive = isActive(Routes.kitchen.cooks)
  const packersIsActive = isActive(Routes.kitchen.packers)
  const replacementsIsActive = isActive(Routes.kitchen.replacements)

  const isAllowAddTransactions =
    (cooksIsActive && isAllow('kitchen.cooks.edit')) ||
    (packersIsActive && isAllow('kitchen.packers.edit')) ||
    (replacementsIsActive && isAllow('kitchen.replacements.edit'))

  let exportRoute = null

  if (isActive(Routes.kitchen.cooks)) {
    exportRoute = 'kitchen.cooks.export'
  } else if (isActive(Routes.kitchen.packers)) {
    exportRoute = 'kitchen.packers.export'
  } else if (isActive(Routes.kitchen.replacements)) {
    exportRoute = 'kitchen.replacements.export'
  }

  return (
    <div className="second-head">
      <div className="tab-category">
        <Link
          href={route(Routes.kitchen.cooks)}
          className={clsx('tab-category__btn', {
            active: isActive(Routes.kitchen.cooks),
          })}
        >
          Для поваров
        </Link>
        <Link
          href={route(Routes.kitchen.packers)}
          className={clsx('tab-category__btn', {
            active: isActive(Routes.kitchen.packers),
          })}
        >
          Для фасовщиков
        </Link>
        <Link
          href={route(Routes.kitchen.replacements)}
          className={clsx('tab-category__btn', {
            active: isActive(Routes.kitchen.replacements),
          })}
        >
          Замены
        </Link>
      </div>
      <div className="second-head__inner">
        {isAllowAddTransactions && (
          <button
            className="second-head__btn second-head__btn--payment js-modal-payroll-open"
            onClick={showSalaryModal}
          >
            Начислить оплату
          </button>
        )}
        <button
          className="second-head__btn second-head__btn--print"
          onClick={() => goExportRoute(exportRoute)}
        >
          Печать
        </button>
      </div>
    </div>
  )
}
