import { usePage } from '@inertiajs/react'
import {
  getActiveTabCode,
  isNeedFiltersWrapper,
} from '$lib/leads/statused/public/helpers'
import { MappedFilterComponentsByStatus } from '$components/Leads/utils/const/mapped'
import { useContext } from 'react'
import { DataGridContext } from '$components/UI/DataGridCompound/DataGridCompound'

const ProgressFiltersWrapper = ({ children }) => {
  return <div className="deal-head">{children}</div>
}

const FiltersWrapper = ({ children }) => {
  const { enums: { tabs = [] } = {} } = usePage().props

  const isNeedWrapper = isNeedFiltersWrapper(tabs)
  if (isNeedWrapper) {
    return <ProgressFiltersWrapper>{children}</ProgressFiltersWrapper>
  }
  return <>{children}</>
}

export const Filters = () => {
  const { data } = useContext(DataGridContext)

  const { enums: { tabs = [] } = {} } = usePage().props

  const activeTabCode = getActiveTabCode(tabs)
  const filters =
    (activeTabCode && MappedFilterComponentsByStatus[activeTabCode]) || []

  return (
    <>
      <FiltersWrapper>
        {filters.map(([FilterComponent, options], i) => (
          <FilterComponent {...{ ...data, ...options, key: i }} />
        ))}
      </FiltersWrapper>
    </>
  )
}
