import { MapContainer, TileLayer, useMapEvents } from 'react-leaflet'
import React, { useEffect, useRef } from 'react'
import { extendOSMClasses } from '$lib/osm/functions'
import {
  deliveryZoneDeleted,
  deliveryZoneSelected,
  newDeliveryZone,
  setCurrentCity,
} from '$lib/deliveries/models/zones'
import { mapClicked, setMapCenter } from '$lib/osm/models/map'
import '$lib/vendor/leaflet/leaflet.css'

extendOSMClasses()

const MapFeatures = ({ children, ...props }) => {
  const map = useMapEvents({
    click: (ev) => {
      ev.originalEvent.preventDefault()

      mapClicked(ev)
      let clickOther = true
      const latlng = ev.latlng

      map.eachLayer((layer) => {
        if (layer.properties && layer.getBounds) {
          const bounds = layer.getBounds()

          if (bounds.isValid() && bounds.contains(latlng)) {
            clickOther = false
          }
        }
      })

      if (clickOther) {
        deliveryZoneSelected(null)
      }
    },
  })

  const mapRef = useRef(map)

  useEffect(() => {
    const unsubscribes = []

    unsubscribes.push(
      deliveryZoneDeleted.watch(() => deliveryZoneSelected(null)),
    )
    // unsubscribes.push(deliveryZoneEdited.watch(() => deliveryZoneSelected(null)))
    unsubscribes.push(newDeliveryZone.watch(() => deliveryZoneSelected(null)))
    unsubscribes.push(
      setMapCenter.watch(({ coordinates }) => {
        mapRef.current.setView(coordinates, props.zoom || 10, {
          animation: true,
        })
      }),
    )
    return () => {
      for (const unsubscribe of unsubscribes) {
        unsubscribe()
      }
    }
  }, [])

  window.map = map
  return children
}

export const OpenStreetMap = ({ children, ...props }) => {
  return (
    <MapContainer {...props}>
      <MapFeatures {...props}>
        <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
        {children}
      </MapFeatures>
    </MapContainer>
  )
}
