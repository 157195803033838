import { DeliveriesColumns } from '$components/Leads/Statused/Entities/Deliveries/DataGrid/DeliveriesColumns'
import { ClientsBody } from '$components/Leads/Statused/Entities/Clients/DataGrid/ClientsBody'
import { LeadsBody } from '$components/Leads/Statused/Entities/Leads/DataGrid/LeadsBody'
import { DeliveriesBody } from '$components/Leads/Statused/Entities/Deliveries/DataGrid/DeliveriesBody'
import { TabCode } from '$lib/leads/statused/public/tab-codes.const'

export const TabsWithFiltersWrapper = [
  TabCode.active,
  TabCode.problem,
  TabCode.archive,
]

export const TabsGridColumns = {
  [TabCode.active]: DeliveriesColumns,
}
