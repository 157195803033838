import { Modal } from '$components/UI/Modals/Modal'
import OrderGiven from '$components/Clients/Orders/OrderGiven'
import { EffectorStore } from '$root/utils/effector/effector-services'
import OrderProblem from '$components/Clients/Orders/OrderProblem'

export class DeliveryActionsStore extends EffectorStore {
  delivery = null

  constructor({ delivery, ...superProps }) {
    super(superProps)
    this.delivery = delivery
  }

  openModal = () => {}

  init({ openModal }) {
    this.openModal = openModal
  }

  onGiven(onFillReason) {
    this.openModal('OrderGiven', Modal, {
      classes: { wrapper: 'order-given' },
      title: 'Отдано клиенту',
      contentComponent: OrderGiven,
      order: this.delivery,
      onSave: (data) => onFillReason(data),
    })
  }

  onProblem(onFillReason) {
    this.openModal('OrderProblem', Modal, {
      classes: { wrapper: 'order-given' },
      title: 'Проблема',
      contentComponent: OrderProblem,
      order: this.delivery,
      onSave: (data) => onFillReason(data),
    })
  }
}
