import React from 'react'
import { useStore } from 'effector-react'
import { $dataGrid, dataGridIsChanged } from '$models/dataGrid'
import { deferred } from '$root/utils/functions'

export const PageHeader = () => {
  const dataGrid = useStore($dataGrid)

  const deferredSearch = deferred((options) => {
    dataGridIsChanged({ search: options.search })
  })

  return (
    <div className="header__wrap">
      <label className="search">
        {/* TODO: <Input {...options}/> */}
        <input
          className="search__input"
          type="text"
          placeholder="По имени, фамилии или телефону"
          defaultValue={dataGrid.search}
          onInput={(ev) => {
            deferredSearch({ search: ev.target.value }, 1.5)
          }}
        />
        <button className="search__btn">Поиск</button>
      </label>
    </div>
  )
}
