import { KitchenHeader } from '$components/Kitchen/Header'
import { Routes } from '$root/utils/constants/routes'
import { KitchenCooksTable } from '$components/Kitchen/KitchenCooksTable'
import { useDataGrid } from '$hooks/useDataGrid'
import { KitchenPackersTable } from '$components/Kitchen/KitchenPackersTable'
import { KitchenReplacementsTable } from '$components/Kitchen/KitchenReplacementsTable'
import { useDataGridCompound } from '$hooks/useDataGridCompound'

export const Kitchen = ({ data }) => {
  const currentRoute = route().current()

  useDataGridCompound({
    data: {
      ...data,
      filter: data.filter || { delivery_date: 'today' },
    },
    route: currentRoute,
  })

  const ComponentByStatus = {
    [Routes.kitchen.cooks]: KitchenCooksTable,
    [Routes.kitchen.packers]: KitchenPackersTable,
    [Routes.kitchen.replacements]: KitchenReplacementsTable,
  }

  const Component = ComponentByStatus[currentRoute]

  return (
    <>
      <KitchenHeader />
      <Component />
    </>
  )
}
