import { Filters } from '$components/Leads/Statused/DataGrid/Header/Filters/Filters'
import { Tabs } from '$components/Leads/Statused/DataGrid/Header/Tabs/Tabs'
import { Columns } from '$components/Leads/Statused/DataGrid/Header/Columns'

export const Header = ({ children }) => {
  return children
}

Header.Tabs = Tabs
Header.Filters = Filters
Header.Columns = Columns
