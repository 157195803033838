export const getFirstContactByType = (client, contactType) => {
  const firstContact =
    (client.contacts &&
      _.first(
        client.contacts.filter(
          (contact) => contact.contact_type_code === contactType,
        ),
      )) ||
    null
  return (
    (client.contacts &&
      client.contacts.length &&
      !!firstContact &&
      firstContact.value) ||
    ''
  )
}
