import clsx from 'clsx'
import { verboseCurrency } from '$root/utils/functions'

export const OtherSalaries = ({ salary, onSelect, isSelected }) => {
  return (
    <button
      className={clsx('reports-stats__btn', { open: isSelected })}
      onClick={onSelect}
    >
      <span className="reports-stats__btn-title">Дополнительно</span>
      <span className="reports-stats__btn-value">
        {verboseCurrency(salary)}
      </span>
    </button>
  )
}
