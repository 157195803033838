const InvoiceCancelBtn = ({ onPay, route }) => {
  return (
    <div
      className="invoice-header-btn invoice-header-btn__cancel"
      onClick={() => onPay({ route })}
    >
      Отменить
    </div>
  )
}

export default InvoiceCancelBtn
