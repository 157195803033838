import { retrieveChild } from '$root/utils/functions'
import React from 'react'

export const SpecificChild = ({
  childs,
  children,
  type,
  DefaultComponent = ({ children: nestedChildren }) => <>{nestedChildren}</>,
  ...other
}) => {
  const child = retrieveChild(childs, type)
  if (!!child && _.keys(other).length) {
    return React.cloneElement(child, { ...other })
  }

  return child || <DefaultComponent>{children}</DefaultComponent>
}
