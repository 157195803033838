import React, { useRef } from 'react'
import { TagsDropdownInput } from '$components/UI/Inputs/TagsDropdown/TagsDropdownInput'
import { UNDEFINED_TITLE } from '$root/utils/constants/service'
import { NoResults } from '$components/UI/DataGrid/NoResults'

export const KitchenComments = ({
  client,
  items,
  onChange,
  disabled = false,
}) => {
  const resetCallback = useRef((activeTagService) => {
    activeTagService.reset()
  })

  return (
    <>
      <p className="modal-right__title">Комментарий на кухне</p>
      {(disabled && client.kitchen_comments.length === 0 && (
        <NoResults title={false} text="------" className="no-padding" />
      )) || (
        <TagsDropdownInput
          items={items}
          values={client.kitchen_comments}
          titleKey={(item) => item.name || UNDEFINED_TITLE}
          onChange={onChange}
          disabled={disabled}
          resetCallback={resetCallback}
          persistKey={'client-kitchen'}
        />
      )}
    </>
  )
}
