import React, { useState } from 'react'
import { Link } from '@inertiajs/react'
import clsx from 'clsx'
import { Menu } from '$components/UI/Sidebar/Menu/Menu'
import { MenuItemIcon } from '$components/UI/Sidebar/Menu/MenuItemIcon'
import { menuStateIsChanged } from '$models/menu'

export const MenuItem = ({ item }) => {
  const [innerIsActive, setInnerIsActive] = useState(false)

  const onClickItemHandler = (event) => {
    event.preventDefault()
    setInnerIsActive(!innerIsActive)
    menuStateIsChanged(false)
  }

  return (
    <>
      <Link
        className={clsx(item.attributes.class, { active: item.active })}
        href={item.children.length <= 0 ? item.url : '#'}
        onClick={(event) =>
          item.children.length > 0 ? onClickItemHandler(event) : undefined
        }
      >
        {item.attributes.icon ? (
          <span>
            <MenuItemIcon icon={item.attributes.icon} />
          </span>
        ) : null}
        <span>{item.title}</span>
      </Link>
      {item.children.length > 0 ? (
        <Menu
          className={clsx('menu__inner', { active: innerIsActive })}
          menu={item.children}
        />
      ) : null}
    </>
  )
}
