import { router, usePage } from '@inertiajs/react'
import { Routes } from '$root/utils/constants/routes'
import { unhandledError } from '$root/utils/functions'
import { TabCode } from '$lib/leads/statused/public/tab-codes.const'
import { onPostSended } from '$models/page'
import { ActionCode } from '$components/Leads/Statused/Entities/Leads/Modal/ChangeStatus/utils/const'
import { OrderStatus } from '$lib/orders/utils/order-status-const'
import { ClientStatus } from '$lib/clients/utils'

export const useLeads = () => {
  const { enums } = usePage().props

  const newClientInfoByLead = (lead) => {
    return {
      last_name: lead.last_name,
      name: lead.name,
      second_name: lead.second_name,
      contacts: [
        {
          value: lead.contact,
          contact_type_code: lead.contact_type_code,
          contact_type_id: lead.contact_type_id,
        },
      ],
    }
  }

  const handleProgress = (lead, data) => {
    const ifNewClient = (lead, page_status, lead_status) => {
      router.get(
        route(Routes.leads.clients.save, {
          id: lead.id,
          status: page_status,
          lead_status,
          ...route().params,
        }),
      )
    }

    switch (lead.status) {
      case TabCode.new:
      case TabCode.pending:
        if (lead.client) {
          handleUpdate(lead, data)
        } else {
          ifNewClient(lead, TabCode.new, data.status)
        }
        break
    }
  }

  const handleUpdate = (lead, data) => {
    if (!enums.tabs) {
      unhandledError()
      return
    }
    const status = data.status ? data.status : lead.status
    delete data.status

    const statusID = status

    if (!statusID) {
      unhandledError()
      return
    }

    const sendingData = {
      ...data,
      id: lead.id,
      [data.status === TabCode.archive ? 'lead_status' : 'status']: status,
    }

    router.post(
      route(Routes.leads.update, {
        status: status,
        id: lead.id,
      }) + (statusID === lead.status_id ? location.search : ''),
      sendingData,
      {
        onSuccess() {
          onPostSended()
        },
      },
    )
  }

  const handleCustomAction = (entity, data) => {
    const id = entity.id

    let url = null

    let sending = {}

    switch (data.status) {
      case ActionCode.ProblemNewDate:
        url = route(Routes.leads.update, {
          id,
          status: TabCode.problem,
        })

        sending = {
          id,
          client_id: entity.client.id,
          delivery_date: data.delivery_date,
          status: data.new_status,
        }
        break
      case ActionCode.ProblemCancel:
        url = route(Routes.leads.update, {
          id,
          status: TabCode.problem,
        })

        sending = {
          status: OrderStatus.Canceled,
        }
        break
      case ActionCode.ProblemReturnCourier:
        url = route(Routes.leads.update, {
          id,
          status: TabCode.problem,
        })

        sending = {
          status: OrderStatus.Active,
          status_comment: data.status_comment,
        }
        break
      case ActionCode.ProblemToFreeze:
        url = route(Routes.leads.clients.save_status, {
          id,
          status: TabCode.problem,
        })

        sending = {
          status_comment: data.status_comment,
          contact_at: data.contact_at,
          status: ClientStatus.Frozen,
        }
        break
    }
    router.post(url, sending)
  }

  const update = (lead, data) => {
    if (_.values(TabCode).includes(data.status)) {
      if (lead?.status == TabCode.new) {
        switch (data.status) {
          case TabCode.archive:
          case TabCode.pending:
            handleProgress(lead, data)
            break
        }
      } else {
        switch (data.status) {
          case TabCode.archive:
            handleProgress(lead, data)
            break
          default:
            handleUpdate(lead, data)
            break
        }
      }
    } else if (_.values(ActionCode).includes(data.status)) {
      handleCustomAction(lead, data)
    }
  }
  return {
    newClientInfoByLead,
    update,
  }
}
