import { isActiveTab } from '$components/Settings/Leads/utils/functions'
import {
  LeadSettingsRoutes,
  LeadSettingsTab,
} from '$components/Settings/Leads/utils/const'
import clsx from 'clsx'
import { router } from '@inertiajs/react'

export const TabLinks = () => {
  const tabs = [
    { code: LeadSettingsTab.Tags, name: 'Комментарии' },
    { code: LeadSettingsTab.Clients, name: 'Клиенты' },
    { code: LeadSettingsTab.Products, name: 'Рационы' },
  ]

  const goToTab = (code) => {
    router.get(route(LeadSettingsRoutes[code]))
  }

  return (
    <div className="second-head">
      <div className="tab-category">
        {tabs.map((tab, i) => (
          <button
            key={i}
            className={clsx('tab-category__btn', {
              active: isActiveTab(tab.code),
            })}
            onClick={() => goToTab(tab.code)}
          >
            {tab.name}
          </button>
        ))}
      </div>
    </div>
  )
}
