import { useDateTime } from '$hooks/useDateTime'
import { OrderStatus } from '$lib/orders/utils/order-status-const'

export const useOrderSuggest = (orders, tableStatus) => {
  const { date, nextDay, prevDay } = useDateTime()

  const listIsEmpty = !orders.length

  const convertedDates = orders.map((order) => date(order.delivery_date))

  const prevDateIsBusy = (order, i) => {
    return convertedDates.length
      ? i > 0 &&
          (convertedDates[i - 1] === convertedDates[i] ||
            convertedDates[i - 1] === prevDay(convertedDates[i]))
      : true
  }

  const dates = orders
    .map((order, i) => {
      return {
        suggestedDate: convertedDates.length ? convertedDates[i] : null,
        hide: prevDateIsBusy(order, i),
      }
    })
    .sort()

  const lastDate =
    (!listIsEmpty && orders[orders.length - 1].delivery_date) || null

  const isActive = (order) => {
    return order.status === OrderStatus.Active
  }

  const isCanceled = (order) => {
    return order.status === OrderStatus.Canceled
  }

  const suggestFill = (order, i) => {
    return (
      (isActive(order) || isCanceled(order)) &&
      i > 0 &&
      date(nextDay(orders[i - 1].delivery_date)) !==
        date(order.delivery_date) &&
      date(nextDay(orders[i - 1].delivery_date)) !==
        date(prevDay(order.delivery_date))
    )
  }

  const needSuggest = (order, i) => {
    return (
      (isActive(order) || isCanceled(order)) &&
      (i === 0 ||
        (i > 0 &&
          date(orders[i - 1].delivery_date) !==
            date(prevDay(order.delivery_date))))
    )
  }

  const wrapSuggest = (order, i) => {
    const suggestFillOrder = suggestFill(order, i)
    const needSuggestOrder = needSuggest(order, i)
    let suggestDate = null
    let suggestLastDay = null
    let isLastSuggest = false
    let hide = dates[i].hide

    if (suggestFillOrder) {
      suggestDate = [
        nextDay(orders[i - 1].delivery_date),
        prevDay(order.delivery_date),
      ]
    } else if (needSuggestOrder) {
      suggestDate = prevDay(order.delivery_date)
    }
    if (i === orders.length - 1) {
      suggestLastDay = lastDate ? nextDay(lastDate) : new Date().toISOString()
      isLastSuggest = isActive(order) || isCanceled(order)
    }

    return {
      ...order,
      suggestFill: suggestFillOrder,
      needSuggest: needSuggestOrder,
      suggestDate,
      suggestLastDay,
      isLastSuggest,
      hide,
    }
  }

  return {
    wrapSuggest,
    orders,
    listIsEmpty,
    convertedDates,
    prevDateIsBusy,
    dates,
    lastDate,
    suggestFill,
    needSuggest,
  }
}
