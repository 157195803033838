import { DataGridCompound } from '$components/UI/DataGridCompound/DataGridCompound'
import { Header } from '../Header'
import { Body } from './Body'
import { DataGridPagination } from '$components/UI/DataGrid/DataGridPagination'
import { TableDataGridWrapper } from '$components/UI/DataGrid/Table/Wrapper'

export const ReportsCouriersFinancesDataGrid = ({ data, children, route }) => {
  return (
    <DataGridCompound data={data} route={route}>
      {children}
    </DataGridCompound>
  )
}

ReportsCouriersFinancesDataGrid.Wrapper = TableDataGridWrapper
ReportsCouriersFinancesDataGrid.Header = Header
ReportsCouriersFinancesDataGrid.Body = Body
ReportsCouriersFinancesDataGrid.Footer = DataGridPagination
