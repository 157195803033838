import { TagItem } from '$components/Settings/Leads/TagsTab/TagItem'
import { usePage } from '@inertiajs/react'
import { router } from '@inertiajs/react'
import { Routes } from '$root/utils/constants/routes'
import { useMemo } from 'react'
import { onDeleteSended } from '$models/page'

export const StatusTagsPanel = ({ status }) => {
  const { tags } = usePage().props

  const tagsByStatus = useMemo(() => {
    return !!tags && !!tags[status.code] ? tags[status.code] : []
  }, [status])

  const onTagDelete = (tag) => {
    router.delete(route(Routes.settings.leads.remove_tag), {
      data: { id: tag.id },
      onSuccess() {
        onDeleteSended()
      },
    })
  }

  return (
    <div className="comments__panel">
      <p className="comments__title">{status.name ? status.name : '</?>'}</p>
      <div className="comments__items">
        {(tagsByStatus.length &&
          tagsByStatus.map((tag, i) => (
            <TagItem tag={tag} onDelete={onTagDelete} key={i} />
          ))) || <p className="no-result__text">Тэгов нет</p>}
      </div>
    </div>
  )
}
