import { createEffect, createEvent, createStore, sample } from 'effector'
import { Routes } from '$root/utils/constants/routes'
import {
  normalizeZoneDataToSend,
  withRandomColor,
} from '$lib/deliveries/helpers/zone'
import { router } from '@inertiajs/react'

export const deliveryZoneSelected = createEvent()
export const deliveryZoneChangeVisibility = createEvent()
export const deliveryZoneDeleted = createEvent()
export const deliveryZoneEdited = createEvent()
export const deliveryZoneSave = createEvent()

export const setCurrentCity = createEvent()
export const changeCurrentCityFilter = createEvent()

export const setZones = createEvent()
export const newDeliveryZone = createEvent()

export const resetSelectedZoneID = createEvent()
export const $selectedZoneID = createStore(
  (route().current() === Routes.deliveries.zone &&
    parseInt(route().params.id)) ||
    null,
)
  .on(deliveryZoneSelected, (__, id) => id)
  .reset(resetSelectedZoneID)

export const resetDeliveryZones = createEvent()
export const $deliveryZones = createStore([])
  .on(setZones, (__, zones) => zones)
  .on(deliveryZoneEdited, (zones, { id, geoObject, ...data }) => {
    const withUpdatedZones = [...zones]
    const zoneIndex = zones.findIndex((zone) => zone.properties.id === id)
    if (zoneIndex > -1) {
      let updatedZone = zones[zoneIndex]
      if (_.keys(data).length) {
        updatedZone = { ...updatedZone, ...data }
      }
      if (geoObject) {
        updatedZone.geometry.coordinates = geoObject.geometry.coordinates
      }
      withUpdatedZones.splice(zoneIndex, 1, updatedZone)
    }
    return withUpdatedZones
  })
  .on(deliveryZoneChangeVisibility, (zones, id) => {
    const withUpdatedZones = [...zones]
    const zoneIndex = zones.findIndex((zone) => zone.properties.id === id)
    if (zoneIndex > -1) {
      const updatedZone = zones[zoneIndex]
      updatedZone.visibility =
        updatedZone.visibility === undefined ? false : !updatedZone.visibility
    }
    return withUpdatedZones
  })
  .on(deliveryZoneDeleted, (zones, id) => {
    const withUpdatedZones = [...zones]
    const zoneIndex = zones.findIndex((zone) => zone.properties.id === id)
    if (zoneIndex > -1) {
      withUpdatedZones.splice(zoneIndex, 1)
    }
    return withUpdatedZones
  })
  .reset(resetDeliveryZones)

export const resetCurrentCity = createEvent()
export const $currentCity = createStore(null)
  .on(setCurrentCity, (state, city) => {
    return { ...city }
  })
  .reset(resetCurrentCity)

const newDeliveryZoneFx = createEffect(({ zone, cityID }) => {
  const zones = $deliveryZones.getState()

  let newZone = {
    ...zone,
    properties: {
      ...zone.properties,
      name: `Зона доставки ${zones.length + 1}`,
      price: 0,
      city_id: cityID,
    },
  }

  newZone = { ...withRandomColor(newZone) }

  try {
    router.post(
      route(Routes.settings.delivery_zones.new),
      normalizeZoneDataToSend(newZone, cityID),
    )
  } catch (e) {
    console.error(e)
  }

  return [...zones, newZone]
})

const deliveryZoneEditFx = createEffect(([zones, id, geoObject, cityID]) => {
  if (geoObject) {
    const zoneIndex = zones.findIndex((zone) => zone.properties.id === id)

    if (zoneIndex > -1) {
      try {
        const zone = zones[zoneIndex]
        zone.geometry.coordinates = geoObject.geometry.coordinates

        if (zone.properties.id.toString().indexOf('*') < 0) {
          router.post(
            route(Routes.settings.delivery_zones.edit),
            normalizeZoneDataToSend(zone, cityID),
          )
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
})

const deliveryZoneSaveFx = createEffect(([zones, city, id]) => {
  const zoneIndex = zones.findIndex((zone) => zone.properties.id === id)

  if (zoneIndex > -1) {
    try {
      const zone = zones[zoneIndex]
      if (zone.properties.id.toString().indexOf('*') > -1) {
        delete zone.properties.id
      }
      zone.properties.city_id = (city && city.id) || null
      router.post(
        route(Routes.settings.delivery_zones.edit),
        normalizeZoneDataToSend(zone, (city && city.id) || null),
      )
    } catch (e) {
      console.error(e)
    }
  }
})

export const deliveryZoneDeleteFx = createEffect((id) => {
  const zones = $deliveryZones.getState()

  const zoneIndex = zones.findIndex((zone) => {
    return zone.properties.id === id
  })

  if (zoneIndex > -1) {
    try {
      const zone = zones[zoneIndex]
      if (zone.properties.id.toString().indexOf('*') < 0) {
        router.delete(route(Routes.settings.delivery_zones.delete), {
          data: { id },
        })
      }
    } catch (e) {
      console.error(e)
    }
  }
  return id
})

const deliveryZonesCityChangeFx = createEffect((city) => {
  router.get(route(route().current()), { ...route().params, city_id: city.id })
})

sample({
  clock: newDeliveryZone,
  target: newDeliveryZoneFx,
})

sample({
  clock: newDeliveryZoneFx.doneData,
  target: $deliveryZones,
})

sample({
  clock: deliveryZoneSave,
  source: [$deliveryZones, $currentCity],
  fn: ([zones, city], id) => [zones, city, id],
  target: deliveryZoneSaveFx,
})

sample({
  clock: deliveryZoneEdited,
  source: [$deliveryZones, $currentCity],
  fn: ([zones, city], { id, geoObject }) => [zones, id, geoObject, city.id],
  target: deliveryZoneEditFx,
})

sample({
  clock: deliveryZoneDeleteFx.doneData,
  target: deliveryZoneDeleted,
})

sample({
  clock: changeCurrentCityFilter,
  target: deliveryZonesCityChangeFx,
})
