import { DataGridCompound } from '$components/UI/DataGridCompound/DataGridCompound'
import { Routes } from '$root/utils/constants/routes'
import { Body } from './Body'
import { DataGridPagination } from '$components/UI/DataGrid/DataGridPagination'
import { Wrapper } from './Wrapper'

const demoData = {
  rows: [
    {
      full_name: 'Чебурашкин Вася',
      active: {
        count: 220,
        amount: 15800,
      },
      new: {
        count: 3,
        amount: 0,
      },
      pending: {
        count: 5,
        amount: -100,
      },
      prolong: {
        count: 3,
        amount: 2300,
      },
      frozen: {
        count: 3,
        amount: 2300,
      },
      profit: 0,
      loss: 0,
      effectiveness: 0,
    },
    {
      full_name: 'Чебурашкин Вася',
      active: {
        count: 220,
        amount: 15800,
      },
      new: {
        count: 3,
        amount: 0,
      },
      pending: {
        count: 5,
        amount: -100,
      },
      prolong: {
        count: 3,
        amount: 2300,
      },
      frozen: {
        count: 3,
        amount: 2300,
      },
      profit: 0,
      loss: 0,
      effectiveness: 0,
    },
    {
      full_name: 'Чебурашкин Вася',
      active: {
        count: 220,
        amount: 15800,
      },
      new: {
        count: 3,
        amount: 0,
      },
      pending: {
        count: 5,
        amount: -100,
      },
      prolong: {
        count: 3,
        amount: 2300,
      },
      frozen: {
        count: 3,
        amount: 2300,
      },
      profit: 0,
      loss: 0,
      effectiveness: 0,
    },
    {
      full_name: 'Чебурашкин Вася',
      active: {
        count: 220,
        amount: 15800,
      },
      new: {
        count: 3,
        amount: 0,
      },
      pending: {
        count: 5,
        amount: -100,
      },
      prolong: {
        count: 3,
        amount: 2300,
      },
      frozen: {
        count: 3,
        amount: 2300,
      },
      profit: 0,
      loss: 0,
      effectiveness: 0,
    },
    {
      full_name: 'Чебурашкин Вася',
      active: {
        count: 220,
        amount: 15800,
      },
      new: {
        count: 3,
        amount: 0,
      },
      pending: {
        count: 5,
        amount: -100,
      },
      prolong: {
        count: 3,
        amount: 2300,
      },
      frozen: {
        count: 3,
        amount: 2300,
      },
      profit: 0,
      loss: 0,
      effectiveness: 0,
    },
    {
      full_name: 'Чебурашкин Вася',
      active: {
        count: 220,
        amount: 15800,
      },
      new: {
        count: 3,
        amount: 0,
      },
      pending: {
        count: 5,
        amount: -100,
      },
      prolong: {
        count: 3,
        amount: 2300,
      },
      frozen: {
        count: 3,
        amount: 2300,
      },
      profit: 0,
      loss: 0,
      effectiveness: 0,
    },
    {
      full_name: 'Чебурашкин Вася',
      active: {
        count: 220,
        amount: 15800,
      },
      new: {
        count: 3,
        amount: 0,
      },
      pending: {
        count: 5,
        amount: -100,
      },
      prolong: {
        count: 3,
        amount: 2300,
      },
      frozen: {
        count: 3,
        amount: 2300,
      },
      profit: 0,
      loss: 0,
      effectiveness: 0,
    },
    {
      full_name: 'Чебурашкин Вася',
      active: {
        count: 220,
        amount: 15800,
      },
      new: {
        count: 3,
        amount: 0,
      },
      pending: {
        count: 5,
        amount: -100,
      },
      prolong: {
        count: 3,
        amount: 2300,
      },
      frozen: {
        count: 3,
        amount: 2300,
      },
      profit: 0,
      loss: 0,
      effectiveness: 0,
    },
    {
      full_name: 'Чебурашкин Вася',
      active: {
        count: 220,
        amount: 15800,
      },
      new: {
        count: 3,
        amount: 0,
      },
      pending: {
        count: 5,
        amount: -100,
      },
      prolong: {
        count: 3,
        amount: 2300,
      },
      frozen: {
        count: 3,
        amount: 2300,
      },
      profit: 0,
      loss: 0,
      effectiveness: 0,
    },
    {
      full_name: 'Чебурашкин Вася',
      active: {
        count: 220,
        amount: 15800,
      },
      new: {
        count: 3,
        amount: 0,
      },
      pending: {
        count: 5,
        amount: -100,
      },
      prolong: {
        count: 3,
        amount: 2300,
      },
      frozen: {
        count: 3,
        amount: 2300,
      },
      profit: 0,
      loss: 0,
      effectiveness: 0,
    },
  ],
  columns: [
    { title: 'Курьер', name: 'courier', sortable: true },
    { title: 'Номер курьера', name: 'number', sortable: true },
    { title: 'Доставок', name: 'deliveries_count', sortable: true },
    { title: 'Успешных', name: 'success_deliveries', sortable: true },
    { title: 'Неудачных', name: 'failed_deliveries', sortable: true },
    { title: 'З\\п за день', name: 'day_salary' },
  ],
}

export const ReportsManagersTiles = ({ data, children, route }) => {
  return (
    <DataGridCompound data={data || demoData} route={route}>
      {children}
    </DataGridCompound>
  )
}

ReportsManagersTiles.Wrapper = Wrapper
ReportsManagersTiles.Body = Body
ReportsManagersTiles.Footer = DataGridPagination
