import { createApi, createEvent, createStore } from 'effector'
import { uid } from '$root/utils/functions'
import React from 'react'

export const setUpdateModal = createEvent()
export const $waitUpdateModal = createStore(false).on(
  setUpdateModal,
  (state, isUpdating) => isUpdating,
)

export const $modals = createStore([])

export const modalsApi = createApi($modals, {
  open: (modals, { component, data = {} }) => {
    const modal = {
      component,
      props: data instanceof Function ? data : { ...data },
      id: !(data instanceof Function) ? data.id : uid(),
    }

    return [...modals, modal]
  },
  close: (modals, __) => {
    modals.splice(modals.length - 1, 1)

    return [...modals]
  },
  clear: (modals, __) => {
    return []
  },
  update: (modals, { id, data }) => {
    const newModals = [...modals]
    if (id && data) {
      const index = modals.findIndex((modal) => modal.id === id)
      const modal = {
        ...modals[index],
        props: { ...modals[index].props, ...data },
      }
      newModals.splice(
        modals.findIndex((modal) => modal.id === id),
        1,
        modal,
      )
    }
    return newModals
  },
  remove: (modals, { id }) => {
    return modals.filter((modal) => modal.id !== id)
  },
})
