import React from 'react'
import { TableDataGridItem } from './Item'

export const TableDataGridBody = ({
  data,
  ItemComponent = TableDataGridItem,
}) => {
  const { rows, columns, canEdit } = data

  return (
    <tbody>
      {rows.map((row, i) => (
        <ItemComponent row={row} columns={columns} canEdit={canEdit} key={i} />
      ))}
    </tbody>
  )
}
