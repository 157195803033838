import { useForm, usePage } from '@inertiajs/react'
import { DropdownBtn } from '$components/UI/Inputs/DropdownBtn/DropdownBtn'
import {
  useFormValidation,
  useNewFormValidation,
} from '$hooks/useFormValidation'
import { Input } from '$components/UI/Inputs/Input'
import { useEffect } from 'react'
import { onPostSended } from '$models/page'

export const NewTransaction = ({ client, onAdd, onSave }) => {
  const { enums } = usePage().props
  const currencyEnum = enums.transactionCurrencies

  const form = useForm({
    currency: _.first(_.keys(currencyEnum)),
    amount: 0,
  })

  const {
    data: transaction,
    setData,
    post,
    errors,
    setError,
    clearErrors,
    reset,
  } = form

  const onCheckIsValid = () => {
    const errors = {}

    if (
      (!transaction.amount && typeof transaction.amount != 'number') ||
      isNaN(transaction.amount)
    ) {
      errors['amount'] = 'Нужно заполнить сумму'
    }

    if (!transaction.currency) {
      errors['currency'] = 'Не выбрана валюта'
    }

    if (transaction.amount <= 0) {
      errors['amount'] = 'Нужно указать положительное значение'
    }

    return errors
  }

  const [validate, markErrors, clearSubmit] = useNewFormValidation({
    persistKey: `new-transaction`,
    onCheckIsValid,
    form,
  })

  const Insert = (type) => {
    if (validate()) {
      onAdd({
        ...transaction,
        amount: type === '+' ? transaction.amount : -transaction.amount,
      })
    }
  }

  useEffect(() => {
    return onPostSended.watch(() => {
      clearSubmit()
      setData('amount', 0)
    })
  }, [])

  return (
    <>
      <div className="modal-right__section">
        <div className="modal-right__inner">
          <div className="modal-right__inner">
            <Input
              type={'number'}
              classes={'input--short'}
              value={transaction.amount}
              onChange={(ev) =>
                setData(
                  'amount',
                  parseFloat(ev.target.value ? ev.target.value : 0),
                )
              }
              error={markErrors && errors.amount}
              placeholder=""
            />
            <DropdownBtn
              needEmpty={false}
              items={currencyEnum}
              titleKey="value"
              valueKey="key"
              appendFirst={false}
              value={transaction.currency}
              onChange={(currency) => setData('currency', currency.key)}
            />
          </div>
          <div className="modal-right__inner">
            <button
              className="btn btn__bordered btn--green"
              onClick={() => Insert('+')}
            >
              Назначить
            </button>
            <button
              className="btn btn__bordered btn--red"
              onClick={() => Insert('-')}
            >
              Списать
            </button>
          </div>
        </div>
      </div>
      <div className="modal-right__section">
        <button className="btn btn__filled btn--blue" onClick={onSave}>
          Сохранить
        </button>
      </div>
    </>
  )
}
