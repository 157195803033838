import { OrdersFields } from '$components/Clients/Orders/OrdersByStatus/Fields'

export const CanceledOrderItem = ({ order, ...other }) => {
  return (
    <>
      <tr className="table-plan--row-danger">
        <OrdersFields status={order.status} order={order} {...other} />
      </tr>
    </>
  )
}
