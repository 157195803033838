import { useStore } from 'effector-react/scope'
import React from 'react'
import { $modals } from '$models/modals'

const Modal = ({ modal }) => {
  const modalProps = {
    ...modal,
    ...(modal.props instanceof Function ? modal.props() : modal.props),
  }
  const ModalComponent = modal.component

  return <ModalComponent {...modalProps} />
}

export const Modals = () => {
  const modals = useStore($modals)
  return modals.map((modal, i) => <Modal modal={modal} key={i} />)
}
