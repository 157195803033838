import { hasPermission, hasPermissions } from '$root/utils/permissions'
import { MessageModal } from '$components/UI/Modals/MessageModal'
import { usePage } from '@inertiajs/react'
import { useModals } from '$hooks/useModals'

export const usePermissions = () => {
  const { user } = usePage().props
  const { openModal } = useModals()

  const isAllow = (permissions, fully = false) => {
    const checkFn = _.isArray(permissions) ? hasPermissions : hasPermission

    return checkFn(user, permissions, fully)
  }

  const isAllowOrError = (permissions, fully = false) => {
    const allow = isAllow(permissions, fully)
    if (!allow) {
      openModal('Error', MessageModal, {
        title: 'У вас недостаточно прав!',
      })
    }
    return allow
  }

  return {
    isAllow,
    isAllowOrError,
  }
}
