import React from 'react'
import { MainLayout } from '$components/UI/Layouts/MainLayout'

import { UsersList } from '$components/Settings/Users/List/UsersList'

export const SettingsUsers = ({ list, detail }) => {
  return <UsersList data={list} detail={detail} />
}

SettingsUsers.layout = (page) => <MainLayout>{page}</MainLayout>
