import { createEvent, createStore, sample } from 'effector'

////////////////////////////
// Отмеченные рационы
////////////////////////////
export const onCheckRation = createEvent()
export const onCheckRationMultiplier = createEvent()
export const updateMultipliers = createEvent()
const setRationsCount = createEvent()

export const $checkedRations = createStore([]).on(
  onCheckRation,
  (rations, { ration, isChecked }) => {
    isChecked
      ? !rations.map((checkedRation) => checkedRation.id).includes(ration.id)
        ? rations.push(ration)
        : rations.splice(
            rations.findIndex(
              (checkedRation) => checkedRation.id === ration.id,
            ),
            1,
            ration,
          )
      : rations.splice(
          rations.findIndex((checkedRation) => checkedRation.id === ration.id),
          1,
        )
    return rations
  },
)

export const $checkedRationMultipliers = createStore({})
  .on(
    onCheckRationMultiplier,
    (state, { ration_id, product_id, multiplier_id, isChecked }) => {
      return {
        ...state,
        [`${ration_id}-${product_id}`]: isChecked ? multiplier_id : 0,
      }
    },
  )
  .on(updateMultipliers, (_, multipliers) => multipliers)

export const $checkedRationsCount = createStore(0).on(
  setRationsCount,
  (_, count) => count,
)

sample({
  clock: onCheckRation,
  source: $checkedRations,
  fn: (rations, _) => {
    return rations.length
  },
  target: setRationsCount,
})

sample({
  clock: onCheckRation,
  source: $checkedRationMultipliers,
  fn: (checkedRationMultipliers, { ration: checkedRation }) => {
    let newCheckedMultipliers = { ...checkedRationMultipliers }
    for (const product of checkedRation.products) {
      newCheckedMultipliers = {
        ...newCheckedMultipliers,
        [`${checkedRation.id}-${product.product_id}`]: product.multiplier_id,
      }
    }
    return newCheckedMultipliers
  },
  target: updateMultipliers,
})
