import React, { useState } from 'react'
import { usePage } from '@inertiajs/react'
import { changeCurrentCityFilter } from '$lib/deliveries/models/zones'
import { useDeliveryZones } from '$lib/deliveries/hooks/useDeliveryZones'
import { TagsDropdown } from '$components/UI/Inputs/TagsDropdown'
import { router } from '@inertiajs/react'

export const DeliverySettingsFilters = () => {
  const { enums } = usePage().props
  const { city } = useDeliveryZones()

  const cities = (enums && enums.cities) || []

  return (
    <TagsDropdown
      variants={cities ? cities : []}
      values={[city]}
      titleKey="name"
      multiple={false}
      onChange={(tags) => {
        const city = _.first(tags)
        router.get(route(route().current()), {
          ...route().params,
          city_id: city.id,
        })
      }}
    >
      {(activeCities) =>
        activeCities.map((activeRation, i) => (
          <TagsDropdown.SearcheableActiveItem
            className="delivery--filters"
            tag={activeRation}
            key={i}
          >
            <TagsDropdown.SearcheableList key={i} ration={activeRation} />
          </TagsDropdown.SearcheableActiveItem>
        ))
      }
    </TagsDropdown>
  )
}
