import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import {
  deliveryZoneChangeVisibility,
  deliveryZoneEdited,
  deliveryZoneSave,
  deliveryZoneSelected,
} from '$lib/deliveries/models/zones'
import { Input } from '$components/UI/Inputs/Input'
import { isScrollVisible } from '$root/utils/functions'
import iconEye from '~svg/icons/icon-eye.svg'
import iconPlacemark from '~svg/icons/icon-placemark.svg'
import iconPencil from '~svg/icons/icon-pencil.svg'
import { deliveryZoneDeleteFx } from '$lib/deliveries/models/zones'

export const ZoneListItem = ({ zone, index }) => {
  const [nameEditing, setNameEditing] = useState(false)
  const [open, setOpen] = useState()
  const titleEditRef = useRef()
  const itemRef = useRef()
  const visibilityRef = useRef()
  const saveBtnRef = useRef()

  const id = useMemo(() => {
    return zone.properties.id
  }, [zone])

  const zoneName = useMemo(() => {
    return zone.properties.name
  }, [zone])

  const isNameEdit = useMemo(() => {
    return !zoneName || nameEditing
  }, [zone, nameEditing])

  const zoneCost = useMemo(() => {
    return zone.properties.price !== null && zone.properties.price !== undefined
      ? zone.properties.price
      : ''
  }, [zone])

  const isAreaVisible = useMemo(() => {
    return zone.visibility === undefined || zone.visibility
  }, [zone.visibility])

  const toggleItem = (state) => {
    setOpen(state)
  }

  const onClickItem = useCallback(
    (ev) => {
      const elementWidth = itemRef.current.clientWidth
      const clickXPosition = ev.nativeEvent.layerX

      if (clickXPosition <= elementWidth) {
        const tagName = ev.target.tagName.toLowerCase()

        if (tagName !== 'input' && ev.target !== titleEditRef.current) {
          if (!visibilityRef.current.contains(ev.target)) {
            if (open) {
              toggleItem(!open)
            } else {
              deliveryZoneSelected(id)
            }
          }
        }
      } else {
        deliveryZoneDeleteFx(id)
        deliveryZoneChangeVisibility(id)
      }
    },
    [open, id],
  )

  useEffect(() => {
    const unsubscribes = []

    unsubscribes.push(
      deliveryZoneSelected.watch((_id) => {
        if (id === _id) {
          if (!isScrollVisible(itemRef.current)) {
            itemRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
          }
          setOpen(true)
        } else {
          setOpen(false)
        }
      }),
    )

    unsubscribes.push(
      deliveryZoneSave.watch((_id) => {
        setNameEditing(false)
      }),
    )

    return () => {
      for (const unsubscribe of unsubscribes) {
        unsubscribe()
      }
    }
  }, [])

  return (
    <div
      className={clsx('accordion-district__item zone-info ', { open: open })}
      onClick={onClickItem}
      ref={itemRef}
    >
      <button className="accordion-district__btn">
        {/*<span className="accordion-district__number" style={{'background-color': zone.properties.color}}>2</span>*/}
        <span
          className="accordion-district__visibility"
          onClick={() => deliveryZoneChangeVisibility(id)}
          ref={visibilityRef}
        >
          <img
            src={isAreaVisible ? iconEye : iconPlacemark}
            title={isAreaVisible ? 'Скрыть' : 'Показать'}
          />
        </span>
        <span className="accordion-district__title">
          {(isNameEdit && (
            <Input
              value={zoneName}
              onChange={(ev) =>
                deliveryZoneEdited({
                  id,
                  properties: {
                    ...zone.properties,
                    name: ev.target.value,
                  },
                })
              }
            />
          )) || (
            <>
              {zoneName}
              <img
                ref={titleEditRef}
                className="accordion-district__title-edit"
                src={iconPencil}
                title="Редактировать"
                onClick={() => {
                  setOpen(true)
                  setNameEditing(true)
                }}
              />
            </>
          )}
        </span>

        <span className="accordion-district__inner">
          <span className="accordion-district__text">Стоимость доставки</span>
          <span className="accordion-district__price">{zoneCost || '-'} ₽</span>
        </span>
      </button>
      <div className="accordion-district__body">
        <label className="input input--grow">
          <Input
            value={zoneCost}
            onChange={(ev) =>
              deliveryZoneEdited({
                id,
                properties: {
                  ...zone.properties,
                  price: ev.target.value,
                },
              })
            }
            placeholder="Введите сумму"
          />
        </label>
        <button
          ref={saveBtnRef}
          className="btn btn__filled btn--blue"
          onClick={() => deliveryZoneSave(id)}
        >
          Сохранить
        </button>
      </div>
    </div>
  )
}
