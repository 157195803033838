import { DeliveryExtraCost } from '$components/Settings/Deliveries/DeliveryExtraCost'
import { Label } from '$components/UI/Inputs/Label'
import React, { useMemo } from 'react'
import { usePage } from '@inertiajs/react'

export const DeliveryExtraCostsSettings = ({ delivery_type_prices }) => {
  const { enums, user } = usePage().props

  const deliveryTypePrices = useMemo(() => {
    return _.keys(enums.deliveryTypes).reduce((result, deliveryTypeCode) => {
      const priceInfo = delivery_type_prices.find(
        (priceInfo) => priceInfo.delivery_type === deliveryTypeCode,
      )
      const price =
        (priceInfo && !isNaN(priceInfo.price) && parseFloat(priceInfo.price)) ||
        0
      const id = (priceInfo && priceInfo.id) || undefined

      result.push({
        id,
        delivery_type: deliveryTypeCode,
        delivery_type_name: enums.deliveryTypes[deliveryTypeCode],
        price: price,
      })

      return result
    }, [])
  }, [delivery_type_prices])

  return (
    <>
      <Label text="Наценки" className="modal-right__title" />
      <div className="accordion-district">
        {deliveryTypePrices.map((deliveryTypeExtraPrice, i) => (
          <DeliveryExtraCost
            title={deliveryTypeExtraPrice.delivery_type_name}
            data={deliveryTypeExtraPrice}
            userCityID={user.city_id}
            key={i}
          />
        ))}
      </div>
    </>
  )
}
