import { usePage } from '@inertiajs/react'
import clsx from 'clsx'
import { OrderStatus } from '$lib/orders/utils/order-status-const'
import { useCallback } from 'react'
import { InvoiceStatus } from '$lib/invoices/utils/const'

export const CourierFinancesSubtable = ({ orders }) => {
  const { enums } = usePage().props

  const getInvoices = useCallback(
    (order) => {
      let invoices = [...order.client.invoices]

      if (order.invoice) {
        invoices.push(order.invoice)
      }

      return invoices
    },
    [orders],
  )

  return (
    <table className="table table-report__couriers-subtable">
      <thead>
        <tr>
          <th>ФИО</th>
          <th>Рацион</th>
          <th>Сумма</th>
          <th>Тип оплаты</th>
        </tr>
      </thead>
      <tbody>
        {orders.map((order, i) => (
          <tr
            key={i}
            className={clsx('courier-finance', {
              'courier-finance--green': order.status === OrderStatus.Done,
              'courier-finance--red': order.status === OrderStatus.Problem,
            })}
          >
            <td>{order.client.full_name}</td>
            <td>
              {order.rations
                .map((rationRelation) => rationRelation.ration.name)
                .join(', ')}
            </td>
            <td>{order.invoices_sum || 0} ₽</td>
            <td>
              {getInvoices(order).map((invoice) => (
                <div className="mt-05">
                  <span>{invoice.amount} ₽ - </span>
                  <span>{enums.paySystems[invoice.pay_system]} </span>
                  {invoice.status === InvoiceStatus.Paid && (
                    <span>(оплачено) </span>
                  )}
                  <br />
                </div>
              ))}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
