import clsx from 'clsx'
import React from 'react'

export const TableDataGridWrapper = ({ className, children }) => {
  return (
    <table className={clsx('table-courier-report', className)}>
      {children}
    </table>
  )
}
