import { ProductMultiplier } from '$components/Settings/Leads/ProductsTab/RationTiles/ProductMultiplier'
import { useProductMultipliers } from '$lib/rations/effector/product-multipliers/useProductMultipliers'

export const ProductMultipliers = ({
  ration,
  rationProductId,
  product,
  multipliers,
  onRemoveProduct,
}) => {
  const { isChecked, onSelect } = useProductMultipliers(
    ration,
    product,
    multipliers,
  )

  return (
    <tr>
      <td>{product.name || '<>'}</td>
      {multipliers.map((multiplier, i) => (
        <ProductMultiplier
          rationID={ration.id}
          productID={product.id}
          multiplierID={multiplier.id}
          checked={isChecked(multiplier.id)}
          onSelect={onSelect}
          key={i}
        />
      ))}
      <td>
        <span className="delete" onClick={() => onRemoveProduct(rationProductId)} />
      </td>
    </tr>
  )
}
