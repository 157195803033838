import { $dataGrid, dataGridIsChanged } from '$models/dataGrid'
import { useStore } from 'effector-react'
import { DatePickerInput } from '$components/UI/Inputs/DatePickerInput'
import { useDateTime } from '$hooks/useDateTime'

export const DatePickerFilter = ({ field, multiple }) => {
  const { dateToSaveFormat } = useDateTime()
  const { filter } = route().params

  const dateChanged = (selectedDate) => {
    window.selectedDate = selectedDate

    if (multiple) {
      if (selectedDate) {
        if (!!selectedDate[0] && !!selectedDate[1]) {
          selectedDate = [
            dateToSaveFormat(selectedDate[0]),
            dateToSaveFormat(selectedDate[1]),
          ]
        } else {
          return
        }
      }
      dataGridIsChanged({ filter: { ...filter, [field]: selectedDate } })
    } else {
      dataGridIsChanged({
        filter: {
          ...filter,
          [field]:
            (selectedDate && dateToSaveFormat(new Date(selectedDate))) || null,
        },
      })
    }
  }

  return (
    <DatePickerInput
      multiple={multiple}
      onChange={(date) => dateChanged(date)}
      value={(filter && filter[field] && new Date(filter[field])) || null}
      showPickerTag={true}
    />
  )
}
