import React from 'react'
import { Modal } from '$components/UI/Modals/Modal'

export const ConfirmCloseAnswer = {
  Yes: 1,
  No: 2,
}

export const CloseConfirmModal = ({ onAnswer }) => {
  return (
    <div className="confirm-modal-wrapper">
      <button
        className="btn btn__filled btn--blue yes"
        onClick={() => onAnswer(ConfirmCloseAnswer.Yes)}
      >
        Да
      </button>
      <button
        className="btn btn__filled btn--default small no"
        onClick={() => onAnswer(ConfirmCloseAnswer.No)}
      >
        Нет
      </button>
    </div>
  )
}

export const showCloseConfirmModal = (openModalFn, closeModalFn) => {
  openModalFn('ConfirmCloseUser', Modal, {
    title: 'Закрыть окно с потерей изменений?',
    contentComponent: CloseConfirmModal,
    classes: { wrapper: 'modal--confirm' },
    async onAnswer(answer) {
      if (answer === ConfirmCloseAnswer.Yes) {
        await closeModalFn()
        await closeModalFn()
      } else {
        await closeModalFn()
      }
    },
  })
}
