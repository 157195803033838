import { usePage } from '@inertiajs/react'
import { getActiveTabCode } from '$lib/leads/statused/public/helpers'
import React, { useContext } from 'react'
import { DataGridContext } from '$components/UI/DataGridCompound/DataGridCompound'
import { MappedItemComponentByStatus } from '$components/Leads/utils/const/mapped'
import { NoResults } from '$components/UI/DataGrid/NoResults'

export const Body = ({ routes }) => {
  const { enums: { tabs = [] } = {} } = usePage().props

  const activeTabCode = getActiveTabCode(tabs)
  const BodyItemComponent =
    (activeTabCode && MappedItemComponentByStatus[activeTabCode]) ||
    (() => <></>)
  const { data } = useContext(DataGridContext)

  return (
    <div>
      {' '}
      {(data.rows.length > 0 &&
        data.rows.map((item, i) => (
          <div key={i} className="deal">
            <BodyItemComponent item={item} routes={routes} />
          </div>
        ))) || (
        <NoResults
          title="Нет данных"
          text="Если есть активные фильры, попробуйте изменить условия поиска."
        />
      )}
    </div>
  )
}
