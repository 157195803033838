import InvoiceViewListItem from '$components/Clients/Invoices/InvoiceViewListItem'
import { useContext } from 'react'
import { EffectorContext } from '$root/utils/effector/effector-services'
import InvoiceEditableListItem from '$components/Clients/Invoices/InvoiceEditableListItem'

const InvoiceListItem = ({ editItem, editOptions }) => {
  const { hoveringEditableService } = useContext(EffectorContext)

  return (
    (!hoveringEditableService.store.isEdit && <InvoiceViewListItem />) ||
    editItem || <InvoiceEditableListItem {...editOptions} />
  )
}

export default InvoiceListItem
