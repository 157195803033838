import React from 'react'
import clsx from 'clsx'
import { router, usePage } from '@inertiajs/react'
import { useDateTime } from '$hooks/useDateTime'

export const BalancePreviewCard = ({ client, routes, routeParams }) => {
  const { enums } = usePage().props

  const { date } = useDateTime()

  const showTransactions = () => {
    router.get(
      route(routes.transactions.list, { id: client.id, ...routeParams }),
    )
  }

  return (
    <>
      <div className="clients-balance-preview__head">
        <div className="modal-right__title clients-balance-preview__title">
          Баланс
        </div>
        <div className="clients-balance-preview__pay-type badge-item">
          <div className="badge-title">Тип оплаты</div>
          <span
            className={clsx({
              [(client.payment_type && 'link') || '']: true,
            })}
          >
            {/* TODO: !clients: payment_type */}
            {enums.clientPaymentTypes[client.payment_type] || 'Не выбрано'}
          </span>
        </div>
      </div>
      <div className="modal-right__balance">
        <div className="modal-right__balance-head">
          <p
            className={clsx('modal-right__balance-value', {
              good: client.balance && client.balance > 0,
              bad: typeof client.balance === 'number' && client.balance < 0,
            })}
          >
            {client.balance ? client.balance : 0} ₽
          </p>
          <p className="modal-right__balance-reward">
            {client.bonus_balance ? client.bonus_balance : 0} Бв
          </p>
          <button
            className="modal-right__balance-details js-modal-btn"
            data-target="balance"
            onClick={showTransactions}
          >
            Детали
          </button>
        </div>
        <p className="modal-right__balance-delivery">
          Следующая доставка на
          <span>
            {(client.next_order_date && date(client.next_order_date)) || '-'}
          </span>
        </p>
      </div>
    </>
  )
}
