import { createEvent, createStore, sample } from 'effector'
import { createEffect } from 'effector/effector.umd'
import { persist } from 'effector-storage/local'

import { router } from '@inertiajs/react'

export const setDistributionDate = createEvent()
export const onResetDistributionDeliveriesDate = createEvent()

// const distributionDateChangeFx = createEffect(({ date, isNeedSending = true }) => {
//     if (isNeedSending) {
//         const routeObject = route()
//         try {
//             router.get(route(routeObject.current(), { ...routeObject.params, date }))
//         } catch (e) {
//             console.error(e)
//         }
//     }
// })

export const $distributionDeliveriesDate = createStore(null)
  .on(setDistributionDate, (state, { date }) => date)
  .reset(onResetDistributionDeliveriesDate)

// sample({
//     clock: setDistributionDate,
//     target: distributionDateChangeFx,
// })

// persist({ store: $distributionDeliveriesDate, key: '$distributionDeliveriesDate' })
