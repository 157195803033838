import { EffectorStore } from '$root/utils/effector/effector-services'
import { router } from '@inertiajs/react'
import { Routes } from '$root/utils/constants/routes'
import { onPostSended } from '$models/page'
import { ClientPaymentType, InvoiceStatus } from '$lib/invoices/utils/const'
import InvoicesModal from '$components/Clients/Invoices/Modal/InvoicesModal'
import { useEffect, useState } from 'react'

export class PickupDeliveriesService extends EffectorStore {
  modals = null
  state = {
    invoicesModalOpen: false,
    setInvoicesModalOpen: null,
  }

  constructor({ deliveries, modals }) {
    super()
    this.modals = modals
    this.deliveries = deliveries
  }

  useContainer(delivery) {
    ;[this.state.invoicesModalOpen, this.state.setInvoicesModalOpen] =
      useState(false)

    const invoices = [delivery.invoice, ...delivery.client.invoices]

    useEffect(() => {
      if (this.state.invoicesModalOpen) {
        this.showInvoicesModal(delivery)
      }
    }, [invoices, this.state.invoicesModalOpen])
  }

  _getOnSaveCb(delivery) {
    return () => this.showInvoicesModal(delivery)
  }

  _initPersist() {
    return 'pickup-deliveries'
  }

  clientIsPrePaid(delivery) {
    return delivery.client.payment_type === ClientPaymentType.Prepayment
  }

  invoiceIsPaid(delivery) {
    return delivery.invoice.status === InvoiceStatus.Paid
  }

  clientHasInvoices(delivery) {
    return delivery.client.invoices.length > 0
  }

  deliveryClientIsPostPaid(delivery) {
    return delivery.client.payment_type === ClientPaymentType.Postpaid
  }

  updateDelivery(delivery) {
    router.post(
      route(Routes.deliveries.pickup.update),
      { orders: [delivery] },
      {
        onSuccess() {
          onPostSended({ postRoute: Routes.deliveries.pickup.update })
        },
      },
    )
  }

  saveInvoice(invoice) {
    router.post(route(Routes.deliveries.pickup.updateInvoice), invoice, {
      onSuccess() {
        onPostSended({ postRoute: Routes.deliveries.pickup.updateInvoice })
      },
    })
  }

  orderGiven(order, data) {
    router.post(
      route(Routes.deliveries.pickup.orderGiven),
      { orders: [data] },
      {
        onSuccess() {
          onPostSended({ postRoute: Routes.deliveries.pickup.orderGiven })
        },
      },
    )
  }

  orderProblem(order, data) {
    router.post(
      route(Routes.deliveries.pickup.orderProblem),
      { orders: [data] },
      {
        onSuccess() {
          onPostSended({ postRoute: Routes.deliveries.pickup.orderProblem })
        },
      },
    )
  }

  showInvoicesModal(delivery) {
    const invoices = [delivery.invoice, ...delivery.client.invoices]

    if (this.clientIsPrePaid(delivery)) {
      delivery.invoice.disallowEdit = true
    }

    this.state.setInvoicesModalOpen(true)

    this.modals.openModal('PickupInvoices', InvoicesModal, {
      title: `Счета`,
      invoices: {
        rows: invoices,
      },
      onSave: this._getOnSaveCb(delivery),
      routes: {
        invoices: {
          update: Routes.deliveries.pickup.updateClientInvoices,
        },
      },
      saveParams: () => ({
        id: delivery.client.id,
      }),
      onClose: () => {
        this.state.setInvoicesModalOpen(false)
      },
    })
  }
}
