import { EffectorDomainLogic } from '$root/utils/effector/domain-logic'
import { router } from '@inertiajs/react'
import { Routes } from '$root/utils/constants/routes'
import { createEvent, createStore, sample } from 'effector'
import { useStore } from 'effector-react'

export class ProductMultipliersDomain extends EffectorDomainLogic {
  _ration = null
  _product = null
  _$checks = {}
  $product = null

  product = null
  multipliersFlags = null

  events = {
    onProductUpdate: null,
  }

  constructor({ ration, product, multipliers }) {
    super()
    this._ration = ration
    this._product = product
    this._multipliers = multipliers
  }

  identify() {
    return `${this._ration.id}-product-multipliers-${this._product.product_id}`
  }

  _initMultipliersFlagsStores() {
    for (const multiplier of this._multipliers) {
      this._$checks[multiplier.id] = createStore(
        this.productMultiplierIsChecked(this._product, multiplier.id),
      )
    }
  }

  _initProductStore() {
    this.events.onProductUpdate = createEvent()
    this.$product = createStore(this._product)
    this.$product.on(this.events.onProductUpdate, (state, product) => product)
  }

  _initSamples() {
    for (const multiplierID in this._$checks) {
      sample({
        clock: this.$product,
        fn: (product) =>
          this.productMultiplierIsChecked(product, parseInt(multiplierID)),
        target: this._$checks[multiplierID],
      })
    }
  }

  init() {
    this._initProductStore()
    this._initMultipliersFlagsStores()
    this._initSamples()
  }

  useStores() {
    this.product = useStore(this.$product)
    this.multipliersFlags = this.useMultipliersFlags()
  }

  productMultiplierIsChecked(product, multiplierID) {
    return product.multiplier_id === multiplierID
  }

  useMultipliersFlags() {
    const checked = {}
    for (const multiplierID in this._$checks) {
      checked[multiplierID] = useStore(this._$checks[multiplierID])
    }
    return checked
  }

  onSelectMultiplier(multiplierID) {
    router.post(route(Routes.settings.leads.rations.link_product_to_ration), {
      rations: [
        {
          ration_id: this._ration.id,
          product_id: this.product.product_id,
          multiplier_id: multiplierID,
        },
      ],
    })
  }

  onProductUpdate(product) {
    this.events.onProductUpdate(product)
  }

  isChecked(multiplierID) {
    return this.multipliersFlags[multiplierID]
  }
}
