import { OrderItemComponentByStatus } from '$lib/orders/utils/const'
import { SuggestNewOrder } from '$components/Clients/Orders/OrdersByStatus/OrderLineItems/SuggestNewOrder'
import { useOrdersByStatus } from '$lib/orders/hooks/useOrdersByStatus'
import { OrderStatus } from '$lib/orders/utils/order-status-const'
import { usePermissions } from '$hooks/usePermissions'
import { useState } from 'react'
import { createDomain } from 'effector'

const getOrderDomain = (order) => {
  return createDomain(order.id)
}

export const OrdersByStatus = ({ status, routes }) => {
  if (!status) return <></>
  const { orders, listIsEmpty } = useOrdersByStatus(status)
  const { isAllow } = usePermissions()

  const isAllowEdit = isAllow('clients.edit')

  const OrderItemConcreteComponent = ({ order, ...other }) => {
    const [orderDomain] = useState(() => getOrderDomain(order))

    const Component =
      OrderItemComponentByStatus[order.status] ||
      OrderItemComponentByStatus[OrderStatus.Default]
    if (
      order.status === OrderStatus.Inactive &&
      status === OrderStatus.Active
    ) {
      return <></>
    }
    return (
      <Component
        order={order}
        tableStatus={status}
        orderDomain={orderDomain}
        {...other}
      />
    )
  }

  const OrderItemComponent = ({ order }) => {
    return (
      <>
        {isAllowEdit && (order.suggestFill || order.needSuggest) && (
          <SuggestNewOrder
            suggestDate={order.suggestDate}
            hide={order.hide}
            listIsEmpty={listIsEmpty}
          />
        )}
        <OrderItemConcreteComponent
          order={order}
          routes={routes}
          allowEdit={isAllowEdit}
        />
        {isAllowEdit && order.isLastSuggest && (
          <SuggestNewOrder
            suggestDate={order.suggestLastDay}
            hide={order.hide}
            listIsEmpty={listIsEmpty}
          />
        )}
      </>
    )
  }

  return (
    <>
      <table className="table-plan">
        <tbody>
          {orders.map((order, i) => (
            <OrderItemComponent order={order} key={i} />
          ))}
        </tbody>
      </table>
    </>
  )
}
