import React from 'react'
import clsx from 'clsx'
import { router } from '@inertiajs/react'
import { Routes } from '$utils/constants/routes'

export const RolesTabs = ({ currentRole, roles }) => {
  const onClickTabHandler = (role) => {
    if (currentRole.name !== role.name) {
      router.visit(
        route(Routes.settings.permissions.list, { roleName: role.name }),
        { only: ['role', 'rolePermissions'] },
      )
    }
  }

  if (roles.length <= 0) return null

  return (
    <div className="tab-category">
      {roles.map((role) => (
        <button
          key={role.name}
          className={clsx('tab-category__btn', {
            active: currentRole.name === role.name,
          })}
          onClick={(event) => onClickTabHandler(role)}
        >
          {role.title}
        </button>
      ))}
    </div>
  )
}
