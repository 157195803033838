import React from 'react'
import { Loader as LoaderComponent } from '$components/UI/Loader'
import { $showLoader, preloaderStateIsChanged } from '$models/loader'
import { useStore } from 'effector-react'

export const useLoader = () => {
  const loading = useStore($showLoader)

  const showLoader = () => {
    preloaderStateIsChanged(true)
  }

  const hideLoader = () => {
    preloaderStateIsChanged(false)
  }

  const Loader = () => {
    return LoaderComponent
  }

  return {
    loading,
    showLoader,
    hideLoader,
    Loader,
  }
}
