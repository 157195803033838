import { createEvent, createStore } from 'effector'
import { persist } from 'effector-storage/local'

export const menuStateIsChanged = createEvent()

export const $menuIsShort = createStore(false).on(
  menuStateIsChanged,
  (store, menuIsShort) => menuIsShort,
)

persist({
  store: $menuIsShort,
  key: 'menuIsShort',
})
