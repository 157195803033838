import { DropdownBtn } from '$components/UI/Inputs/DropdownBtn/DropdownBtn'
import React from 'react'
import { useForm } from '@inertiajs/react'
import { useFormValidation } from '$hooks/useFormValidation'
import { Input } from '$components/UI/Inputs/Input'
import { Routes } from '$root/utils/constants/routes'

export const NewStatusTag = ({ statusReasons }) => {
  const { data, setData, post, processing, errors, setError, clearErrors } =
    useForm({
      value: null,
      status: null,
    })

  const onCheckIsValid = () => {
    let isValid = true

    if (!data.value) {
      setError('value', 'Необходимо заполнить текст тэга')
      isValid = false
    }

    if (!data.status) {
      setError('status', 'Необходимо указать статус лида')
      isValid = false
    }

    return isValid
  }

  const [validate, markErrors] = useFormValidation(
    `new-leads-status`,
    onCheckIsValid,
    data,
    errors,
    clearErrors,
  )

  const onAddNewTag = (e) => {
    e.preventDefault()

    if (validate()) {
      post(route(Routes.settings.leads.new_tag))
    }
  }

  return (
    <div className="comments__panel">
      <p className="comments__title">Комментарий</p>
      <div className="comments__row">
        <Input
          type="text"
          classes={'comments__input'}
          placeholder="Комментарий"
          value={data.value || ''}
          onChange={(e) => setData('value', e.target.value)}
          error={markErrors && !!errors.value}
        />

        <DropdownBtn
          items={statusReasons}
          titleKey="value"
          valueKey="key"
          value={
            !!statusReasons && statusReasons.length ? statusReasons[0].id : null
          }
          emptyText={'Статус'}
          error={!!markErrors && !!errors.status}
          onChange={(status) => {
            setData('status', status.key)
          }}
        />
        <button className="btn btn__filled btn--blue" onClick={onAddNewTag}>
          Добавить
        </button>
      </div>
    </div>
  )
}
