import React from 'react'
import { MainLayout } from '$components/UI/Layouts/MainLayout'
import { Kitchen } from '$components/Kitchen'
import { usePage } from '@inertiajs/react'

export const KitchenCooks = () => {
  const { list } = usePage().props

  return <Kitchen data={list} />
}

KitchenCooks.layout = (page) => <MainLayout>{page}</MainLayout>
