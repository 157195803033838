import icon from '~svg/icons/icon-lg-penalty.svg'

export const PenaltyPane = ({ amount }) => {
  return (
    <div className="color-panel color-panel--red">
      <img
        className="color-panel__icon"
        src={icon}
        alt="Штрафы"
        title="Штрафы"
      />
      <p className="color-panel__title">Штрафы</p>
      <p className="color-panel__descr">Общая сумма штрафов сотрудников</p>
      <p className="color-panel__value">{amount || '-'}</p>
    </div>
  )
}
