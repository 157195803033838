import React, { useMemo } from 'react'
import clsx from 'clsx'
import { useStore } from 'effector-react'
import { $dataGrid, dataGridIsChanged } from '$models/dataGrid'

export const SortableColumn = ({ children, column, ...props }) => {
  const { order } = useStore($dataGrid)

  const parametrizedOrder = order || route().params.order

  const currentSort = useMemo(() => {
    return (parametrizedOrder && parametrizedOrder[column.name]) || null
  }, [parametrizedOrder])

  const className = {
    table__desc:
      parametrizedOrder &&
      parametrizedOrder[column.name] &&
      parametrizedOrder[column.name] === 'desc',
    table__asc:
      parametrizedOrder &&
      parametrizedOrder[column.name] &&
      parametrizedOrder[column.name] === 'asc',
    pointer: column.sortable,
  }
  const onSort = () => {
    const nextSort =
      (currentSort === null && 'asc') ||
      (currentSort === 'asc' && 'desc') ||
      (currentSort === 'desc' && null)
    let newSort = parametrizedOrder

    if (!newSort) {
      newSort = {}
    }

    newSort[column.name] = nextSort
    if (!nextSort) {
      delete newSort[column.name]
    }

    dataGridIsChanged({
      order: newSort,
    })
  }

  return React.cloneElement(children, {
    ...props,
    className: clsx(className),
    onClick: onSort,
  })
}
