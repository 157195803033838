import { usePage } from '@inertiajs/react'
import { Ration } from '$components/Settings/Leads/ProductsTab/RationTiles/Ration'
import {
  $checkedRations,
  onCheckRation,
} from '$components/Settings/Leads/ProductsTab/models'
import { useStore } from 'effector-react'
import { useEffect } from 'react'

export const RationTiles = () => {
  const { rations } = usePage().props
  const checkedRations = useStore($checkedRations)

  useEffect(() => {
    const checkedIDs = checkedRations.map((checkedRation) => checkedRation.id)
    for (const ration of rations) {
      if (checkedIDs.includes(ration.id)) {
        onCheckRation({ ration, isChecked: true })
      }
    }
  }, [rations])

  return (
    <div className="products">
      {rations.map((ration, i) => (
        <Ration ration={ration} key={i} />
      ))}
    </div>
  )
}
