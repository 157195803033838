import { Routes } from '$root/utils/constants/routes'
import { ContactsField } from '$components/Leads/Statused/DataGrid/Fields/ContactsField'

export const DeliveryContactField = ({ delivery }) => {
  return (
    <ContactsField
      linkClassName="no-margin"
      name={delivery.client ? delivery.client.full_name : '<>'}
      // TODO: Роут через старницу лидов, а не клиентов
      link={
        (delivery.client &&
          route(Routes.leads.clients.orders.list, {
            id: delivery.id,
            ...route().params,
          })) ||
        null
      }
      contact={delivery.address || null}
    />
  )
}
