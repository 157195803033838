import { DataGridCompound } from '$components/UI/DataGridCompound/DataGridCompound'
import { Header } from './Header'
import { Body } from './Body'
import { DataGridPagination } from '$components/UI/DataGrid/DataGridPagination'
import { TableDataGridWrapper } from '$components/UI/DataGrid/Table/Wrapper'

export const ReportsInvoicesDataGrid = ({
  data,
  children,
  wrapper,
  header,
  body,
  footer,
  route,
}) => {
  return (
    <DataGridCompound
      data={data}
      route={route}
      wrapper={wrapper}
      header={header}
      body={body}
      footer={footer}
    >
      {children}
    </DataGridCompound>
  )
}

ReportsInvoicesDataGrid.Wrapper = TableDataGridWrapper
ReportsInvoicesDataGrid.Header = Header
ReportsInvoicesDataGrid.Body = Body
ReportsInvoicesDataGrid.Footer = DataGridPagination
