import clsx from 'clsx'
import { router } from '@inertiajs/react'
import { Routes } from '$root/utils/constants/routes'

const tabs = [
  {
    title: 'Доставки',
    route: [
      Routes.reports.deliveries.page,
      Routes.reports.deliveries.finances.page,
    ],
  },
  {
    title: 'Самовывоз',
    route: [Routes.reports.pickups.page, Routes.reports.pickups.finances.page],
  },
  // { title: 'Менеджеры', route: [Routes.reports.managers.page] },
  { title: 'Счета', route: [Routes.reports.invoices.page] },
  { title: 'Расходы', route: [Routes.reports.expenses.page] },
  // { title: 'Компания', route: [Routes.reports.company.page] },
]

export const ReportsTabs = () => {
  const goTab = (tab) => {
    const newRoute = route(_.first(tab.route), {})

    if (route(route().current()) !== newRoute) {
      router.get(newRoute)
    }
  }

  return (
    <div className="second-head">
      <div className="tab-category">
        {tabs.map((tab, i) => (
          <button
            className={clsx('tab-category__btn', {
              active: tab.route.includes(route().current()),
            })}
            key={i}
            onClick={() => goTab(tab)}
          >
            {tab.title}
          </button>
        ))}
      </div>
    </div>
  )
}
