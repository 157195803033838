import { EffectorDomainLogic } from '$root/utils/effector/domain-logic'
import { createEvent, createStore, sample } from 'effector'
import { useStore } from 'effector-react'
import { Routes } from '$root/utils/constants/routes'
import { router } from '@inertiajs/react'
import { useDateTime } from '$hooks/useDateTime'
import { useEffect } from 'react'

export class DistributionInfoEffector extends EffectorDomainLogic {
  currentDay = null

  zoneID = null
  zones = null
  deliveries = null
  couriers = null

  checkedStore = []
  isEditingCouriers = false

  $checkedStore = null

  zoneStore = null
  $zoneStore = null

  currentCourierStore = null
  $currentCourierStore = null

  couriersStore = null
  $couriersStore = null

  couriersDeliveriesStore = null
  $couriersDeliveriesStore = null

  events = {
    updateCouriers: null,
    updateEditingCouriers: null,
    toggleItem: null,
    toggleAll: null,
    setCurrentCourier: null,
    clearAllChecked: null,
    resetCurrentCourier: null,
  }

  constructor({ zoneID, zones, deliveries }) {
    super()
    this.zoneID = zoneID
    this.zones = zones
    this.deliveries = deliveries
  }

  get zoneSelected() {
    return !!this.zoneStore
  }

  identify() {
    return `distribution-deliveries-${this.zoneID}`
  }

  _getCurrentZone() {
    return this.zones.find((zone) => zone.properties.id === this.zoneID)
  }

  _getZoneCouriers(zone) {
    return zone.properties.couriers
  }

  _getCouriersDeliveries() {
    return this.deliveries.reduce((courierDeliveriesResult, delivery) => {
      if (delivery.courier) {
        courierDeliveriesResult[delivery.courier.id] = delivery
      }
      return courierDeliveriesResult
    }, {})
  }

  _initStores() {
    let zone = null
    let zoneCouriers = []

    if (this.zoneID) {
      zone = this._getCurrentZone()
      zoneCouriers = this._getZoneCouriers(zone)
    }

    this.$zoneStore = createStore(zone)
    this.$couriersDeliveriesStore = createStore(this._getCouriersDeliveries())

    this.events.updateCouriers = createEvent()
    this.events.updateEditingCouriers = createEvent()
    this.events.resetCurrentCourier = createEvent()

    this.$couriersStore = createStore(zoneCouriers).on(
      this.events.updateCouriers,
      (state, couriers) => couriers,
    )

    this.$isEditingCouriers = createStore(false).on(
      this.events.updateEditingCouriers,
      (state, isEditing) => isEditing,
    )

    this.events.setCurrentCourier = createEvent()
    this.$currentCourierStore = createStore(null)
      .on(this.events.setCurrentCourier, (currentCourier, courier) => {
        if (currentCourier && courier === currentCourier) return null
        return courier
      })
      .reset(this.events.resetCurrentCourier)

    this.events.toggleAll = createEvent()
    this.events.toggleItem = createEvent()
    this.events.clearAllChecked = createEvent()

    this.$checkedStore = createStore([])
      .on(this.events.toggleItem, (checkedDeliveries, delivery) => {
        const index = checkedDeliveries.map((d) => d.id).indexOf(delivery.id)
        if (index < 0) {
          return [...checkedDeliveries, delivery]
        } else {
          checkedDeliveries.splice(index, 1)
          return [...checkedDeliveries]
        }
      })
      .on(this.events.toggleAll, () => this.deliveries)
      .on(this.events.clearAllChecked, () => [])
  }

  _initSamples() {
    sample({
      clock: this.events.setCurrentCourier,
      target: this.events.clearAllChecked,
    })
  }

  init() {
    this._initStores()
    this._initSamples()
  }

  useStores() {
    this.zoneStore = useStore(this.$zoneStore)
    this.couriersStore = useStore(this.$couriersStore)
    this.couriersDeliveriesStore = useStore(this.$couriersDeliveriesStore)
    this.currentCourierStore = useStore(this.$currentCourierStore)
    this.checkedStore = useStore(this.$checkedStore)
    this.isEditingCouriers = useStore(this.$isEditingCouriers)
  }

  useContainer(deliveries) {
    const { dateToSaveFormat } = useDateTime()
    this.currentDay = dateToSaveFormat(new Date())
    this.deliveries = deliveries
  }

  useZoneCouriersContainer(zones) {
    useEffect(() => {
      this.zones = zones

      if (zones.length > 0 && this.zoneID) {
        this.updateCouriers(this._getZoneCouriers(this._getCurrentZone()))
      }
    }, [zones])
  }

  updateCouriers(couriers) {
    this.events.updateCouriers(couriers)
  }

  setEditingCouriers(isEditing) {
    this.events.updateEditingCouriers(isEditing)
    if (isEditing) {
      this.setCourier(null)
      this.clearAllChecked()
    }
  }

  toggleItem(delivery) {
    this.events.toggleItem(delivery)
  }

  toggleAll() {
    this.events.toggleAll()
  }

  clearAllChecked() {
    this.events.clearAllChecked()
  }

  setCourier(courier) {
    this.events.setCurrentCourier(courier)
  }

  sendChecked() {
    const $this = this
    if (!this.currentCourierStore) return

    const delivery_date = route().params.date || this.currentDay
    const manager_id = this.currentCourierStore.id
    const orders = this.checkedStore.map((delivery) => ({
      id: delivery.id,
      manager_id,
      delivery_date,
    }))

    router.post(
      route(Routes.deliveries.distribution, { id: this.zoneID }),
      {
        orders,
      },
      {
        onSuccess() {
          $this.clearAllChecked()
          $this.events.resetCurrentCourier()
        },
      },
    )
  }
}
