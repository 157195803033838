import { useForm } from '@inertiajs/react'
import { useFormValidation } from '$hooks/useFormValidation'
import { useStore } from 'effector-react'
import { $validationFns } from '$components/Leads/Statused/Entities/Leads/Modal/ChangeStatus/utils/models'

export const useStatusValidation = (defaultData) => {
  const validationFns = useStore($validationFns)
  const { data, setData, post, processing, errors, setError, clearErrors } =
    useForm(defaultData)

  const onCheckIsValid = () => {
    let isValid = true
    if (!data.status) {
      setError('status', 'Нужно выбрать статус')
      isValid = false
    }

    const fns = validationFns[data.status]
    for (const fieldName in fns) {
      const validFn = fns[fieldName]
      const interceptError = (e) => {
        setError(fieldName, e)
      }

      if (!validFn(data, interceptError)) {
        isValid = false
      } else {
        clearErrors([fieldName])
      }
    }

    return isValid
  }

  const [validate, markErrors] = useFormValidation(
    `leads-change-status-validation`,
    onCheckIsValid,
    data,
    errors,
    clearErrors,
    false,
  )

  return {
    validate,
    data,
    setData,
    post,
    processing,
    errors,
    markErrors,
    setError,
    clearErrors,
  }
}
