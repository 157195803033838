export const TagItem = ({ tag, onDelete }) => {
  return (
    <div className="comments__item">
      <button className="comments__item-delete" onClick={() => onDelete(tag)}>
        Удалить комментарий
      </button>
      <span>{tag.value ? tag.value : '</?>'}</span>
    </div>
  )
}
