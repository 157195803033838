import React from 'react'
import clsx from 'clsx'

export const NoResults = ({ title, text = null, ...other }) => {
  const defaultTitle = 'Не найдено'

  return (
    <div {...other} className={clsx('no-result', other.className)}>
      {title !== false && (
        <p className="no-result__title">{title || defaultTitle}</p>
      )}
      {text && <p className="no-result__text">{text && text}</p>}
    </div>
  )
}
