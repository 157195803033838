import { Link } from '@inertiajs/react'
import { Routes } from '$root/utils/constants/routes'
import clsx from 'clsx'

export const ContactsField = ({
  name,
  link,
  contact,
  className,
  linkClassName,
}) => {
  return (
    <div className={clsx('deal__contacts', className)}>
      {(link && (
        <Link className={clsx('deal__name', linkClassName)} href={link}>
          {name ? name : '<>'}
        </Link>
      )) || <span>{(name && name) || '<>'}</span>}
      <a className="deal__phone" href="#">
        {contact}
      </a>
    </div>
  )
}
