import React from 'react'
import { MenuItem } from '$components/UI/Sidebar/Menu/MenuItem'

export const Menu = (props) => {
  return props.menu.length ? (
    <div className={props.className}>
      {props.menu.map((item, key) => (
        <MenuItem key={key} item={item} />
      ))}
    </div>
  ) : null
}
