import icon from '~svg/icons/icon-lg-prizes.svg'

export const AwardsPane = ({ amount }) => {
  return (
    <div className="color-panel color-panel--green">
      <img
        className="color-panel__icon"
        src={icon}
        alt="Премии"
        title="Премии"
      />
      <p className="color-panel__title">Премии</p>
      <p className="color-panel__descr">Общая сумма премий сотрудников</p>
      <p className="color-panel__value">{amount || '-'}</p>
    </div>
  )
}
