import clsx from 'clsx'
import { router, usePage } from '@inertiajs/react'
import { Routes } from '$root/utils/constants/routes'
import React, { useCallback, useEffect, useMemo } from 'react'
import { usePermissions } from '$hooks/usePermissions'
import { useModals } from '$hooks/useModals'
import { StaffTransactions } from '$components/Staff/Transactions/StaffTransactions'
import { SideModal } from '$components/UI/Modals/SideModal'
import useDeliveryPageFilter from '$lib/deliveries/hooks/useDeliveryPageFilter'
import { useDeliveryPageCleanings } from '$lib/deliveries/hooks/useDeliveryPageCleanings'
import { goExportRoute } from '$root/utils/functions'
import { Modal } from '$components/UI/Modals/Modal'
import { ModalSize } from '$root/utils/constants/modals'
import { useStore } from 'effector-react/scope'
import { $modals } from '$models/modals'
import { SelectResponsibleManagerForm } from '$components/Deliveries/DistributionDeliveries/SelectResponsibleManagerForm'
import _ from 'lodash'

export const DeliveryPageURIName = {
  Evening: 'delivery_evening',
  Morning: 'delivery_morning',
  PickUp: 'pickup',
}

const pickupTab = {
  title: 'Самовывоз',
  route: Routes.deliveries.pickup.page,
}

const tabs = [
  {
    title: 'Вечерняя доставка',
    route: Routes.deliveries.page,
    uriPageName: DeliveryPageURIName.Evening,
    default: true,
  },
  {
    title: 'Утренняя доставка',
    route: Routes.deliveries.page,
    uriPageName: DeliveryPageURIName.Morning,
  },
  pickupTab,
]

const DeliveriesPageTabs = () => {
  const currentRoute = route().current()
  const routeParams = route().params
  const { isAllow } = usePermissions()
  const { filter, updateFilter } = useDeliveryPageFilter()
  const { cleanChangeTab } = useDeliveryPageCleanings()

  const goTab = (tab) => {
    if (tab.uriPageName) {
      cleanChangeTab()
      updateFilter('delivery_type', tab.uriPageName, tab.route)
    } else {
      router.get(route(tab.route))
    }
  }

  const currentUriTabFilterName = useMemo(() => {
    return (filter.delivery_type && filter.delivery_type) || null
  }, [routeParams])

  const isActive = useCallback(
    (tab) => {
      const currentRouteIsMatched = currentRoute === tab.route
      const pageUriIsMatched =
        !!currentUriTabFilterName && tab.uriPageName === currentUriTabFilterName

      if (pageUriIsMatched) {
        return true
      } else if (!pageUriIsMatched && !currentUriTabFilterName) {
        if (
          currentRouteIsMatched &&
          currentRoute === Routes.deliveries.pickup.page
        ) {
          return true
        }
        return currentRouteIsMatched && tab.default
      }
    },
    [currentRoute, routeParams],
  )

  const { enums, orders } = usePage().props
  const { openModal, closeModal } = useModals()

  const getCourierDeliveries = (courierID) => {
    return (
      (enums.couriersDeliveries && enums.couriersDeliveries[courierID]) || []
    )
  }

  const showResponsibleManagerModal = () => {
    openModal('ResponsibleManager', Modal, {
      size: ModalSize.ExtraLarge,
      contentComponent: SelectResponsibleManagerForm,
      title: 'Выберите ответственного',
      users: _.values(enums.users) || [],
      onSelect: (userId) => {
        if (orders && orders.rows.length > 0) {
          router.post(
            route(Routes.deliveries.saveDeliveryInfo, route().params),
            {
              orders: orders.rows.map((row) => ({
                id: row.id,
                manager_id: userId,
              })),
            },
            {
              onFinish() {
                closeModal()
              },
            },
          )
        } else {
          alert('Список заказов пуст')
        }
      },
    })
  }

  const showSalaryModal = () => {
    const couriersWithTable = [
      ..._.values(enums.couriers).map((user) => {
        const courierDeliveries = getCourierDeliveries(user.id)

        return {
          ...user,
          customTable: () =>
            (courierDeliveries.length > 0 && (
              <table className="salary-delivery-table">
                <thead>
                  <td>Район</td>
                  <td>Кол-во адресов</td>
                </thead>
                <tbody>
                  {courierDeliveries.length > 0 ? (
                    courierDeliveries.map((delivery, i) => (
                      <tr key={i}>
                        <td>{delivery.delivery_zone_name}</td>
                        <td>{delivery.orders_count}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )) || <></>,
        }
      }),
    ]

    openModal('CouriersSalary', SideModal, {
      contentComponent: StaffTransactions,
      title: 'Расчет зарплаты',
      users: couriersWithTable,
      salaryRates: enums.salaryRates,
      routes: { save: Routes.deliveries.save_salary },
    })
  }

  return (
    <div className="second-head">
      <div className="tab-category">
        {tabs.map((tab, i) => (
          <button
            className={clsx('tab-category__btn', { active: isActive(tab) })}
            key={i}
            onClick={() => goTab(tab)}
          >
            {tab.title}
          </button>
        ))}
      </div>
      {isAllow(['deliveries.edit', 'deliveries.create']) && (
        <>
          <div className="tab-actions">
            {isActive(pickupTab) && (
              <button
                className="second-head__btn second-head__btn--payment js-modal-payroll-open"
                onClick={showResponsibleManagerModal}
              >
                Назначить ответственного
              </button>
            )}

            <button
              className="second-head__btn second-head__btn--payment js-modal-payroll-open"
              onClick={showSalaryModal}
            >
              Начислить оплату
            </button>
            <button
              className="second-head__btn second-head__btn--print"
              onClick={() => {
                let exportRoute = 'deliveries.export'

                if (route().current() === 'deliveries.pickup') {
                  exportRoute = 'deliveries.pickup.export'
                } else if (route().current() === 'deliveries.zone') {
                  exportRoute = 'deliveries.zone.export'
                }

                goExportRoute(exportRoute)
              }}
            >
              Печать
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default DeliveriesPageTabs
