import React from 'react'

import avatarImage from '~images/avatar.jpg'
import { logout } from '$root/api/auth'

export const CurrentUser = ({ user }) => {
  return (
    <div className="user">
      <div className="user__photo">
        <img className="user__photo-img" src={avatarImage} alt="" />
      </div>
      <div className="user__info">
        <p className="user__name">{user.name}</p>
        {user.roles.length > 0 ? (
          <p className="user__position">{_.first(user.roles)}</p>
        ) : null}
      </div>
      <button className="user__btn-logout" onClick={(event) => logout()}>
        Выйти
      </button>
    </div>
  )
}
