import React from 'react'
import { DeliveriesFilters } from '$components/Deliveries/Head/DeliveriesFilters'
import { SideModal } from '$components/UI/Modals/SideModal'
import { StaffTransactions } from '$components/Staff/Transactions/StaffTransactions'
import { useModals } from '$hooks/useModals'
import { usePage } from '@inertiajs/react'
import { Routes } from '$root/utils/constants/routes'
import { usePermissions } from '$hooks/usePermissions'
import { useDeliveryZones } from '$lib/deliveries/hooks/useDeliveryZones'

export const DeliveriesHead = () => {
  const { enums } = usePage().props
  const { openModal } = useModals()
  const { isAllow } = usePermissions()
  const { selectedZone } = useDeliveryZones()

  const getCourierDeliveries = (courierID) => {
    return (
      (enums.couriersDeliveries && enums.couriersDeliveries[courierID]) || []
    )
  }

  const showSalaryModal = () => {
    const couriersWithTable = [
      ...enums.couriers.map((user) => {
        const courierDeliveries = getCourierDeliveries(user.id)

        return {
          ...user,
          customTable: () =>
            (courierDeliveries.length > 0 && (
              <table className="salary-delivery-table">
                <thead>
                  <td>Район</td>
                  <td>Кол-во адресов</td>
                </thead>
                <tbody>
                  {courierDeliveries.length > 0 ? (
                    courierDeliveries.map((delivery, i) => (
                      <tr key={i}>
                        <td>{delivery.delivery_zone_name}</td>
                        <td>{delivery.orders_count}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                  )}
                </tbody>
              </table>
            )) || <></>,
        }
      }),
    ]

    openModal('CouriersSalary', SideModal, {
      contentComponent: StaffTransactions,
      title: 'Расчет зарплаты',
      users: couriersWithTable,
      salaryRates: enums.salaryRates,
      routes: { save: Routes.deliveries.save_salary },
    })
  }

  return (
    <>
      <div className="distribution-deliveries__filters">
        <DeliveriesFilters />
      </div>
    </>
  )
}
