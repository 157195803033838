import { AddNewTag } from '$components/UI/Inputs/TagsDropdown/AddNewTag'
import { createContext, useEffect, useState } from 'react'
import { ActiveTagDropdown } from '$components/UI/Inputs/TagsDropdown/ActiveTag'
import { ActiveTagReplacer } from '$components/UI/Inputs/TagsDropdown/ActiveTag/Replacer'
import { ActiveTagRationReplacer } from '$components/UI/Inputs/TagsDropdown/ActiveTag/Replacer/Ration'
import { SearcheableActiveItem } from '$components/UI/Inputs/TagsDropdown/ActiveTag/Searcheable/Searcheable'
import { SearcheableList } from '$components/UI/Inputs/TagsDropdown/ActiveTag/Searcheable/SearcheableList'

export const TagsDropdownContext = createContext()

export const TagsDropdown = ({
  children,
  setParams,
  variants,
  multiple = true,
  values,
  titleKey,
  disabled = false,
  onChange,
  error = false,
  needEmpty = true,
  ...otherProps
}) => {
  const emptyValue = { [titleKey]: 'Не выбрано' }

  const getDefaultValues = (values) => {
    return !_.isEmpty(values) ? values : needEmpty ? [emptyValue] : []
  }

  const [activeTags, setActiveTags] = useState(() => getDefaultValues(values))

  const onAddNewTag = (tag) => {
    const newActiveTags = [...activeTags, tag]
    setActiveTags(newActiveTags)

    if (onChange) {
      onChange(newActiveTags)
    }
    return true
  }

  const onRemoveActiveTag = (tag) => {
    if (disabled) {
      return
    }
    const newActiveTags = [...activeTags]
    const index = activeTags.indexOf(tag)
    if (!!index || index === 0) {
      newActiveTags.splice(index, 1)
    }

    setActiveTags(newActiveTags)

    if (onChange) {
      onChange(newActiveTags)
    }
  }

  const onChangeActiveTag = (currentTag, tag) => {
    const newActiveTags = [...activeTags]
    const index = newActiveTags.indexOf(currentTag)

    if (!!index || index === 0) {
      newActiveTags.splice(index, 1, tag)
    }

    setActiveTags(newActiveTags)

    if (onChange) {
      onChange(newActiveTags)
    }
    return true
  }

  useEffect(() => {
    setActiveTags(values || getDefaultValues(values))
  }, [values])

  return (
    <TagsDropdownContext.Provider
      value={{
        values: activeTags,
        titleKey,
        multiple,
        tags: variants,
        onAdd: onAddNewTag,
        onRemove: onRemoveActiveTag,
        onChange: onChangeActiveTag,
        disabled,
        ...otherProps,
      }}
    >
      {children(activeTags)}
    </TagsDropdownContext.Provider>
  )
}

TagsDropdown.ActiveTagDropdown = ActiveTagDropdown
TagsDropdown.SearcheableActiveItem = SearcheableActiveItem
TagsDropdown.SearcheableList = SearcheableList
TagsDropdown.ActiveTagReplacer = ActiveTagReplacer
TagsDropdown.ActiveTagRationReplacer = ActiveTagRationReplacer
TagsDropdown.AddNewTag = AddNewTag
