import { fork } from 'effector'

export const scope = fork()
export const DistributionDeliveriesScopes = {
  scope: fork(),
  DeliveryZone: {
    scope: fork(),
  },
}
export const qwe = fork()
