import React, { createContext } from 'react'
import { NoResults } from '$components/UI/DataGrid/NoResults'
import { TableDataGridHead } from '$components/UI/DataGrid/Table/Head'
import { TableDataGridBody } from '$components/UI/DataGrid/Table/Body'
import { TableDataGridFooter } from '$components/UI/DataGrid/Table/Footer'
import { TableDataGridWrapper } from '$components/UI/DataGrid/Table/Wrapper'
import { SpecificChild } from '$components/UI/React/SpecificChild'
import { useDataGridCompound } from '$hooks/useDataGridCompound'

export const DataGridContext = createContext({
  data: [],
  detail: null,
  route: null,
})

export const DataGridCompound = ({
  data,
  route,
  children,
  wrapper: Wrapper = undefined,
  header: Header = undefined,
  body: Body = undefined,
  footer: Footer = undefined,
  noResults = undefined,
  ...other
}) => {
  useDataGridCompound({ data, route })

  const WrapperOverload = ({ children: nestedChildren }) => nestedChildren

  const WrapperComponent = ({ children: wrapperChildren }) =>
    Wrapper ? (
      React.cloneElement(Wrapper, { children: wrapperChildren })
    ) : (
      <SpecificChild
        childs={children}
        type={DataGridCompound.Wrapper}
        DefaultComponent={WrapperOverload}
      >
        {children || wrapperChildren}
      </SpecificChild>
    )

  const HeaderComponent = ({ children }) =>
    Header || <SpecificChild childs={children} type={DataGridCompound.Head} />
  const BodyComponent = ({ children }) =>
    Body || <SpecificChild childs={children} type={DataGridCompound.Body} />
  const FooterComponent = ({ children }) =>
    (Footer && React.cloneElement(Footer, { data })) || (
      <SpecificChild
        data={data}
        childs={children}
        type={DataGridCompound.Footer}
      />
    )

  return (
    <DataGridContext.Provider
      value={{
        data,
        route,
      }}
    >
      <WrapperComponent>
        <HeaderComponent />
        {!data.rows.length &&
          (noResults || <NoResults text="В списке пусто" /> || <></>)}
        <BodyComponent />
      </WrapperComponent>
      <FooterComponent />
    </DataGridContext.Provider>
  )
}

DataGridCompound.Wrapper = TableDataGridWrapper
DataGridCompound.Head = TableDataGridHead
DataGridCompound.Body = TableDataGridBody
DataGridCompound.Footer = TableDataGridFooter
