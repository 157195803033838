import React, { useEffect } from 'react'
import { Label } from '$components/UI/Inputs/Label'

export const StatusField = ({
  data,
  title,
  fieldName,
  required,
  validationFn,
  invalidText,
  errors,
  markErrors,
  onValidation,
  children,
}) => {
  useEffect(() => {
    onValidation(fieldName, (form, setError) => {
      let isValid = true
      if (required) {
        if (!form[fieldName]) {
          setError('Это обязательное поле')
          isValid = false
        }
      }
      if (!!validationFn && validationFn instanceof Function) {
        isValid = validationFn(data)
      }
      return isValid
    })
  }, [])

  return (
    <>
      <Label text={title} />
      {markErrors && errors && errors.hasOwnProperty(fieldName) && (
        <span className="text__error no-margin">{errors[fieldName]}</span>
      )}
      {children}
    </>
  )
}
