import { EffectorDomainLogic } from '$root/utils/effector/domain-logic'
import { useStore } from 'effector-react'
import { ReplaceQuantityCalculator } from '$lib/rations/effector/ration-replacements/quantity-calculator'
import { IncreaseReplaceStrategy } from '$lib/rations/effector/ration-replacements/replace-strategy/increase-strategy'
import { DeleteReplaceStrategy } from '$lib/rations/effector/ration-replacements/replace-strategy/delete-strategy'
import { DecreaseReplaceStrategy } from '$lib/rations/effector/ration-replacements/replace-strategy/decrease-strategy'
import { createEvent } from 'effector'
import { MultiplierCode } from '$root/utils/products/const'
import { getEnumMappedIdByData } from '$root/utils/enums'

export class RationReplacementsEffector extends EffectorDomainLogic {
  enums = null

  _clientRoutes = null
  _ration = null
  products = null
  $products = null

  _ration_id_key = null
  _order = null
  _routes = null

  events = {
    setProducts: null,
  }

  constructor({ clientRoutes, order, ration, ration_id_key, routes, enums }) {
    super()

    this.enums = enums
    this._clientRoutes = clientRoutes
    this._ration_id_key = ration_id_key
    this._ration = ration
    this._order = order
    this._routes = routes
  }

  identify() {
    return `${this._order.id}-${this._ration[this._ration_id_key]}-${this._ration.id}-replacements-${route().current()}`
  }

  _withProductReplacements(products) {
    return products.reduce((array, product) => {
      const quantityCalculator = new ReplaceQuantityCalculator(
        this._ration,
        product,
        this.enums,
        this.productIsInRation(product),
      )
      const quantityCalculates =
        quantityCalculator.getProductQuantityWithWeight()
      return [
        ...array,
        {
          ...product,
          replaceQuantity:
            (quantityCalculates && quantityCalculates.quantity) || 0,
          isInRation: this.productIsInRation(product),
        },
      ]
    }, [])
  }

  _initialProducts() {
    return this._withProductReplacements([
      ...this.getRationProducts(),
      ...this.getNotRationProducts(),
    ])
  }

  _createProductsStore() {
    this.events.setProducts = createEvent()
    this.$products = this._domain
      .store('products', this._initialProducts())
      .on(this.events.setProducts, (state, products) => products)
  }

  init() {
    try {
      this._createProductsStore()
    } catch (e) {
      console.warn(e)
    }
  }

  getProductsStore() {
    return this.products
  }

  useStores() {
    this.products = useStore(this.$products)
  }

  onRationUpdated(ration) {
    this._ration = ration
    this.events.setProducts(this._initialProducts())
  }

  productIsInRation(product) {
    return (
      this.getRationProducts()
        .map((rationProduct) => rationProduct.id)
        .indexOf(product.id) > -1
    )
  }

  getRationProducts() {
    return this._ration.products.map(({ product_id, ...other }) => ({
      ...other,
      ...this.enums.products[product_id],
    }))
  }

  getNotRationProducts() {
    const rationProductsIds = this.getRationProducts().map(
      (product) => product.id,
    )

    let productsNotInration = []

    try {
      productsNotInration =
        (this.enums.products &&
          _.values(this.enums.products).filter(
            (product) => rationProductsIds.indexOf(product.id) < 0,
          )) ||
        []
    } catch (e) {
      console.warn(e)
    }

    return productsNotInration
  }

  getReplaceStrategy(product, count) {
    const quantityCalculator = new ReplaceQuantityCalculator(
      this._ration,
      product,
      this.enums,
      this.productIsInRation(product),
    )
    const currentQuantityInfo = quantityCalculator.getProductQuantity()
    const currentQuantity =
      (currentQuantityInfo && currentQuantityInfo.quantity) || 0
    const currentReplace =
      (currentQuantityInfo && currentQuantityInfo.replace) || null
    const productIsInRation = this.productIsInRation(product)

    let multiplierX0 = getEnumMappedIdByData(
      this.enums.multipliers,
      'code',
      MultiplierCode.x0,
    )

    if (count > 0) {
      if (
        productIsInRation &&
        currentReplace &&
        currentReplace.multiplier_id === multiplierX0.id
      ) {
        return new DeleteReplaceStrategy(
          this._clientRoutes,
          this._ration,
          product,
          this._ration_id_key,
          currentQuantityInfo.replace.id,
          currentReplace,
        )
      } else {
        return new IncreaseReplaceStrategy(
          this.enums,
          this._clientRoutes,
          this._ration,
          product,
          this._ration_id_key,
          productIsInRation,
          currentReplace,
          currentQuantity,
        )
      }
    } else {
      if (
        (productIsInRation &&
          currentQuantity === 1 &&
          !!currentReplace &&
          currentReplace.multiplier_id === multiplierX0.id) ||
        (!productIsInRation && currentQuantity === 0)
      ) {
        return null
      }
      if (
        (currentQuantity === 1 && !productIsInRation) ||
        (productIsInRation && currentQuantity === 1)
      ) {
        return new DeleteReplaceStrategy(
          this._clientRoutes,
          this._ration,
          product,
          this._ration_id_key,
          currentQuantityInfo.replace.id,
          currentReplace,
        )
      } else if (currentQuantity === 0 && productIsInRation) {
        return new IncreaseReplaceStrategy(
          this.enums,
          this._clientRoutes,
          this._ration,
          product,
          this._ration_id_key,
          productIsInRation,
          currentReplace,
        )
      } else if (currentQuantity === 1 && !productIsInRation) {
        return new IncreaseReplaceStrategy(
          this.enums,
          this._clientRoutes,
          this._ration,
          product,
          this._ration_id_key,
          productIsInRation,
          currentReplace,
        )
      } else {
        return new DecreaseReplaceStrategy(
          this.enums,
          this._clientRoutes,
          this._ration,
          product,
          this._ration_id_key,
          currentQuantityInfo.quantity,
          currentReplace,
        )
      }
    }
  }

  onChangeReplace(product, count, onChangeCb = undefined) {
    const strategy = this.getReplaceStrategy(product, count)
    if (strategy) {
      strategy.do(onChangeCb, this._ration)
    }
  }
}
