import { ContactsField } from '$components/Leads/Statused/DataGrid/Fields/ContactsField'
import { Chat } from '$components/Leads/Statused/DataGrid/Fields/Chat'
import { ContactType } from '$root/utils/constants/clients'
import { StatusReasonField } from '$components/Leads/Statused/DataGrid/Fields/StatusReasonField'
import { Routes } from '$root/utils/constants/routes'
import { ContactAtField } from '$components/Leads/Statused/Entities/Clients/DataGrid/Fields/ContactAtField'
import { RationsField } from '$components/Leads/Statused/DataGrid/Fields/RationsField'
import { formatPhone } from '$root/utils/functions'

export const ProlongFrozenClientsitem = ({ item: client }) => {
  const phone =
    (client.contacts &&
      client.contacts.find(
        (contact) => contact.contact_type_code === ContactType.Phone,
      )) ||
    null

  return (
    <>
      <ContactsField
        name={client.full_name}
        link={
          (client &&
            route(Routes.leads.clients.view, {
              ...route().params,
              id: client.id,
            })) ||
          null
        }
        contact={(phone && formatPhone(phone.value)) || null}
      />
      <RationsField
        rations={client.rations || []}
        last_delivery={client.last_order_date}
      />
      <Chat />
      <StatusReasonField reason={client.status_comment} />
      <ContactAtField client={client} />
    </>
  )
}
