import EffectorContextProvider from '$components/UI/React/EffectorContextProvider'
import { EffectorServices } from '$root/utils/effector/effector-services'
import { InvoiceStore } from '$lib/invoices/new-effector/invoice-list-new-store'
import React, { useEffect, useRef } from 'react'
import InvoiceEditableListItem from '$components/Clients/Invoices/InvoiceEditableListItem'
import { onPostSended } from '$models/page'
import { Routes } from '$root/utils/constants/routes'

const EditOneInvoice = ({ invoice, onSave, closeModal }) => {
  const ref = useRef()
  const invoiceService = EffectorServices.getService({
    service: new InvoiceStore({
      invoice,
    }),
  })

  const invoiceServices = invoiceService.services

  const { formService, hoveringEditableService } = invoiceServices

  invoiceService.useContainer()

  const form = formService.useContainer()

  hoveringEditableService.useHeaderContainer(ref)

  const saveInvoice = () => {
    if (formService.validateForm()) {
      onSave(form.data)
    }
  }

  useEffect(() => {
    form.clearErrors()

    return () => onPostSended.watch(() => closeModal())
  }, [])

  useEffect(() => {
    invoiceService.updateInvoice(invoice)
  }, [invoice])

  return (
    <EffectorContextProvider services={{ invoiceService }}>
      <div className="invoice" ref={ref}>
        <EffectorContextProvider
          services={{ formService, hoveringEditableService }}
          value={{ form }}
        >
          <InvoiceEditableListItem />
        </EffectorContextProvider>
      </div>
      <button className="btn btn__filled btn--blue" onClick={saveInvoice}>
        Сохранить
      </button>
    </EffectorContextProvider>
  )
}

export default EditOneInvoice
