import { router } from '@inertiajs/react'
import { getEnumMappedIdByData } from '$root/utils/enums'
import { MultiplierCode } from '$root/utils/products/const'

export class IncreaseReplaceStrategy {
  _enums = null
  _clientRoutes = null
  _ration = null
  _product = null
  _productIsInRation = null
  _quantity = null
  _replace = null
  _routeParams = null
  _ration_id_key = null

  constructor(
    enums,
    clientRoutes,
    ration,
    product,
    ration_id_key,
    productIsInRation,
    currentReplace,
    quantity = null,
  ) {
    this._ration_id_key = ration_id_key
    this._enums = enums
    this._clientRoutes = clientRoutes
    this._routeParams = route().params
    this._ration = ration
    this._product = product
    this._productIsInRation = productIsInRation
    this._quantity = quantity
    this._replace = currentReplace
  }

  do(onChangeCb = undefined) {
    let multiplierId = this._product.multiplier_id || this._ration.multiplier_id
    let multiplierX0 = getEnumMappedIdByData(
      this._enums.multipliers,
      'code',
      MultiplierCode.x0,
    )
    let quantity = this._quantity + 1

    if (!this._quantity && !this._productIsInRation && !!this._replace) {
      multiplierId = multiplierX0.id
      quantity = 1
    } else if (
      this._quantity &&
      this._productIsInRation &&
      this._replace &&
      this._replace.multiplier_id === multiplierX0.id
    ) {
      quantity = 1
    } else if (this._quantity === null && this._replace === null) {
      quantity = 1
      multiplierId = multiplierX0.id
    }

    const replacement = {
      ...(this._replace || {}),
      [this._ration_id_key]: this._ration[this._ration_id_key],
      product_id: this._product.id,
      multiplier_id: multiplierId,
      quantity,
    }

    if (onChangeCb) {
      onChangeCb(this._ration, this._replace, replacement)
    } else {
      router.post(
        route(this._clientRoutes.replacements.update, {
          id: this._routeParams.id,
          status: this._routeParams.status,
        }),
        replacement,
      )
    }
  }
}
