import React from 'react'
import { MainLayout } from '$components/UI/Layouts/MainLayout'
import { ReportsTabs } from '$components/Reports/Tabs/ReportsTabs'
import { ExpensesDataGrid } from '$components/Reports/DataGrids/Expenses/ExpensesDataGrid'
import { ExpensesReportsFilters } from '$components/Reports/Filters/ExpensesReportsFilters'
import { Routes } from '$root/utils/constants/routes'

export const ReportsExpenses = ({ list }) => {
  return (
    <>
      <ReportsTabs />
      <ExpensesReportsFilters />
      <ExpensesDataGrid data={list} route={Routes.reports.expenses.page}>
        <ExpensesDataGrid.Wrapper className="table table--expenses table--last-td-bold table--first-td-bold">
          <ExpensesDataGrid.Header />
          <ExpensesDataGrid.Body />
          <ExpensesDataGrid.Footer />
        </ExpensesDataGrid.Wrapper>
      </ExpensesDataGrid>
    </>
  )
}

ReportsExpenses.layout = (page) => <MainLayout>{page}</MainLayout>
