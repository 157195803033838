import React from 'react'
import { useStore } from 'effector-react'
import { $dataGrid, dataGridIsChanged } from '$models/dataGrid'
import { deferred, goExportRoute } from '$root/utils/functions'
import { useModals } from '$hooks/useModals'
import { ClientEditModal } from '$components/Clients/Modal/Detail/EditModal'
import { usePermissions } from '$hooks/usePermissions'
import { Routes } from '$root/utils/constants/routes'
import { showCloseConfirmModal } from '$components/UI/Modals/CloseConfirmModal'

export const PageHeader = () => {
  const dataGrid = useStore($dataGrid)
  const { openModal, closeModal } = useModals()
  const { isAllow } = usePermissions()

  const deferredSearch = deferred((options) => {
    dataGridIsChanged({ search: options.search })
  })

  const onShowEditForm = () => {
    openModal('NewClient', ClientEditModal, {
      title: 'Новый клиент',
      routes: Routes.clients,
      onClose: ({ isUserClick }) => {
        if (isUserClick) {
          showCloseConfirmModal(openModal, closeModal)
          return true
        }
      },
    })
  }

  return (
    <div className="header__wrap">
      {isAllow('clients.create') && (
        <button
          className="header__btn-add"
          onClick={() => {
            onShowEditForm()
          }}
        >
          Новый клиент
        </button>
      )}

      <button
        className="second-head__btn second-head__btn--print"
        onClick={() => {
          goExportRoute('clients.finance.export')
        }}
      >
        Фин. отчет
      </button>

      <label className="search">
        <input
          className="search__input"
          type="text"
          placeholder="По имени, фамилии или телефону"
          defaultValue={dataGrid.search}
          onInput={(ev) => {
            deferredSearch({ search: ev.target.value }, 1.5)
          }}
        />
        <button className="search__btn">Поиск</button>
      </label>
    </div>
  )
}
