import React from 'react'
import { NoResults } from '$components/UI/DataGrid/NoResults'
import { ListItem } from './ListItem'
import { router } from '@inertiajs/react'
import { Routes } from '$root/utils/constants/routes'
import { usePermissions } from '$hooks/usePermissions'

export const Body = ({ data }) => {
  const { isAllowOrError } = usePermissions()

  const onListItemClick = (id) => {
    if (isAllowOrError('clients.read')) {
      router.get(
        route(Routes.clients.view, { id }) + location.search,
        undefined,
        {
          only: ['detail'],
        },
      )
    }
  }

  return (
    <>
      {(data.rows.length &&
        data.rows.map((client, i) => (
          <ListItem client={client} key={i} onClick={onListItemClick} />
        ))) || <NoResults />}
    </>
  )
}
