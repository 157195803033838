import { isActiveTab } from '$components/Settings/Leads/utils/functions'
import { LeadSettingsTab } from '$components/Settings/Leads/utils/const'
import { useDataGridCompound } from '$hooks/useDataGridCompound'
import { Routes } from '$root/utils/constants/routes'
import { SettingsClientsFilters } from '$components/Settings/Leads/ClientsTab/DataGrid/SettingsClientsFilters'
import { useEffectorDomain } from '$hooks/useEffectorDomain'
import { SettingsClientsEffector } from '$lib/settings/leads/clients/effector'
import { SettingsClientsContext } from '$lib/settings/leads/clients/context'
import { SortableColumn } from '$components/UI/DataGrid/SortableColumn'
import clsx from 'clsx'
import { getEnumTitleById } from '$root/utils/enums'
import { usePage } from '@inertiajs/react'
import { router } from '@inertiajs/react'
import { DataGridPagination } from '$components/UI/DataGrid/DataGridPagination'
import React, { useCallback } from 'react'
import { ContactType } from '$root/utils/constants/clients'
import { formatPhone } from '$root/utils/functions'

const columns = [
  { title: 'Фио', name: 'full_name', sortable: true },
  { title: 'Тип', name: 'type', sortable: true },
  { title: 'Город', name: 'city', sortable: true },
  { title: 'Адрес', name: 'address' },
  { title: 'Телефон', name: 'phone' },
  { title: 'Статус', name: 'status', sortable: true },
]

const TableColumn = ({ column, className, ...other }) => {
  return (
    <th {...other} className={clsx(className)}>
      {column.title}
    </th>
  )
}

const Column = ({ column, children, ...other }) =>
  (column.sortable && (
    <SortableColumn column={column} {...other}>
      {children}
    </SortableColumn>
  )) || <>{children}</>

export const ClientsTab = () => {
  if (!isActiveTab(LeadSettingsTab.Clients)) return <></>

  const { enums = {}, list } = usePage().props

  const { domain, service: settingsClientsService } = useEffectorDomain(
    new SettingsClientsEffector({}),
  )

  settingsClientsService.useStores()
  settingsClientsService.useContainer()

  useDataGridCompound({ data: list, route: Routes.settings.leads.clients.page })

  const removeClient = (id) => {
    router.delete(route(Routes.settings.leads.clients.delete), { data: { id } })
  }

  const getClientPhone = useCallback((contacts) => {
    const phone = ((contacts && _.isArray(contacts) && contacts) || []).find(
      (contact) => contact.contact_type_code === ContactType.Phone,
    )

    return (phone && phone.value) || ''
  }, [])

  return (
    <SettingsClientsContext.Provider value={{ settingsClientsService }}>
      <SettingsClientsFilters />
      <table className="table table--sort table--deals-clients">
        <thead>
          <tr>
            {columns.map((column, i) => (
              <Column column={column} key={i}>
                <TableColumn column={column} />
              </Column>
            ))}
          </tr>
        </thead>
        <tbody>
          {list.rows.map((client, i) => (
            <tr key={i}>
              <td>
                <a href={route(Routes.clients.view, { id: client.id })}>
                  {client.full_name}
                </a>
              </td>
              <td>
                {(client.status && (enums.clientTypes || {})[client.type]) ||
                  ''}
              </td>
              <td>{client.city_name}</td>
              <td>
                <div className="table--deals-clients__addresses">
                  {(client.addresses || []).map((addressWrapper, i) => (
                    <span className="table--deals-clients__address" key={i}>
                      {addressWrapper.address}
                    </span>
                  ))}
                </div>
              </td>
              <td>{formatPhone(getClientPhone(client.contacts))}</td>
              <td>
                {(client.status &&
                  (enums.clientStatuses || {})[client.status]) ||
                  ''}
                <button
                  className="table__btn-remove"
                  onClick={() => removeClient(client.id)}
                >
                  Удалить
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <DataGridPagination data={list} />
    </SettingsClientsContext.Provider>
  )
}
