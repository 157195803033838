import { Routes } from '$root/utils/constants/routes'
import { ContactsField } from '$components/Leads/Statused/DataGrid/Fields/ContactsField'
import { useStore } from 'effector-react'
import { $clientRoutes } from '$lib/orders/models/orders-store'

export const LeadContactsField = ({ lead }) => {
  const clientRoutes = useStore($clientRoutes)

  return (
    <ContactsField
      name={lead.client ? lead.client.full_name : lead.full_name}
      link={
        (lead.client &&
          clientRoutes &&
          route(clientRoutes.view, {
            ...route().params,
            id: lead.id,
          })) ||
        null
      }
      contact={lead.contact ? lead.contact : 'Контакт неопределен'}
    />
  )
}
