export const DeliveriesColumns = () => {
  return (
    <div className="deal-sort">
      <button>Клиент</button>
      <button>Дата</button>
      <button className="deal-sort__desc">Рацион</button>
      <button className="deal-sort__desc">Сумма</button>
    </div>
  )
}
