import { Label } from '$components/UI/Inputs/Label'
import React from 'react'
import clsx from 'clsx'

export const TextArea = ({
  reff,
  title,
  placeholder,
  value = '',
  onChange,
  disabled = false,
  cols = '30',
  rows = '4',
  className = [],
  wrapperClassName = [],
  error,
}) => {
  return (
    <>
      {title && <Label text={title} />}
      <div className={clsx(wrapperClassName, 'form__row')}>
        <textarea
          ref={reff}
          className={clsx('textarea', className, { error })}
          value={value || ''}
          name=""
          cols={cols}
          rows={rows}
          placeholder={placeholder}
          disabled={disabled}
          onChange={(ev) => {
            if (onChange) {
              onChange(ev.target.value)
            }
          }}
        ></textarea>
      </div>
    </>
  )
}
