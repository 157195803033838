import avatar from '~images/avatar-courier.jpg'
import clsx from 'clsx'
import { useState } from 'react'

export const CourierLinkingSelector = ({
  onChangeCourier,
  couriers = [],
  courier,
  index,
}) => {
  const [isEditing, setEditing] = useState()

  return (
    <div
      className={clsx('delivery-zone__couriers', { 'open-list': isEditing })}
    >
      <div className="delivery-zone__couriers-inner">
        <button
          className="delivery-zone__courier delivery-zone__courier--select"
          onClick={() => setEditing(!isEditing)}
        >
          {(courier && (
            <>
              <img
                className="delivery-zone__courier-avatar"
                src={avatar}
                alt=""
              />
              <span className="delivery-zone__courier-name">
                {courier.name || '-'}
              </span>
              {/*{ !!courier.orders_count && (*/}
              <span className="delivery-zone__courier-info">
                адресов{' '}
                <span className="delivery-zone__courier-value">
                  {courier.orders_count || 0}
                </span>
              </span>
              {/*) }*/}
            </>
          )) || (
            <span className="delivery-zone__courier-inner">
              <span className="delivery-zone__courier-title">
                Выбрать курьера
              </span>
              <span className="delivery-zone__courier-available">
                Доступно {couriers.length}
              </span>
            </span>
          )}
        </button>
        <div className="delivery-zone__couriers-list">
          {courier && (
            <button
              className="delivery-zone__courier"
              onClick={() => {
                setEditing(false)
                onChangeCourier(index, null)
              }}
            >
              <span className="delivery-zone__courier-name">Убрать</span>
            </button>
          )}
          {couriers.map((courierPossible, i) => (
            <button
              className="delivery-zone__courier"
              onClick={() => {
                setEditing(false)
                onChangeCourier(index, courierPossible)
              }}
              key={i}
            >
              <img
                className="delivery-zone__courier-avatar"
                src={avatar}
                alt=""
              />
              <span className="delivery-zone__courier-name">
                {courierPossible.name}
              </span>
              {/*<span className="delivery-zone__courier-info">адресов*/}
              {/*  <span*/}
              {/*      className="delivery-zone__courier-value">{ courierPossible.orders_count || 0 }</span>*/}
              {/*</span>*/}
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}
