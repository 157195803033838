export class EffectorDomainLogic {
  /** @type EffectorDomain */
  _domain = null

  /** @param domain: {EffectorDomain} */
  setDomain(domain) {
    this._domain = domain
  }

  /** Ключ домена */
  identify() {}

  /** Логика домена */
  init() {}
}
