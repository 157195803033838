import { OrderFieldComponentsByStatus } from '$lib/orders/utils/const'

export const OrdersFields = (props) => {
  const { status } = props
  const Fields = OrderFieldComponentsByStatus[status]

  return Fields.map((Field, i) => (
    <td key={i}>{Field && <Field {...props} />}</td>
  ))
}
