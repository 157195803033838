import React, { useEffect, useState } from 'react'
import { useModals } from '$hooks/useModals'
import { MessageModal } from '$components/UI/Modals/MessageModal'
import _ from 'lodash'
import {
  EffectorServices,
  EffectorStore,
} from '$root/utils/effector/effector-services'
import { createEvent, createStore } from 'effector'
import { useStore } from 'effector-react'

export const useFormValidation = (
  persistKey,
  onCheckIsValid,
  formData,
  errors,
  clearErrors,
  showModal = true,
) => {
  const [submitted, setSubmitted] = useState(false)
  const [markErrors, setMarkErrors] = useState(false)
  const [sendingForm, setSendingForm] = useState(false)

  const { openModal } = useModals()

  const onSendingForm = () => setSendingForm(true)

  const validate = (onSubmit = true, ...other) => {
    clearErrors()

    if (!submitted && onSubmit) {
      setSubmitted(onSubmit)
    }

    if ((onSubmit || submitted) && !markErrors) {
      setMarkErrors(true)
    }

    const isValid = onCheckIsValid(other)

    if (onSubmit && isValid) {
      setSendingForm(true)
    }

    return isValid
  }

  useEffect(() => {
    if (
      showModal &&
      submitted &&
      Object.values(errors).length &&
      !sendingForm
    ) {
      openModal('FormValidationError', MessageModal, {
        permit: true,
        title: 'Данные формы некорректны!',
        messages: Object.values(errors),
      })
    }
  }, [errors])

  useEffect(() => {
    setSendingForm(false)
    validate(false)
  }, [formData])

  return [validate, markErrors, onSendingForm]
}

class Submitted extends EffectorStore {
  submitted = false
  $submitted = null

  events = {
    setSubmitted: null,
  }

  init() {
    this.events.setSubmitted = createEvent()
    this.$submitted = createStore(this.submitted).on(
      this.events.setSubmitted,
      (state, submitted) => submitted,
    )
  }

  useStores() {
    return useStore(this.$submitted)
  }

  setSubmitted(submitted) {
    this.events.setSubmitted(submitted)
  }

  clearSubmit() {
    this.setSubmitted(false)
  }
}
export const useNewFormValidation = ({
  persistKey,
  onCheckIsValid,
  form,
  showModal = true,
}) => {
  const submittedService = EffectorServices.getService({
    service: new Submitted({ persistKey }),
  })
  const clearSubmit = submittedService.clearSubmit.bind(submittedService)

  const submitted = submittedService.useStores()

  const [isValid, setIsValid] = useState(false)

  const { openModal } = useModals()

  const validate = (onSubmit = true) => {
    let valid = false

    form.clearErrors()

    const errors = onCheckIsValid(form.data)

    if (onSubmit) {
      submittedService.setSubmitted(true)
    }

    if (_.keys(errors).length > 0) {
      form.setError(errors)

      if (showModal && onSubmit) {
        openModal('FormValidationError', MessageModal, {
          permit: true,
          title: 'Данные формы некорректны!',
          messages: Object.values(errors),
        })
      }
    } else {
      valid = true
    }

    setIsValid(valid)

    return valid
  }

  useEffect(() => {
    validate(false)
  }, [form.data])

  return [validate, submitted && !isValid, clearSubmit]
}
