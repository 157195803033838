import React, { useEffect } from 'react'
import { DeliveryZoneLayer } from '$components/Deliveries/Maps/OSM/DeliveryZoneLayer'
import { NewDeliveryZoneControl } from '$components/Deliveries/Maps/OSM/Controls/NewDeliveryZoneControl'
import { useMap } from 'react-leaflet'
import {
  deliveryZoneEdited,
  deliveryZoneSelected,
  newDeliveryZone,
} from '$lib/deliveries/models/zones'
import { reversePolygonGeoCoordinates } from '$lib/osm/functions'
import { onChangeRoute } from '$models/page'
import { Routes } from '$root/utils/constants/routes'
import { usePage } from '@inertiajs/react'

export const DeliveryZonesLayer = ({ zones, allowEdit = false }) => {
  const map = useMap()
  const { user } = usePage().props
  const routeCityID = route().params.city_id
  const cityID = routeCityID || user.city_id

  const onNewZone = (newZone) => {
    newDeliveryZone({ zone: newZone, cityID })
  }

  const onZoneEdited = ({ id, geoObject, ...data }) => {
    reversePolygonGeoCoordinates(geoObject)
    deliveryZoneEdited({ id, geoObject, ...data, cityID })
  }

  useEffect(() => {
    if (map) {
      window.map = map
    }
  }, [map])

  return (
    <>
      {allowEdit && <NewDeliveryZoneControl onNew={onNewZone} />}
      {zones.map(
        (zone, i) =>
          (!!zone.visibility || zone.visibility === undefined) && (
            <DeliveryZoneLayer
              key={`${zone.properties.id}-${zone.iter}`}
              zone={zone}
              allowEdit={allowEdit}
              onZoneEdited={onZoneEdited}
            />
          ),
      )}
    </>
  )
}
