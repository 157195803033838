import { DataGridContext } from '$components/UI/DataGridCompound/DataGridCompound'
import { useContext } from 'react'
import { useDateTime } from '$hooks/useDateTime'

export const Body = () => {
  const {
    data: { rows: expenses },
  } = useContext(DataGridContext)
  const { date } = useDateTime()
  return (
    <tbody>
      {expenses.map((expense, i) => (
        <tr key={i}>
          <td>{expense.expense_type_name}</td>
          <td>{expense.comment}</td>
          <td>{date(expense.created_at)}</td>
          <td>{expense.created_by_full_name}</td>
          <td>{expense.amount}</td>
        </tr>
      ))}
    </tbody>
  )
}
