import { TagsDropdown } from '$components/UI/Inputs/TagsDropdown'
import { getEnumItemById } from '$root/utils/enums'

export const CityInput = ({
  cities,
  cityId,
  onChange,
  isError,
  needAdd = true,
}) => {
  return (
    <div className="mb-1">
      <TagsDropdown
        variants={cities || []}
        values={(cityId && [getEnumItemById(cities, cityId)]) || null}
        titleKey="name"
        multiple={false}
        onChange={(selectedCities) => {
          const city = _.first(selectedCities)
          onChange(city)
        }}
      >
        {(activeCities) => {
          return (
            <>
              {activeCities.map((activeCity, i) => (
                <TagsDropdown.SearcheableActiveItem
                  tag={activeCity}
                  key={i}
                  style={{ cursor: 'pointer' }}
                  className={isError ? 'error' : undefined}
                >
                  <TagsDropdown.SearcheableList key={i} ration={activeCity} />
                </TagsDropdown.SearcheableActiveItem>
              ))}
              {!cityId && needAdd && <TagsDropdown.AddNewTag />}
            </>
          )
        }}
      </TagsDropdown>
    </div>
  )
}
