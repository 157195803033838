import { Item } from './Item'

export const Body = ({ children, deliveries }) => {
  return (
    <tbody>
      {deliveries.map((delivery, i) => (
        <Item delivery={delivery} key={i} />
      ))}
      {children}
    </tbody>
  )
}
