import { router } from '@inertiajs/react'

export class DeleteReplaceStrategy {
  _clientRoutes = null
  _routeParams = null
  _ration = null
  _product = null
  _replaceId = null
  _ration_id_key = null

  constructor(
    clientRoutes,
    ration,
    product,
    ration_id_key,
    replaceId,
    replace,
  ) {
    this._ration_id_key = ration_id_key
    this._clientRoutes = clientRoutes
    this._routeParams = route().params
    this._ration = ration
    this._product = product
    this._replaceId = replaceId
    this._replace = replace
  }

  do(onChangeCb = undefined) {
    const replacement = { id: this._replaceId }

    if (onChangeCb) {
      onChangeCb(this._ration, this._replace, replacement, true)
    } else {
      router.delete(
        route(this._clientRoutes.replacements.delete, {
          id: this._routeParams.id,
          status: this._routeParams.status,
        }),
        {
          data: replacement,
        },
      )
    }
  }
}
