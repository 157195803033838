import { usePage } from '@inertiajs/react'
import moment from 'moment-timezone'

export const useDateTime = () => {
  const { timezone } = usePage().props

  /**
   * Складывает части дат a по разделителю s
   * @param d {Date} - передаваемая дата
   * @param a {Array[]}
   * */
  const join = (d, a, s) => {
    function format(m) {
      let f = new Intl.DateTimeFormat('en', m)
      return f.format(d)
    }

    return a.map(format).join(s)
  }

  /** Добавляет к дате количество дней */
  const addDays = (d, days) => {
    var date = d instanceof Date ? d : new Date(d)
    date.setDate(date.getDate() + days)
    return date
  }

  /** Формирует даты между начальной и конечной */
  const dateRange = (startDate, endDate, addFn = undefined, interval = 1) => {
    addFn = addFn || addDays

    var retVal = []
    var current = new Date(startDate)
    var end = new Date(endDate)

    while (current <= end) {
      retVal.push(new Date(current))
      current = addFn(current, interval)
    }
    return retVal
  }

  /** Возвращает текущую дату строкой в ISO формате */
  const currentDate = () => {
    if (timezone) {
      return moment().tz(timezone).format()
    }
    return null
  }

  /**
   * Возвращает дату в локальном формате
   * @param d {Date}
   * */
  const date = (d) => {
    let momentDate = moment(d)
    if (timezone) {
      momentDate = momentDate.tz(timezone)
    }
    return momentDate.toDate().toLocaleDateString()
  }

  /**
   * Возвращает время даты в локальном формате
   * @param d {Date}
   * */
  const time = (d) => {
    let momentDate = moment(d)
    if (timezone) {
      momentDate = momentDate.tz(timezone)
    }
    return momentDate.toDate().toLocaleTimeString()
  }

  /**
   * Возвращает время и дату в локальном формате
   * @param d {Date}
   * */
  const dateTime = (d) => {
    let momentDate = moment(d)
    if (timezone) {
      momentDate = momentDate.tz(timezone)
    }
    return momentDate.format().toLocaleString()
  }

  /**
   * Возвращает следующий день
   * @param d {Date}
   * @return {Date}
   * */
  const nextDay = (d) => {
    return moment(d).add(1, 'd')
  }

  /**
   * Возвращает предыдущий день
   * @param d {Date}
   * @return {Date}
   * */
  const prevDay = (d) => {
    return moment(d).subtract(1, 'd')
  }

  /**
   * Возвращает переданный день в строке ISO формата
   * @param d {Date}
   * */
  const dateToISO = (d) => {
    let momentDate = moment(d)
    if (timezone) {
      momentDate = momentDate.tz(timezone)
    }
    return momentDate.toISOString()
  }

  /**
   * Возвращает дату в пригодном для хранения на бэкенде формате
   * @param d {Date}
   * @return {string} - 2000-01-01 */
  const dateToSaveFormat = (d) => {
    let a = [{ year: 'numeric' }, { month: '2-digit' }, { day: '2-digit' }]
    let s = join(d, a, '-')
    return s
  }

  /**
   * Возвращает дату в информационном формате: 23 февраля 1990 г.
   * @param d {Date} */
  const dateVerbosed = (d) => {
    const dateFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }
    return d
      .toLocaleDateString(undefined, dateFormatOptions)
      .replace(/[\S]+\,([\s\S]*?)/g, '$1')
  }

  /**
   * Возвращает дату в формате "1 авг."
   * */
  const dateMonthVerbosed = () => {
    return moment().format('D MMM')
  }

  return {
    currentDate,
    date,
    time,
    dateTime,
    prevDay,
    nextDay,
    dateToISO,
    dateVerbosed,
    dateToSaveFormat,
    dateRange,
    addDays,
    dateMonthVerbosed,
  }
}
