import React, { useState } from 'react'
import { AuthLayout } from '$components/UI/Layouts/AuthLayout'

import { login } from '$root/api/auth'

export const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = (event) => {
    event.preventDefault()
    login({ email, password })
  }

  return (
    <form className="auth__form" onSubmit={handleSubmit}>
      <h1 className="auth__title">Авторизация</h1>
      <label className="auth__label input">
        <input
          type="text"
          placeholder="Логин"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
      </label>
      <label className="auth__label input">
        <input
          type="password"
          placeholder="Пароль"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
      </label>
      <button className="btn btn__filled btn--blue" type="submit">
        Войти
      </button>
    </form>
  )
}

Login.layout = (page) => <AuthLayout>{page}</AuthLayout>
