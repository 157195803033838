import { SpecificChild } from '$components/UI/React/SpecificChild'

import { Title } from './Title'
import { Head } from './Head'
import { Body } from './Body'
import { Footer } from './Footer'

export const DataGrid = ({ children, deliveries }) => {
  return (
    <div className="delivery-table">
      <SpecificChild childs={children} type={DataGrid.Title} />
      <table className="table table--sort table--deals-clients table--last-tr-bold">
        <SpecificChild childs={children} type={DataGrid.Head} />
        <SpecificChild
          childs={children}
          type={DataGrid.Body}
          deliveries={deliveries}
        >
          <SpecificChild childs={children} type={DataGrid.Footer} />
        </SpecificChild>
      </table>
    </div>
  )
}

DataGrid.Title = Title
DataGrid.Head = Head
DataGrid.Body = Body
DataGrid.Footer = Footer
