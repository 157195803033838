import { useStore } from 'effector-react'
import {
  $currentCity,
  $deliveryZones,
  $selectedZoneID,
  setCurrentCity,
  setZones,
} from '$lib/deliveries/models/zones'
import { useEffect, useMemo } from 'react'
import { usePage } from '@inertiajs/react'
import { normalizeZoneDataToDraw } from '$lib/deliveries/helpers/zone'
import { polylabel } from '$lib/vendor/polylabel'

const useDeliveryZoneFilters = () => {
  const { enums: { cities = [] } = {}, user } = usePage().props

  const routeParams = route().params
  let filter = routeParams.filter
  if (!filter) {
    filter = {}
  }
  let filteringCityID = filter.city_id || routeParams.city_id
  filteringCityID = (!!filteringCityID && parseInt(filteringCityID)) || null
  const defaultCityID = filteringCityID || user.city_id || null
  const defaultCity =
    (defaultCityID && cities.find((city) => city.id === defaultCityID)) || null

  useEffect(() => {
    setCurrentCity(defaultCity)
  }, [defaultCity])

  return { city: defaultCity }
}

function isDiff(obj1, obj2, path) {
  obj1 = obj1 || {}
  obj2 = obj2 || {}

  return (
    _.reduce(
      obj1,
      function (result, value, key) {
        var p = path ? path + '.' + key : key
        if (_.isObject(value)) {
          var d = isDiff(value, obj2[key], p)
          return d.length ? result.concat(d) : result
        }
        return _.isEqual(value, obj2[key]) ? result : result.concat(p)
      },
      [],
    ).length > 0
  )
}

export const useDeliveryZones = (zonesData = null) => {
  const { city } = useDeliveryZoneFilters()
  const zones = useStore($deliveryZones)
  const selectedZoneID = useStore($selectedZoneID)
  const currentZone = zones.find(
    (zone) => zone.properties.id === selectedZoneID,
  )
  const currentZoneCoordinates =
    (selectedZoneID &&
      currentZone &&
      polylabel(currentZone.geometry.coordinates, 1.0)) ||
    false

  const deliveryZones = useMemo(() => {
    return zones
  }, [zones])

  const selectedZone = useMemo(() => {
    return (
      (deliveryZones.length &&
        deliveryZones.find((zone) => zone.properties.id === selectedZoneID)) ||
      null
    )
  }, [deliveryZones, selectedZoneID])

  useEffect(() => {
    if (zonesData) {
      const zormalizedZonesData = zonesData.map(normalizeZoneDataToDraw)
      setZones(zormalizedZonesData)
    }
  }, [zonesData])

  return {
    city,
    zones: deliveryZones,
    selectedZoneID,
    selectedZone,
    currentZoneCoordinates,
  }
}
