import React from 'react'
import { MainLayout } from '$components/UI/Layouts/MainLayout'
import { ReportsTabs } from '$components/Reports/Tabs/ReportsTabs'
import { ReportsFilters } from '$components/Reports/Filters/ReportsFilters'
import { ReportsManagersTiles } from '$components/Reports/DataGrids/Managers/ReportsManagersTiles'
import { Routes } from '$root/utils/constants/routes'

export const ReportsManagers = ({ list }) => {
  return (
    <>
      <ReportsTabs />
      <ReportsFilters />
      <ReportsManagersTiles data={list} route={Routes.reports.managers.page}>
        <ReportsManagersTiles.Wrapper>
          <ReportsManagersTiles.Body />
          <ReportsManagersTiles.Footer />
        </ReportsManagersTiles.Wrapper>
      </ReportsManagersTiles>
    </>
  )
}

ReportsManagers.layout = (page) => <MainLayout>{page}</MainLayout>
