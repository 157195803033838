import { TextArea } from '$components/UI/Inputs/TextArea'
import { TimeInput } from '$components/UI/Inputs/TimeInput'
import React, { useEffect } from 'react'
import { useForm } from '@inertiajs/react'
import { useModals } from '$hooks/useModals'
import { useFormValidation } from '$hooks/useFormValidation'
import { onPostSended } from '$models/page'
import { useDateTime } from '$hooks/useDateTime'

export const EditDeliveryInfo = ({ order, disabled, onChange }) => {
  const { date, dateToSaveFormat } = useDateTime()
  const { closeModal } = useModals()
  const { data, setData, errors, setError, clearErrors } = useForm(order)
  let unsubscribe = null

  const onCheckIsValid = () => {
    let isValid = true

    if (!data.delivery_time && !data.comment) {
      setError('*', 'Нужно заполнить хотябы одно поле')
      isValid = false
    }

    return isValid
  }

  const [validate, markErrors] = useFormValidation(
    `edit-delivery-info-${order.id}`,
    onCheckIsValid,
    data,
    errors,
    clearErrors,
  )

  const onSubmit = (e) => {
    e.preventDefault()
    if (validate()) {
      onChange({
        ...order,
        ...data,
        delivery_date: dateToSaveFormat(new Date(order.delivery_date)),
      })
    }
  }

  useEffect(() => {
    unsubscribe = onPostSended.watch(() => {
      closeModal()
    })
    return () => unsubscribe()
  }, [])

  return (
    <>
      <TimeInput
        disabled={disabled}
        placeholder="Время __:__"
        error={markErrors && !data.delivery_time && !!errors['*']}
        value={
          typeof data.delivery_time === 'number' ? data.delivery_time : null
        }
        onChange={(value) => {
          setData('delivery_time', value)
        }}
      />
      <TextArea
        disabled={disabled}
        placeholder="Комментарий"
        error={markErrors && !data.comment && !!errors['*']}
        value={data.comment || null}
        onChange={(value) => {
          setData('comment', value)
        }}
      />

      {!disabled && (
        <div className="flex justify-between">
          <button className="btn btn__filled btn--blue" onClick={onSubmit}>
            Сохранить
          </button>
          <button
            className="btn btn__filled btn--default"
            onClick={() => closeModal(false)}
          >
            Закрыть
          </button>
        </div>
      )}
    </>
  )
}
