import { router } from '@inertiajs/react'

export const CopyOrderButton = ({ order, routes }) => {
  return (
    <button
      className="table-plan__btn-copy"
      onClick={() => {
        router.post(route(routes.orders.copy, route().params), {
          order_id: order.id,
        })
      }}
      title="Копировать"
    >
      Копировать
    </button>
  )
}
