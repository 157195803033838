import { ProductMultipliers } from '$components/Settings/Leads/ProductsTab/RationTiles/ProductMultipliers'
import { router, usePage } from '@inertiajs/react'
import { Routes } from '$root/utils/constants/routes'
import { onDeleteSended } from '$models/page'

export const RationMultipliers = ({ ration, multipliers }) => {
  const { enums } = usePage().props

  const onRemoveProduct = (id) => {
    router.delete(route(Routes.settings.leads.rations.remove_product), {
      data: { id },
      onSuccess() {
        onDeleteSended()
      },
    })
  }

  console.log(ration)
  return (ration.products || []).map((product, i) => {
    return (
      <ProductMultipliers
        ration={ration}
        rationProductId={product.id}
        product={{
          ...product,
          ...enums.products[product.product_id],
        }}
        multipliers={multipliers}
        onRemoveProduct={onRemoveProduct}
        key={i}
      />
    )
  })
}
