import { useDateTime } from '$hooks/useDateTime'
import { useOrdersTable } from '$lib/orders/hooks/useOrdersTable'

export const SuggestNewOrder = ({ hide, last, suggestDate, isEmpty }) => {
  if ((last && isEmpty) || hide) return <></>
  const { dateToSaveFormat } = useDateTime()
  const { buildNewOrder, newOrder } = useOrdersTable()
  const isRange = suggestDate && Array.isArray(suggestDate)

  const { date, dateRange } = useDateTime()

  const buildSuggestedOrder = (delivery_date) => {
    return {
      ...buildNewOrder(),
      delivery_date: dateToSaveFormat(delivery_date),
    }
  }

  const applySuggest = () => {
    let newOrders = []

    if (_.isArray(suggestDate)) {
      for (const delivery_date of dateRange.apply(null, suggestDate)) {
        newOrders.push(buildSuggestedOrder(delivery_date))
      }
    } else {
      newOrders.push(buildSuggestedOrder(suggestDate))
    }

    // TODO: newOrders -> отрефакторить передачу списка или элемент на создание/обновление
    newOrder({ order: newOrders })
  }

  return (
    <>
      <tr className="table-plan--row-warning">
        <td>
          {(!isRange && date(suggestDate)) ||
            suggestDate.map((suggestDatePart, i) => (
              <span key={i}>
                {date(suggestDatePart)} <br />
              </span>
            ))}
        </td>
        <td>
          <div className="table-plan__rations"></div>
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td>
          <button className="table-plan__btn-apply" onClick={applySuggest} />
        </td>
      </tr>
    </>
  )
}
