import React, { useContext, useMemo } from 'react'
import { DataGridContext } from '$components/UI/DataGridCompound/DataGridCompound'
import { DropdownBtn } from '$components/UI/Inputs/DropdownBtn/DropdownBtn'
import { usePage } from '@inertiajs/react'
import { dataGridIsChanged } from '$models/dataGrid'
import { DatePickerInput } from '$components/UI/Inputs/DatePickerInput'
import { useDateTime } from '$hooks/useDateTime'
import { SettingsClientsContext } from '$lib/settings/leads/clients/context'
import { Routes } from '$root/utils/constants/routes'

const clientStatusesDemo = {
  active: 'Активен',
  prolong: 'Нужно продлять',
  frozen: 'В заморозке',
  archive: 'Архивный',
}

export const SettingsClientsFilters = () => {
  const { enums = {} } = usePage().props
  const { filter } = useContext(DataGridContext)
  const { settingsClientsService } = useContext(SettingsClientsContext)
  settingsClientsService.useContainer()

  const { dateToSaveFormat } = useDateTime()

  const routeParams = route().params

  const filterWithUrl = useMemo(() => {
    return filter || routeParams.filter || {}
  }, [filter, routeParams])

  const status = filterWithUrl.status || undefined
  const date =
    (filterWithUrl &&
      filterWithUrl.date && [filterWithUrl.date[0], filterWithUrl.date[1]]) ||
    null

  return (
    <div className="deal-head">
      {/*<DropdownBtn*/}
      {/*    items={ enums.clientStatuses || clientStatusesDemo }*/}
      {/*    titleKey="value"*/}
      {/*    valueKey="key"*/}
      {/*    value={status}*/}
      {/*    onChange={status => {*/}
      {/*        dataGridIsChanged({ filter: { ...filterWithUrl, status: status && status.key || undefined } })*/}
      {/*    }}*/}
      {/*/>*/}

      {/*<DatePickerInput*/}
      {/*    onChange={ (date) => {*/}
      {/*        if (!date) {*/}
      {/*            dataGridIsChanged({ filter: { ...filterWithUrl, date:null } })*/}
      {/*        }*/}
      {/*        if ( !!date && !date[0] || !date[1]){*/}
      {/*            return null*/}
      {/*        }*/}
      {/*        else {*/}
      {/*            dataGridIsChanged({ filter: { ...filterWithUrl, date: [dateToSaveFormat(new Date(date[0])), dateToSaveFormat(new Date(date[1]))] } })*/}
      {/*        }*/}
      {/*    } }*/}
      {/*    showPickerTag={true}*/}
      {/*    value={ date }*/}
      {/*    multiple={true}*/}
      {/*/>*/}

      <div className="deal-head__btns-wrap">
        <button
          className="deal-head__btn-import"
          onClick={() => settingsClientsService.import()}
        >
          Импорт
        </button>
        <a
          className="deal-head__btn-export"
          href={route(Routes.settings.leads.clients.export)}
          target="_blank"
          rel="noreferrer"
        >
          Экспорт
        </a>
      </div>
    </div>
  )
}
