import React from 'react'
import { MobileLayout } from '$components/UI/Layouts/MobileLayout'
import { DeliveriesList } from '$components/Mobile/DeliveriesList'
import { Routes } from '$root/utils/constants/routes'
import { Delivery } from '$components/Mobile/Delivery'
import { NoResults } from '$components/UI/DataGrid/NoResults'
import { DeliverySuccessFinish } from '$components/Mobile/DeliverySuccessFinish'
import { ProblemDelivery } from '$components/Mobile/ProblemDelivery'
import { HowMuchIsLeft } from '$components/Mobile/HowMuchIsLeft'

export const Courier = ({ detail, list }) => {
  const currentRoute = route().current()

  if (currentRoute === Routes.mobile.list)
    return <DeliveriesList deliveries={list} />
  if (currentRoute === Routes.mobile.delivery_finish && route().params.problem)
    return <ProblemDelivery delivery={detail} />
  if (currentRoute === Routes.mobile.delivery_finish && route().params.success)
    return <DeliverySuccessFinish delivery={detail} />
  if (currentRoute === Routes.mobile.detail)
    return <Delivery delivery={detail} />
  if (currentRoute === Routes.mobile.left) return <HowMuchIsLeft list={list} />
  return <NoResults text="Нет данных" />
}
Courier.layout = (page) => <MobileLayout>{page}</MobileLayout>
