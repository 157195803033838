import { useEffect } from 'react'

export const useOutsideClick = (ref, callback) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (!ref.current.contains(event.target)) {
        callback()
      }
    }

    if (!ref.current) return

    document.removeEventListener('mousedown', handleClickOutside)
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}
