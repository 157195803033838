import { Chat } from '$components/Leads/Statused/DataGrid/Fields/Chat'
import { Source } from '$components/Leads/Statused/Entities/Leads/DataGrid/Fields/Source'
import { LeadChangeStatusAction } from '$components/Leads/Statused/Entities/Leads/DataGrid/Fields/LeadChangeStatusAction'
import { LeadContactsField } from '$components/Leads/Statused/Entities/Leads/DataGrid/Fields/LeadContactsField'

export const NewLeadsItem = ({ item: lead }) => {
  return (
    <>
      <LeadContactsField lead={lead} />

      <div className="deal__inner">
        <Chat />
        <Source lead={lead} />
      </div>
      <LeadChangeStatusAction lead={lead} />
    </>
  )
}
