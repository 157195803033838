import { useCallback, useState } from 'react'
import { router } from '@inertiajs/react'
import { Routes } from '$root/utils/constants/routes'
import { MessageModal } from '$components/UI/Modals/MessageModal'
import { useModals } from '$hooks/useModals'
import { NoResults } from '$components/UI/DataGrid/NoResults'
import { KitchenSettingsTabs } from '$components/Settings/Kitchen/KitchenSettingsTabs'

export const KitchenSettings = ({ tags, tabs }) => {
  const { openModal } = useModals()

  const [name, setName] = useState()

  const newTag = useCallback(() => {
    if (!name) {
      openModal('Error', MessageModal, {
        title: 'Нужно заполнить название',
      })
      return
    }
    router.post(route(Routes.settings.kitchen.new), { name })
  }, [name])

  const deleteExpenseType = (id) => {
    router.delete(route(Routes.settings.kitchen.delete), { data: { id } })
  }

  return (
    <>
      <KitchenSettingsTabs tabs={tabs} />
      <div className="comments">
        <div className="comments__panel">
          <p className="comments__title">Комментарий по кухне</p>
          <div className="comments__row">
            <label className="input comments__input">
              <input
                type="text"
                placeholder="Комментарий"
                value={name}
                onChange={(ev) => setName(ev.target.value)}
              />
            </label>
            <button
              className="btn btn__filled btn--blue"
              onClick={() => newTag()}
            >
              Добавить
            </button>
          </div>
        </div>
        <div className="comments__wrap">
          {tags.length === 0 && (
            <NoResults title="" text="Нет данных" style={{ width: '100%' }} />
          )}
          {_.chunk(tags, 5).map((chunkedTags, i) => (
            <div className="comments__panel">
              <div className="comments__items">
                {chunkedTags.map((tag, i) => (
                  <div className="comments__item comments__item--right" key={i}>
                    {tag.name}
                    <button
                      className="comments__item-delete"
                      title="Удалить комментарий"
                      onClick={() => deleteExpenseType(tag.id)}
                    >
                      Удалить комментарий
                    </button>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
