import React, { useEffect } from 'react'
import { RolePermissionsGroupRow } from '$components/Settings/Permissions/RolePermissionsGroupRow'
import {
  $permissions,
  $saveEnabled,
  permissionsApi,
  saveEnabledChanged,
} from './models'
import { useStore } from 'effector-react'
import _ from 'lodash'
import { router } from '@inertiajs/react'
import { Routes } from '$utils/constants/routes'

export const RolePermissionsList = ({ role, rolePermissions, enums }) => {
  const permissions = useStore($permissions)
  const saveEnabled = useStore($saveEnabled)

  useEffect(() => {
    permissionsApi.clear()
    saveEnabledChanged(false)
  }, [])

  const onClickSaveButton = () => {
    router.post(
      route(Routes.settings.permissions.set, { roleName: role.name }),
      { permissions: permissions },
    )
  }

  return (
    <>
      <table className="table table--accordion">
        <thead>
          <tr>
            <th>Сущность</th>
            <th>Чтение</th>
            <th>Добавление</th>
            <th>Изменение</th>
            <th>Удаление</th>
          </tr>
        </thead>
        <tbody>
          {_.map(rolePermissions, (value, key) => (
            <RolePermissionsGroupRow
              key={key}
              groupName={key}
              groupPermissions={value}
              lang={enums.lang}
            />
          ))}
        </tbody>
      </table>
      <button
        className="btn btn__filled btn--green"
        disabled={!saveEnabled}
        onClick={onClickSaveButton}
      >
        Сохранить
      </button>
    </>
  )
}
