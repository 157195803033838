const checkUserPermission = (user, permission, fully = true) => {
  if (user && Array.isArray(permission)) {
    let result = [user.permissions, permission].reduce((a, b) =>
      a.filter((c) => b.includes(c)),
    )
    if (fully) {
      return result.length === permission.length
    }
    return result.length > 0
  } else if (user && permission) {
    return (
      user.permissions.find(
        (userPermission) => userPermission === permission,
      ) !== undefined
    )
  }
  return false
}

export const hasPermission = (user, permission) => {
  return checkUserPermission(user, permission)
}

export const hasPermissions = (user, permissions, fully = true) => {
  return checkUserPermission(user, permissions, fully)
}
