import React from 'react'
import { SideModal } from '$components/UI/Modals/SideModal'
import { ModalSize } from '$utils/constants/modals'
import { Header } from './Header'
import { Orders } from '$components/Clients/Orders'
import { router } from '@inertiajs/react'

export const ClientOrdersModal = (props) => {
  const { client, routes } = props

  return (
    <SideModal
      size={ModalSize.ExtraLarge}
      {...props}
      data={{
        title: client ? `${client.full_name}` : 'План доставок клиента...',
      }}
      onClose={() => {
        router.get(route(routes.view, route().params))
      }}
    >
      <Header {...props} />
      <Orders {...props} />
    </SideModal>
  )
}
