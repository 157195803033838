import { Link } from '@inertiajs/react'
import { Routes } from '$utils/constants/routes'
import React, { useCallback } from 'react'
import { UNDEFINED_TITLE } from '$root/utils/constants/service'

export const TableDataGridItem = ({ row, columns, canEdit }) => {
  const renderColumnName = useCallback((column, item) => {
    return column.render
      ? column.render(item)
      : item[column.name] || UNDEFINED_TITLE
  }, [])

  return (
    <tr key={row.id}>
      {columns.map((column, i) => (
        <td key={column.name}>
          {canEdit && i === 0 ? (
            <Link
              href={
                route(Routes.settings.users.edit, { id: row.id }) +
                location.search
              }
            >
              {renderColumnName(column, row)}
            </Link>
          ) : (
            renderColumnName(column, row)
          )}
        </td>
      ))}
    </tr>
  )
}
