import clsx from 'clsx'
import { usePage } from '@inertiajs/react'
import { getActiveTabCode } from '$lib/leads/statused/public/helpers'
import { useContext } from 'react'
import { DataGridContext } from '$components/UI/DataGridCompound/DataGridCompound'

export const TabEntityCount = ({ tab, index }) => {
  const { enums: { tabs = [], tabs_counter = {} } = {} } = usePage().props
  const { data } = useContext(DataGridContext)
  const activeTabCode = getActiveTabCode(tabs)
  const isActiveTab = activeTabCode && tab.attributes.code === activeTabCode

  const getColorClass = () => {
    return tab.attributes.class || null
  }

  return (
    <span className={clsx(['badge', getColorClass()])}>
      {(isActiveTab && data && data.meta && data.meta.total) ||
        tabs_counter[tab.attributes.code] ||
        0}
    </span>
  )
}
