import { useOrdersTable } from '$lib/orders/hooks/useOrdersTable'
import { OrderStatus } from '$lib/orders/utils/order-status-const'

export const ProblemOrderActionButton = ({ order }) => {
  const { updateOrder } = useOrdersTable()

  const onChangeOrderStatus = () => {
    const status = OrderStatus.Problem

    if (status) {
      updateOrder({ order, updatingOrders: { ...order, status } })
    }
  }

  return (
    <button
      className="table-plan__btn-bad"
      onClick={() => onChangeOrderStatus()}
      title="Проблема"
    >
      Проблема
    </button>
  )
}
