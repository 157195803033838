import React, { useContext, useRef } from 'react'
import InvoiceHead from '$components/Clients/Invoices/InvoiceHead'
import EffectorContextProvider from '$components/UI/React/EffectorContextProvider'
import { EffectorContext } from '$root/utils/effector/effector-services'
import InvoiceListItem from '$components/Clients/Invoices/InvoiceListItem'
import clsx from 'clsx'

const InvoiceContainer = ({ children, className }) => {
  const ref = useRef()

  const { invoiceService } = useContext(EffectorContext)
  const invoiceServices = invoiceService.services

  const { formService, hoveringEditableService } = invoiceServices

  invoiceService.useContainer()

  const form = formService.useContainer()

  hoveringEditableService.useHeaderContainer(ref)

  return (
    <div
      className={clsx(
        'invoice',
        { invoice__paid: invoiceService.isPaid },
        className,
        { hidden: invoiceService.isHiddenInvoice },
      )}
      ref={ref}
    >
      <EffectorContextProvider
        services={{ formService, hoveringEditableService }}
        value={{ form }}
      >
        {children}
      </EffectorContextProvider>
    </div>
  )
}

const Invoice = ({ headerActions }) => {
  return (
    <InvoiceContainer>
      <InvoiceHead actions={headerActions} />
      <InvoiceListItem />
    </InvoiceContainer>
  )
}

export const InvoiceMobile = ({ headerActions, actions, paySystems }) => {
  return (
    <InvoiceContainer className="invoice__mobile">
      <InvoiceHead isEditPersistent={true} actions={headerActions} />
      <InvoiceListItem editOptions={{ actions, paySystems }} />
    </InvoiceContainer>
  )
}

export default Invoice
