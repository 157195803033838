import { action, makeObservable, observable } from 'mobx'

class PageSelfCleans {
  cleanings = {}
  currentRouteHref = null
  mounted = false

  constructor() {
    makeObservable(this, {
      mounted: observable,
      cleanings: observable,
      addCleaning: action.bound,
      setCurrentRouteHref: action.bound,
      deleteCleanup: action.bound,
    })
  }

  _checkDefaultList(route) {
    if (!this.cleanings[route]) {
      this.cleanings[route] = []
    }
  }

  addCleaning(route, fn) {
    this._checkDefaultList(route)
    this.cleanings[route].push(fn)
  }

  addCleaningPages(routes, fn) {
    for (const route of routes) {
      if (new RegExp(route).test(location.pathname)) {
        this.addCleaning(route, fn)
      }
    }
    this.cleanings = { ...this.cleanings }
  }

  setCurrentRouteHref(href) {
    if (this.currentRouteHref !== href) {
      this.currentRouteHref = href
    }
  }

  deleteCleanup(keys) {
    for (const k of keys) {
      delete this.cleanings[k]
    }
  }

  handleChangeHref(href) {
    if (this.mounted) {
      const deletingKeys = []

      for (const k in this.cleanings) {
        if (!new RegExp(k).test(href)) {
          for (const fn of this.cleanings[k]) {
            fn()
          }
          deletingKeys.push(k)
        }
      }

      this.deleteCleanup(deletingKeys)
    }

    this.mounted = true
  }
}

export const pageSelfCleans = new PageSelfCleans()
