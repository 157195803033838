import { router } from '@inertiajs/react'

export class DecreaseReplaceStrategy {
  _clientRoutes = null
  _routeParams = null
  _ration_id_key = null
  _ration = null
  _product = null
  _quantity = null

  constructor(
    enums,
    clientRoutes,
    ration,
    product,
    ration_id_key,
    quantity,
    currentReplace,
  ) {
    this._ration_id_key = ration_id_key
    this._clientRoutes = clientRoutes
    this._routeParams = route().params
    this._ration = ration
    this._product = product
    this._quantity = quantity
    this._replace = currentReplace
    this._enums = enums
  }

  do(onChangeCb = undefined) {
    const allRationProducts = this._enums.rations.reduce((products, ration) => {
      for (const product of ration.products) {
        if (!products.includes(product)) {
          products.push(product)
        }
      }
      return products
    }, [])

    const replacement = {
      ...(this._replace || {}),
      [this._ration_id_key]: this._ration[this._ration_id_key],
      product_id: this._product.id,
      multiplier_id:
        this._product.multiplier_id ||
        allRationProducts.find((product) => product.id === this._product.id)
          .multiplier_id,
      quantity: this._quantity - 1,
    }

    if (onChangeCb) {
      onChangeCb(this._ration, this._replace, replacement)
    } else {
      router.post(
        route(this._clientRoutes.replacements.update, {
          id: this._routeParams.id,
          status: this._routeParams.status,
        }),
        replacement,
      )
    }
  }
}
