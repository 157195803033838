import InvoiceList from '$components/Clients/Invoices/InvoiceList'
import Invoice from '$components/Clients/Invoices/Invoice'
import { router } from '@inertiajs/react'
import { SideModal } from '$components/UI/Modals/SideModal'
import InvoicesModalHeader from '$components/Clients/Invoices/Modal/InvoicesModalHeader'
import { ModalSize } from '$root/utils/constants/modals'
import React from 'react'
import { MessageModal } from '$components/UI/Modals/MessageModal'
import { useModals } from '$hooks/useModals'
import { useDataGridCompound } from '$hooks/useDataGridCompound'
import { Routes } from '$root/utils/constants/routes'
import { DataGridCompound } from '$components/UI/DataGridCompound/DataGridCompound'
import { NoResults } from '$components/UI/DataGrid/NoResults'
import { InvoiceListStore } from '$lib/invoices/new-effector/invoice-list-new-store'
import { EffectorServices } from '$root/utils/effector/effector-services'
import EffectorContextProvider from '$components/UI/React/EffectorContextProvider'
import { useDateTime } from '$hooks/useDateTime'
import InvoicePayBtn from '$components/Clients/Invoices/InvoicePayBtn'
import InvoiceCancelBtn from '$components/Clients/Invoices/InvoiceCancelBtn'

const InvoicesModal = ({
  invoices: invoicesDataGrid,
  routes,
  saveParams = undefined,
  onSave = undefined,
  ...props
}) => {
  const { dateToSaveFormat } = useDateTime()

  const { openModal } = useModals()

  const invoicesService = EffectorServices.getService({
    service: new InvoiceListStore({
      invoices: invoicesDataGrid.rows,
      persistKey: 'modal-invoices-list',
      saveRoute: routes.invoices.update,
      saveParams,
    }),
    options: {
      dateToSaveFormat,
      onSave,
    },
  })

  invoicesService.useContainer()
  invoicesService.checkOnUpdatedInvoices(invoicesDataGrid.rows)

  useDataGridCompound({
    data: invoicesDataGrid,
    route: Routes.settings.leads.clients.page,
  })

  const showErrorInvoiceForms = () => {
    openModal('TransactionFormInvoicesError', MessageModal, {
      title: 'Формы счетов заполнены некорректно',
    })
  }

  const saveInvoices = () => invoicesService.save(showErrorInvoiceForms)

  return (
    <EffectorContextProvider services={{ invoicesService }}>
      <SideModal
        size={ModalSize.Base}
        {...props}
        onClose={() => {
          const routeParams = route().params
          invoicesService.reset()
          if (routes.transactions) {
            router.get(route(routes.transactions.list, routeParams))
          }
          if (props.onClose) {
            props.onClose()
          }
        }}
        classes={{ wrapper: 'invoices-modal' }}
      >
        <></>
        <DataGridCompound
          data={{ ...invoicesDataGrid, rows: invoicesService.store.invoices }}
          route={routes.invoices.list}
          header={<InvoicesModalHeader {...props} />}
          body={
            <InvoiceList
              invoices={
                <>
                  {invoicesService.store.invoices.map((invoiceService, i) => {
                    return (
                      <EffectorContextProvider
                        services={{ invoiceService }}
                        key={i}
                      >
                        <Invoice
                          headerActions={({ onPay, onCancel }) => (
                            <>
                              {invoiceService.isMayPayed && (
                                <InvoicePayBtn
                                  onPay={onPay}
                                  route={Routes.mobile.payInvoice}
                                />
                              )}
                              {invoiceService.isMayCancel && (
                                <InvoiceCancelBtn
                                  onPay={onCancel}
                                  route={Routes.mobile.cancelInvoice}
                                />
                              )}
                            </>
                          )}
                        />
                      </EffectorContextProvider>
                    )
                  })}
                  <button
                    className="btn btn__filled btn--blue"
                    onClick={saveInvoices}
                  >
                    Сохранить
                  </button>
                </>
              }
              {...props}
            />
          }
          noResults={<NoResults title={false} text="В списке пусто" />}
        />
      </SideModal>
    </EffectorContextProvider>
  )
}

export default InvoicesModal
