import { EditControl } from 'react-leaflet-draw'
import { FeatureGroup } from 'react-leaflet'
import React, { useEffect, useMemo, useState } from 'react'
import { uid } from '$root/utils/functions'
import {
  initLeafletDrawingLocales,
  reversePolygonGeoCoordinates,
} from '$lib/osm/functions'

export const NewDeliveryZoneControl = ({ onNew }) => {
  const [layer, setLayer] = useState(null)

  const locales = useMemo(() => {
    initLeafletDrawingLocales()
  }, [])

  useEffect(() => {
    if (layer) {
      layer.remove()
      setLayer(null)
    }
  }, [layer])

  return (
    <FeatureGroup>
      <EditControl
        position="topright"
        onCreated={(ev) => {
          const geoObject = ev.layer.toGeoJSON()
          setLayer(ev.layer)
          geoObject.properties.id = `*${uid()}*`
          reversePolygonGeoCoordinates(geoObject)
          onNew(geoObject)
        }}
        edit={{ remove: false, edit: false }}
        draw={{
          marker: false,
          circle: false,
          rectangle: false,
          circlemarker: false,
          // polygon: false,
          polyline: false,
        }}
      ></EditControl>
    </FeatureGroup>
  )
}
