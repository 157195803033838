import { createApi, createEvent, createStore } from 'effector'
import { OrderStatus } from '$lib/orders/utils/order-status-const'

export const setClientRoutes = createEvent()
export const $clientRoutes = createStore(null).on(
  setClientRoutes,
  (state, routes) => routes,
)

export const resetOrdersTable = createEvent()

export const $ordersTable = createStore({ client: null, orders: {} }).reset(
  resetOrdersTable,
)
export const $client = $ordersTable.map((ordersTable) => ordersTable.client)
export const $orders = $ordersTable.map((ordersTable) => ordersTable.orders)

export const ordersTableApi = createApi($ordersTable, {
  init: (ordersTable, { client, orders }) => {
    {
      const emptyOrdersTable = _.values(OrderStatus).reduce(
        (grouped, status) => {
          return { ...grouped, ...{ [status]: [] } }
        },
        {},
      )

      return { client, orders: _.assign(emptyOrdersTable, orders) }
    }
  },
  updateTable({ client, orders }, { order, updatingOrders }) {
    orders = updatingOrders.reduce((ordersTable, updatingOrder) => {
      const status = (order && order.status) || updatingOrder.status
      const index = (ordersTable[status] || []).findIndex((issetOrder) => {
        return (
          (order &&
            (issetOrder.id || issetOrder.uid || true) ===
              (order.id || order.uid || false)) ||
          false
        )
      })
      if (index > -1) {
        ordersTable[status].splice(index, 1, updatingOrder)
      } else {
        ordersTable[status].push(updatingOrder)
      }
      return { ...ordersTable, [status]: ordersTable[status] }
    }, orders)

    return { client, orders }
  },
  deleteFromTable({ client, orders }, { order }) {
    const status = order.status
    const index = orders[status].findIndex(
      (issetOrder) =>
        (issetOrder.id || issetOrder.uid || true) ===
        (order.id || order.uid || false),
    )
    if (index > -1) {
      orders[status].splice(index, 1)
    }
    return { client, orders: { ...orders, [status]: orders[status] } }
  },
})
