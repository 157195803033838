import React from 'react'
import { MainLayout } from '$components/UI/Layouts/MainLayout'
import { List } from '$components/Clients/List'
import { PageHeader } from '$components/Clients/PageHeader'
import { useClientModals } from '$hooks/clients/useClientModals'
import { Routes } from '$root/utils/constants/routes'
import { resetInactiveOrdersDates } from '$lib/clients/store'
import { resetAllOrdersDeliveryType } from '$lib/clients/plan-order-delivery-types-store'
import { useGlobalStore } from '$lib/page/provider'
import { ClientsSectionRouteRegExp } from '$root/config/root-routes'

export const Clients = ({ list, detail, orders, transactions, invoices }) => {
  const { pageSelfCleans } = useGlobalStore()

  pageSelfCleans.addCleaning(ClientsSectionRouteRegExp, () => {
    resetInactiveOrdersDates()
    resetAllOrdersDeliveryType()
  })

  const detailModalProps = {
    routeParams: route().params,
  }

  useClientModals(
    Routes.clients,
    detail,
    orders,
    transactions,
    invoices,
    detailModalProps,
  )

  return <List data={list} />
}

Clients.layout = (page) => (
  <MainLayout PageHeader={PageHeader}>{page}</MainLayout>
)
