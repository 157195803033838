import { MultiplierCode } from '$root/utils/products/const'
import { getEnumMappedIdByData } from '$root/utils/enums'

export class ReplaceQuantityCalculator {
  _ration = null
  _product = null
  _isInRation = false
  ADDITIONAL_WEIGHT_IS_IN_RATION = 1

  constructor(ration, product, _enums, isInRation = false) {
    this._ration = ration
    this._product = product
    this._enums = _enums
    this._isInRation = isInRation
  }

  _getRationProductReplacementItem(product) {
    return (
      (this._ration.replacements &&
        this._ration.replacements.find(
          (replacement) => replacement.product_id === product.id,
        )) ||
      undefined
    )
  }

  _additionalReplaceQuantity() {
    return (this._isInRation && this.ADDITIONAL_WEIGHT_IS_IN_RATION) || 0
  }

  _getReplaceQuantityWithRationProductWeight(replace, product) {
    let quantity = (replace && replace.quantity) || 0

    if (replace && this._isInRation) {
      let multiplierX0 = getEnumMappedIdByData(
        this._enums.multipliers,
        'code',
        MultiplierCode.x0,
      )

      if (replace.quantity === 1 && replace.multiplier_id === multiplierX0.id) {
        quantity = 0
      } else {
        quantity = replace.quantity + this._additionalReplaceQuantity()
      }
    }

    if (!replace && this._isInRation) {
      quantity = this._additionalReplaceQuantity()
    }

    return quantity
  }

  getProductQuantity() {
    const replace = this._getRationProductReplacementItem(this._product)
    return (
      (replace &&
        typeof replace.quantity !== 'undefined' && {
          replace,
          quantity: replace.quantity,
        }) ||
      0
    )
  }

  getProductQuantityWithWeight() {
    const replace = this._getRationProductReplacementItem(this._product)
    return {
      replace: replace || null,
      quantity: this._getReplaceQuantityWithRationProductWeight(
        replace,
        this._product,
      ),
    }
  }
}
