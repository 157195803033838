import clsx from 'clsx'
import { DropdownBtn } from '$components/UI/Inputs/DropdownBtn/DropdownBtn'
import React from 'react'

const DayTypeDropdownBtn = ({ items, value, disabled, onChange }) => {
  return (
    <DropdownBtn
      className="day-type-dropdown"
      items={items}
      disabled={disabled}
      value={value}
      needEmpty={false}
      valueKey="key"
      emptyText={<div className="day-type-dropdown__item empty" />}
      activeItem={(item) => {
        return (
          <div
            className={clsx('day-type-dropdown__item', { [item.key]: true })}
          />
        )
      }}
      titleKey={(item) => (
        <div className={clsx('day-type-dropdown__item', { [item.key]: true })}>
          {item.value}
        </div>
      )}
      needTitle={false}
      onChange={onChange}
    />
  )
}

export default DayTypeDropdownBtn
