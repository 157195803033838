import L from 'leaflet'
import { deliveryZoneChangeVisibility } from '$lib/deliveries/models/zones'

export const reversePolygonGeoCoordinates = (geoObject) => {
  geoObject.geometry.coordinates = geoObject.geometry.coordinates.map((node) =>
    node.map((coordinates) => [...coordinates].reverse()),
  )
}

export const reversePointGeoCoordinates = (geoObject) => {
  geoObject.geometry.coordinates = [...geoObject.geometry.coordinates].reverse()
}

export const reversedPoint = (geoObject) => {
  if (!geoObject) {
    return null
  }
  const coordinates =
    (geoObject.geometry && geoObject.geometry.coordinates) ||
    geoObject.coordinates
  return {
    ...geoObject,
    geometry: {
      ...geoObject.geometry,
      coordinates: [...coordinates].reverse(),
    },
  }
}

export const initLeafletDrawingLocales = () => {
  L.drawLocal.draw.toolbar.actions = {
    title: 'Отменить рисование',
    text: 'Отмена',
  }

  L.drawLocal.draw.toolbar.finish = {
    title: 'Завершить',
    text: 'Завершить',
  }

  L.drawLocal.draw.toolbar.undo = {
    title: 'Удалить последнюю нарисованную точку',
    text: 'Удалить последнюю точку',
  }

  L.drawLocal.draw.toolbar.buttons.polygon = 'Новая зона'

  L.drawLocal.draw.handlers.polygon.tooltip = {
    start: 'Нажмите на карту чтобы начать разметку.',
    cont: 'Продолжить точку.',
    end: 'Нажмите на первую точку чтобы замкнуть область',
  }

  L.drawLocal.edit.toolbar.buttons = {
    edit: 'Редактировать зону',
    editDisabled: 'Невозможно начать редактирование',
    remove: 'Удалить зону',
    removeDisabled: 'Невозможно удалить зону',
  }

  L.drawLocal.edit.toolbar.actions = {
    save: {
      title: 'Сохранить изменения',
      text: 'Сохранить',
    },
    cancel: {
      title: 'Отменить изменения',
      text: 'Отмена',
    },
    clearAll: {
      title: 'Удалить все зоны',
      text: 'Удалить все',
    },
  }

  L.drawLocal.edit.handlers = {
    edit: {
      tooltip: {
        text: 'Перетаскивайте точки зоны для редактирования.',
        subtext: 'Нажмите "Отмена", чтобы отменить изменения.',
      },
    },
    remove: {
      tooltip: {
        text: 'Нажмите на точку чтобы удалить её.',
      },
    },
  }
}

export const extendOSMClasses = () => {
  L.LabelOverlay = L.Layer.extend({
    initialize: function (/*LatLng*/ latLng, /*String*/ label, options) {
      this._latlng = latLng
      this._label = label
      this._visibility = true
      L.Util.setOptions(this, options)
      deliveryZoneChangeVisibility.watch((id) => {
        this._changeVisibility(!this._visibility)
      })
    },
    options: {
      offset: new L.Point(-20, 5),
      name: '',
      id: null,
    },
    onAdd: function (map) {
      this._map = map
      if (!this._container) {
        this._initLayout()
      }
      map.getPanes().popupPane.appendChild(this._container)
      this._container.innerHTML = this._label
      map.on('movestart', this._update_start, this)
      map.on('moveend', this._update_end, this)
      this._update_end()
    },
    onRemove: function (map) {
      map.getPanes().popupPane.removeChild(this._container)
      map.off('movestart', this._update_start, this)
      map.off('moveend', this._update_end, this)
    },
    _changeVisibility: function (isVisible) {
      this._container.style.opacity = 0
      this._visibility = isVisible
    },
    _update_start: function () {
      // this._container.style.opacity = 0
      // L.DomUtil.setPosition(this._container, 0);
    },
    _update_end: function () {
      // this._container.style.opacity = 1
      var pos = this._map.latLngToLayerPoint(this._latlng)
      var op = new L.Point(
        pos.x + this.options.offset.x,
        pos.y - this.options.offset.y,
      )
      L.DomUtil.setPosition(this._container, op)
    },
    _initLayout: function () {
      this._container = L.DomUtil.create('div', 'leaflet-label-overlay')
    },
    isMapLabel: function () {
      return true
    },
    setName: function (name) {
      this.options.name = name
      this._container.querySelector('span').innerHTML =
        `<strong>${name}</strong>`
    },
    updatePosition: function (pos = undefined) {
      var pos = this._map.latLngToLayerPoint(pos ? pos : this._latlng)
      var op = new L.Point(
        pos.x + this.options.offset.x,
        pos.y - this.options.offset.y,
      )
      L.DomUtil.setPosition(this._container, op)
    },
  })
}

export const createZoneInstances = (zones) => {
  return zones.map((zone) => {
    const polygon = L.polygon(zone.geometry.coordinates)
    polygon.properties = zone.properties
    return polygon
  })
}

export const isCoordinatesInsidePolygon = (coordinates, poly) => {
  let inside = false
  const [x, y] = coordinates

  for (var ii = 0; ii < poly.getLatLngs().length; ii++) {
    const polyPoints = poly.getLatLngs()[ii]
    for (let i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
      const xi = polyPoints[i].lat,
        yi = polyPoints[i].lng
      const xj = polyPoints[j].lat,
        yj = polyPoints[j].lng

      const intersect =
        yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
      if (intersect) inside = !inside
    }
  }

  return inside
}

export const distanceFromCoordinates = (lat1, lon1, lat2, lon2) => {
  var R = 6378.137 // Radius of earth in KM
  var dLat = (lat2 * Math.PI) / 180 - (lat1 * Math.PI) / 180
  var dLon = (lon2 * Math.PI) / 180 - (lon1 * Math.PI) / 180
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c
  return d * 1000 // meters
}
