import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useModal } from '$hooks/useModal'
import { ModalSize } from '$utils/constants/modals'

export const Modal = ({
  title,
  size = ModalSize.Base,
  classes,
  onClose,
  children,
  contentComponent,
  showOnMount = false,
  ...data
}) => {
  const { show, cls, Header, Content, closeModal } = useModal(
    classes,
    contentComponent,
    onClose,
    children,
    data,
  )
  const [mounted, setMounted] = useState(false)

  const DefaultHeader = !Array.isArray(children) ? (
    <p className="modal__title">{title}</p>
  ) : null

  const ModalSizeClasses = {
    // small: {
    //     wrapper: [],
    //     overlay: [],
    //     content: [],
    //     body: [],
    // },
    [ModalSize.Base]: {
      wrapper: ['js-modal', 'modal'],
      overlay: ['modal__overlay', 'js-modal-overlay'],
      content: ['js-modal-start'],
      body: ['modal__body'],
    },
    // large: {
    //     wrapper: [],
    //     overlay: [],
    //     content: [],
    // },
    [ModalSize.ExtraLarge]: {
      wrapper: ['modal', 'modal--xl', 'js-modal'],
      overlay: ['modal__overlay', 'js-modal-overlay'],
      content: ['js-modal-start'],
      body: ['modal__body'],
    },
  }

  const SizeClasses = ModalSizeClasses[size]
    ? ModalSizeClasses[size]
    : ModalSizeClasses[ModalSize.Base]

  useEffect(() => {
    setMounted(true)
  }, [])

  return (
    <div
      className={clsx([
        cls.wrapper,
        SizeClasses.wrapper,
        'modal--no-overflow',
        {
          show: show,
        },
      ])}
    >
      <div
        className={clsx(SizeClasses.overlay, cls.overlay)}
        onClick={() => {
          closeModal(false, { isUserClick: true })
        }}
      ></div>

      <div className={clsx('modal__content', cls.content)}>
        {(Header && Header) || DefaultHeader}
        {showOnMount
          ? (mounted && Content && Content) || null
          : Content || null}
      </div>
    </div>
  )
}
