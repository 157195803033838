const demoDelivery = {
  id: 1,
  client: {
    id: 1,
    full_name: 'Сыроежкина Надя',
    phone: '+796587552',
  },
  address: {
    address: 'Адрес доставки',
    point: null,
  },
  rations: [
    {
      id: 1,
      name: 'Fit',
    },
  ],
  discount_price: 100,
  comment: '',
}

export const DeliverySuccessFinish = ({ delivery = demoDelivery }) => {
  return (
    <div className="mobile-status">
      <p className="mobile-status__name">
        {(delivery.client && delivery.client.full_name) ||
          (delivery.client && delivery.client.name) ||
          ''}
      </p>
      <p className="mobile-status__address">{delivery.address}</p>
      <p className="mobile-status__text mobile-status__text--success">
        Заказ доставлен
      </p>
    </div>
  )
}
