import { getOrdersByStatus } from '$lib/orders/models/init-order-table'

export const useOrdersByStatus = (status) => {
  const { orders, listIsEmpty } = (status && getOrdersByStatus(status)) || {}

  return {
    orders,
    listIsEmpty,
  }
}
