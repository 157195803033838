import { FinanceDashboard } from '$components/Reports/Company/Dashboard/FinanceDashboard'
import { DashboardChart } from '$components/Reports/Company/Dashboard/DashboardChart'

export const Dashboard = ({
  expense,
  profit,
  prevDifference,
  prevDifferencePercent,
}) => {
  return (
    <div className="reports-company__row">
      <FinanceDashboard
        expense={expense}
        profit={profit}
        prevDifference={prevDifference}
        prevDifferencePercent={prevDifferencePercent}
      />
      <DashboardChart />
    </div>
  )
}
