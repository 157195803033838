import React, { useEffect } from 'react'
import { DropdownBtn } from '$components/UI/Inputs/DropdownBtn/DropdownBtn'
import { useForm, usePage } from '@inertiajs/react'
import { useModals } from '$hooks/useModals'
import { useOrdersTable } from '$lib/orders/hooks/useOrdersTable'
import { onPostSended } from '$models/page'
import { Input } from '$components/UI/Inputs/Input'

export const EditDiscountInfo = ({ order, disabled }) => {
  const { enums } = usePage().props
  const { updateOrder } = useOrdersTable()
  const { closeModal } = useModals()
  const { data, setData, transform } = useForm(
    (order.discount &&
      order.discount.amount && {
        amount: order.discount.amount,
        currency: order.discount.currency,
      }) ||
      {},
  )
  let unsubscribe = null

  const onSubmit = (e) => {
    e.preventDefault()

    const updatedOrder = {
      ...order,
      discount: {
        ...order.discount,
        amount: data.amount,
        currency: data.currency,
      },
    }

    if (!updatedOrder.discount.amount || !updatedOrder.discount.currency) {
      updatedOrder.discount = null
    }

    updateOrder({
      order,
      updatingOrders: {
        ...order,
        ...updatedOrder,
      },
    })
  }

  useEffect(() => {
    unsubscribe = onPostSended.watch(() => {
      closeModal()
    })
    return () => unsubscribe()
  }, [])

  return (
    <>
      <div className="form__row">
        <DropdownBtn
          disabled={disabled}
          className="mb-1"
          items={(enums && enums.discountCurrencies) || []}
          value={(data && data.currency) || null}
          valueKey="key"
          titleKey="value"
          onChange={(selected) => {
            setData('currency', (selected && selected.key) || null)
          }}
        />

        <Input
          disabled={disabled}
          type="number"
          step="0.01"
          placeholder="Скидка"
          value={data.amount}
          onChange={(ev) => {
            if (!ev.target.value) {
              setData((prevDiscount) => ({
                ...prevDiscount,
                amount: 0,
                currency: null,
              }))
            } else {
              setData(
                'amount',
                ev.target.value &&
                  !isNaN(ev.target.value) &&
                  parseFloat(ev.target.value),
              )
            }
          }}
        />
        <p className="form__note mb-1">Персональная скидка на этот заказ</p>
      </div>

      {!disabled && (
        <div className="flex justify-between">
          <button className="btn btn__filled btn--blue" onClick={onSubmit}>
            Сохранить
          </button>
          <button
            className="btn btn__filled btn--default"
            onClick={() => closeModal(false)}
          >
            Закрыть
          </button>
        </div>
      )}
    </>
  )
}
