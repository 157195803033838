import { Chat } from '$components/Leads/Statused/DataGrid/Fields/Chat'
import { LeadChangeStatusAction } from '$components/Leads/Statused/Entities/Leads/DataGrid/Fields/LeadChangeStatusAction'
import { PendingAgo } from '$components/Leads/Statused/Entities/Leads/DataGrid/Fields/PendingAgo'
import { ContactDate } from '$components/Leads/Statused/Entities/Leads/DataGrid/Fields/ContactDate'
import { LeadContactsField } from '$components/Leads/Statused/Entities/Leads/DataGrid/Fields/LeadContactsField'

export const PendingLeadsItem = ({ item: lead }) => {
  return (
    <>
      <LeadContactsField lead={lead} />
      <PendingAgo lead={lead} />
      <Chat lead={lead} />
      <ContactDate lead={lead} />
      <LeadChangeStatusAction lead={lead} />
    </>
  )
}
