import { Modal } from '$components/UI/Modals/Modal'
import { ChangeContactAtDate } from '$components/Leads/Statused/Entities/Leads/Modal/ChangeContactAtDate'
import { ChangeContactAtDateClient } from '$components/Leads/Statused/Entities/Clients/Modal/ChangeContactAtDateClient'
import { useModals } from '$hooks/useModals'
import { useDateTime } from '$hooks/useDateTime'

export const ContactAtField = ({ client }) => {
  const { openModal } = useModals()
  const { date } = useDateTime()

  const changePendingDate = () => {
    openModal('ChangePendingDate', Modal, {
      contentComponent: ChangeContactAtDateClient,
      client,
      classes: { wrapper: 'modal--no-overflow' },
    })
  }

  return (
    <div className="deal__contact">
      <p className="deal__contact-tag"></p>
      <div className="deal__contact-date" onClick={changePendingDate}>
        {`Связаться:  ${(client.contact_at && date(client.contact_at)) || '-'}`}
      </div>
    </div>
  )
}
