import { Body } from './Body'
import { Footer } from './Footer'
import { DataGridCompound } from '$components/UI/DataGridCompound/DataGridCompound'
import { Routes } from '$root/utils/constants/routes'
import { Header } from '$components/Leads/Statused/DataGrid/Header/Header'
import { useLeads } from '$lib/leads/hooks/useLeads'
import { router } from '@inertiajs/react'
import { useClientModals } from '$hooks/clients/useClientModals'
import { useStore } from 'effector-react'
import { $clientRoutes } from '$lib/orders/models/orders-store'

export const LeadsStatusedDataGrid = ({
  data,
  children,
  client,
  detail,
  orders,
  transactions,
  invoices,
  routes,
}) => {
  const { newClientInfoByLead } = useLeads()
  const clientRoutes = useStore($clientRoutes)

  let detailModalProps = {
    routeParams: route().params,
  }

  detailModalProps =
    (detail && {
      ...detailModalProps,
      onSuccessSave: {
        openDeliveryPlanOnNew: true,
      },
      onClose() {
        if (route().current().indexOf(routes.list) !== -1) {
          router.get(route(routes.list, route().params))
        }
      },
    }) ||
    detailModalProps

  useClientModals(
    routes,
    client || (detail && newClientInfoByLead(detail)) || null,
    orders,
    transactions,
    invoices,
    detailModalProps,
  )

  if (!clientRoutes) return <></>

  return (
    <DataGridCompound data={data} route={Routes.leads.list}>
      {children}
    </DataGridCompound>
  )
}

LeadsStatusedDataGrid.Header = Header
LeadsStatusedDataGrid.Body = Body
LeadsStatusedDataGrid.Footer = Footer
