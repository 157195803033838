import React, { useEffect } from 'react'
import { router } from '@inertiajs/react'
import { useLoader } from '$hooks/useLoader'
import { Loader } from '$components/UI/Loader'
import { usePageErrors } from '$hooks/usePageErrors'
import { useModals } from '$hooks/useModals'
import { MobilePageHeader } from '$components/Mobile/MobilePageHeader'
import { Routes } from '$root/utils/constants/routes'

export const MobileLayout = ({ children }) => {
  const { loading, showLoader, hideLoader } = useLoader()
  const currentRoute = route().current()
  const isShowPrevPageButton = currentRoute !== Routes.mobile.list

  const { Modals } = useModals()
  usePageErrors()

  useEffect(() => {
    router.on('start', showLoader)
    router.on('finish', hideLoader)
  }, [])

  const goPrev = () => {
    router.get(route(Routes.mobile.list))
  }

  return (
    <>
      <div className="mobile-wrapper">
        <div className="mobile-wrapper__header">
          <div className="mobile-header">
            <a className="mobile-header__logo" href="#"></a>
            <h1 className="mobile-header__title">Доставки</h1>
          </div>
          {(isShowPrevPageButton && (
            <button className="mobile-back" onClick={goPrev}>
              <span>Назад</span>
            </button>
          )) || <MobilePageHeader />}
        </div>
        <div className="mobile-wrapper__body">{children}</div>
      </div>
      {loading && <Loader />}
      <Modals />
    </>
  )
}
