import { SideModal } from '$components/UI/Modals/SideModal'
import { Header } from './Header'
import { EditForm } from '$components/Clients/EditForm'
import { ModalSize } from '$utils/constants/modals'

export const ClientEditModal = ({ client, ...extra }) => {
  const { routes } = extra

  return (
    <SideModal size={ModalSize.Small} {...extra}>
      <Header
        title={client ? 'Редактирование клиента' : 'Новый клиент'}
        showEditButton={false}
        showClientBadges={false}
        client={client}
        {...extra}
      />
      <EditForm client={client} routes={routes} {...extra} />
    </SideModal>
  )
}
