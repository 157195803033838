import { DeliveryZone } from '$components/Deliveries/DistributionDeliveries/DeliveryZone/DeliveryZone'
import { router } from '@inertiajs/react'
import { Routes } from '$root/utils/constants/routes'

export const DeliveryZones = ({ zones }) => {
  const onSaveLinking = (zone) => {
    router.post(
      route(Routes.deliveries.zone.couriers.save, { id: route().params.id }),
      {
        couriers: zone.properties.couriers.map((courier) => ({
          id: courier.id,
        })),
      },
    )
  }

  return (
    <div className="delivery-zone">
      {zones.map((zone) => (
        <DeliveryZone
          zone={zone}
          key={zone.properties.id}
          onSave={onSaveLinking}
        />
      ))}
    </div>
  )
}
