import clsx from 'clsx'
import { $dataGrid, dataGridIsChanged } from '$models/dataGrid'
import { DropdownBtn } from '$components/UI/Inputs/DropdownBtn/DropdownBtn'
import React, { useMemo } from 'react'
import { useStore } from 'effector-react'

export const InvoicesFilters = () => {
  const { filter } = useStore($dataGrid)

  const filterWithUrl = useMemo(() => {
    return filter || route().params.filter
  }, [filter])

  const StatusFilters = [
    { name: 'Все счета', value: null },
    { name: 'Неоплаченные', value: 'not_paid' },
    { name: 'Оплаченные', value: 'paid' },
  ]

  return (
    <DropdownBtn
      className={clsx('min-width')}
      // TODO: demo
      items={StatusFilters}
      value={(filterWithUrl && filterWithUrl.status) || null}
      emptyText="Статус"
      titleKey="name"
      valueKey="value"
      needEmpty={false}
      onChange={(statusValue) => {
        if (!statusValue) {
          dataGridIsChanged({
            filter: { ...(filterWithUrl || {}), status: undefined },
          })
          return
        }
        dataGridIsChanged({
          filter: { ...(filterWithUrl || {}), status: statusValue.value },
        })
      }}
    />
  )
}
