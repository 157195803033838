import { NoResults } from '$components/UI/DataGrid/NoResults'
import { DataGridCompound } from '$components/UI/DataGridCompound/DataGridCompound'
import React, { useEffect } from 'react'
import { Routes } from '$root/utils/constants/routes'
import PickUpDeliveryListItem from '$components/Deliveries/PickUpDeliveries/PickUpDeliveryListItem'
import { useOrdersTable } from '$lib/orders/hooks/useOrdersTable'
import { useModals } from '$hooks/useModals'
import { EffectorServices } from '$root/utils/effector/effector-services'
import { PickupDeliveriesService } from '$lib/deliveries/pickup/effector/pickup-deliveries-store'
import EffectorContextProvider from '$components/UI/React/EffectorContextProvider'

const PickUpDeliveries = ({ deliveriesGrid }) => {
  const modals = useModals()
  const deliveries = (deliveriesGrid && deliveriesGrid.rows) || []

  const pickupService = EffectorServices.getService({
    service: new PickupDeliveriesService({ deliveries, modals }),
  })

  const { init } = useOrdersTable()

  useEffect(() => {
    init({ orders: deliveries, isPickUp: true })
  }, [deliveries])

  return (
    (deliveriesGrid && (
      <EffectorContextProvider services={{ pickupService }}>
        <DataGridCompound
          data={deliveriesGrid}
          route={Routes.deliveries.pickup.page}
          body={
            <div className="pickup-deliveries">
              <div className="pickup-delivery">
                <div className="pickup-delivery__column">Клиент</div>
                <div className="pickup-delivery__column">Ответственный</div>
                <div className="pickup-delivery__column">Рационы</div>
                <div className="pickup-delivery__column">Комментарий</div>
                <div className="pickup-delivery__column">Счет</div>
                <div className="pickup-delivery__column pickup-delivery__action-head">
                  Действия
                </div>
              </div>
              {deliveries.map((delivery, i) => (
                <PickUpDeliveryListItem delivery={delivery} key={i} />
              ))}
            </div>
          }
          noResults={<NoResults title={false} text="Список пуст" />}
        />
      </EffectorContextProvider>
    )) || <NoResults className="no-padding" title={false} text="Список пуст" />
  )
}

export default PickUpDeliveries
