import { useContext } from 'react'
import { DataGridContext } from '$components/UI/DataGridCompound/DataGridCompound'
import clsx from 'clsx'

export const Body = () => {
  const {
    data: { rows: couriers },
  } = useContext(DataGridContext)

  return (
    <tbody>
      {couriers.map((courier, i) => (
        <tr key={i}>
          <td>
            <a href="#">
              {courier.full_name || `${courier.last_name} ${courier.name}`}
            </a>
          </td>
          <td>{courier.pickups_count || 0}</td>
          <td
            className={clsx({
              'table--green':
                !isNaN(courier.success_pickups) &&
                courier.success_pickups > 0 &&
                courier.success_pickups === courier.deliveries_count,
            })}
          >
            {courier.success_pickups || 0}
          </td>
          <td
            className={clsx({
              'table--red':
                !isNaN(courier.failed_pickups) && courier.failed_pickups > 0,
              'table--green':
                !isNaN(courier.failed_pickups) && courier.failed_pickups === 0,
            })}
          >
            {courier.failed_pickups || 0}
          </td>
          <td
            className={clsx({
              'table--green': !isNaN(courier.salary) && courier.salary > 0,
            })}
          >
            {courier.salary || 0} ₽
          </td>
        </tr>
      ))}
    </tbody>
  )
}
