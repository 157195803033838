import { time } from '$root/utils/time'
import { useContext, useMemo } from 'react'
import { DayType } from '$root/utils/constants/day-night'
import clsx from 'clsx'
import { DistributionDeliveriesContext } from '$components/Deliveries/DistributionDeliveries/context'
import { useModals } from '$hooks/useModals'
import { Modal } from '$components/UI/Modals/Modal'
import { formatPhone } from '$root/utils/functions'
import { EditDeliveryInfo } from '$components/Clients/Orders/OrdersByStatus/Fields/EditDeliveryInfo'
import { router } from '@inertiajs/react'
import { Routes } from '$root/utils/constants/routes'

export const Item = ({ delivery }) => {
  const { distributionService } = useContext(DistributionDeliveriesContext)
  const { openModal, closeModal } = useModals()

  const isDay = useMemo(() => {
    return (
      delivery.delivery_time &&
      time.getDayType(
        time.parseTimeString(
          time.convertTimeByTimeString(delivery.delivery_time),
        ),
      ) === DayType.Day
    )
  }, [delivery])

  const isNight = useMemo(() => {
    return (
      delivery.delivery_time &&
      time.getDayType(
        time.parseTimeString(
          time.convertTimeByTimeString(delivery.delivery_time),
        ),
      ) === DayType.Night
    )
  }, [delivery])

  const isCheckedIndex = distributionService.checkedStore
    .map((d) => d.id)
    .indexOf(delivery.id)
  const isChecked = isCheckedIndex > -1

  const showDeliveryCommentModal = (delivery) => {
    openModal('DeliveryOptions', Modal, {
      title: 'Дополнительная информация доставки',
      contentComponent: EditDeliveryInfo,
      order: delivery,
      onChange: (updatedOrder) => {
        router.post(
          route(Routes.deliveries.saveDeliveryInfo),
          { orders: [updatedOrder] },
          {
            async onFinish() {
              await closeModal()
            },
          },
        )
      },
    })
  }

  return (
    <tr>
      <td>
        {distributionService.currentCourierStore && (
          <label className="input-chckbx delivery-table__checkbox">
            {/*TODO: checked...*/}
            <input
              type="checkbox"
              checked={isChecked}
              onChange={() => distributionService.toggleItem(delivery)}
            />
            <span></span>
            {isChecked && (
              <span className="delivery-table__checkbox-number">
                {isCheckedIndex + 1}
              </span>
            )}
          </label>
        )}
      </td>
      <td>{delivery.address}</td>
      <td className="p-0">
        <span className="phone">
          {formatPhone(delivery.client.default_phone)}
        </span>
      </td>
      <td>
        {delivery.manager &&
          (delivery.manager.full_name ||
            `${delivery.manager.last_name} ${delivery.manager.name} ${delivery.manager.second_name}`)}
      </td>
      <td>
        {(delivery.client && delivery.client.full_name) ||
          `${delivery.client.last_name} ${delivery.client.name} ${delivery.client.second_name}`}
      </td>
      <td>
        {delivery.rations &&
          delivery.rations.map((ration, i) => (
            <span key={i}>{ration.name || ration.ration_name || '</>'}</span>
          ))}
      </td>
      <td>{delivery.invoices_sum || '-'}</td>
      <td>
        <span
          className={clsx('table__time', {
            'table-plan__time--day': isDay,
            'table-plan__time--night': isNight,
          })}
        >
          {(delivery.delivery_time &&
            time.convertTimeByTimeString(delivery.delivery_time)) ||
            '--:--'}
        </span>
        <button
          className="table__btn-comment"
          onClick={() => showDeliveryCommentModal(delivery)}
        ></button>
      </td>
    </tr>
  )
}
