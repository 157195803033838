import React from 'react'
import { useStore } from 'effector-react'

import { $menuIsShort, menuStateIsChanged } from '$models/menu'

export const Logo = ({ onClick }) => {
  const menuIsShort = useStore($menuIsShort)

  const logoClickHandler = (event) => {
    if (onClick) {
      onClick()
    } else {
      event.preventDefault()
      menuStateIsChanged(!menuIsShort)
    }
  }

  return <div className="sidebar__logo" onClick={logoClickHandler} />
}
