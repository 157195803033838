import { verboseCurrency } from '$root/utils/functions'
import { useContext } from 'react'
import { DistributionDeliveriesContext } from '$components/Deliveries/DistributionDeliveries/context'

export const Footer = () => {
  const { distributionService } = useContext(DistributionDeliveriesContext)
  distributionService.useStores()

  return (
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>
        {
          _.keys(
            _.groupBy(
              distributionService.deliveries,
              (delivery) => delivery.client.id,
            ),
          ).length
        }
      </td>
      <td>
        {distributionService.deliveries.reduce((count, delivery) => {
          count += (delivery.rations && delivery.rations.length) || 0
          return count
        }, 0)}
      </td>
      <td>
        {verboseCurrency(
          distributionService.deliveries.reduce((amount, delivery) => {
            amount +=
              (delivery.invoices_sum && parseFloat(delivery.invoices_sum)) || 0
            return amount
          }, 0),
        )}
      </td>
      <td></td>
    </tr>
  )
}
