import { createContext } from 'react'
import { ViewContacts } from './ViewContacts/ViewContacts'
import { EditContacts } from './EditContacts/EditContacts'

export const ClientContactsContext = createContext({ client: null })

export const ClientContacts = ({ client, children }) => {
  return (
    <ClientContactsContext.Provider value={{ client }}>
      {children}
    </ClientContactsContext.Provider>
  )
}

ClientContacts.ViewContacts = ViewContacts
ClientContacts.EditContacts = EditContacts
