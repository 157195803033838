import { OrderStatus } from '$lib/orders/utils/order-status-const'
import { useOrdersTable } from '$lib/orders/hooks/useOrdersTable'
import { useEffect, useMemo } from 'react'
import { CopyOrderButton } from '$components/Clients/Orders/OrdersByStatus/Fields/CopyOrderButton'
import { ProblemOrderActionButton } from '$components/Clients/Orders/OrdersByStatus/Fields/ProblemOrderActionButton'
import { useDateTime } from '$hooks/useDateTime'
import { resetOrderInactiveDates } from '$lib/clients/store'
import { usePersistOrderDates } from '$lib/clients/hooks'
import { onPostSended } from '$models/page'
import { resetOrderDeliveryType } from '$lib/clients/plan-order-delivery-types-store'

export const ActionsField = ({ order, allowEdit, routes }) => {
  const { dateToSaveFormat } = useDateTime()
  const { updateOrder, deleteOrder } = useOrdersTable()
  const { inactiveOrdersDates } = usePersistOrderDates()

  const statusIsActive = useMemo(
    () => order.status === OrderStatus.Active,
    [order],
  )
  const statusIsDone = useMemo(() => order.status === OrderStatus.Done, [order])
  const statusIsCanceled = useMemo(
    () => order.status === OrderStatus.Canceled,
    [order],
  )
  const statusIsInactive = useMemo(
    () => order.status === OrderStatus.Inactive,
    [order],
  )
  const statusIsProblem = useMemo(
    () => order.status === OrderStatus.Problem,
    [order],
  )
  const issetDeliveryDate = useMemo(() => {
    return (
      !!order.delivery_date || (inactiveOrdersDates[order.id] || []).length > 0
    )
  }, [order, inactiveOrdersDates[order.id]])

  const showStatusActionBtn = useMemo(() => {
    return (
      statusIsActive ||
      statusIsCanceled ||
      statusIsProblem ||
      (statusIsInactive && issetDeliveryDate)
    )
  }, [
    order,
    statusIsActive,
    statusIsCanceled,
    statusIsProblem,
    statusIsInactive,
    issetDeliveryDate,
  ])

  const onDeleteOrder = () => {
    deleteOrder({ order })
  }

  const onChangeOrderStatus = () => {
    let status = null
    if (statusIsActive) {
      status = OrderStatus.Canceled
    } else if (statusIsCanceled) {
      status = OrderStatus.Active
    } else if (statusIsProblem) {
      status = OrderStatus.Active
    } else if (statusIsInactive && issetDeliveryDate) {
      status = OrderStatus.Active
    }

    let sendingOrder = { ...order, status }

    if (status) {
      if ((inactiveOrdersDates[order.id] || []).length > 0) {
        const activeDates = inactiveOrdersDates[order.id]
        if (activeDates.length > 0) {
          sendingOrder.delivery_dates = activeDates.map((date) =>
            dateToSaveFormat(new Date(date)),
          )
        } else {
          sendingOrder.delivery_dates = null
        }
      } else {
        if (sendingOrder.delivery_date) {
          sendingOrder.delivery_dates = [
            dateToSaveFormat(new Date(sendingOrder.delivery_date)),
          ]
        } else {
          sendingOrder.delivery_dates = null
        }
      }

      updateOrder({ order, updatingOrders: sendingOrder })
    } else if (statusIsInactive) {
      onDeleteOrder()
    }
  }

  useEffect(() => {
    onPostSended.watch(({ id, action }) => {
      if (action === 'order updated' && !!id) {
        resetOrderInactiveDates(id)
        resetOrderDeliveryType(id)
      }
    })
  })

  return (
    <>
      <div className="flex align-items-center justify-end">
        {allowEdit && (
          <>
            {showStatusActionBtn && (
              <button
                className="table-plan__btn-action"
                onClick={onChangeOrderStatus}
              >
                {(statusIsActive && 'Отменить') ||
                  ((statusIsCanceled || statusIsProblem) && 'В работу') ||
                  (statusIsInactive && issetDeliveryDate && 'Добавить')}
              </button>
            )}
            {!statusIsDone && (
              <button
                className="table-plan__btn-action-delete"
                onClick={onDeleteOrder}
              >
                Удалить
              </button>
            )}
            <CopyOrderButton order={order} routes={routes} />
            {statusIsCanceled && <ProblemOrderActionButton order={order} />}
          </>
        )}
      </div>
    </>
  )
}
