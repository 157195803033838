import { useLeads } from '$components/Leads/utils/use-leads'
import { Modal } from '$components/UI/Modals/Modal'
import { useModals } from '$hooks/useModals'
import { ChangeContactAtDate } from '$components/Leads/Statused/Entities/Leads/Modal/ChangeContactAtDate'

export const ContactDate = ({ lead }) => {
  const { update } = useLeads()
  const { openModal } = useModals()

  const removeReason = (reasonRemoving) => {
    update(lead, {
      // TODO: $api/
      status_reasons: [
        ...lead.status_reasons.filter((reason) => reason !== reasonRemoving),
      ],
    })
  }

  const changePendingDate = () => {
    openModal('ChangePendingDate', Modal, {
      contentComponent: ChangeContactAtDate,
      lead,
      classes: { wrapper: 'modal--no-overflow' },
    })
  }

  return (
    <div className="deal__contact">
      <p className="deal__contact-tag">
        {lead.status_reasons.map((reason, i) => (
          <button className="tag" key={i} onClick={() => removeReason(reason)}>
            {reason.status_reason_value}
          </button>
        ))}
      </p>
      <div className="deal__contact-date" onClick={changePendingDate}>
        {lead.contact_at
          ? 'Связаться ' + new Date(lead.contact_at).toLocaleDateString()
          : ''}
      </div>
    </div>
  )
}
