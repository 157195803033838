import { Input } from '$components/UI/Inputs/Input'
import { DropdownBtn } from '$components/UI/Inputs/DropdownBtn/DropdownBtn'
import React from 'react'
import { usePage } from '@inertiajs/react'

export const Discount = ({ client }) => {
  const { enums } = usePage().props

  return (
    <>
      <p className="modal-right__title">Скидка</p>
      <div className="modal-right__container">
        {(client.discount && (
          <>
            <Input
              type="text"
              disabled={true}
              placeholder="Скидка"
              classes={{ 'input--short': true }}
              value={client.discount.amount}
            />

            <DropdownBtn
              disabled={true}
              items={enums.discountCurrencies}
              valueKey="key"
              titleKey="value"
              value={client.discount && client.discount.currency}
            />
            <p className="modal-right__note">
              Персональная скидка клиента, на все заказы
            </p>
          </>
        )) || <span>Не установлена</span>}
      </div>
    </>
  )
}
