import React, { useEffect, useState } from 'react'
import { useModals } from '$hooks/useModals'

export const useModal = (
  classes,
  ContentComponent,
  onClose,
  children,
  data,
  isNeedDelay,
) => {
  const [show, setVisibility] = useState()
  const { closeModal: closeCurrentModal } = useModals()
  let hideTimeoutID = null
  let removeTimeoutID = null

  classes = {
    wrapper: classes && classes.wrapper ? classes.wrapper : {},
    overlay: classes && classes.overlay ? classes.overlay : {},
    content: classes && classes.content ? classes.content : {},
    body: classes && classes.body ? classes.body : {},
  }

  const closeModal = (needDelay = true, closeData = {}) => {
    const _hideModal = async () => {
      if (onClose) {
        const cancel = onClose(closeData)
        if (cancel) return
      }

      setVisibility(false)

      await new Promise((resolve) => {
        if (needDelay) {
          hideTimeoutID = setTimeout(() => {
            resolve()
          }, 300)
        } else resolve()
      })

      closeCurrentModal(needDelay)
    }

    if (needDelay) {
      removeTimeoutID = setTimeout(_hideModal)
    } else _hideModal()
  }

  useEffect(() => {
    const _showModal = () => setVisibility(true)

    let showTimeoutID = null
    if (isNeedDelay) {
      showTimeoutID = setTimeout(() => {
        _showModal()
      })
    } else _showModal()

    return () => {
      if (showTimeoutID) clearTimeout(showTimeoutID)
      if (hideTimeoutID) clearTimeout(hideTimeoutID)
      if (removeTimeoutID) clearTimeout(removeTimeoutID)
    }
  }, [])

  let Header, Content
  if (Array.isArray(children)) {
    ;[Header, Content] = children
  } else {
    Content = children
      ? children
      : ContentComponent && <ContentComponent {...{ ...data, closeModal }} />
  }

  if (Header)
    Header = React.cloneElement(Header, { ...Header.props, closeModal })
  if (Content)
    Content = React.cloneElement(Content, { ...Content.props, closeModal })

  return {
    show,
    Header,
    Content,
    cls: classes,
    closeModal,
  }
}
