import { useEffect, useMemo } from 'react'
import { DropdownBtn } from '$components/UI/Inputs/DropdownBtn/DropdownBtn'
import { usePage } from '@inertiajs/react'
import { AllowedLeadsPageActions, ChangeStatusLabel } from './utils/const'
import { getActiveTabCode } from '$lib/leads/statused/public/helpers'

export const ChangeStatusSelect = ({ status, onChangeStatus, onError }) => {
  const { enums } = ({} = usePage().props)
  const currentTabCode = getActiveTabCode(enums.tabs || [])

  const getAllowedStatuses = () => {
    return enums.tabs.filter((tab) =>
      AllowedLeadsPageActions[currentTabCode]
        ? AllowedLeadsPageActions[currentTabCode].status.indexOf(
            tab.attributes.code,
          ) !== -1
        : false,
    )
  }

  const getAllowedActions = () => {
    return (
      (AllowedLeadsPageActions[currentTabCode] &&
        AllowedLeadsPageActions[currentTabCode].actions) ||
      []
    )
  }

  const availableActions = useMemo(() => {
    const statuses = getAllowedStatuses()
    const actions = getAllowedActions()

    return [...statuses, ...actions]
  }, [status, currentTabCode])

  const checkCustomName = (statusOrAction) => {
    if (ChangeStatusLabel.hasOwnProperty(currentTabCode)) {
      let customLabel

      customLabel = ChangeStatusLabel[currentTabCode][statusOrAction.code]

      if (customLabel) {
        statusOrAction.title = customLabel
      }
    }
    return statusOrAction
  }

  const normalizeTabsToStatuses = (statusOrAction) => {
    let code
    let title

    if (!statusOrAction.attributes) {
      code = statusOrAction
      title = statusOrAction
    } else {
      code = statusOrAction.attributes.code
      title = statusOrAction.title
    }

    return { code, title }
  }

  useEffect(() => {
    if (!AllowedLeadsPageActions.hasOwnProperty(currentTabCode)) {
      onError('Нет действий для этого статуса')
    }
  }, [])

  return (
    <DropdownBtn
      items={
        availableActions.map(normalizeTabsToStatuses).map(checkCustomName) || []
      }
      multiple={false}
      valueKey="code"
      titleKey="title"
      needEmpty={false}
      onChange={onChangeStatus}
    />
  )
}
