import { Routes } from '$root/utils/constants/routes'
import { router } from '@inertiajs/react'
import { useEffect } from 'react'

const useDeliveryPageFilter = () => {
  let { filter } = route().params

  if (!filter) {
    filter = {}
  }

  const updateFilter = (name, value, newRoute = undefined) => {
    filter[name] = value

    let routeName = route().current()

    if (routeName !== Routes.deliveries.pickup.page) {
      routeName = Routes.deliveries.zone.list
    }

    router.get(route(newRoute || routeName), {
      ...{ ...route().params, id: undefined },
      filter,
    })
  }

  return { filter, updateFilter }
}

export default useDeliveryPageFilter
