import { CourierFinancesSubtable } from '$components/Reports/DataGrids/Couriers/Finances/CourierFinancesSubtable'
import { useState } from 'react'
import clsx from 'clsx'

export const CourierFinancesCollapse = ({ courier }) => {
  const [open, setOpen] = useState(false)
  const allowOpen = courier.getOrders().length > 0

  return (
    <>
      <tr
        className={clsx({ 'allow-open': allowOpen, open })}
        onClick={(ev) => {
          ev.preventDefault()
          ev.stopPropagation()
          allowOpen && setOpen(!open)
        }}
      >
        <td>{courier.full_name}</td>
        <td>{courier.pickups_count || 0}</td>
        <td>{courier.cash_sum || 0} ₽</td>
        <td>{courier.cashless_sum || 0} ₽</td>
        <td>{courier.not_paid_sum || 0} ₽</td>
      </tr>
      {open && (
        <tr className="table-courier-report__finances-row">
          <td colSpan={5} className="table-courier-report__finances">
            <div className="table-courier-report__wrapper">
              <CourierFinancesSubtable orders={courier.getOrders()} />
            </div>
          </td>
        </tr>
      )}
    </>
  )
}
