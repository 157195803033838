import React, { useEffect } from 'react'
import { MainLayout } from '$components/UI/Layouts/MainLayout'
import { PageHeader } from '$components/Leads/PageHeader'
import { LeadsStatusedDataGrid } from '$components/Leads/Statused/DataGrid/LeadsStatusedDataGrid'
import { Routes } from '$root/utils/constants/routes'
import { resetInactiveOrdersDates } from '$lib/clients/store'
import { useGlobalStore } from '$lib/page/provider'
import { DeliveriesSectionRouteRegExp } from '$root/config/root-routes'

export const Clients = ({ list, client, orders, invoices }) => {
  const clientRoutes = Routes.leads.clients
  const { pageSelfCleans } = useGlobalStore()

  useEffect(() => {
    pageSelfCleans.addCleaning(DeliveriesSectionRouteRegExp, () => {
      resetInactiveOrdersDates()
    })
  }, [])

  return (
    <LeadsStatusedDataGrid
      data={list}
      client={client}
      orders={orders}
      invoices={invoices}
      routes={clientRoutes}
    >
      <LeadsStatusedDataGrid.Header>
        <LeadsStatusedDataGrid.Header.Tabs />
        <LeadsStatusedDataGrid.Header.Filters />
        <LeadsStatusedDataGrid.Header.Columns />
      </LeadsStatusedDataGrid.Header>
      <LeadsStatusedDataGrid.Body routes={clientRoutes} />
      <LeadsStatusedDataGrid.Footer />
    </LeadsStatusedDataGrid>
  )
}
Clients.layout = (page) => (
  <MainLayout PageHeader={PageHeader}>{page}</MainLayout>
)
