import { MainLayout } from '$components/UI/Layouts/MainLayout'
import React from 'react'
import { ReportsTabs } from '$components/Reports/Tabs/ReportsTabs'
import { ReportsFilters } from '$components/Reports/Filters/ReportsFilters'
import { ReportsSubTabs } from '$components/Reports/DataGrids/Couriers/Tabs'
import { Routes } from '$root/utils/constants/routes'
import { ReportsCouriersFinancesDataGrid } from '$components/Reports/DataGrids/Couriers/Finances/CourierFinancesDataGrid'

export const ReportsPickupsFinances = ({ orders, list }) => {
  const fillCourierOrders = (courier) => {
    return { ...courier, getOrders: () => orders[courier.id] || [] }
  }

  return (
    <>
      <ReportsTabs />
      <ReportsSubTabs
        tabs={[
          { title: 'Эффективность', route: Routes.reports.pickups.page },
          { title: 'Финансы', route: Routes.reports.pickups.finances.page },
        ]}
      />
      <ReportsFilters dateMultiple={false} />

      <ReportsCouriersFinancesDataGrid
        data={{ ...list, rows: list.rows.map(fillCourierOrders) }}
        route={Routes.reports.pickups.finances.page}
      >
        <ReportsCouriersFinancesDataGrid.Wrapper className="table">
          <ReportsCouriersFinancesDataGrid.Header />
          <ReportsCouriersFinancesDataGrid.Body />
          <ReportsCouriersFinancesDataGrid.Footer />
        </ReportsCouriersFinancesDataGrid.Wrapper>
      </ReportsCouriersFinancesDataGrid>
    </>
  )
}

ReportsPickupsFinances.layout = (page) => <MainLayout>{page}</MainLayout>
