import { Routes } from '$root/utils/constants/routes'
import { router, usePage } from '@inertiajs/react'
import { useDateTime } from '$hooks/useDateTime'

export const MobilePageHeader = () => {
  const { dateMonthVerbosed } = useDateTime()
  const { statistics = {} } = usePage().props

  const goLeft = () => {
    router.get(route(Routes.mobile.left))
  }

  return (
    <div className="mobile-info">
      <p className="mobile-info__date">{dateMonthVerbosed()}</p>
      <p className="mobile-info__data">
        <button className="mobile-info__remains" onClick={goLeft}>
          Осталось{' '}
          <span className="mobile-info__remains-value">
            {statistics.rations_left || 0}
          </span>
        </button>
        <span className="mobile-info__addresses">
          {statistics.orders_left || 0}
        </span>
      </p>
    </div>
  )
}
