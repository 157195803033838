import { AdvertPane } from '$components/Reports/Company/Panes/AdvertPane'
import { PenaltyPane } from '$components/Reports/Company/Panes/PenaltyPane'
import { SalesPane } from '$components/Reports/Company/Panes/SalesPane'
import { AwardsPane } from '$components/Reports/Company/Panes/AwardsPane'
import { verboseCurrency } from '$root/utils/functions'

export const ReportPanes = ({ amounts }) => {
  return (
    <>
      <div className="color-panel__wrap">
        <AdvertPane amount={verboseCurrency(amounts.advert)} />
        <PenaltyPane amount={verboseCurrency(amounts.penalty)} />
        <AwardsPane amount={verboseCurrency(amounts.awards)} />
        <SalesPane amount={verboseCurrency(amounts.sales)} />
      </div>
    </>
  )
}
