import { useContext } from 'react'
import { DataGridContext } from '$components/UI/DataGridCompound/DataGridCompound'
import clsx from 'clsx'
import { SortableColumn } from '$components/UI/DataGrid/SortableColumn'

const Column = ({ column, className, ...other }) => {
  return (
    <th {...other} className={clsx(className)}>
      {column.title}
    </th>
  )
}

const ColumnWrapper = ({ column, children, ...other }) =>
  (column.sortable && (
    <SortableColumn column={column} {...other}>
      {children}
    </SortableColumn>
  )) || <>{children}</>

export const Header = () => {
  const {
    data: { columns },
  } = useContext(DataGridContext)

  return (
    <thead>
      <tr>
        {columns.map((column, i) => (
          <ColumnWrapper column={column} key={i}>
            <Column column={column} />
          </ColumnWrapper>
        ))}
      </tr>
    </thead>
  )
}
