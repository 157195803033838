import { createDomain } from 'effector'
import React, { useEffect, useState } from 'react'
import { uid } from '$root/utils/functions'

export class BaseEffectorDomainsFactory {
  domains = {}
  _logic = null

  _getDomain(key) {
    return this.domains[key]
  }

  _newDomain(key, logic = null) {
    this.domains[key] = new EffectorDomain(createDomain(key), logic, this)
    window.effectors.push(this.domains[key])
    return this.domains[key]
  }

  setLogic(logic) {
    this._logic = logic
  }

  getService() {
    return this._logic
  }

  domain(key, logic = null) {
    if (!window.effectors) {
      window.effectors = []
    }

    let domain = this._getDomain(key)

    if (!domain) {
      domain = this._newDomain(key, logic)
      domain.init()
      if (!window.domains) {
        window.domains = []
      }
      domains.push(domain)
    }

    if (!domain.isInitialized()) {
      return null
    }
    return this._getDomain(key)
  }
}

export class EffectorDomain extends BaseEffectorDomainsFactory {
  /** @type {Domain} */
  _domain = null
  _stores = {}
  _parent = null
  _initialized = false

  constructor(domain, logic = undefined, parent = undefined) {
    super()
    this._initialized = false
    this._domain = domain
    if (logic) {
      this._logic = logic
      this._logic.setDomain(this)
    }
    this._parent = parent
  }

  isInitialized() {
    return this._initialized
  }

  setInitialized() {
    this._initialized = true
  }

  init() {
    if (!this.isInitialized()) {
      if (this._logic && !!this._logic.init) {
        this._logic.init()
      }
    }
    this.setInitialized(true)
  }

  _getStore(name) {
    return this._stores[name]
  }

  _newStore(name, value) {
    this._stores[name] = this._domain.createStore(value)
  }

  store(name, value) {
    if (!this._getStore(name)) {
      this._newStore(name, value)
    }
    return this._getStore(name)
  }

  removeDomain(domain) {
    const key = _.findKey(this.domains, (iterDomain) => iterDomain === domain)
    if (key) {
      delete this.domains[key]
    }
  }

  remove() {
    if (this.domains) {
      for (const k in this.domains) {
        this.domains[k].remove()
      }
    }
    if (this._parent) {
      this._parent.removeDomain(this)
    }
  }
}
