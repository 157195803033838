import React, { useContext } from 'react'
import { ClientContactsContext } from '$components/Clients/Fields/Contacts/ClientContacts'

export const AddressContacts = () => {
  const { client } = useContext(ClientContactsContext)

  return (
    <>
      {client.addresses.map((addressInfo, i) => (
        <tr key={i}>
          <td>Адрес</td>
          <td>{addressInfo.address}</td>
        </tr>
      ))}
    </>
  )
}
