import { OrdersFields } from '$components/Clients/Orders/OrdersByStatus/Fields'

export const ActiveOrderItem = ({ order, routes, allowEdit }) => {
  return (
    <>
      <tr className="table-plan--row-success">
        <OrdersFields
          status={order.status}
          order={order}
          routes={routes}
          allowEdit={allowEdit}
        />
      </tr>
    </>
  )
}
