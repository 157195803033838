import { OrdersByStatus } from '$components/Clients/Orders/OrdersByStatus'
import { OrderStatus } from '$lib/orders/utils/order-status-const'
import React, { useEffect } from 'react'
import { useOrdersTable } from '$lib/orders/hooks/useOrdersTable'
import { usePermissions } from '$hooks/usePermissions'

export const Orders = ({ client, orders, ...other }) => {
  const { init, newOrder } = useOrdersTable()
  const { isAllow } = usePermissions()

  useEffect(() => {
    init({ client, orders })
  }, [client, orders])

  return (
    <>
      <div className="delivery-table">
        <div className="modal-right__section">
          <p className="modal-right__title">План доставок питания</p>
          <OrdersByStatus status={OrderStatus.Active} {...other} />
        </div>
        <div className="modal-right__section modal-right__section--right">
          <button className="modal-right__btn-report">Отправить счет</button>
        </div>
        <div className="modal-right__section">
          <div className="modal-right__title flex align-items-center">
            Неактивные доставки
            {isAllow('clients.edit') && (
              <div
                className="add-delivery-btn flex align-items-center"
                onClick={() => newOrder()}
              >
                <button className="delivery-table__btn-package" />
                <span className="add-btn-text">Добавить</span>
              </div>
            )}
          </div>
          <OrdersByStatus status={OrderStatus.Inactive} {...other} />
        </div>
        <div className="modal-right__section">
          <p className="modal-right__title">Проблемные доставки</p>
          <OrdersByStatus status={OrderStatus.Problem} {...other} />
        </div>
        <div className="modal-right__section">
          <p className="modal-right__title">Выполненные доставки</p>
          <OrdersByStatus status={OrderStatus.Done} {...other} />
        </div>
      </div>
    </>
  )
}
