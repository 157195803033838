import clsx from 'clsx'
import { IMaskInput } from 'react-imask'
import { useCallback, useEffect, useRef, useState } from 'react'
import { time } from '$root/utils/time'

export const TimeInput = ({
  placeholder,
  disabled,
  className,
  value,
  error,
  onChange,
}) => {
  const inputRef = useRef(null)
  const ref = useRef(null)

  const [timeValue, setTime] = useState(time.convertTimeByTimeString(value))

  const initValue = useCallback(() => {
    const formattedValue = time.convertTimeByTimeString(value)
    setTime(formattedValue)

    if (formattedValue.length === 5) {
      ref.current.maskRef.unmaskedValue = formattedValue
    } else {
      ref.current.maskRef.unmaskedValue = ''
    }
  }, [value])

  useEffect(initValue, [value])

  return (
    <label className={clsx('input', className)}>
      <IMaskInput
        className={clsx({ error })}
        // mask="0 000 000 00 00"
        mask="XX:YY"
        blocks={{
          XX: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 23,
          },
          YY: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 59,
          },
        }}
        radix="."
        unmask={'typed'} // true|false|'typed'
        ref={ref}
        inputRef={inputRef} // access to nested input
        // DO NOT USE onChange TO HANDLE CHANGES!
        // USE onAccept INSTEAD
        onAccept={
          // depending on prop above first argument is
          // `value` if `unmask=false`,
          // `unmaskedValue` if `unmask=true`,
          // `typedValue` if `unmask='typed'`
          (updatedValue, mask) => {
            if (onChange) {
              onChange(time.parseTimeString(updatedValue), mask)
            }
          }
        }
        type="text"
        placeholder={placeholder}
        defaultValue={timeValue}
        disabled={disabled}
      />
    </label>
  )
}
