import { createEvent, createStore } from 'effector'
import { router } from '@inertiajs/react'
import { deepCompare } from '$root/utils/functions'

export const dataGridInitialized = createEvent()
export const dataGridIsChanged = createEvent()
export const routeIsChanged = createEvent()
export const resetDatagrid = createEvent()

// TODO: сделать хук useDataGrid, который вернет параметры и функции текущего грида
export const $dataGrid = createStore({
  route: undefined,
  routeParams: undefined,
  search: undefined,
  filter: undefined,
  order: undefined,
  page: undefined,
  totals: {},
  columns: [],
})
  .on(dataGridInitialized, (store, data) => data)
  .on(dataGridIsChanged, (store, data) => ({ ...store, ...data }))
  .on(routeIsChanged, (store, { route, routeParams }) => ({
    ...store,
    route,
    routeParams,
  }))
  .reset(resetDatagrid)

/**
 * Отправляет данные параметров грида на бэкенд
 * @param data {$dataGrid} - текущие данные хранилища
 * @return data - текущее состояние хранилища, не подвергается изменениям, остается прежним
 * */
dataGridIsChanged.watch((payload) => {
  const dataGrid = $dataGrid.getState()

  const data = { ...dataGrid, ...payload }

  if (data.hasOwnProperty('filter') && !data.filter) {
    delete data['filter']
  } else if (data.hasOwnProperty('filter')) {
    const getEmptyKey = () => {
      return _.findKey(data.filter, function (filterValue) {
        return !filterValue && typeof filterValue !== 'boolean'
      })
    }
    let issetEmpty = getEmptyKey() !== undefined

    while (issetEmpty) {
      const i = getEmptyKey()
      if (i) {
        _.unset(data.filter, i)
      }
      issetEmpty = getEmptyKey() !== undefined
    }

    if (!_.keys(data.filter).length) {
      data.filter = undefined
    }
  }

  if (data.hasOwnProperty('search') && !data.search) {
    delete data['search']
  }

  if (
    // Если при неактивной пагинации происходит поиск или фильтрация,
    //  сбрасываем пагинацию на 1 страницу, иначе это считается пагинация по поиску и фильтрам
    ((!!payload.search || !!payload.filter) &&
      !dataGrid.page &&
      !dataGrid.search) ||
    // Если поиск или фильтр изменились
    (!!payload.search &&
      !!dataGrid.search &&
      !deepCompare(payload.search, dataGrid.search)) ||
    (!!payload.filter &&
      !!dataGrid.filter &&
      !deepCompare(payload.filter, dataGrid.filter))
  ) {
    delete data['page']
  }

  if (!data.route) {
    data.route = route().current()
  }

  data.routeParams = data.routeParams ? data.routeParams : {}
  // Получаем аргументы в виде массива для отправки их в функцию получения роута датагрида
  const currentRouteParams = route().params

  delete currentRouteParams['filter']
  delete currentRouteParams['search']
  delete currentRouteParams['page']
  delete currentRouteParams['order']

  const routeArgs = [data.route, { ...currentRouteParams, ...data.routeParams }]
  let { search, filter, page, order } = data

  if ((order && _.keys(order).length === 0) || !order) {
    order = undefined
  }

  if (!page) {
    page = undefined
  }

  router.get(
    route.apply(this, routeArgs),
    { search, filter, page, order },
    {
      // onSuccess() {
      //     onChangeRoute()
      // },
    },
  )

  // Возврат текущего состояния на сохранение в хранилище
  return dataGrid
})
