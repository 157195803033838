import { TagDropdownItem } from '$components/UI/Inputs/TagsDropdown/TagDropdownItem'
import { useContext } from 'react'
import { ActiveTagContext } from '$components/UI/Inputs/TagsDropdown/ActiveTag'
import { TagsDropdownContext } from '$components/UI/Inputs/TagsDropdown'

export const ActiveTagReplacer = ({ item }) => {
  const { onSelectItem } = useContext(ActiveTagContext)
  const { tags, titleKey, disabled } = useContext(TagsDropdownContext)

  return tags.map((tag, i) => (
    <TagDropdownItem
      currentItem={item}
      tag={tag}
      titleKey={titleKey}
      key={i}
      onSelectItem={onSelectItem}
      disabled={disabled}
    />
  ))
}
