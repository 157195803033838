import React, { useEffect } from 'react'
import { router } from '@inertiajs/react'
import { useLoader } from '$hooks/useLoader'
import { Loader } from '$components/UI/Loader'
import { usePageErrors } from '$hooks/usePageErrors'
import { useModals } from '$hooks/useModals'

export const AuthLayout = ({ children }) => {
  const { loading, showLoader, hideLoader } = useLoader()
  const { Modals } = useModals()
  usePageErrors()
  useEffect(() => {
    router.on('start', showLoader)
    router.on('finish', hideLoader)
  }, [])

  return (
    <>
      <div className="auth">
        <div className="auth__wrapper">
          <div className="auth__logo">Eat for day логотип</div>
          {children}
        </div>
      </div>
      {loading && <Loader />}
      <Modals />
    </>
  )
}
