import React from 'react'

export const Comment = ({ client }) => {
  return (
    <div className="modal-right__section">
      <p className="modal-right__title">Комментарий по клиенту</p>
      <div className="modal-right__text">
        <p>{client.comment || '------'}</p>
      </div>
    </div>
  )
}
