import React from 'react'
import { DistributionDeliveries } from '$components/Deliveries/DistributionDeliveries/DistributionDeliveries'
import { useDeliveryZones } from '$lib/deliveries/hooks/useDeliveryZones'

export const Deliveries = ({ zones, deliveries }) => {
  useDeliveryZones(zones)

  return (
    <DistributionDeliveries deliveries={deliveries}>
      <DistributionDeliveries.Map />
      <DistributionDeliveries.DeliveryZones />
      <DistributionDeliveries.DataGrid>
        <DistributionDeliveries.DataGrid.Title />
        <DistributionDeliveries.DataGrid.Head />
        <DistributionDeliveries.DataGrid.Body>
          <DistributionDeliveries.DataGrid.Footer />
        </DistributionDeliveries.DataGrid.Body>
      </DistributionDeliveries.DataGrid>
    </DistributionDeliveries>
  )
}
