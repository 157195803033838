import { useForm, usePage } from '@inertiajs/react'
import { verboseCurrency } from '$root/utils/functions'
import { DropdownBtn } from '$components/UI/Inputs/DropdownBtn/DropdownBtn'
import { NoResults } from '$components/UI/DataGrid/NoResults'
import { useFormValidation } from '$hooks/useFormValidation'
import { Input } from '$components/UI/Inputs/Input'
import { router } from '@inertiajs/react'
import { Routes } from '$root/utils/constants/routes'
import { useEffect } from 'react'

export const SettingsFinancePayment = () => {
  const { enums, salary_rates } = usePage().props

  const {
    data,
    setData,
    post,
    transform,
    errors,
    setError,
    clearErrors,
    reset,
  } = useForm({
    checkedRate: null,
    currency: null,
    amount: 0,
  })

  useEffect(() => {
    let newCurrency = null
    if (data.checkedRate) {
      newCurrency = data.checkedRate.currency
    }
    setData('currency', newCurrency)
  }, [data.checkedRate])

  const onCheckIsValid = () => {
    let isValid = true

    if (!data.checkedRate) {
      setError('role_id', 'Нужно отметить изменяемую ставку')
      isValid = false
    }

    if (
      (!data.amount && typeof data.amount != 'number') ||
      isNaN(data.amount)
    ) {
      setError('amount', 'Нужно заполнить сумму ставки')
      isValid = false
    }

    if (!data.currency) {
      setError('currency', 'Не выбран тип ставки')
      isValid = false
    }

    if (data.amount <= 0) {
      setError('amount', 'Введите сумму')
      isValid = false
    }

    return isValid
  }

  transform((sendingData) => {
    const checkedRate = sendingData['checkedRate']
    delete sendingData['checkedRate']
    sendingData.role_id = checkedRate.role_id
    return sendingData
  })

  const [validate, markErrors] = useFormValidation(
    `settings-add-payment`,
    onCheckIsValid,
    data,
    errors,
    clearErrors,
  )

  const submit = () => {
    if (validate()) {
      post(route(Routes.settings.finance.payment.save))
    }
  }

  return (
    <div className="payment">
      <div className="payment__col payment__col--two-third">
        <div className="check-list">
          <p className="check-list__title">Ставки</p>
          <div className="check-list__section">
            {(salary_rates.length > 0 &&
              salary_rates.map((salary_rate, i) => (
                <label className="check-list__item" key={i}>
                  <span className="input-chckbx">
                    <input
                      type="checkbox"
                      checked={data.checkedRate === salary_rate}
                      onChange={() =>
                        setData(
                          'checkedRate',
                          (data.checkedRate !== salary_rate && salary_rate) ||
                            null,
                        )
                      }
                    />
                    <span></span>
                  </span>
                  <span className="check-list__item-title">
                    {salary_rate.role_title}
                  </span>
                  <span className="check-list__item-inner">
                    <span className="check-list__item-type">
                      {enums.salaryCurrencies &&
                        enums.salaryCurrencies[salary_rate.currency]}
                    </span>
                    <span className="check-list__item-cost">
                      {verboseCurrency(salary_rate.amount, 0)}
                    </span>
                  </span>
                </label>
              ))) || <NoResults title="Нет данных" />}
          </div>
        </div>
      </div>
      <div className="payment__col payment__col--one-third">
        <div className="payment__form">
          <p className="payment__form-title">Назначить оплату</p>
          <div className="payment__row">
            <label className="input">
              <input
                type="text"
                placeholder={
                  (data.checkedRate && data.checkedRate.role_title) ||
                  'Не заполнено'
                }
                disabled
              />
            </label>
          </div>
          <div className="payment__row">
            <DropdownBtn
              items={enums.salaryCurrencies}
              titleKey="value"
              valueKey="key"
              value={data.currency}
              onChange={(selected) => {
                setData(
                  'currency',
                  (selected && selected.currency) || selected.key,
                )
              }}
              error={markErrors && errors && errors.currency}
            />
          </div>
          <div className="payment__row">
            <Input
              type="number"
              value={data.amount}
              placeholder="Сумма"
              onChange={(ev) => setData('amount', ev.target.value)}
              error={markErrors && errors && errors.amount}
            />
          </div>
          <div className="payment__row">
            <button className="btn btn__filled btn--blue" onClick={submit}>
              Изменить
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
