import { randomColor } from '$root/utils/functions'

const reverseCoordinates = (coordinates) => {
  return (
    (coordinates &&
      coordinates.length &&
      coordinates[0].map((latlng) => [...latlng].reverse())) ||
    []
  )
}

export const normalizeZoneDataToSend = (zoneData, cityID = undefined) => {
  const id = zoneData.properties.id

  const zoneDataSending = {
    zone: {
      ...zoneData.geometry,
      coordinates: [reverseCoordinates(zoneData.geometry.coordinates)],
    },
    ...zoneData.properties,
    city_id: cityID,
  }

  if (id.toString().indexOf('*') > -1) {
    delete zoneDataSending.id
  }

  return zoneDataSending
}

export const normalizeZoneDataToDraw = (zoneData) => {
  const coordinates = reverseCoordinates(zoneData.geometry.coordinates)

  return {
    ...zoneData,
    geometry: {
      ...zoneData.geometry,
      coordinates: [coordinates],
    },
  }
}

export const withRandomColor = (zone) => ({
  ...zone,
  properties: { ...zone.properties, color: randomColor() },
})
