import React from 'react'
import { MainLayout } from '$components/UI/Layouts/MainLayout'
import { Routes } from '$root/utils/constants/routes'
import { SettingsFinanceTabs } from '$components/Settings/Finance/SettingsFinanceTabs'
import { SettingsFinanceExpenses } from '$components/Settings/Finance/Expenses/SettingsFinanceExpenses'
import { SettingsFinancePayment } from '$components/Settings/Finance/Payment/SettingsFinancePayment'

const tabs = [
  { title: 'Оплата', route: Routes.settings.finance.payment.page },
  { title: 'Расходы', route: Routes.settings.finance.expenses.page },
]

export const SettingsFinance = ({ expense_types = [] }) => {
  const currentRoute = route().current()

  return (
    <div>
      <SettingsFinanceTabs tabs={tabs} />
      {(currentRoute === Routes.settings.finance.payment.page && (
        <SettingsFinancePayment />
      )) || <SettingsFinanceExpenses tags={expense_types} />}
    </div>
  )
}

SettingsFinance.layout = (page) => <MainLayout>{page}</MainLayout>
