export const Routes = {
  clients: {
    list: 'clients',
    view: 'clients.edit',
    edit: 'clients.edit',
    save: 'clients.store',
    transactions: {
      list: 'clients.transactions',
      save: 'clients.transactions.store',
    },
    orders: {
      list: 'clients.orders',
      update: 'clients.orders.store',
      delete: 'clients.orders.delete',
      replacements: {
        update: 'clients.orders.replacements.store',
        delete: 'clients.orders.replacements.delete',
      },
      copy: 'clients.orders.copy',
    },
    replacements: {
      update: 'clients.replacements.store',
      delete: 'clients.replacements.delete',
    },
    invoices: {
      list: 'clients.invoices',
      update: 'clients.invoices.store',
      // list: 'clients.invoices.list',
    },
  },
  leads: {
    list: 'leads.list',
    clients: {
      list: 'leads.list',
      view: 'leads.client',
      save: 'leads.client.store',
      save_status: 'leads.client.status.store',
      transactions: {
        list: 'leads.client.transactions',
        save: 'leads.client.transactions.store',
      },
      orders: {
        list: 'leads.client.orders',
        update: 'leads.client.orders.store',
        delete: 'leads.client.orders.delete',
        replacements: {
          update: 'leads.client.orders.replacements.store',
          delete: 'leads.client.orders.replacements.delete',
        },
        copy: 'leads.client.orders.copy',
      },
      replacements: {
        update: 'leads.client.replacements.store',
        delete: 'leads.client.replacements.delete',
      },
      invoices: {
        list: 'leads.client.invoices',
        update: 'leads.client.invoices.store',
      },
    },
    update: 'leads.update',
  },
  deliveries: {
    page: 'deliveries',
    pickup: {
      page: 'deliveries.pickup',
      updateInvoice: 'deliveries.invoice.store',
      updateClientInvoices: 'clients.invoices.store',
      orderGiven: 'deliveries.store',
      orderProblem: 'deliveries.store',
      update: 'deliveries.store',
    },
    zone: {
      list: 'deliveries',
      active: 'deliveries.zone',
      couriers: {
        save: 'deliveries.couriers.store',
      },
    },
    distribution: 'deliveries.distribution.store',
    save_salary: 'deliveries.couriers.transactions.store',
    saveDeliveryInfo: 'deliveries.store',
  },
  kitchen: {
    cooks: 'kitchen.cooks',
    packers: 'kitchen.packers',
    replacements: 'kitchen.replacements',
    save_salary: 'kitchen.employees.transactions.store',
  },
  reports: {
    deliveries: {
      page: 'reports.deliveries',
      finances: {
        page: 'reports.deliveries.finances',
      },
    },
    pickups: {
      page: 'reports.pickups',
      finances: {
        page: 'reports.pickups.finances',
      },
    },
    managers: {
      page: 'reports.managers',
    },
    invoices: {
      page: 'reports.invoices',
    },
    expenses: {
      page: 'reports.expenses',
      new: 'reports.expenses.store',
    },
    company: {
      page: 'reports.company',
    },
  },
  mobile: {
    list: 'mobile.courier.orders',
    detail: 'mobile.courier.orders.detail',
    left: 'mobile.courier.statistics',
    delivery_done: 'mobile.courier.orders.store',
    delivery_cancel: 'mobile.courier.orders.store',
    delivery_finish: 'mobile.courier.orders.detail',
    saveInvoice: 'mobile.courier.invoice.store',
    payInvoice: 'mobile.courier.invoice.store',
    cancelInvoice: 'mobile.courier.invoice.store',
  },
  settings: {
    users: {
      list: 'settings.users',
      edit: 'settings.users.edit',
      store: 'settings.users.store',
    },
    leads: {
      tags: 'settings.leads.status.reasons',
      new_tag: 'settings.leads.status.reasons.store',
      remove_tag: 'settings.leads.status.reasons.delete',
      clients: {
        page: 'settings.leads.clients',
        delete: 'settings.leads.clients.delete',
        import: 'settings.leads.clients.import',
        export: 'settings.leads.clients.export',
      },
      rations: {
        list: 'settings.leads.rations',
        store_ration: 'settings.leads.rations.store',
        remove_ration: 'settings.leads.rations.delete',
        new_product: 'settings.leads.products.store',
        remove_product: 'settings.leads.rations.products.delete',
        save_prices: 'settings.leads.products.prices.store',
        link_product_to_ration: 'settings.leads.rations.products.store',
      },
    },
    delivery_zones: {
      new: 'settings.delivery.zones.store',
      edit: 'settings.delivery.zones.store',
      delete: 'settings.delivery.zones.delete',
      extra_charge: 'settings.delivery.types.store',
    },
    permissions: {
      list: 'settings.permissions.list',
      set: 'settings.permissions.set',
      role: {
        add: 'settings.permissions.role.add',
      },
    },
    finance: {
      payment: {
        page: 'settings.finance.salary',
        save: 'settings.finance.salary.store',
      },
      expenses: {
        page: 'settings.finance.expenses',
        new: 'settings.finance.expenses.store',
        delete: 'settings.finance.expenses.delete',
      },
    },
    kitchen: {
      comments: 'settings.kitchen.comments',
      new: 'settings.kitchen.comments.store',
      delete: 'settings.kitchen.comments.delete',
    },
  },
}
