import { Routes } from '$root/utils/constants/routes'

export const LeadSettingsTab = {
  Tags: 1,
  Clients: 2,
  Products: 3,
}

export const LeadSettingsRoutes = {
  [LeadSettingsTab.Tags]: Routes.settings.leads.tags,
  [LeadSettingsTab.Clients]: Routes.settings.leads.clients.page,
  [LeadSettingsTab.Products]: Routes.settings.leads.rations.list,
}
