import { Routes } from '$root/utils/constants/routes'
import { DataGridCompound } from '$components/UI/DataGridCompound/DataGridCompound'
import { Header } from './Header'
import { Body } from './Body'
import { DataGridPagination } from '$components/UI/DataGrid/DataGridPagination'
import { TableDataGridWrapper } from '$components/UI/DataGrid/Table/Wrapper'

const demoData = {
  rows: [
    {
      category: 'Закупка продуктов',
      description: 'Дозакупка, поавра не рассчитали',
      date: '11.07.2022',
      created_by: 'Соколов А',
      amount: 4500,
    },
    {
      category: 'Закупка продуктов',
      description: 'Дозакупка, поавра не рассчитали',
      date: '11.07.2022',
      created_by: 'Соколов А',
      amount: 1,
    },
    {
      category: 'Закупка продуктов',
      description: 'Дозакупка, поавра не рассчитали',
      date: '11.07.2022',
      created_by: 'Соколов А',
      amount: 1,
    },
    {
      category: 'Закупка продуктов',
      description: 'Дозакупка, поавра не рассчитали',
      date: '11.07.2022',
      created_by: 'Соколов А',
      amount: 1300,
    },
    {
      category: 'Закупка продуктов',
      description: 'Дозакупка, поавра не рассчитали',
      date: '11.07.2022',
      created_by: 'Соколов А',
      amount: 1,
    },
    {
      category: 'Закупка продуктов',
      description: 'Дозакупка, поавра не рассчитали',
      date: '11.07.2022',
      created_by: 'Соколов А',
      amount: 1,
    },
    {
      category: 'Закупка продуктов',
      description: 'Дозакупка, поавра не рассчитали',
      date: '11.07.2022',
      created_by: 'Соколов А',
      amount: 2500,
    },
    {
      category: 'Закупка продуктов',
      description: 'Дозакупка, поавра не рассчитали',
      date: '11.07.2022',
      created_by: 'Соколов А',
      amount: 1,
    },
  ],
  columns: [
    { title: 'Категория', name: 'category', sortable: true },
    { title: 'Описание', name: 'description' },
    { title: 'Дата', name: 'date', sortable: true },
    { title: 'Добавил', name: 'created_by', sortable: true },
    { title: 'Сумма', name: 'amount', sortable: true },
  ],
}

export const ExpensesDataGrid = ({ data, children, route }) => {
  return (
    <DataGridCompound data={data || demoData} route={route}>
      {children}
    </DataGridCompound>
  )
}

ExpensesDataGrid.Wrapper = TableDataGridWrapper
ExpensesDataGrid.Header = Header
ExpensesDataGrid.Body = Body
ExpensesDataGrid.Footer = DataGridPagination
