import React, { useEffect, useRef } from 'react'
import { useModals } from '$hooks/useModals'
import { ClientEditModal } from '$components/Clients/Modal/Detail/EditModal'
import ClientHeaderBadges from '$components/Clients/Modal/Detail/ClientHeaderBadges'
import { createEvent, createStore } from 'effector'
import { useStore } from 'effector-react'
import { showCloseConfirmModal } from '$components/UI/Modals/CloseConfirmModal'

const changeClientData = createEvent()
const $clientData = createStore(null).on(changeClientData, (_, data) => data)

export const Header = ({
  title,
  client,
  routes,
  saveRoute = undefined,
  routeParams = undefined,
  showEditButton = true,
  showClientBadges = true,
}) => {
  const { openModal, closeModal } = useModals()

  const clientData = useStore($clientData)
  const clientDataRef = useRef(clientData)

  useEffect(() => {
    clientDataRef.current = clientData
  }, [clientData])

  const openEditModal = () => {
    openModal('EditClient', ClientEditModal, {
      client,
      routes,
      saveRoute,
      routeParams,
      onClose: ({ isUserClick }) => {
        if (isUserClick) {
          showCloseConfirmModal(openModal, closeModal)
          return true
        }
      },
      title: client ? 'Редактирование клиента' : 'Новый клиент',
    })
  }

  return (
    <div className="modal-right__header">
      <div className="modal-right__header-top">
        {title && <p className="modal-right__header-name">{title}</p>}
        {showEditButton && (
          <button
            className="modal-right__btn-edit js-modal-edit"
            onClick={openEditModal}
          >
            Редактировать
          </button>
        )}
      </div>

      {showClientBadges && (
        <>
          <ClientHeaderBadges client={client} />
        </>
      )}
    </div>
  )
}
