import { Modal } from '$components/UI/Modals/Modal'
import { ChangeStatusSelect } from '$components/Leads/Statused/Entities/Leads/Modal/ChangeStatus/ChangeStatusSelect'
import { FieldsByStatus } from '$components/Leads/Statused/Entities/Leads/Modal/ChangeStatus/FieldsByStatus'
import { useEffect } from 'react'
import { useModals } from '$hooks/useModals'

export const ChangeStatusComponent = ({
  data,
  statusError,
  setStatusError,
  errors,
  markErrors,
  updateFormData,
  onSubmit,
  modalOptions,
  closeModal,
}) => {
  const { openModal } = useModals()

  useEffect(() => {
    if (statusError) {
      openModal('ChangeLeadStatusError', Modal, {
        title: statusError,
        contentComponent: () => (
          <button
            className="btn btn__filled btn--blue"
            onClick={() => closeModal(false)}
          >
            Закрыть
          </button>
        ),
      })
    }
  }, [statusError])

  if (statusError) return <></>

  return (
    <Modal {...modalOptions} title={'Перевести'}>
      <>
        <ChangeStatusSelect
          status={data.status}
          onError={(e) => {
            setStatusError(e)
          }}
          onChangeStatus={(status) => {
            updateFormData('status', (status && status.code) || null)
          }}
        />
        <FieldsByStatus
          data={data}
          status={data.status}
          errors={errors}
          markErrors={markErrors}
          onChange={updateFormData}
        />
        <div className="leads-page-action-modal__actions">
          <button className="btn btn__filled btn--blue" onClick={onSubmit}>
            Перевести
          </button>
          <button
            className="btn btn__filled btn--default"
            onClick={() => closeModal(false)}
          >
            Закрыть
          </button>
        </div>
      </>
    </Modal>
  )
}
