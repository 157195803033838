import React, { useEffect, useState } from 'react'
import { MainLayout } from '$components/UI/Layouts/MainLayout'
import '$lib/vendor/leaflet/leaflet.draw.css'
import { DeliverySettings } from '$components/Settings/Deliveries'
import { FinanceTabs } from '$components/Settings/FinanceTabs'
import { usePage } from '@inertiajs/react'
import { useDeliveryZones } from '$lib/deliveries/hooks/useDeliveryZones'

export const SettingsDelivery = () => {
  const { delivery_zones, delivery_type_prices = [] } = usePage().props

  const [zoneList, setZoneList] = useState(() =>
    delivery_zones.map((zone, i) => ({ ...zone, iter: i })),
  )

  useEffect(() => {}, [delivery_zones])

  const { zones } = useDeliveryZones(zoneList)

  return (
    <>
      <FinanceTabs />
      <DeliverySettings
        zones={zones}
        delivery_type_prices={delivery_type_prices}
      />
    </>
  )
}

SettingsDelivery.layout = (page) => <MainLayout>{page}</MainLayout>
