import { isActiveTab } from '$components/Settings/Leads/utils/functions'
import { LeadSettingsTab } from '$components/Settings/Leads/utils/const'
import { Toolbar } from '$components/Settings/Leads/ProductsTab/Toolbar'
import { RationTiles } from '$components/Settings/Leads/ProductsTab/RationTiles'

export const ProductsTab = () => {
  if (!isActiveTab(LeadSettingsTab.Products)) return <></>

  return (
    <>
      <Toolbar />
      <RationTiles />
    </>
  )
}
