import React, { useState } from 'react'
import { RolePermissionsRow } from '$components/Settings/Permissions/RolePermissionsRow'
import clsx from 'clsx'
import _ from 'lodash'

export const RolePermissionsGroupRow = ({
  groupName,
  groupPermissions,
  lang,
}) => {
  const title = _.get(lang, groupName)
  const groupHasAccessList = _.isEqual(
    _.keys(groupPermissions).sort(),
    ['read', 'create', 'edit', 'delete'].sort(),
  )

  const [rowIsOpen, setRowIsOpen] = useState(false)

  const rowClickHandler = () => {
    setRowIsOpen(!rowIsOpen)
  }

  return (
    <>
      <tr
        className={clsx('table__accordion--group', { open: rowIsOpen })}
        onClick={rowClickHandler}
      >
        <td colSpan="5">
          <span>{title.main ? title.main : title}</span>
        </td>
      </tr>
      {groupHasAccessList ? (
        <RolePermissionsRow
          title={title.main ? title.main : title}
          permissionName={groupName}
          currentValues={groupPermissions}
          isOpen={rowIsOpen}
        />
      ) : (
        _.map(groupPermissions, (values, key) => (
          <RolePermissionsRow
            key={key}
            permissionName={_.join([groupName, key], '.')}
            title={_.get(lang, _.join([groupName, key], '.'))}
            currentValues={values}
            isOpen={rowIsOpen}
          />
        ))
      )}
    </>
  )
}
