import clsx from 'clsx'
import { useContext, useMemo } from 'react'
import { usePermissions } from '$hooks/usePermissions'
import { router, usePage } from '@inertiajs/react'
import { EffectorContext } from '$root/utils/effector/effector-services'
import {
  InvoicePayableTypePrefix,
  InvoiceStatus,
} from '$lib/invoices/utils/const'

export default ({ actions, isEditPersistent = false }) => {
  const { user } = usePage().props
  const { isAllow } = usePermissions()
  const { form, hoveringEditableService, invoiceService, invoicesService } =
    useContext(EffectorContext)

  const invoice = form.data

  const hasPermsOnEdit = useMemo(() => {
    return isAllow(['clients.edit', 'clients.create'])
  }, [user])

  const setIsEdit = hoveringEditableService.setIsEdit.bind(
    hoveringEditableService,
  )

  const checkOnSaveInvoiceCb = () => {
    if (invoicesService.onSave) {
      invoicesService.onSave()
    }
  }

  const onCancel = ({ route: updateRoute }) => {
    router.post(
      route(updateRoute),
      {
        id: invoice.id,
        status: InvoiceStatus.Canceled,
      },
      {
        onSuccess: checkOnSaveInvoiceCb,
      },
    )
  }

  const onPay = ({ route: updateRoute }) => {
    router.post(
      route(updateRoute),
      {
        id: invoice.id,
        status: InvoiceStatus.Paid,
      },
      {
        onSuccess: checkOnSaveInvoiceCb,
      },
    )
  }

  const allowEdit = invoiceService.allowEdit

  return (
    <div
      className={clsx('invoice-header', {
        'is-edit': hoveringEditableService.store.isEdit,
      })}
      onClick={() => (hasPermsOnEdit || isEditPersistent) && setIsEdit(false)}
    >
      <span className="invoice-header__number">
        {(invoice.id &&
          `${invoice.payable_type && InvoicePayableTypePrefix[invoice.payable_type]} №${invoice.id}`) ||
          'Новый счет клиента'}
      </span>
      <div className="invoice-header__actions">
        {invoiceService.allowEdit &&
          actions &&
          actions({
            onCancel,
            onPay,
          })}

        <div
          className={clsx({
            'invoice-header__actions-edit':
              allowEdit &&
              !invoiceService.isPaid &&
              (!hoveringEditableService.store.isEdit ||
                (!hoveringEditableService.store.isEdit && isEditPersistent)),
            'invoice-header-edit-chevron': hoveringEditableService.store.isEdit,
            open:
              (hasPermsOnEdit &&
                !hoveringEditableService.store.isEdit &&
                hoveringEditableService.store.isEditable) ||
              isEditPersistent,
          })}
          onClick={(ev) => {
            ev.preventDefault()
            ev.stopPropagation()
            setIsEdit(!hoveringEditableService.store.isEdit)
          }}
        />
      </div>
    </div>
  )
}
