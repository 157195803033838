const demoList = [
  { ration_name: 'Fit', take: 13, left: 1 },
  { ration_name: 'Fit', take: 13, left: 1 },
  { ration_name: 'Fit', take: 13, left: 1 },
  { ration_name: 'Fit', take: 13, left: 1 },
  { ration_name: 'Fit', take: 13, left: 1 },
  { ration_name: 'Fit', take: 13, left: 1 },
  { ration_name: 'Fit', take: 13, left: 1 },
  { ration_name: 'Fit', take: 13, left: 1 },
]

export const HowMuchIsLeft = ({ list }) => {
  return (
    <div className="mobile-wrapper__body">
      <div className="mobile-rations-left">
        <table>
          <tr>
            <th>Рацион</th>
            <th>Взял</th>
            <th>Осталось</th>
          </tr>
          {list.map((item, i) => (
            <tr>
              <td>{item.name}</td>
              <td>{item.count_total}</td>
              <td>{item.count_left}</td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  )
}
