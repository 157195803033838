import { DatePickerInput } from '$components/UI/Inputs/DatePickerInput'
import React, { useMemo } from 'react'
import { $dataGrid, dataGridIsChanged } from '$models/dataGrid'
import { useStore } from 'effector-react'
import { useDateTime } from '$hooks/useDateTime'
import { DropdownBtn } from '$components/UI/Inputs/DropdownBtn/DropdownBtn'
import clsx from 'clsx'
import { usePage } from '@inertiajs/react'
import { deferred } from '$root/utils/functions'
import { useModals } from '$hooks/useModals'
import { Modal } from '$components/UI/Modals/Modal'
import { ExpenseModal } from '$components/Reports/Modals/ExpenseModal'

export const ExpensesReportsFilters = () => {
  const { enums = {} } = usePage().props
  const { openModal } = useModals()
  const { currentDate, dateToSaveFormat } = useDateTime()
  const { filter } = useStore($dataGrid)

  const filterWithUrl = useMemo(() => {
    return filter || route().params.filter
  }, [filter])

  const deferredFilter = deferred((options) => {
    dataGridIsChanged({ filter: options.filter })
  })

  const showExpenseDialog = () => {
    openModal('ExpenseDialog', Modal, {
      title: 'Фиксировать расходы',
      contentComponent: ExpenseModal,
    })
  }

  return (
    <div className="deal-head">
      <DropdownBtn
        className={clsx('min-width')}
        // TODO: demo
        items={enums.expenseTypes || []}
        multiple={true}
        value={(filterWithUrl && filterWithUrl.expense_type) || undefined}
        emptyText="Все категории"
        titleKey="name"
        valueKey="id"
        onChange={(selected) => {
          if (selected.length === 1 && !selected[0].id) {
            dataGridIsChanged({
              filter: { ...(filterWithUrl || {}), expense_type: undefined },
            })
            return
          }
          const filtering = selected.map((selectedItem) => selectedItem.id)

          if (_.isArray(filtering) ? filtering.length : selected) {
            deferredFilter(
              {
                filter: { ...(filterWithUrl || {}), expense_type: filtering },
              },
              1.5,
            )
          }
        }}
      />
      <DatePickerInput
        onChange={(date) => {
          if (!date) {
            dataGridIsChanged({ filter: { ...filterWithUrl, date: null } })
          }
          if ((!!date && !date[0]) || !date[1]) {
            return null
          } else {
            dataGridIsChanged({
              filter: {
                ...filterWithUrl,
                date: [
                  dateToSaveFormat(new Date(date[0])),
                  dateToSaveFormat(new Date(date[1])),
                ],
              },
            })
          }
        }}
        showPickerTag={true}
        value={
          (filterWithUrl &&
            filterWithUrl.date && [
              filterWithUrl.date[0],
              filterWithUrl.date[1],
            ]) ||
          null
        }
        multiple={true}
      />
      <button className="deal-head__btn-expenses" onClick={showExpenseDialog}>
        Фиксировать расходы
      </button>
    </div>
  )
}
