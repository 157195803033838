import { DeliveryContactField } from '$components/Leads/Statused/Entities/Deliveries/DataGrid/Fields/DeliveryContactField'
import { DeliveryChatField } from '$components/Leads/Statused/Entities/Deliveries/DataGrid/Fields/DeliveryChatField'
import { DeliveryRationField } from '$components/Leads/Statused/Entities/Deliveries/DataGrid/Fields/DeliveryRationField'
import { DeliveryAmountField } from '$components/Leads/Statused/Entities/Deliveries/DataGrid/Fields/DeliveryAmountField'
import { useDateTime } from '$hooks/useDateTime'

export const ActiveDeliveriesItem = ({ item: delivery, routes }) => {
  const { dateVerbosed } = useDateTime()
  return (
    <>
      <DeliveryContactField delivery={delivery} />

      <div className="deal__freeze deal__freeze--progress">
        <div className="deal__contact-date">
          {dateVerbosed(new Date(delivery.delivery_date))}
        </div>
        <DeliveryChatField delivery={delivery} />
        <DeliveryRationField delivery={delivery} routes={routes} />
        <DeliveryAmountField delivery={delivery} />
      </div>
    </>
  )
}
