import React from 'react'
import clsx from 'clsx'
import { ReactComponent as CloseIcon } from '~svg/icons/icon-interface-close.svg'
import { useModal } from '$hooks/useModal'
import { ModalSize } from '$utils/constants/modals'

export const SideModal = ({
  title,
  size = ModalSize.Base,
  classes,
  contentComponent,
  onClose,
  children,
  ...data
}) => {
  const { show, Header, Content, cls, closeModal } = useModal(
    classes,
    contentComponent,
    onClose,
    children,
    data,
    true,
  )

  const DefaultHeader = !Array.isArray(children) ? (
    <div className="modal-right__header">
      <div className="modal-right__header-top">
        <p className="modal-right__header-name">{title}</p>
      </div>
    </div>
  ) : null

  const ModalSizeClasses = {
    [ModalSize.Small]: {
      wrapper: ['js-modal-right-sm', 'modal-right--sm'],
      overlay: ['js-close-modal-sm'],
      content: ['js-modal-start'],
      body: ['modal-right__body-inner'],
    },
    [ModalSize.Base]: {
      wrapper: ['js-modal-right'],
      overlay: ['js-close-modal'],
      content: ['js-modal-start'],
      body: ['modal-right__body'],
    },
    [ModalSize.Large]: {
      wrapper: ['js-modal-right', 'modal-right', 'modal-right--lg'],
      overlay: ['js-close-modal'],
      content: ['js-modal-start'],
      body: ['modal-right__body'],
    },
    [ModalSize.ExtraLarge]: {
      wrapper: ['modal-right--xl', 'js-modal-right', 'js-modal-right-xl'],
      overlay: ['js-close-modal'],
      content: ['js-modal-start'],
      body: ['modal-right__body'],
    },
  }

  const SizeClasses = ModalSizeClasses[size]

  return (
    <div
      className={clsx('modal-right', [
        ...SizeClasses.wrapper,
        cls.wrapper,
        {
          show: show,
        },
      ])}
    >
      <div
        onClick={() => closeModal(false, { isUserClick: true })}
        className={clsx('modal-right__overlay', [
          ...SizeClasses.overlay,
          cls.overlay,
        ])}
      />

      <div
        className={clsx('modal-right__content', [
          ...SizeClasses.content,
          cls.content,
        ])}
      >
        {(Header && Header) || DefaultHeader}
        <div className="modal-right__body-wrap">
          <div className={clsx([...SizeClasses.body, cls.body])}>{Content}</div>
        </div>
        <button
          className="modal-right__btn-close js-close-modal"
          onClick={() => closeModal(false, { isUserClick: true })}
        >
          <CloseIcon />
          Закрыть
        </button>
      </div>
    </div>
  )
}
