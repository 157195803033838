import React from 'react'
import { TagsDropdown } from '$components/UI/Inputs/TagsDropdown'

export const TagsDropdownInput = ({
  items,
  resetCallback,
  values,
  onChange,
  persistKey = undefined,
  disabled = false,
  titleKey = 'name',
}) => {
  return (
    <div className="modal-right__tags">
      <TagsDropdown
        variants={items ? items : []}
        setParams={[]}
        values={values}
        titleKey="name"
        onChange={(changesItems) => onChange(changesItems)}
        disabled={disabled}
        needEmpty={false}
      >
        {(activeItems) => (
          <>
            {activeItems.map((activeItem, i) => (
              <TagsDropdown.ActiveTagDropdown
                tag={activeItem}
                key={i}
                resetCallback={resetCallback}
                persistKey={(persistKey && `${persistKey}-${i}`) || undefined}
              >
                <TagsDropdown.ActiveTagReplacer item={activeItem} />
              </TagsDropdown.ActiveTagDropdown>
            ))}
            {!disabled && <TagsDropdown.AddNewTag />}
          </>
        )}
      </TagsDropdown>
    </div>
  )
}
