import { useCallback, useMemo } from 'react'
import { ContactType } from '$root/utils/constants/clients'
import { getFirstContactByType } from '$lib/contacts/helpers'
import { useDateTime } from '$hooks/useDateTime'
import { OrderStatus } from '$lib/orders/utils/order-status-const'
import { sortOrdersByDate } from '$lib/orders/helpers/table-functions'
import { formatPhone } from '$root/utils/functions'

export const ClientReport = ({ client, deliveries }) => {
  const { date } = useDateTime()

  const address = useMemo(() => {
    return getFirstContactByType(client, ContactType.Address)
  }, [client])

  const phone = useMemo(() => {
    return getFirstContactByType(client, ContactType.Phone)
  }, [client])

  const sortedDeliveries = useMemo(() => {
    return sortOrdersByDate(_.flatten(_.values(deliveries)))
  }, [deliveries])

  const reportDeliveries = useMemo(() => {
    const reportStatuses = [OrderStatus.Active, OrderStatus.Done]
    return sortedDeliveries.filter(
      (delivery) => reportStatuses.indexOf(delivery.status) > -1,
    )
  }, [deliveries])

  const getDeliveryRations = useCallback(
    (delivery) => {
      const deliveryRations =
        (delivery.rations &&
          delivery.rations.map(
            (ration) => ration.name || ration.ration_name,
          )) ||
        []
      return deliveryRations.map((ration_name, i) => (
        <span key={i}>{ration_name},&nbsp;</span>
      ))
    },
    [deliveries],
  )

  return (
    <div className="clients-report">
      <p className="clients-report__title">{client.full_name}</p>
      <p className="clients-report__address">{address}</p>
      <p className="clients-report__phone">{formatPhone(phone)}</p>
      <table className="table-report">
        <tbody>
          <tr>
            <th>Дата</th>
            <th>Рационы</th>
            <th>Сумма</th>
            {/*<th>Баллы</th>*/}
          </tr>
          {reportDeliveries.map((delivery, i) => (
            <tr key={i}>
              <td>{date(delivery.delivery_date)}</td>
              <td>{getDeliveryRations(delivery)}</td>
              <td>{delivery.discount_price || '-'} ₽</td>
              {/*<td>125</td>*/}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
