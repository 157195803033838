import React from 'react'
import { MainLayout } from '$components/UI/Layouts/MainLayout'
import { RolesTabs } from '$components/Settings/Permissions/Actions/RolesTabs'
import { AddRole } from '$components/Settings/Permissions/Actions/AddRole'
import { RolePermissionsList } from '$components/Settings/Permissions/RolePermissionsList'
import { usePage } from '@inertiajs/react'

export const SettingsPermissions = () => {
  const { role, rolePermissions, enums } = usePage().props

  return (
    <>
      <div className="second-head second-head--less">
        {role && enums.roles.length > 0 ? (
          <RolesTabs currentRole={role} roles={enums.roles} />
        ) : null}
        <AddRole />
      </div>
      {!_.isEmpty(rolePermissions) ? (
        <RolePermissionsList
          role={role}
          rolePermissions={rolePermissions}
          enums={enums}
        />
      ) : null}
    </>
  )
}

SettingsPermissions.layout = (page) => <MainLayout>{page}</MainLayout>
