import React from 'react'
import clsx from 'clsx'
import { useDateTime } from '$hooks/useDateTime'

export const BalanceCard = ({ client }) => {
  const { date } = useDateTime()

  return (
    <div className="modal-right__section">
      <p className="modal-right__title">Баланс</p>
      <div className="modal-right__balance">
        <div className="modal-right__balance-head modal-right__balance-head--detail">
          <p
            className={clsx('modal-right__balance-value', {
              good: client.balance && client.balance > 0,
              bad: typeof client.balance === 'number' && client.balance < 0,
            })}
          >
            {client.balance ? client.balance : 0} ₽
          </p>
          <p className="modal-right__balance-reward">
            {client.bonus_balance ? client.bonus_balance : 0} Бв
          </p>
        </div>
        {/* TODO: client.next_delivery*/}
        <p className="modal-right__balance-delivery">
          Следующая доставка на
          <span>
            {/*TODO: заменить на useDateTime */}
            {(client.next_order_date && date(client.next_order_date)) || '-'}
          </span>
        </p>
      </div>
    </div>
  )
}
