import { ReactComponent as Clients } from '~svg/icons/icon-main-users.svg'
import { ReactComponent as Leads } from '~svg/icons/icon-main-deals.svg'
import { ReactComponent as Deliveries } from '~svg/icons/icon-main-delivery.svg'
import { ReactComponent as Kitchen } from '~svg/icons/icon-main-kitchen.svg'
import { ReactComponent as Reports } from '~svg/icons/icon-main-reports.svg'
import { ReactComponent as Settings } from '~svg/icons/icon-main-settings.svg'

export const icons = {
  clients: Clients,
  leads: Leads,
  deliveries: Deliveries,
  kitchen: Kitchen,
  reports: Reports,
  settings: Settings,
}
