import { DropdownBtn } from '$components/UI/Inputs/DropdownBtn/DropdownBtn'
import { Label } from '$components/UI/Inputs/Label'
import { $checkedRations } from '$components/Settings/Leads/ProductsTab/models'
import { useForm } from '@inertiajs/react'
import { useFormValidation } from '$hooks/useFormValidation'
import { useStore } from 'effector-react'
import { router } from '@inertiajs/react'
import { Routes } from '$root/utils/constants/routes'
import { useModals } from '$hooks/useModals'
import { MessageModal } from '$components/UI/Modals/MessageModal'

export const LinkingProductToRation = ({ products, multipliers }) => {
  const checkedRations = useStore($checkedRations)
  const { openModal, closeModal } = useModals()

  const { data, setData, post, errors, setError, clearErrors } = useForm({})

  const onCheckIsValid = () => {
    let isValid = true

    if (!data.product_id) {
      setError('product', 'Нужно заполнить товар')
      isValid = false
    }

    if (!data.multiplier_id) {
      setError('multiplier', 'Нужно выбрать порцию')
      isValid = false
    }

    return isValid
  }

  const [validate, markErrors] = useFormValidation(
    `linking-ration-${checkedRations.map((ration) => ration.id).join(',')}-products`,
    onCheckIsValid,
    data,
    errors,
    clearErrors,
  )

  const onSubmit = () => {
    if (validate()) {
      const formData = []
      for (const checkedRation of checkedRations) {
        formData.push({
          ration_id: checkedRation.id,
          product_id: data.product_id,
          multiplier_id: data.multiplier_id,
        })
      }

      if (formData.length) {
        router.post(
          route(Routes.settings.leads.rations.link_product_to_ration),
          {
            rations: formData,
          },
          {
            onSuccess() {
              closeModal()
            },
          },
        )
      }
    }
  }

  return (
    <>
      <Label text="Товар" />
      <DropdownBtn
        items={products}
        value={data.product_id}
        onChange={(product) => setData('product_id', product.id)}
        error={markErrors && errors.product}
      />
      <Label text="Порция" />
      <DropdownBtn
        items={multipliers}
        value={data.multiplier_id}
        onChange={(multiplier) => setData('multiplier_id', multiplier.id)}
        error={markErrors && errors.multiplier}
      />
      <button className="btn btn__filled btn--blue" onClick={onSubmit}>
        Сохранить
      </button>
    </>
  )
}
