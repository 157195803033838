import { ClientViewModal } from '$components/Clients/Modal/Detail/ViewModal'
import { router, usePage } from '@inertiajs/react'
import { ClientOrdersModal } from '$components/Clients/Modal/ClientOrdersModal'
import { useEffect } from 'react'
import { useModals } from '$hooks/useModals'
import { SideModal } from '$components/UI/Modals/SideModal'
import { TransactionHistory } from '$components/Clients/TransactionHistory'
import { ClientEditModal } from '$components/Clients/Modal/Detail/EditModal'
import { setClientRoutes } from '$lib/orders/models/orders-store'
import InvoicesModal from '$components/Clients/Invoices/Modal/InvoicesModal'

export const useClientModals = (
  routes,
  client = null,
  orders = null,
  transactions = null,
  invoices = null,
  detailModalProps = {},
  ordersModalProps = {},
  transactionsModalProps = {},
) => {
  const { openModal } = useModals()
  const { errors } = usePage().props

  const checkClientModals = () => {
    if (client) {
      openModal(
        'ViewEditClient',
        (client.id && ClientViewModal) || ClientEditModal,
        {
          client,
          routes,
          ...detailModalProps,
          onClose() {
            let closeParams = route().params
            if (closeParams.id) {
              delete closeParams['id']
            }
            router.get(route(routes.list, closeParams))
          },
        },
      )
    }

    if (orders) {
      openModal('ClientOrdersModal', ClientOrdersModal, {
        client,
        routes,
        ...ordersModalProps,
        orders,
      })
    }

    if (transactions) {
      openModal('TransactionsHistory', SideModal, {
        contentComponent: TransactionHistory,
        title:
          client.full_name ||
          `${client.last_name} ${client.name} ${client.second_name}`,
        routes,
        classes: { wrapper: client.balance >= 0 ? 'good' : 'bad' },
        client,
        transactions,
        ...transactionsModalProps,
        onClose() {
          router.get(route(routes.view, { id: client.id, ...route().params }))
        },
      })
    }

    if (invoices && route().current() === routes.invoices.list) {
      openModal('Invoices', InvoicesModal, {
        invoices,
        routes,
        ...ordersModalProps,
      })
    }
  }

  useEffect(() => {
    if (routes) {
      setClientRoutes(routes)
    }
  }, [routes])

  useEffect(() => {
    if (errors && _.keys(errors).length > 0) {
      return
    }
    checkClientModals()
  }, [
    client,
    orders,
    routes,
    detailModalProps,
    route().params,
    transactions,
    invoices,
    errors,
  ])
}
