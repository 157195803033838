import { DropdownBtn } from '$components/UI/Inputs/DropdownBtn/DropdownBtn'
import { TimeInput } from '$components/UI/Inputs/TimeInput'
import React from 'react'
import { usePage } from '@inertiajs/react'

export const DeliveryInfo = ({ client }) => {
  const { enums } = usePage().props

  return (
    <>
      <p className="modal-right__title">Комментарий курьеру</p>
      {client.delivery_info && client.delivery_info.comment && (
        <div className="modal-right__text">
          <p>
            {(client.delivery_info && client.delivery_info.comment) || '------'}
          </p>
        </div>
      )}
      <div className="modal-right__container">
        <DropdownBtn
          disabled={true}
          items={enums.deliveryTypes}
          titleKey="value"
          valueKey="key"
          value={client.delivery_info && client.delivery_info.delivery_type}
        />
        <TimeInput
          disabled={true}
          placeholder="Время не заполнено"
          value={client.delivery_info ? client.delivery_info.time : 0}
        />
      </div>
    </>
  )
}
