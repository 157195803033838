import React from 'react'
import { Body } from './Body'
import { Footer } from './Footer'
import { Routes } from '$utils/constants/routes'
import { useDataGrid } from '$hooks/useDataGrid'

export const List = ({ data }) => {
  useDataGrid(data, Routes.clients.list)

  return (
    <section className="clients datagrid">
      <Body data={data} />
      <Footer data={data} />
    </section>
  )
}
