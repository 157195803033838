import { useForm } from '@inertiajs/react'

import { Routes } from '$root/utils/constants/routes'
import { useFormValidation } from '$hooks/useFormValidation'
import { useModals } from '$hooks/useModals'

import { Modal } from '$components/UI/Modals/Modal'
import { Input } from '$components/UI/Inputs/Input'
import { DropdownBtn } from '$components/UI/Inputs/DropdownBtn/DropdownBtn'

export const CreateUpdateRationModal = (props) => {
  const { multipliers = [], ration = null } = props
  const { closeModal } = useModals()
  const { data, setData, post, errors, setError, clearErrors } = useForm(
    (ration && ration) || {
      name: null,
      price: null,
      multiplier_id: null,
    },
  )

  const onCheckIsValid = () => {
    let isValid = true

    if (!data.name) {
      setError('name', 'Название - обязательное поле')
      isValid = false
    }

    if (!data.price) {
      setError('price', 'Цена - обязательное поле')
      isValid = false
    }

    if (!data.multiplier_id) {
      setError('multiplier_id', 'Множитель - обязательное поле')
      isValid = false
    }

    return isValid
  }

  const [validate, markErrors] = useFormValidation(
    `update-ration-${(ration && ration.id) || 'new'}`,
    onCheckIsValid,
    data,
    errors,
    clearErrors,
  )

  const onSubmit = (e) => {
    e.preventDefault()

    if (validate()) {
      post(route(Routes.settings.leads.rations.store_ration), {
        onSuccess() {
          closeModal()
        },
      })
    }
  }

  return (
    <Modal {...props}>
      <>
        <div className="modal__row">
          <Input
            placeholder="Название"
            classes="mb-1"
            value={data.name}
            onChange={(ev) => setData('name', ev.target.value)}
            error={markErrors && !!errors && errors.name}
          />
          <Input
            placeholder="Цена"
            classes="mb-1"
            value={data.price}
            onChange={(ev) => setData('price', _.toNumber(ev.target.value))}
            error={markErrors && !!errors && errors.price}
          />
          <DropdownBtn
            items={multipliers}
            value={data.multiplier_id}
            onChange={(multiplier) =>
              setData('multiplier_id', (multiplier && multiplier.id) || null)
            }
            error={markErrors && errors.multiplier_id}
          />
        </div>
        <div className="modal__row">
          <button className="btn btn__filled btn--green" onClick={onSubmit}>
            Сохранить
          </button>
        </div>
      </>
    </Modal>
  )
}
