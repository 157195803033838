import { UsersFilter } from './Actions/UsersFilter'
import { AddUser } from './Actions/AddUser'
import React from 'react'
import { usePage } from '@inertiajs/react'

export const UserListHeader = ({ data }) => {
  const { enums } = usePage().props

  return (
    <div className="second-head no-border">
      <UsersFilter filter={data.filter} roles={enums.roles} />
      <AddUser />
    </div>
  )
}
