export const ProductMultiplier = ({
  rationID,
  productID,
  multiplierID,
  checked,
  onSelect,
}) => {
  return (
    <td>
      <label className="input-radio flex align-items-center justify-center">
        <input
          name={`${rationID}-${productID}`}
          type="radio"
          checked={checked}
          onChange={() => onSelect(multiplierID)}
        />
        <span></span>
      </label>
    </td>
  )
}
