import { usePage } from '@inertiajs/react'
import { router } from '@inertiajs/react'
import { Routes } from '$root/utils/constants/routes'
import { unhandledError } from '$root/utils/functions'
import { TabCode } from '$lib/leads/statused/public/tab-codes.const'
import { useEffect } from 'react'
import { onPostSended } from '$models/page'

export const useLeads = () => {
  const { enums } = usePage().props

  const newClientInfoByLead = (lead) => {
    const contact =
      (!_.isEmpty(lead.contact) &&
        enums.contactTypes.find(
          (contact) => contact.id === lead.contact_type_id,
        )) ||
      false
    return {
      last_name: lead.last_name,
      name: lead.name,
      second_name: lead.second_name,
      contacts: contact && [
        {
          value: lead.contact,
          contact_type_code: contact.code,
          contact_type_id: contact.id,
        },
      ],
    }
  }

  const handleProgress = (lead, data) => {
    const ifHasClient = (lead, status) => {
      router.post(
        route(Routes.leads.update, {
          id: lead.id,
          status,
        }),
        data,
      )
    }

    const ifNewClient = (lead, status, data) => {
      router.get(
        route(Routes.leads.clients.save, {
          id: lead.id,
          status,
        }),
        data,
      )
    }

    switch (lead.status) {
      case TabCode.new:
      case TabCode.pending:
        if (lead.client) {
          handleUpdate(lead, data)
        } else {
          ifNewClient(lead, data.status, data)
        }
        break
    }
  }

  const handleUpdate = (lead, data) => {
    if (!enums.tabs) {
      unhandledError()
      return
    }
    const status = data.status ? data.status : lead.status
    delete data.status

    const statusID = status

    if (!statusID) {
      unhandledError()
      return
    }

    const sendingData = {
      ...data,
      id: lead.id,
      status,
    }

    router.post(
      route(Routes.leads.update, {
        status: status,
        id: lead.id,
      }) + (statusID === lead.status_id ? location.search : ''),
      sendingData,
    )
  }

  const update = (lead, data) => {
    switch (data.status) {
      case TabCode.active:
        handleProgress(lead, data)
        break
      default:
        handleUpdate(lead, data)
        break
    }
  }

  useEffect(() => {
    const unsubscribe = onPostSended.watch((props = {}) => {
      if (props) {
        const {
          detail: client,
          isNew,
          clientRoutes,
          routeParams,
          openDeliveryPlanOnNew,
        } = props
        if (isNew && openDeliveryPlanOnNew) {
          router.get(
            route(clientRoutes.orders.list, {
              id: client.id,
              status: routeParams.status,
            }),
          )
        }
      }
    })

    return () => unsubscribe()
  }, [])

  return {
    newClientInfoByLead,
    update,
  }
}
