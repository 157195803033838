import { ErrorLayout } from '$components/UI/Layouts/ErrorLayout'
import { Logo } from '$components/UI/Sidebar/Logo'
import { router } from '@inertiajs/react'
import { usePageErrors } from '$hooks/usePageErrors'

export const Error = ({ status }) => {
  usePageErrors()

  const goToMainPage = () => {
    router.get('/')
  }

  const title = {
    503: '503: Сайт временно отключен для проведения технических работ',
    500: '500: Упс, что-то пошло не так...',
    404: '404: Страница не найдена',
    403: '403: Доступ запрещен',
  }[status]

  const description = {
    503: '',
    500: 'Напишите нам о том, что случилось, это ускорит решение проблемы: info@hands.center',
    404: '',
    403: 'Доступ к этой странице ограничен',
  }[status]

  return (
    <div className="flex justify-center align-items-center flex-column">
      <Logo onClick={goToMainPage} />
      <h1>{title}</h1>
      <div>{description}</div>
    </div>
  )
}

Error.layout = (page) => <ErrorLayout>{page}</ErrorLayout>
