import { EditControl } from 'react-leaflet-draw'
import { FeatureGroup, Polygon, Tooltip, useMap } from 'react-leaflet'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { polylabel } from '$lib/vendor/polylabel'
import {
  $selectedZoneID,
  deliveryZoneSelected,
} from '$lib/deliveries/models/zones'
import { useStore } from 'effector-react'
import { setMapCenter } from '$lib/osm/models/map'

export const DeliveryZoneLayer = ({
  zone,
  onZoneEdited,
  allowEdit = false,
  allowSelect = true,
}) => {
  const selectedZoneID = useStore($selectedZoneID)
  const mapLabel = useRef()
  const polygon = useRef()
  const map = useMap()

  const zoneID = useMemo(() => {
    return zone.properties.id
  }, [zone])
  const zoneIDRef = useRef(zoneID)

  const color = useMemo(() => {
    return selectedZoneID === zoneID ? '#5B14B6' : zone.properties.color
  }, [zone, selectedZoneID])

  const coordinates = zone.geometry.coordinates

  const editing = (selectedZoneID && selectedZoneID === zoneID) || false

  const showEditPanel = editing && allowEdit

  const editRules = useMemo(() => {
    return {
      remove: false,
      edit: editing && allowEdit ? { edit: true } : false,
    }
  }, [selectedZoneID])

  const _getLabelPosition = useCallback(
    (layer, reverse = false) => {
      var position = polylabel(layer.toGeoJSON().geometry.coordinates, 1.0)
      const [lng, lat] = position
      return !reverse ? [lat, lng] : [lng, lat]
    },
    [polygon],
  )

  const drawLabel = (layer) => {
    const [lng, lat] = _getLabelPosition(layer, true)
    const span = document.createElement('span')
    span.innerHTML = `<span
                               className="zone-label"
                               style="text-shadow: ${layer.properties.color} 1px 1px 1px;"
                          >
                               <strong>${layer.properties.name}</strong>
                          </span>`

    var labelTitle = new L.LabelOverlay(
      [lat, lng],
      span.innerHTML,
      layer.properties,
    )
    map.addLayer(labelTitle)
    if (mapLabel.current) {
      mapLabel.current.remove()
    }
    mapLabel.current = labelTitle
  }

  const initProperties = () => {
    polygon.current.properties = zone.properties
  }

  const subscribeEvents = () => {
    if (allowSelect) {
      polygon.current.on('click', () => deliveryZoneSelected(zoneID))
    }
  }

  const onStopEdit = (ev) => {
    ev.layers.eachLayer((layer) => {
      if (!layer.toGeoJSON || layer.properties.id !== zoneID) return
      const geoObject = layer.toGeoJSON()
      onZoneEdited({ id: zoneID, geoObject, ...layer.properties })
    })
  }

  useEffect(() => {
    if (polygon.current) {
      initProperties()
      drawLabel(polygon.current)
      subscribeEvents()
    }
  }, [polygon.current])

  useEffect(() => {
    if (map) {
      map.on('draw:edited', onStopEdit)
    }
  }, [map])

  useEffect(() => {
    if (mapLabel.current) {
      mapLabel.current.setName(zone.properties.name)
    }
  }, [zone.properties.name])

  useEffect(() => {
    const unsubscribe = deliveryZoneSelected.watch((id) => {
      if (id === zoneIDRef.current) {
        setMapCenter({ coordinates: _getLabelPosition(polygon.current) })
      }
    })
    return () => {
      // mapLabel.current.remove()
      unsubscribe()
    }
  }, [])

  return (
    <FeatureGroup key={JSON.stringify(coordinates)}>
      {showEditPanel && (
        <EditControl
          position="topright"
          edit={editRules}
          draw={{
            marker: false,
            circle: false,
            rectangle: false,
            circlemarker: false,
            polygon: false,
            polyline: false,
          }}
        ></EditControl>
      )}
      <Polygon ref={polygon} pathOptions={{ color }} positions={coordinates}>
        <Tooltip sticky>{zone.properties.name}</Tooltip>
      </Polygon>
    </FeatureGroup>
  )
}
