import React from 'react'
import { useModals } from '$hooks/useModals'
import { EditUserModal } from '$components/Settings/Users/Modal/EditUser'
import { showCloseConfirmModal } from '$components/UI/Modals/CloseConfirmModal'

export const AddUser = () => {
  const { openModal, closeModal } = useModals()

  const clickButtonHandler = () => {
    openModal('NewUser', EditUserModal, {
      classes: { wrapper: 'modal--no-overflow' },
      onClose: ({ isUserClick }) => {
        if (isUserClick) {
          showCloseConfirmModal(openModal, closeModal)
          return true
        }
      },
    })
  }

  return (
    <div className="second-head__inner">
      <button className="header__btn-add" onClick={clickButtonHandler}>
        Добавить сотрудника
      </button>
    </div>
  )
}
