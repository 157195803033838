import { EffectorContext } from '$root/utils/effector/effector-services'

const EffectorContextProvider = ({ children, services = {}, value = {} }) => {
  return (
    <EffectorContext.Consumer>
      {(parentServices) => {
        return (
          <EffectorContext.Provider
            value={{ ...parentServices, ...services, ...value }}
          >
            {children}
          </EffectorContext.Provider>
        )
      }}
    </EffectorContext.Consumer>
  )
}

export default EffectorContextProvider
