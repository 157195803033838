import { usePage } from '@inertiajs/react'
import clsx from 'clsx'
import { useMemo } from 'react'

const ClientStatusColor = {
  active: 'green',
  prolong: 'orange',
  frozen: 'yellow',
  archive: 'gray',
}

const ClientTypeBadge = ({ client }) => {
  const { enums } = usePage().props

  return <Badge>{client.type && enums.clientTypes[client.type]}</Badge>
}

const StatusBadge = ({ client }) => {
  const { enums } = usePage().props

  return (
    <Badge>
      <div className="badge-title">Статус:</div>
      <div className="badge-colored-value">
        <span
          className={clsx({
            [(client.status in ClientStatusColor &&
              ClientStatusColor[client.status]) ||
            'no-color']: true,
          })}
        >
          {client.status && enums.clientStatuses[client.status]}
        </span>
      </div>
    </Badge>
  )
}

const DeliveryTypeBadge = ({ client }) => {
  const { enums } = usePage().props

  const deliveryTypeName = useMemo(() => {
    if (
      client.delivery_info &&
      client.delivery_info.delivery_type &&
      enums.deliveryTypes
    ) {
      return enums.deliveryTypes[client.delivery_info.delivery_type]
    }
    return 'Не выбрано'
  }, [client, enums.deliveryTypes])

  return (
    <Badge>
      <div className="badge-title">Тип доставки:</div>
      <span>{deliveryTypeName}</span>
    </Badge>
  )
}

const Badge = ({ children }) => {
  return <div className="badge-item clients-badge-item">{children}</div>
}

const ClientHeaderBadges = ({ client }) => {
  return (
    <div className="badges-wrapper">
      {client.type && <ClientTypeBadge client={client} />}
      {client.status && <StatusBadge client={client} />}
      <DeliveryTypeBadge client={client} />
    </div>
  )
}

export default ClientHeaderBadges
