import { Modal } from '$components/UI/Modals/Modal'

const ModalContent = ({ messages, closeModal }) => {
  return (
    <>
      {(messages && Array.isArray(messages) && (
        <div className="modal__row">
          {Object.values(messages).map((message, i) => (
            <p key={i}>{message}</p>
          ))}
        </div>
      )) ||
        (messages && !Array.isArray(messages) && <p>{messages}</p>)}
      <div className="modal__row">
        <button
          className="btn btn__filled btn--blue"
          onClick={() => {
            closeModal(false)
          }}
        >
          Закрыть
        </button>
      </div>
    </>
  )
}

export const MessageModal = (props) => {
  return (
    <Modal {...props}>
      <ModalContent {...props} />
    </Modal>
  )
}
