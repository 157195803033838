import { EffectorDomainLogic } from '$root/utils/effector/domain-logic'
import { useStore } from 'effector-react'
import { createEvent, createStore, sample } from 'effector'
import { StaffTransactionType } from '$lib/staff/const'

export class StaffTransactionEffector extends EffectorDomainLogic {
  user = null
  _transaction_types = {}
  _transaction_currencies = {}

  $date = null
  $payroll = null
  $currency = null
  $count = null
  $additionalAmount = null
  $comment = null
  $transactionType = null

  events = {
    changeCurrency: null,
    changeCount: null,
    changeDate: null,
    changeAdditionalAmount: null,
    changeTransactionType: null,
    changeComment: null,
    changeSalary: null,
    changeAward: null,
    onChange: null,
  }

  constructor({ user, transaction_types, transaction_currencies, onChange }) {
    super()
    this.user = user
    this._transaction_types = transaction_types
    this._transaction_currencies = transaction_currencies
    this.onChange = onChange
  }

  identify() {
    return `staff-transaction-${this.user.id}`
  }

  _defaultCurrency() {
    const userRole = this.getUserRole()
    const userRoleID = (userRole && userRole.id) || null

    if (userRoleID) {
      return this._transaction_currencies[userRoleID]
    }
  }

  _defaultTransactionType() {
    if (this._transaction_types) {
      return this._transaction_types[_.first(_.keys(this._transaction_types))]
    }
  }

  _initPayrollStore() {
    this.$payroll = this._domain.store('payroll', 0)

    this.events.changeSalary = createEvent()
    this.events.changeAward = createEvent()

    this.$salaryTransaction = this._domain
      .store('salaryTransaction', null)
      .on(this.events.changeSalary, (state, salary) => salary)

    this.$awardTransaction = this._domain
      .store('awardTransaction', null)
      .on(this.events.changeAward, (state, award) => award)

    this.events.onChange = createEvent()
    this.events.onChange.watch((transaction) => {
      if (this.onChange) {
        this.onChange(transaction)
      }
    })
  }

  _initCurrencyStore() {
    this.events.changeCurrency = createEvent()
    this.$currency = this._domain
      .store('currency', this._defaultCurrency())
      .on(this.events.changeCurrency, (state, currency) => currency)
  }

  _initCountStore() {
    this.events.changeCount = createEvent()
    this.$count = this._domain
      .store('count', 0)
      .on(
        this.events.changeCount,
        (state, count) => (!isNaN(count) && parseFloat(count)) || 0,
      )
  }

  _initAdditionalAmountStore() {
    this.events.changeAdditionalAmount = createEvent()
    this.$additionalAmount = this._domain
      .store('additionalAmount', 0)
      .on(
        this.events.changeAdditionalAmount,
        (state, count) => (!isNaN(count) && parseFloat(count)) || 0,
      )
  }

  _initCommentStore() {
    this.events.changeComment = createEvent()
    this.$comment = this._domain
      .store('comment', '')
      .on(this.events.changeComment, (state, comment) => comment)
  }

  _initDateStore() {
    this.events.changeDate = createEvent()
    this.$date = this._domain
      .store('date', new Date())
      .on(this.events.changeDate, (state, date) => date)
  }

  _initTransactionTypeStore() {
    this.events.changeTransactionType = createEvent()
    this.$transactionType = this._domain
      .store('transactionType', this._defaultTransactionType())
      .on(this.events.changeTransactionType, (state, payroll) => payroll)
  }

  _initSamples() {
    sample({
      clock: [
        this.$count,
        this.$currency,
        this.$additionalAmount,
        this.$transactionType,
      ],
      source: [
        this.$count,
        this.$currency,
        this.$additionalAmount,
        this.$transactionType,
      ],
      fn: ([count, currency, additionalAmount, transactionType]) => {
        return (
          currency.amount * ((!isNaN(count) && count) || 0) +
          ((!isNaN(additionalAmount) && additionalAmount) || 0) *
            transactionType.multiplier
        )
      },
      target: this.$payroll,
    })

    sample({
      clock: [this.$count, this.$currency, this.$date],
      source: [this.$count, this.$currency, this.$date],
      fn: ([count, currency, date]) => {
        const amount = currency.amount * ((!isNaN(count) && count) || 0)

        return {
          user_id: this.user.id,
          type: StaffTransactionType.Salary,
          amount,
          date,
        }
      },
      target: this.$salaryTransaction,
    })

    sample({
      clock: [
        this.$additionalAmount,
        this.$transactionType,
        this.$comment,
        this.$currency,
        this.$date,
      ],
      source: [
        this.$additionalAmount,
        this.$transactionType,
        this.$comment,
        this.$currency,
        this.$date,
      ],
      fn: ([additionalAmount, transactionType, comment, currency, date]) => {
        const amount =
          ((!isNaN(additionalAmount) && additionalAmount) || 0) *
          transactionType.multiplier
        return {
          user_id: this.user.id,
          type:
            amount > 0
              ? StaffTransactionType.Award
              : StaffTransactionType.Penalty,
          amount,
          comment,
          date,
        }
      },
      target: this.$awardTransaction,
    })

    sample({
      clock: [this.$salaryTransaction, this.$awardTransaction],
      source: [this.$salaryTransaction, this.$awardTransaction],
      fn: ([salaryTransaction, awardTransaction]) => {
        const transactions = []

        if (salaryTransaction) transactions.push(salaryTransaction)
        if (awardTransaction) transactions.push(awardTransaction)

        return transactions
      },
      target: this.events.onChange,
    })
  }

  _initOpenToggle() {
    this.events.setOpen = createEvent()
    this.$isOpen = createStore(false).on(
      this.events.setOpen,
      (state, isOpen) => isOpen,
    )
  }

  init() {
    this._initPayrollStore()
    this._initCurrencyStore()
    this._initCountStore()
    this._initAdditionalAmountStore()
    this._initCommentStore()
    this._initDateStore()
    this._initTransactionTypeStore()
    this._initSamples()
    this._initOpenToggle()
  }

  setOpen(newIsOpen) {
    this.events.setOpen(newIsOpen)
  }

  usePayrollStore() {
    return useStore(this.$payroll)
  }

  useCurrencyStore() {
    return useStore(this.$currency)
  }

  useCountStore() {
    return useStore(this.$count)
  }

  useAdditionalAmountStore() {
    return useStore(this.$additionalAmount)
  }

  useCommentStore() {
    return useStore(this.$comment)
  }

  useDateStore() {
    return useStore(this.$date)
  }

  useTransactionTypeStore() {
    return useStore(this.$transactionType)
  }

  changeCurrency(currencyEnum) {
    this.events.changeCurrency(currencyEnum.value)
  }

  changeCount(count) {
    this.events.changeCount(count)
  }

  changeTransactionType(transactionTypeEnum) {
    this.events.changeTransactionType(transactionTypeEnum.value)
  }

  changeAdditionalAmount(amount) {
    this.events.changeAdditionalAmount(amount)
  }

  changeComment(amount) {
    this.events.changeComment(amount)
  }

  changeDate(date) {
    this.events.changeDate(new Date(date))
  }

  getIsOpen() {
    return this.isOpen
  }

  useToggle() {
    this.isOpen = useStore(this.$isOpen)

    return {
      isOpen: this.isOpen,
      setOpen: this.setOpen.bind(this),
    }
  }

  getRoleTitle() {
    const firstRole = this.getUserRole()
    return (firstRole && firstRole.title) || null
  }

  getUserRole() {
    return this.user.first_role
  }
}
