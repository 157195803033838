import { useModals } from '$hooks/useModals'
import { useForm, usePage } from '@inertiajs/react'
import { useFormValidation } from '$hooks/useFormValidation'
import { Routes } from '$root/utils/constants/routes'
import { Input } from '$components/UI/Inputs/Input'
import { DropdownBtn } from '$components/UI/Inputs/DropdownBtn/DropdownBtn'
import { DatePickerInput } from '$components/UI/Inputs/DatePickerInput'
import { useDateTime } from '$hooks/useDateTime'

export const ExpenseModal = (props) => {
  const { enums } = usePage().props
  const { dateToSaveFormat } = useDateTime()

  const { closeModal } = useModals()

  const initialData = {
    expense_type_id: null,
    date: new Date(),
    amount: 0,
    comment: null,
  }
  const { data, transform, setData, post, errors, setError, clearErrors } =
    useForm(initialData)

  transform((sendingData) => {
    return { ...sendingData, date: dateToSaveFormat(sendingData.date) }
  })
  const onCheckIsValid = () => {
    let isValid = true

    if (!data.expense_type_id) {
      setError('expense_type_id', 'Не выбрана статься расходов')
      isValid = false
    }

    if (!data.amount) {
      setError('amount', 'Не указана сумма')
      isValid = false
    }

    return isValid
  }

  const [validate, markErrors] = useFormValidation(
    `add-expense`,
    onCheckIsValid,
    data,
    errors,
    clearErrors,
  )

  const onSubmit = (e) => {
    if (validate()) {
      post(route(Routes.reports.expenses.new), {
        onSuccess() {
          setData(initialData)
          closeModal()
        },
      })
    }
  }

  return (
    <>
      <DropdownBtn
        items={enums.expenseTypes}
        titleKey="name"
        valueKey="id"
        value={data.expense_type_id}
        onChange={(selected) => {
          setData('expense_type_id', selected.id)
        }}
        error={markErrors && errors.expense_type_id}
      />
      <Input
        value={data.amount}
        placeholder="Сумма"
        onChange={(ev) => setData('amount', parseFloat(ev.target.value))}
        error={markErrors && errors.amount}
      />
      <div className="modal__row">
        <DatePickerInput
          value={data.date}
          inline={true}
          onChange={(date) => setData('date', date)}
        />
      </div>
      <div className="modal__row">
        <Input
          value={data.comment}
          placeholder="Комментарий"
          onChange={(ev) => setData('comment', ev.target.value)}
        />
      </div>
      <div className="modal__row">
        <button className="btn btn__filled btn--blue" onClick={onSubmit}>
          Сохранить
        </button>
      </div>
    </>
  )
}
