import React, { useState } from 'react'
import { Routes } from '$root/utils/constants/routes'
import { router } from '@inertiajs/react'
import clsx from 'clsx'

export const ReportsSubTabs = ({ tabs: initialTabs }) => {
  const [tabs] = useState(initialTabs)

  const goTab = (tab) => {
    const newRoute = route(tab.route, (!!tab.demo && { is_invoice: 1 }) || {})

    if (route(route().current()) !== newRoute) {
      router.get(newRoute)
    }
  }

  return (
    <div className="contact-within">
      {tabs.map((tab, i) => (
        <button
          className={clsx('contact-within__date no-remove-icon', {
            active: route().current() === tab.route,
          })}
          key={i}
          onClick={() => goTab(tab)}
        >
          {tab.title}
        </button>
      ))}
    </div>
  )
}
