import React from 'react'

export const EffectorContext = React.createContext({ services: {} })

export class EffectorStore {
  persistKey = null
  parent = null
  services = {}

  constructor({ persistKey = null, parent = null } = {}) {
    this.persistKey = persistKey
    this.parent = parent
  }

  persist(recursive = false) {
    if (!recursive && this._initServices) {
      this._initServices()
    }
    if (!this.persistKey) {
      this.persistKey = this._initPersist()
    }

    return `${(this.parent && `${this.parent.persist(recursive)}-`) || ''}${this.persistKey}`
  }

  _reset() {}

  reset() {
    if (this._reset) {
      this._reset()
    }
    if (this.parent) {
      this.parent.reset()
    }
  }

  _initServices() {}

  _initPersist() {
    throw new Error('Initializing persist key does not implemented')
  }

  addService(name, store) {
    let service = this.services[name]
    if (!service) {
      this.services[name] = store
    }
  }

  setOptions(options) {
    for (const k in options) {
      this[k] = options[k]
    }
  }
}

class _EffectorServices {
  map = new Map()

  getService({ service, options, recursive = false }) {
    if (!this.map.get(service.persist(recursive))) {
      if (service.init) {
        service.init(options)
      }
      this.map.set(service.persist(recursive), service)
    } else {
      service = this.map.get(service.persist(recursive))
    }
    return service
  }
}

export const EffectorServices = new _EffectorServices()
