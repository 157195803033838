import { useEffectorDomain } from '$hooks/useEffectorDomain'
import { ProductMultipliersDomain } from '$lib/rations/effector/product-multipliers/product-multipliers-domain'
import { useEffect } from 'react'

export const useProductMultipliers = (ration, product, multipliers) => {
  const { service: productMultipliersService } = useEffectorDomain(
    new ProductMultipliersDomain({
      ration,
      product,
      multipliers,
    }),
  )

  productMultipliersService.useStores()

  const onSelectMultiplier = (multiplierID) => {
    productMultipliersService.onSelectMultiplier(multiplierID)
  }

  useEffect(() => {
    productMultipliersService.onProductUpdate(product)
  }, [product])

  return {
    isChecked: productMultipliersService.isChecked.bind(
      productMultipliersService,
    ),
    onSelect: onSelectMultiplier,
  }
}
