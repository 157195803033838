import { useModals } from '$hooks/useModals'
import { TabCode } from '$lib/leads/statused/public/tab-codes.const'
import { LeadsChangeStatusModal } from '$components/Leads/Statused/Entities/Leads/Modal/ChangeStatus'

export const LeadChangeStatusAction = ({ lead }) => {
  const { openModal } = useModals()

  const showChangeStatusModal = () => {
    openModal('ChangeLeadStatus', LeadsChangeStatusModal, {
      lead,
      tabCodes: TabCode,
    })
  }

  return (
    <button className="deal__btn-transfer" onClick={showChangeStatusModal}>
      Перевести
    </button>
  )
}
