import { Input } from '$components/UI/Inputs/Input'
import React, { useMemo } from 'react'

export const ContactByType = ({
  placeholder = 'Контакт',
  contacts,
  className,
  contactType,
  onChange,
  ...other
}) => {
  const contact = useMemo(
    () => ({
      contact_type_code: contactType,
      ...contacts.find((contact) => contact.contact_type_code === contactType),
    }),
    [contacts],
  )

  return (
    <Input
      type="text"
      placeholder={placeholder}
      classes={className}
      value={contact.value || ''}
      onChange={(ev) => onChange({ ...contact, value: ev.target.value })}
      {...other}
    />
  )
}
