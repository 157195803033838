export const OrderStatus = {
  Active: 'active',
  Inactive: 'disabled',
  Canceled: 'canceled',
  Done: 'completed',
  Problem: 'problem',
  Default: '*',
}

export const OrderStatusVerbosed = {
  [OrderStatus.Active]: 'Активная',
  [OrderStatus.Inactive]: 'Не активная',
  [OrderStatus.Canceled]: 'Отменена',
  [OrderStatus.Done]: 'Выполнена',
  [OrderStatus.Problem]: 'Проблема',
  [OrderStatus.Default]: 'Неизвестно',
}
