import clsx from 'clsx'

export const DeliverySettingsColumn = ({
  children,
  isLeft = false,
  isRight = false,
}) => {
  return (
    <div
      className={clsx({
        'delivery__col-map': isLeft,
        'delivery__col-info': isRight,
      })}
    >
      {children}
    </div>
  )
}
