import React from 'react'
import { useForm } from '@inertiajs/react'
import { Routes } from '$utils/constants/routes'

export const AddRoleForm = ({ closeModal }) => {
  const { data, setData, post, processing, errors } = useForm({
    name: '',
    title: '',
  })

  const onClickSaveButtonHandler = () => {
    post(route(Routes.settings.permissions.role.add), {
      onSuccess() {
        closeModal()
      },
    })
  }

  return (
    <>
      <div className="modal__row">
        <label className="input">
          <input
            type="text"
            value={data.name}
            placeholder="Код должности"
            onChange={(e) => setData('name', e.target.value)}
          />
        </label>
      </div>
      <div className="modal__row">
        <label className="input">
          <input
            type="text"
            value={data.title}
            placeholder="Наименование должности"
            onChange={(e) => setData('title', e.target.value)}
          />
        </label>
      </div>
      <div className="modal__row">
        <button
          className="btn btn__filled btn--blue"
          onClick={onClickSaveButtonHandler}
        >
          Сохранить
        </button>
      </div>
    </>
  )
}
