import { DropdownFilter } from '$components/UI/DataGrid/Filters/Dropdown'
import { usePage } from '@inertiajs/react'

export const RationFilter = ({ field }) => {
  const { enums } = usePage().props

  return (
    <DropdownFilter
      field={field}
      variants={enums.rations || []}
      titleKey="name"
      emptyText="Рацион"
      needEmpty={false}
      multiple={true}
    />
  )
}
