import { verboseCurrency } from '$root/utils/functions'

export const FinanceDashboard = ({
  expense,
  profit,
  prevDifference,
  prevDifferencePercent,
}) => {
  return (
    <div className="reports-company__col reports-company__col--one-third">
      <div className="stats-color">
        <div className="stats-color__item stats-color__item--red">
          <p className="stats-color__value">{verboseCurrency(expense)}</p>
          <p className="stats-color__descr">Расход за 10 июля 2022</p>
        </div>
        <div className="stats-color__item stats-color__item--green">
          <p className="stats-color__value">{verboseCurrency(profit)}</p>
          <p className="stats-color__descr">Приход за 10 июля 2022</p>
        </div>
        <div className="stats-color__item  stats-color__item--orange">
          <p className="stats-color__value">
            {verboseCurrency(prevDifference)}
          </p>
          <p className="stats-color__descr">В сравнении с прошлым периодом</p>
        </div>
        <div className="stats-color__item  stats-color__item--blue">
          <p className="stats-color__value">
            {prevDifferencePercent > 0 && '+'}
            {prevDifferencePercent}%
          </p>
          <p className="stats-color__descr">В сравнении с прошлым периодом</p>
        </div>
      </div>
    </div>
  )
}
