import { createEvent, createStore } from 'effector'

export const resetAllOrdersDeliveryType = createEvent()
export const resetOrderDeliveryType = createEvent()
export const selectedDeliveryType = createEvent()

export const $persistOrderDeliveryTypes = createStore({})
  .reset(resetAllOrdersDeliveryType)
  .on(selectedDeliveryType, (data, { id, deliveryType }) => {
    return { ...data, [id]: deliveryType }
  })
  .on(resetOrderDeliveryType, (data, id) => {
    if (data[id]) {
      delete data[id]
    }
    return { ...data }
  })
