import React from 'react'
import { dataGridIsChanged } from '$models/dataGrid'

export const DataGridPagination = ({ data }) => {
  if (!data || (data && (!data.meta || !data.links))) return <></>

  const { meta: pagination, links } = data
  const { current_page, last_page } = pagination

  if (data.meta.last_page === 1) return null

  const prevButtonClickHandler = () => {
    if (links.prev) {
      // TODO: router.get(links.prev) учитывая фильтры и поиск
      dataGridIsChanged({ page: current_page - 1 })
    }
  }

  const nextButtonClickHandler = () => {
    if (links.next) {
      // TODO: router.get(links.next) учитывая фильтры и поиск
      dataGridIsChanged({ page: current_page + 1 })
    }
  }

  return (
    <div className="datagrid__pagination">
      <button
        className="btn btn__filled btn--blue"
        disabled={false}
        onClick={prevButtonClickHandler}
      >
        Назад
      </button>
      {current_page} / {last_page}
      <button
        className="btn btn__filled btn--blue"
        disabled={false}
        onClick={nextButtonClickHandler}
      >
        Вперед
      </button>
    </div>
  )
}
