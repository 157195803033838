import { DeliveryZonesLayer } from '$components/Deliveries/Maps/OSM/DeliveryZonesLayer'
import { OpenStreetMap } from '$components/UI/Maps/OSM/OpenStreetMap'
import { useDeliveryZones } from '$lib/deliveries/hooks/useDeliveryZones'
import { MapEmptyCoordinates } from '$root/utils/constants/map'
import { getCityCoordinates } from '$lib/cities/helpers'

export const Map = () => {
  const { city, zones, currentZoneCoordinates } = useDeliveryZones()

  if (!city) return <></>

  return (
    <OpenStreetMap
      center={
        currentZoneCoordinates ||
        getCityCoordinates(city) ||
        MapEmptyCoordinates
      }
      zoom={10}
      zoomControl={true}
      style={{
        height: '450px',
        width: '450px',
        zIndex: 0,
      }}
    >
      <DeliveryZonesLayer zones={zones} />
    </OpenStreetMap>
  )
}
