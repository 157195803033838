import clsx from 'clsx'
import { IMaskInput } from 'react-imask'
import { useRef } from 'react'
import { normalizePhone, uid } from '$root/utils/functions'

export const PhoneInput = ({
  disabled,
  classes = {},
  value,
  onChange,
  subtitle = null,
}) => {
  const ref = useRef()

  // useEffect(() => {
  //     // onCtrlV.watch((clipboard) => {
  //     //     console.log(cl)
  //     //     if (ref.current && ref.current.maskRef) {
  //     //         if (document.activeElement.dataset.id === ref.current.element.dataset.id) {
  //     //             ref.current.maskRef.unmaskedValue = normalizePhone(clipboard)
  //     //         }
  //     //     }
  //     // })
  // }, [])

  return (
    <>
      <div className={clsx('input', 'phone-input', classes)} ref={ref}>
        {subtitle && subtitle}
        <IMaskInput
          placeholder="Телефон"
          ref={ref}
          data-id={uid()}
          definitions={{
            '#': /[789]/,
            0: /[0-9]/,
          }}
          mask="+# ({9}00) 000-00-00"
          unmask={false} // true|false|'typed'
          // DO NOT USE onChange TO HANDLE CHANGES!
          // USE onAccept INSTEAD
          onAccept={(value, mask) => {
            value = value.replace(/^\+[78](.*)/i, '7$1')
            value = value.replace(/^\+9(.*)/i, '+7 (9$1')
            mask.updateValue(value)
            mask.unmaskedValue = normalizePhone(value)
            onChange && onChange(mask.value, mask)
          }}
          type="text"
          // placeholder={ placeholder }
          placeholderChar={'#'}
          value={value}
          disabled={disabled}
        />
      </div>
    </>
  )
}
