import React from 'react'
import { MainLayout } from '$components/UI/Layouts/MainLayout'
import { ReportsTabs } from '$components/Reports/Tabs/ReportsTabs'
import { ReportsFilters } from '$components/Reports/Filters/ReportsFilters'
import { Dashboard } from '$components/Reports/Company/Dashboard/Dashboard'
import { SalariesPanes } from '$components/Reports/Company/Salaries/SalariesPanes'
import { ReportPanes } from '$components/Reports/Company/Panes/ReportPanes'
import { SalaryPaneCode } from '$lib/reports/salaries-panes-const'

const demoExpense = 24500
const demoProfit = 68700
const demoPrevDifference = 5300
const demoPrevDifferencePercent = 3

const demoSalaries = {
  [SalaryPaneCode.Fabric]: {
    title: 30000,
    list: [
      {
        id: 1,
        department: 'Производство',
        salary: 1500,
        awards: 0,
        penalty: 2000,
        total: 3000,
      },
    ],
  },
  [SalaryPaneCode.Office]: {
    title: 100000,
    list: [
      {
        id: 1,
        department: 'Продажи',
        salary: 1500,
        awards: 0,
        penalty: 2000,
        total: 3000,
      },
    ],
  },
  [SalaryPaneCode.Management]: {
    title: 200000,
    list: [
      {
        id: 1,
        department: 'Зам. директора',
        salary: 1500,
        awards: 0,
        penalty: 2000,
        total: 3000,
      },
    ],
  },
  [SalaryPaneCode.Other]: {
    title: 15000,
    list: [
      {
        id: 1,
        department: 'Курьер',
        salary: 1500,
        awards: 0,
        penalty: 2000,
        total: 3000,
      },
    ],
  },
}

const demoPanelsAmounts = {
  advert: 1000,
  penalty: 1000,
  awards: 1000,
  sales: 1000,
}

export const ReportsCompany = ({
  expense,
  profit,
  prevDifference,
  prevDifferencePercent,
  salaries,
  amounts,
}) => {
  return (
    <>
      <ReportsTabs />
      <ReportsFilters />
      <div className="reports-company">
        <Dashboard
          expense={expense || demoExpense}
          profit={profit || demoProfit}
          prevDifference={prevDifference || demoPrevDifference}
          prevDifferencePercent={
            prevDifferencePercent || demoPrevDifferencePercent
          }
        />
        <SalariesPanes salaries={salaries || demoSalaries} />
        <ReportPanes amounts={amounts || demoPanelsAmounts} />
      </div>
    </>
  )
}

ReportsCompany.layout = (page) => <MainLayout>{page}</MainLayout>
