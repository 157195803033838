export const getDeliveryClientAddresses = (client) => {
  return (
    (client.addresses &&
      client.addresses.map((address) => ({
        id: address.id,
        address: address.address,
        point: address.point,
      }))) ||
    []
  )
}
