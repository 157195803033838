export const ContactType = {
  Address: 'address',
  Phone: 'phone',
  EMAIL: 'email',
  Telegram: 'telegram',
  WhatsApp: 'whatsapp',
  Viber: 'viber',
  Vk: 'vk',
  FACEBOOK: 'facebook',
  Instagram: 'instagram',
  TIKTOK: 'tiktok',
}

export const StatusColorClass = {
  active: 'good',
  prolong: 'orange',
  frozen: 'yellow',
  archive: 'gray3',
}
