import L from 'leaflet'
import { onSelectGeocodeResult } from '$lib/osm/models/map'

export const addGeoSearchControl = (map) => {
  if (map) {
    const searchControl = L.Control.geocoder({
      geocoder: L.Control.Geocoder.nominatim(),
      placeholder: 'Введите запрос для поиска...',
      errorMessage: 'Не найдено.',
      showResultIcons: true,
      defaultMarkGeocode: false,
    })

    searchControl.on('markgeocode', (ev) => {
      onSelectGeocodeResult(ev)
    })
    map.addControl(searchControl)

    return () => map.removeControl(searchControl)
  }
}
