import React from 'react'
import { dataGridIsChanged } from '$models/dataGrid'
import { DropdownBtn } from '$components/UI/Inputs/DropdownBtn/DropdownBtn'

export const UsersFilter = ({ filter, roles }) => {
  const filterName = 'roles.name'
  if (!roles) return null

  return (
    <DropdownBtn
      items={roles}
      emptyText="Все сотрудники"
      valueKey="name"
      titleKey="title"
      value={filter && filter[filterName] ? filter[filterName] : null}
      onChange={(role) => {
        dataGridIsChanged({
          filter: {
            [filterName]: (role && role.name) || undefined,
          },
        })
      }}
    />
  )
}
