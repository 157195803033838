import React from 'react'
import { MainLayout } from '$components/UI/Layouts/MainLayout'
import { ReportsTabs } from '$components/Reports/Tabs/ReportsTabs'
import { ReportsFilters } from '$components/Reports/Filters/ReportsFilters'
import { ReportsDeliveriesDataGrid } from '$components/Reports/DataGrids/Couriers/ReportsDeliveriesDataGrid'
import { Routes } from '$root/utils/constants/routes'
import { ReportsSubTabs } from '$components/Reports/DataGrids/Couriers/Tabs'

export const ReportsDeliveries = ({ list }) => {
  return (
    <>
      <ReportsTabs />
      <ReportsSubTabs
        tabs={[
          { title: 'Эффективность', route: Routes.reports.deliveries.page },
          { title: 'Финансы', route: Routes.reports.deliveries.finances.page },
        ]}
      />
      <ReportsFilters />
      <ReportsDeliveriesDataGrid
        data={list}
        route={Routes.reports.deliveries.page}
      >
        <ReportsDeliveriesDataGrid.Wrapper className="table table--last-td-bold">
          <ReportsDeliveriesDataGrid.Header />
          <ReportsDeliveriesDataGrid.Body />
          <ReportsDeliveriesDataGrid.Footer />
        </ReportsDeliveriesDataGrid.Wrapper>
      </ReportsDeliveriesDataGrid>
    </>
  )
}

ReportsDeliveries.layout = (page) => <MainLayout>{page}</MainLayout>
