import { Modal } from '$components/UI/Modals/Modal'
import { useModals } from '$hooks/useModals'
import { EditDiscountInfo } from '$components/Clients/Orders/OrdersByStatus/Fields/EditDiscountInfo'

export const AmountField = ({ order, allowEdit }) => {
  const { openModal } = useModals()

  const showEditDiscountInfoModal = () => {
    openModal('OrderDiscountOptions', Modal, {
      title: 'Скидка',
      contentComponent: EditDiscountInfo,
      order,
      disabled: !allowEdit,
    })
  }
  return (
    <>
      <span className="table-plan__sum">
        <span className="table-plan__sum-money">
          {(order.discount_price && order.discount_price) || order.price || '-'}{' '}
          ₽
        </span>
      </span>
      <button
        className="table-plan__discount"
        onClick={showEditDiscountInfoModal}
      >
        <span>
          {(order.discount && order.discount.amount && order.discount.amount) ||
            'Скидки нет'}
        </span>
        {/* TODO: функция получения символа валюты или константа как вместо Enum */}
        <span>
          {(order.discount &&
            order.discount.currency &&
            ((order.discount.currency === 'ruble' && ' ₽') || ' %')) ||
            ''}
        </span>
      </button>
    </>
  )
}
