import React, { useContext, useMemo } from 'react'
import { useDateTime } from '$hooks/useDateTime'
import { ClientContactsContext } from '$components/Clients/Fields/Contacts/ClientContacts'

export const BirthdayInfo = () => {
  const { client } = useContext(ClientContactsContext)
  const { dateVerbosed } = useDateTime()

  const formattedBirthday = useMemo(() => {
    if (client) {
      return (
        (client.birthday && dateVerbosed(new Date(client.birthday))) || null
      )
    }
  }, [client])

  return (
    (formattedBirthday && (
      <tr>
        <td>Родился</td>
        <td>{formattedBirthday}</td>
      </tr>
    )) || <></>
  )
}
