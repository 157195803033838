import React from 'react'

// TODO: вывод окна ошибок при существующем поле errors в pageProps
export const ErrorLayout = ({ children }) => {
  // const { loading, showLoader, hideLoader } = useLoader()
  //
  // useEffect(() => {
  //     router.on('start', showLoader)
  //     router.on('finish', hideLoader)
  // }, [])

  return (
    <>
      <div className="wrapper error-page">
        <div className="content">
          <main className="main">{children}</main>
        </div>
      </div>
      {/*{ loading && <Loader/> }*/}
    </>
  )
}
