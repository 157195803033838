import { createEffect, createEvent, createStore, sample } from 'effector'

export const onPostSended = createEvent()
export const onDeleteSended = createEvent()

export const onChangeRoute = createEvent()

export const onChangePage = createEvent()
export const setCurrentPage = createEvent()

const setPageCleaning = createEvent()
export const onCtrlV = createEvent()

const checkCleansFx = createEffect(({ cleanings, page }) => {
  if (!cleanings.hasOwnProperty(page)) {
    onChangePage({
      page,
      callback: () => {},
    })
  }

  if (page) {
    for (const pageName of _.keys(cleanings)) {
      const cleaningCallbacks = cleanings[pageName]

      if (pageName === page) {
        continue
      }
      for (const callbackInfo of cleaningCallbacks) {
        callbackInfo.callback()
      }
      delete cleanings[pageName]
    }
  }

  for (const cleaningPage of _.keys(cleanings)) {
    onChangePage({ page, callback: cleanings[cleaningPage].callback })
  }
  setPageCleaning({ ...cleanings })
})

export const $currentPage = createStore(null).on(
  setCurrentPage,
  (state, page) => page,
)

export const $pageCleanings = createStore({})
  .on(setPageCleaning, (state, cleanings) => cleanings)
  .on(onChangePage, (state, { page, callback }) => {
    let newCleanings = { ...state }
    if (!newCleanings[page]) {
      newCleanings[page] = []
    }
    newCleanings[page].push({ callback })
    return newCleanings
  })

sample({
  clock: $currentPage,
  source: $pageCleanings,
  fn: (cleanings, page) => ({ cleanings, page }),
  target: checkCleansFx,
})
