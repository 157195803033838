import { DatePickerInput } from '$components/UI/Inputs/DatePickerInput'
import { useOrdersTable } from '$lib/orders/hooks/useOrdersTable'
import { useDateTime } from '$hooks/useDateTime'
import { OrderStatus } from '$lib/orders/utils/order-status-const'
import { plural } from '$root/utils/functions'
import { usePersistOrderDates } from '$lib/clients/hooks'

export const DateField = ({ order, allowEdit, status, ...other }) => {
  if (status !== OrderStatus.Inactive) {
    return <DefaultDatePickerInput order={order} allowEdit={allowEdit} />
  }
  return (
    <InactiveDatePickerInput order={order} allowEdit={allowEdit} {...other} />
  )
}

const DefaultDatePickerInput = ({ order, allowEdit }) => {
  const { dateToSaveFormat } = useDateTime()
  const { updateOrder } = useOrdersTable()

  return (
    <>
      <DatePickerInput
        value={order.delivery_date && new Date(order.delivery_date)}
        onChange={(selectedDate) => {
          updateOrder({
            order,
            updatingOrders: {
              ...order,
              delivery_date: dateToSaveFormat(new Date(selectedDate)),
            },
          })
        }}
        showPickerTag={false}
        disabled={!allowEdit}
      />
    </>
  )
}

const InactiveDatePickerInput = ({ order, allowEdit }) => {
  const { inactiveOrdersDates, newSelectedDate, deleteSelectedDate } =
    usePersistOrderDates()

  const highlightDates = [...(inactiveOrdersDates[order.id] || [])]

  return (
    <>
      <DatePickerInput
        popperClassName={'inactive-order-dates, highlighted_dates'}
        highlightDates={highlightDates}
        onSelect={(selectedDate) => {
          const searchFn = (item) => item.toString() === selectedDate.toString()
          const issetDate = highlightDates.findIndex(searchFn)

          if (issetDate >= 0) {
            deleteSelectedDate({ id: order.id, date: selectedDate })
          } else {
            newSelectedDate({ id: order.id, date: selectedDate })
          }
        }}
        disabled={!allowEdit}
        shouldCloseOnSelect={false}
        label={
          (highlightDates.length > 0 &&
            `${highlightDates.length} ${plural(highlightDates.length, 'дата', 'даты', 'дат')}`) ||
          ''
        }
        onChange={undefined}
      />
    </>
  )
}
