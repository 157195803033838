import icon from '~svg/icons/icon-lg-advertising.svg'

export const AdvertPane = ({ amount }) => {
  return (
    <div className="color-panel color-panel--blue">
      <img
        className="color-panel__icon"
        src={icon}
        alt="Реклама"
        title="Реклама"
      />
      <p className="color-panel__title">Реклама</p>
      <p className="color-panel__descr">
        Ведение рекламы в социальных сетях и мессенджерах
      </p>
      <p className="color-panel__value">{amount || '-'}</p>
    </div>
  )
}
