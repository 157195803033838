import { TitleCouriers } from '$components/Deliveries/DistributionDeliveries/DataGrid/TitleCouriers'
import { useContext } from 'react'
import { DistributionDeliveriesContext } from '$components/Deliveries/DistributionDeliveries/context'

export const Title = () => {
  const { distributionService } = useContext(DistributionDeliveriesContext)

  const couriers = distributionService.couriersStore

  return (
    <>
      {distributionService.zoneSelected && (
        <p className="delivery-table__title">
          {distributionService.zoneStore.properties.name}
        </p>
      )}
      <div className="delivery-table__head">
        <div className="delivery-table__couriers">
          <TitleCouriers couriers={couriers} />
        </div>
        {couriers.length > 0 && (
          <button
            className="delivery-table__btn-sequence"
            onClick={() => distributionService.sendChecked()}
          >
            Сформировать очередь
          </button>
        )}
      </div>
    </>
  )
}
