import { OrderStatus } from '$lib/orders/utils/order-status-const'
import { MessageModal } from '$components/UI/Modals/MessageModal'

export const DefaultOrder = {
  status: null,
  delivery_date: null,
  address: null,
  rations: [],
  time: null,
  delivery_time: null,
  delivery_type: null,
  comment: null,
  discount: null,
  price: null,
}

export const groupingOrdersByStatus = (deliveries) => {
  return _.groupBy(deliveries, (delivery) => delivery.status)
}

export const sortOrdersByDate = (deliveries) => {
  return _.sortBy(deliveries, (delivery) => delivery.delivery_date)
}

export const hasDoneOrders = (deliveries) => {
  return (
    _.filter(
      deliveries,
      (delivery) => !!delivery.id && delivery.status === OrderStatus.Done,
    ).length > 0
  )
}

export const doneOrderNotChangeableErrorMessage = (messages) => {
  return [
    'DeliveryDiscountChangeError',
    MessageModal,
    {
      title: 'Ошибка',
      messages,
    },
  ]
}
