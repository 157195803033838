import { OrdersFields } from '$components/Clients/Orders/OrdersByStatus/Fields'

export const InactiveOrderItem = ({ order, tableStatus, ...other }) => {
  return (
    <tr className="table-plan--row-inactive">
      <OrdersFields
        status={order.status}
        tableStatus={tableStatus}
        order={order}
        {...other}
      />
    </tr>
  )
}
