import { TextArea } from '$components/UI/Inputs/TextArea'
import React, { useCallback, useEffect } from 'react'
import { onPostSended } from '$models/page'
import { Routes } from '$root/utils/constants/routes'
import { useNewFormValidation } from '$hooks/useFormValidation'
import { useForm } from '@inertiajs/react'
import { OrderStatus } from '$lib/orders/utils/order-status-const'

const OrderProblem = ({ id: modalId, order, onSave, closeModal }) => {
  const form = useForm(order)

  const validateFields = useCallback(() => {
    const errors = {}

    if (!form.data.status_comment) {
      errors.status_comment = 'Комментарий не заполнен'
    }

    return errors
  }, [form.data.status_comment])

  const [validate, markErrors] = useNewFormValidation({
    persistKey: modalId,
    onCheckIsValid: validateFields,
    form,
  })

  useEffect(() => {
    form.clearErrors()

    return () => onPostSended.watch(() => closeModal())
  }, [])

  const saveGiven = () => {
    if (validate()) {
      onSave({
        id: form.data.id,
        status_comment: form.data.status_comment,
        status: OrderStatus.Problem,
      })
    }
  }

  return (
    <>
      <TextArea
        value={form.data.status_comment}
        onChange={(status_comment) =>
          form.setData('status_comment', status_comment)
        }
        error={markErrors && form.errors.status_comment}
        placeholder="Комментарий"
      />
      <button className="btn btn__filled btn--blue" onClick={saveGiven}>
        Сохранить
      </button>
    </>
  )
}

export default OrderProblem
