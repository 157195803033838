import { EffectorDomainLogic } from '$root/utils/effector/domain-logic'
import { useModals } from '$hooks/useModals'
import { Modal } from '$components/UI/Modals/Modal'
import { ImportClients } from '$components/Settings/Leads/ClientsTab/ImportClients/ImportClients'

export class SettingsClientsEffector extends EffectorDomainLogic {
  events = {}

  constructor({}) {
    super()
  }

  identify() {
    return `settings-clients`
  }

  _initStores() {}

  init() {
    this._initStores()
  }

  useStores() {
    // this.zoneStore = useStore(this.$zoneStore)
  }

  useContainer(deliveries) {
    this.modals = useModals()
  }

  import() {
    this.modals.openModal('ImportFileClients', Modal, {
      title: 'Импорт клиентов',
      contentComponent: ImportClients,
      classes: { wrapper: 'modal--no-overflow' },
    })
  }
}
